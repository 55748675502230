import DialogTitle from "@/components/DialogTitle";
import { AccountTypeEnum } from "@/redux/accounts/accountsTypes";
import { downloadStatementFile } from "@/redux/statements/statementsSlice";
import { ExportType, StatementExportPayload } from "@/redux/statements/statementsTypes";
import { Button, Dialog, DialogContent, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Loader from "./Loader";
import LoaderCircular from "./LoaderCircular";

interface Props {
  open: boolean;
  onClose: () => void;
  userId: number;
  accountId: number;
  type: AccountTypeEnum;
  fileName?: string;
  ccy?: string;
}

const getStartOfDay = (date: Date) => {
  try {
    const utcDate = Date.UTC(date.getUTCFullYear(), (date.getUTCMonth()), date.getUTCDate());
    return utcDate;
  }
  catch (e) {
    return undefined;
  }
};

const getEndOfDay = (date: Date) => {
  try {
    const utcDate = Date.UTC(date.getUTCFullYear(), (date.getUTCMonth()), date.getUTCDate(), 23, 59, 59);
    return utcDate;
  }
  catch (e) {
    return undefined;
  }
};



const ExportStatementDialog = ({
  open,
  onClose,
  userId,
  accountId,
  type,
  fileName,
  ccy
}: Props) => {
  const { t } = useTranslation();
  const [exportType, setExportType] = useState<ExportType>(type === AccountTypeEnum.VAULT ? ExportType.EXCEL : ExportType.PDF);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const from = getStartOfDay(startDate);
  const to = getEndOfDay(endDate);

  const getLast60Month = () => {
    const d = new Date();
    d.setMonth(d.getMonth() - 60);
    return new Date(d.getFullYear(), d.getMonth(), 1);
  };
  const searchPayload: StatementExportPayload = {
    userId,
    type: type,
    accountId: accountId ? `${accountId}` : undefined,
    from: from?.toString() || '',
    to: to?.toString() || '',
    search: '',
  };

  const handleTypeChange = (event: React.MouseEvent<HTMLElement>, value: ExportType,) => {
    if (value) {
      setExportType(value);
    }
  };

  const handleDownloadStatements = async () => {
    if (!from || !to) return;

    setLoading(true);
    const payload = searchPayload;

    if (searchPayload.type === AccountTypeEnum.WALLET) {
      payload.ccy = ccy;
    }

    if (
      exportType === ExportType.EXCEL && (
        searchPayload.type === AccountTypeEnum.CARD
        || searchPayload.type === AccountTypeEnum.BANK
        || searchPayload.type === AccountTypeEnum.BANK_AND_CARD
        || searchPayload.type === AccountTypeEnum.TOKEN)
    ) {
      payload.templateIdentifier = 'transactions.sheet.template.extended';
    }

    try {
      await downloadStatementFile(exportType, payload, fileName ?? 'statements');
    }
    catch (e) {
      console.log(e);
      setLoading(false);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog
        id="export-statements"
        open={open}
        fullWidth
        onClose={() => onClose()}
        PaperProps={{
          sx: {
            minHeight: "300px",
            maxHeight: "300px",
          },
        }}
      >
        <DialogTitle onClose={() => onClose()}>
          {t('form.fields.exportStatements')}
        </DialogTitle>
        <DialogContent>
          {loading ? <>
            <Loader />
            <LoaderCircular />
          </>
            : <Grid container>
              <Grid item xs={12} py={2} >
                <ToggleButtonGroup
                  className='type-btn-group'
                  value={exportType}
                  exclusive
                  fullWidth
                  onChange={handleTypeChange}
                >
                  {type !== AccountTypeEnum.VAULT && <ToggleButton className='type-btn' value={ExportType.PDF}>PDF</ToggleButton>}
                  <ToggleButton className='type-btn' value={ExportType.EXCEL}>Excel</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} pb={4}>
                <Grid item container className='month-selection' spacing={2}>
                  <Grid item xs={12} sm={6} >
                    <DatePicker
                      disableFuture={true}
                      label="Starting on"
                      views={['year', 'month', 'day']}
                      format={'dd-MMM-yyyy'}
                      minDate={getLast60Month()}
                      maxDate={endDate ?? new Date()}
                      value={startDate}
                      sx={{
                        width: '100%'
                      }}
                      onChange={(date: any) => {
                        setStartDate(date);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      disableFuture={true}
                      label="End on"
                      views={['year', 'month', 'day']}
                      format={'dd-MMM-yyyy'}
                      minDate={startDate ?? getLast60Month()}
                      maxDate={new Date()}
                      value={endDate}
                      sx={{
                        width: '100%'
                      }}
                      onChange={(date: any) => {
                        setEndDate(date);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} alignSelf='flex-end'>
                <Button
                  className='month-btn'
                  type="submit"
                  variant="contained"
                  color='primary'
                  fullWidth
                  onClick={handleDownloadStatements}
                >
                  {t('form.buttons.export', { type: exportType })}
                </Button>
              </Grid>
            </Grid>}
        </DialogContent>
      </Dialog>
    </LocalizationProvider>
  );
};

export default ExportStatementDialog;
