import DetailCard from '@/components/cards/DetailCard';
import EditChangePencil from '@/icons/EditChangePencil';
import { Grid, GridProps, IconButton, Typography } from '@mui/material';
import { isValidElement } from 'react';


export interface DetailInfoProps { field: any, value: any | undefined, xs?: number, editable?: boolean, maxWidth?: string, onEditClick?: () => void };

interface Props {
    details: Array<DetailInfoProps>,
    firstColProps?: GridProps,
    secondColProps?: GridProps,
    light?: boolean
};

const DetailInfo = ({ details, firstColProps, secondColProps, light }: Props) => {

    return (
        <DetailCard
            light={light}
            sx={{
                height: '100%'
            }}>
            <Grid container columnSpacing={2} rowSpacing={1.5} justifyContent='space-between'>
                {details.map((p, idx) => {
                    return (
                        <Grid key={idx} container item xs={12}>
                            <Grid container {...firstColProps} alignItems='center'>
                                <Grid item xs={5} alignItems='center'>
                                    {isValidElement(p.field) ? p.field :
                                        <Typography variant="body2" color='text.secondary'>{p.field}</Typography>
                                    }
                                </Grid>
                                <Grid item xs={7} container  {...secondColProps} alignItems='center'>
                                    {isValidElement(p?.value) ? p.value ?? '-' :
                                        <Typography variant='body2' color={p?.value === 'N/A' ? 'textSecondary' : 'primary'}>{p?.value ?? '-'}</Typography>
                                    }
                                    {
                                        p.editable && p.onEditClick &&
                                        <IconButton size="small" aria-label="close" sx={{ ml: '2px' }} onClick={p.onEditClick}>
                                            <EditChangePencil sx={{ fontSize: '0.813rem' }} strokeWidth={2.5} />
                                        </IconButton>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </DetailCard >);
};

export default DetailInfo;