import { useEffect } from 'react';
import Routes from '@/routes/Routes';
import NavigationScroll from '@/components/NavigationScroll';
import ThemeCustomization from 'themes';
import * as countries from 'i18n-iso-countries';
import { AuthProvider } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import Interceptors from './components/Interceptors';
import './App.css';
import Snackbar from './components/Snackbar';
import TwoFAWeb from './components/TwoFAWeb';
import { useSelector } from './store/store';

const App = () => {

    const { ready } = useTranslation('translations');
    const { isEmi } = useSelector((state) => state.credentials);

    useEffect(() => {
        countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
    }, []);

    if (!ready) return <Loader />;

    return (
        <ThemeCustomization>
            <Interceptors />
            <TwoFAWeb />
            <NavigationScroll>
                <AuthProvider>
                    <>
                        <Routes isEmi={isEmi} />
                        <Snackbar />
                    </>
                </AuthProvider>
            </NavigationScroll>
        </ThemeCustomization>
    );
};

export default App;
