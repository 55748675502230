import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { Activity, PendingActivitiesEnum, PendingActivitiesStore } from './pendingActivitiesTypes';

export const paginationParamsInit = {
    filter: '',
    orderBy: 'createdDate',
    sort: SortDirectionEnum.DESC,
    take: 15,
    skip: 0
};

const initialState: PendingActivitiesStore = {
    [PendingActivitiesEnum.USER]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit,
        storedUserId: null,
    },
    [PendingActivitiesEnum.USER_CARD]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit,
        storedUserId: null,
        storedAccountId: null
    }
};


const slice = createSlice({
    name: 'pendingActivities',
    initialState,
    reducers: {
        reset: (state, action: PayloadAction<PendingActivitiesEnum>) => {
            const type = action.payload;
            state[type] = {
                loading: false,
                list: [],
                error: null,
                count: 0,
                pagination: paginationParamsInit,
                storedUserId: null,
                ...(type === PendingActivitiesEnum.USER_CARD && { storedAccountId: null })
            };
        },
        setActivities(state, action: PayloadAction<{ type: PendingActivitiesEnum, list: Array<Activity> }>) {
            state[action.payload.type].list = action.payload.list;
        },
        setLoading: (state, action: PayloadAction<{ type: PendingActivitiesEnum, loading: boolean }>) => {
            state[action.payload.type].loading = action.payload.loading
        },
        setError: (state, action: PayloadAction<{ type: PendingActivitiesEnum, error: string }>) => {
            state[action.payload.type].error = action.payload.error;
        },
        setCount: (state, action: PayloadAction<{ type: PendingActivitiesEnum, count: number }>) => {
            state[action.payload.type].count = action.payload.count;
        },
        setPagination: (state, action: PayloadAction<{ type: PendingActivitiesEnum, pagination: PaginateParams }>) => {
            state[action.payload.type].pagination = action.payload.pagination;
        },
        setStoredData: (state, action: PayloadAction<{ type: PendingActivitiesEnum, userId: number, accountId?: number }>) => {
            state[action.payload.type].storedUserId = action.payload.userId;
            if (action.payload.accountId) state[action.payload.type].storedAccountId = action.payload.accountId;
        },
    }
});

export const { setLoading, setError, setCount, setPagination, setActivities, reset, setStoredData } = slice.actions;

export const getPendingActivitiesByUser = (type: PendingActivitiesEnum, userId: number, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ type, loading: true }));
            const response = await request.get(`/api/console/pending-activities/by-user/${userId}/list`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take,
                }
            });
            const { data } = response;
            dispatch(setActivities({ type, list: data.list }));
            dispatch(setCount({ type, count: data.count }));
            dispatch(setPagination({ type, pagination: payload }));
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLoading({ type, loading: false }))
        }
    };
};

export const getPendingActivitiesByAccount = (type: PendingActivitiesEnum, userId: number, accountId: number, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ type, loading: true }));
            const response = await request.get(`/api/console/pending-activities/by-account/${userId}/list/${accountId}`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take,
                }
            });
            const { data } = response;
            dispatch(setActivities({ type, list: data.list }));
            dispatch(setCount({ type, count: data.count }));
            dispatch(setPagination({ type, pagination: payload }));
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLoading({ type, loading: false }))
        }
    };
};

export const restartPendingActivitiesByUser = async (userId: number) => {
    const response = await request.post(`/api/console/pending-activities/restart/by-user/${userId}`);
    return response?.data;
};

export const restartPendingActivitiesByAccount = async (accountId: number) => {
    const response = await request.post(`/api/console/pending-activities/restart/by-account/${accountId}`);
    return response?.data;
};

export default slice.reducer;
