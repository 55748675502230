import { EmiSection, EmiSectionType, Permission } from "@/redux/user/types";


export const checkPermission = (permissions: Array<Permission> | null, permission: string): boolean => {
	if (permissions?.find((v) => v.permission === permission)) {
		return true;
	} else {
		return false;
	}
};

export const checkAtLeastOnePermission = (permissions: Array<Permission> | null, requiredPermissions: Array<string>): boolean => {
	if (!permissions) {
		return false;
	}

	return requiredPermissions.some(permission =>
		permissions.some(userPermission => userPermission.permission === permission)
	);
};

export const checkWritePermission = (permissions: Array<Permission> | null, permission: string): boolean => {
	if (permissions && permissions.find((v) => v.permission === permission && v.writePermission === true)) {
		return true;
	} else {
		return false;
	}
};

export const checkAtLeastOneWritePermission = (permissions: Array<Permission> | null, requiredPermissions: Array<string>): boolean => {
	if (!permissions) {
		return false;
	}

	return requiredPermissions.some(permission =>
		permissions.some(userPermission => userPermission.permission === permission && userPermission.writePermission === true)
	);
};

export const checkSectionEnabled = (sections: Array<EmiSectionType>, sectionToCheck: EmiSection): boolean => {
	return sections.some(section => section.lookupCode === sectionToCheck && section.enabled);
};

export const checkSectionTabEnabled = (sections: Array<EmiSectionType>, sectionToCheck: EmiSection, tabToCheck: string): boolean => {
	const section = sections.find(section => section.lookupCode === sectionToCheck && section.enabled);
	if (!section) return true;
	if (!section.tabs || section.tabs.trim() === '') return true;
	return !section.tabs.split(';').includes(tabToCheck);
};

export const checkSectionSubTabEnabled = (sections: Array<EmiSectionType>, sectionToCheck: EmiSection, tabToCheck: string, subTabToCheck: string): boolean => {
	const section = sections.find(section => section.lookupCode === sectionToCheck && section.enabled);
	if (!section) return true;
	if (!section.subTabs || !section.subTabs[tabToCheck]) return true;
	return !section.subTabs[tabToCheck].split(';').includes(subTabToCheck);
};