import * as React from "react";
import { SvgIcon } from '@mui/material';

const Users = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="10.0013" cy="5.0013" r="3.33333" stroke="currentColor" strokeWidth="1.2" />
            <path opacity="0.5" d="M15 7.4987C16.3807 7.4987 17.5 6.56596 17.5 5.41536C17.5 4.26477 16.3807 3.33203 15 3.33203" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path opacity="0.5" d="M5 7.4987C3.61929 7.4987 2.5 6.56596 2.5 5.41536C2.5 4.26477 3.61929 3.33203 5 3.33203" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <ellipse cx="10" cy="14.1654" rx="5" ry="3.33333" stroke="currentColor" strokeWidth="1.2" />
            <path opacity="0.5" d="M16.668 15.8346C18.1298 15.5141 19.168 14.7022 19.168 13.7513C19.168 12.8004 18.1298 11.9885 16.668 11.668" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path opacity="0.5" d="M3.33203 15.8346C1.87016 15.5141 0.832031 14.7022 0.832031 13.7513C0.832031 12.8004 1.87016 11.9885 3.33203 11.668" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default Users;