import * as React from "react";
import { SvgIcon } from '@mui/material';
const LightBulbSolid = (props: any) => (
    <SvgIcon {...props}>
        <svg
            width={26}
            height={26}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.5 17.5H12.5H7.5Z" fill="currentColor" />
            <path d="M7.5 17.5H12.5M10 2.5C7.23858 2.5 5 4.73858 5 7.5C5 8.51208 5.3007 9.45393 5.81764 10.2411C6.61256 11.4515 7.00985 12.0567 7.06144 12.1471C7.52041 12.9512 7.43573 12.6674 7.49349 13.5915C7.49998 13.6954 7.5 13.8526 7.5 14.1669C7.5 14.6271 7.8731 15.0002 8.33333 15.0002L11.6667 15.0002C12.1269 15.0002 12.5 14.6271 12.5 14.1669C12.5 13.8526 12.5 13.6954 12.5065 13.5915C12.5643 12.6674 12.4793 12.9512 12.9382 12.1471C12.9898 12.0568 13.3876 11.4515 14.1825 10.2411C14.6995 9.45393 15.0002 8.51208 15.0002 7.5C15.0002 4.73858 12.7614 2.5 10 2.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.6667 15H8.33333L7.08333 12.0833L5 8.33333L5.83333 5L8.75 2.5H11.6667L14.1667 5L15 8.33333L12.9167 12.0833L11.6667 15Z" fill="currentColor" />
        </svg>
    </SvgIcon>
);
export default LightBulbSolid;
