import { Box, Divider, Grid, Typography } from "@mui/material";

const FormDivider = ({ text }: { text: string }) => {
    return (
        <Grid container alignItems="center">
            <Grid item xs>
                <Divider />
            </Grid>
            <Grid item>
                <Box display="flex" justifyContent="center" px={2}>
                    <Typography variant="h4" sx={{ textTransform: 'capitalize', fontWeight: 500, fontSize: '15px' }}>{text}</Typography>
                </Box>
            </Grid>
            <Grid item xs>
                <Divider />
            </Grid>
        </Grid>
    )
};

export default FormDivider;