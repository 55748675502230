import { Grid, Typography } from "@mui/material";
import CurrencyLogo from "../CurrencyLogo";
import { NumericFormat } from "react-number-format";
import { getCurrencySign } from "@/utils/currency";
import { useTranslation } from "react-i18next";

interface Props {
    balance: number,
    currency: string,
    logoSource?: string,
    name?: string,
    decimalScale?: number,
}

const AccountItem = ({ currency, logoSource, name, balance, decimalScale }: Props) => {
    const { t } = useTranslation();

    return (
        <Grid container flexDirection='column' flex={1} >
            <Grid item container>
                <Grid item xs='auto'>
                    <CurrencyLogo logoUrl={logoSource} />
                </Grid>
                <Grid item xs='auto' container pl={1} >
                    <Grid item xs={12}>
                        <Typography variant='body2' fontWeight={700}> {name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='caption' color='text.secondary' >
                            <NumericFormat
                                displayType={'text'}
                                fixedDecimalScale={true}
                                thousandsGroupStyle='thousand'
                                thousandSeparator={true}
                                decimalScale={decimalScale ? decimalScale : 2}
                                value={balance}
                                prefix={`${t('form.fields.available').toString()} `}
                                suffix={` ${getCurrencySign(currency)}`} />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AccountItem;
