import { PaginateParams } from '@/types/pagination';
import { UserType } from '../user/types';
import { FilterOptionType } from '@/components/dataGrid/CustomFilterHeader';

export interface DocumentTypeWBH {
    documentId: number;
    documentNumber: string;
    wthType: string;
    transactionId: number;
    transactionNumber: string;
    wlpId: string;
    date: string;
    transactionDate: string;
    status: string;
    type: string;
    paymentType: string;
    integration: string;
    accountIntegration: string;
    documentProc: string;
    transactionProc: string;
    userId: number;
    clientId: string;
    email: string;
    firstName: string;
    lastName: string;
    userProfileType: UserType;
    companyName: string;
    amount: number;
    amountString: string;
    currency: string;
    displayDecimals: number;
    accountId: number;
    account: string;
    accountType: string;
    approvalType: string;
    toAmount: number;
    toAmountString: string;
    toAmountCurrency: string;
    toAmountDisplayDecimals: number;
    toAccountId: number;
    toAccount: string;
    feeShouldBeShown: boolean;
    feeAmount: number;
    feeAmountString: string;
    feeAmountCurrency: string;
    feeAmountDisplayDecimals: number;
    flagged: boolean;
    flaggedBy: number;
    flaggedByFirstName: string;
    flaggedByLastName: string;
    flaggedReason: string;
    complianceOfficerId: number;
    complianceOfficerFirstName: string;
    complianceOfficerLastName: string;
    allowedToApprove: boolean;
    documentApprovals: Array<DocumentApproval>;
    attachments: Map<string, string>,
    documentCheckServiceProvider: string;
};

export interface DocumentApproval {
    userId: number;
    user: string;
    firstName: string;
    lastName: string;
    date: number;
    status: string;
};

export interface DocumentTransactionType {
    referenceCode: string,
    walletId: string,
    sysAcctType?: string,
    amount: number,
    rate: number,
    amountSystemCurrency: number,
    asset: string,
    type: string,
    date: number,
    paymentDetails?: string
}

export interface TransfersStore {
    [key: string]: {
        loading: boolean,
        list: Array<DocumentTypeWBH>,
        error: string | null,
        count: number,
        pagination: PaginateParams,
        filters: {
            [key: string]: Array<FilterOptionType>
        },
        filtersSet: boolean;
    }
}

export interface DocumentApproveRejectPaylod {
    docId: number,
    docUserId: number,
    docNumber: string,
    documentWlp: string,
    reason: string,
    options?: any,
    files?: any
};

export enum TrasferStoreKeyEnum {
    ALL = "ALL",
    BANK = 'BANK',
    WALLET = 'WALLET',
    TRUST = 'TRUST',
    TOKEN = 'TOKEN',
    CRYPTO_TRANSFER = 'CRYPTO_TRANSFER',
    CRYPTO_TRADE = 'CRYPTO_TRADE',
    FLAGGED = 'FLAGGED',
    USER_FLAGGED = 'USER_FLAGGED',
    TOP_UP_BY_CARD = 'TOP_UP_BY_CARD',
};

export enum DocumentTypeEnum {
    CARD_ORDER = "CARD_ORDER",
    CARD_TRANSACTION = "CARD_TRANSACTION",
    DEACTIVATION = "DEACTIVATION",
    FX_ORDER = "FX_ORDER",
    INCOMING_PAYMENT = "INCOMING_PAYMENT",
    MANUAL_ADJUSTMENT = "MANUAL_ADJUSTMENT",
    MONTH_FEE_CALC = "MONTH_FEE_CALC",
    PAYMENT_ORDER = "PAYMENT_ORDER",
    TOKEN_TRADE = "TOKEN_TRADE",
    TOPUP_FROM_CARD = "TOPUP_FROM_CARD",
    TRANSFER_ORDER = "TRANSFER_ORDER",
    USER_LEVEL_SUBSCRIPTION = "USER_LEVEL_SUBSCRIPTION",
    CARD_BLOCK_AND_PULLOUT = "CARD_BLOCK_AND_PULLOUT",
    CARD_PULLOUT = 'CARD_PULLOUT',
    CARD_TOPUP = 'CARD_TOPUP'
};

export enum CryptoTradesEnum {
    CRYPTO_TRADE = "CRYPTO_TRADE",
};

export enum CardTransactionsEnum {
    CARD_WITHDRAW = 'CARD_WITHDRAW',
    CARD_PURCHASE = 'CARD_PURCHASE',
};

export enum TokensFundsInOutEnum {
    INCOMING_PAYMENT_TOKENS = 'INCOMING_PAYMENT_TOKENS',
    PAYMENT_ORDER_TOKENS = 'PAYMENT_ORDER_TOKENS'
};

export enum TokenTransfersEnum {
    TRANSFER = 'TRANSFER',
    PAYMENT = 'PAYMENT'
};

export enum SystemTransfersEnum {
    SYSTEM_DEPOSIT_FEE = "SYSTEM_DEPOSIT_FEE",
    SYSTEM_TRANSFER = "SYSTEM_TRANSFER",
};

export enum DocumentStatusEnum {
    COMPLETED = 'COMPLETED',
    NEW = 'NEW',
    PENDING = 'PENDING',
    PENDING_EXTERNALLY = 'PENDING_EXTERNALLY',
    PENDING_FX = 'PENDING_FX',
    APPROVAL = 'APPROVAL',
    LEGAL_APPROVAL = 'LEGAL_APPROVAL',
    LEGAL_APPROVED = 'LEGAL_APPROVED',
    APPROVED = 'APPROVED',
    APPROVED_BY_RISK_MANAGER = 'APPROVED_BY_RISK_MANAGER',
    APPROVED_BY_COMPLIANCE_MANAGER = 'APPROVED_BY_COMPLIANCE_MANAGER',
    REJECTED = 'REJECTED',
    CANCELED = 'CANCELED',
    ERROR = 'ERROR',
    FAILED = 'FAILED',
    REVERTED = 'REVERTED'
};

export enum TokenDocumentStatusEnum {
    DEPOSIT = "DEPOSIT",
    WITHDRAW = "WITHDRAW"
};