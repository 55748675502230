import * as React from "react";
import { SvgIcon } from '@mui/material';

const Copy = (props: any) => (
  <SvgIcon {...props}>
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 8V6a4 4 0 0 1 4-4h6a4 4 0 0 1 4 4v6a4 4 0 0 1-4 4h-2M8 8H6a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4v-2M8 8h4a4 4 0 0 1 4 4v4"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
export default Copy;
