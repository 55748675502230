import { createRoot } from 'react-dom/client'; 
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './i18n'; 
import App from 'App';
import { BASE_PATH } from 'config';
import { store } from '@/store/store';
import * as serviceWorker from 'serviceWorker';
import reportWebVitals from 'reportWebVitals';
import { ConfigProvider } from 'contexts/ConfigContext'; 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css'; 

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
/*     <React.StrictMode> removed as causes side effects, like interceptor beeing called twice for rejection */
        <Provider store={store}>
            <ConfigProvider>
                <BrowserRouter basename={BASE_PATH}>
                    <App />
                    <ToastContainer
                        position="bottom-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        closeOnClick={true}
                        pauseOnHover={true}
                        draggable={false}
                        theme='light'
                    />
                </BrowserRouter>
            </ConfigProvider>
        </Provider>
  /*   </React.StrictMode> */
    // document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
