export const addFiles = (formData: FormData, files: File[]) => {

    if (files) { 
        for (let file of files) {
            formData.append('files', file);
        } 
    } 
};


export const readFileContent = async (file: File): Promise<string> => {
    return new Promise<string>((resolve) => {
        if (!file) {
            resolve('');
        }

        const reader = new FileReader();

        reader.onload = () => {
            const text = reader?.result?.toString() ?? '';
            resolve(text);
        };

        reader.readAsText(file);
    });
};
