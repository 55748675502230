import { FilterOptionType } from "@/components/dataGrid/CustomFilterHeader";
import { PaginateParams } from "@/types/pagination"
import { DocumentTypeWBH } from "../transfers/transfersTypes";

export interface TokensTransactionsStore {
    [key: string]: {
        loading: boolean,
        list: Array<DocumentTypeWBH>,
        error: string | null,
        count: number,
        pagination: PaginateParams,
        filters: {
            [key: string]: Array<FilterOptionType>
        },
        filtersSet: boolean;
    }
}

export enum TransactionsTypes {
    TRADES = "TRADES",
    TRANSFERS = "TRANSFERS",
};