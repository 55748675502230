import { PaginateParams } from "@/types/pagination";
import { FilterOptionType } from "@/components/dataGrid/CustomFilterHeader";
import { MonitoringUser } from "../clientsMonitoring/clientsMonitoringTypes";

export interface UsersRiskGroupStore {
    [key: string]: {
        loading: boolean,
        list: Array<MonitoringUser>,
        error: string | null,
        count: number,
        pagination: PaginateParams,
        filters: {
            [key: string]: Array<FilterOptionType>
        },
        filtersSet: boolean;
    }
}

export enum RiskGroupsType {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH"
}