import { CardDetails, CardOptionType, CardReplacelPayload } from "@/redux/cards/cardsTypes";
import { Button, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import AccountDropdown2 from "@/components/form/AccountDropdown2";
import { Account } from "@/redux/accounts/accountsTypes";
import { isEmpty } from "lodash";
import DetailInfo from "@/components/DetailInfo";
import { NumericFormat } from "react-number-format";
import { showErrorNotification, showSuccessNotification } from "@/utils/errors";
import { getDeliveryMethods, postReplaceCard, sumCreationFees, sumFees, sumMonthlyFees } from "@/redux/cards/cardsSlice";
import { CardDeliveryAddressType } from "./EmiCardReplaceDialog";
import { useEffect, useState } from "react";
import { ProcessFeeEnum } from "@/redux/process/processTypes";
import _ from "lodash";

interface Props {
    isPhysical: boolean,
    oldCard: CardDetails,
    cardOption: CardOptionType,
    accountList: Account[],
    deliveryAddress?: CardDeliveryAddressType,
    onClose: () => void,
}

const CardReplaceConfirm = ({ isPhysical, oldCard, accountList, cardOption, deliveryAddress, onClose }: Props) => {
    const { t } = useTranslation();


    const [deliveryOptions, setDeliveryOptions] = useState<any[]>([]);
    const [selectedDelivery, setSelectedDelivery] = useState<any>();
    const shipmentByCountry = cardOption?.config?.validateAddress === true;
    const deliveryFee = 0;
    const montlyFee = cardOption?.fees ? sumMonthlyFees(cardOption?.fees) : 0;
    const creationFee = cardOption?.fees ? sumCreationFees(cardOption?.fees) : 0;
    const [loadingShipments, setLoadingShipments] = useState(false);

    const currency = cardOption.processProperties.ccy;

    const totalPrice = montlyFee + creationFee + deliveryFee;

    const deliveryIssue = !selectedDelivery?.proc;
    const addressIssue =
        !deliveryAddress?.address ||
        !deliveryAddress?.city ||
        !deliveryAddress?.countryIso3 ||
        !deliveryAddress?.postalCode;

    const disabled = isPhysical ? deliveryIssue || addressIssue : false;
    const initialValues = {
        feeAccountId: '',
    };
    const validationSchema = Yup.object({
        feeAccountId: Yup.number().required(t('form.validator.required').toString())
    });

    useEffect(() => {

        const get = async () => {
            try {
                setLoadingShipments(true);
                if (deliveryAddress?.countryIso2) {
                    const result = await getDeliveryMethods(cardOption.integration, deliveryAddress?.countryIso2, `${deliveryAddress?.address} ${deliveryAddress?.city}  ${deliveryAddress?.countryName} ${deliveryAddress?.postalCode}`);
                    const options = result.map((p: any) => { return { ...p, fee: sumFees(p.fees, 0, ProcessFeeEnum.FIXED) }; }) || [];
                    setDeliveryOptions(options);
                    options?.length > 0 && setSelectedDelivery(options[0]);

                }
                setLoadingShipments(false);
            }
            catch (e) {
                setLoadingShipments(false);
                console.log(e);
            }
        };
        if (shipmentByCountry) {
            get();

        }
        else {
            setDeliveryOptions([]);

            const options = _.values(cardOption?.subProcesses).map(p => { return { ...p, fee: sumFees(p.fees, 0, ProcessFeeEnum.FIXED) }; }) || [];
            setDeliveryOptions(options);
            options?.length > 0 && setSelectedDelivery(options[0]);

        }
    }, [cardOption.integration, cardOption?.subProcesses, deliveryAddress?.address, deliveryAddress?.city, deliveryAddress?.countryIso2, deliveryAddress?.countryName, deliveryAddress?.postalCode, shipmentByCountry]);

    const handleDeliveryChange = (event: any) => {
        setSelectedDelivery(deliveryOptions.find(p => p.proc === event.target.value));
    };


    const submitPhysical = async (formData: any, formikProps: any) => {
        try {


            const payload: CardReplacelPayload = {
                userId: oldCard.userId,
                replacedCardId: oldCard.id,
                feeAccountId: formData.feeAccountId,
                cardProduct: cardOption.proc,
                shipProc: selectedDelivery?.proc,
                city: deliveryAddress?.city,
                country: deliveryAddress?.countryIso3,
                address: deliveryAddress?.address,
                zip: deliveryAddress?.postalCode,

                //	smsPhoneNumber: user?.mobileNumber, 
            };

            await postReplaceCard(payload);
            showSuccessNotification('Card successfully ordered');

        }
        catch (e) {
            showErrorNotification(e);
        }
        finally {
            onClose();
        }
    };

    const cardDetails = [{
        field: t('cards.cardLabel'),
        value: cardOption.name,
    },
    {
        field: t('cards.typeOfCard'),
        value: t(isPhysical ? 'form.fields.physical' : 'form.fields.virtual'),
    }
    ];

    const cardDelivery = [{
        field: t('form.fields.address'),
        value: <Grid>
            <Typography variant="body2">{deliveryAddress?.address || ''}</Typography>
            <Typography variant="body2">{deliveryAddress?.city || ''}</Typography>
            <Typography variant="body2">{deliveryAddress?.countryName || ''} </Typography>
            <Typography variant="body2">{deliveryAddress?.postalCode || ''}</Typography>
        </Grid>
    }
    ];

    const cardPrice = [{
        field: <Grid>
            <Typography variant="body2" color='text.secondary'>{t('cards.totalPrice')}</Typography>
            <Typography variant="body2" color='text.secondary'>{t('cards.princeIncl')}</Typography>
        </Grid>,
        value: <Typography
            variant='h2'
            color='text.secondary'
        >
            <NumericFormat
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandsGroupStyle='thousand'
                thousandSeparator={true}
                value={totalPrice}
                prefix={`${currency} `} />
        </Typography>
    },];

    return (
        <Grid container rowGap={2} flexDirection='column' flexGrow={1} flexWrap='nowrap'>
            <Grid item xs={12} container >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={submitPhysical}
                >
                    {({ isSubmitting, errors }) => {
                        return (
                            <Form >
                                <Grid container spacing={1} alignContent='flex-start'>
                                    <Grid item xs={12}>
                                        <AccountDropdown2
                                            fieldName='feeAccountId'
                                            fieldlabel={t('cards.payFromUserAccount').toString()}
                                            accountList={accountList}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DetailInfo details={cardDetails} />
                                    </Grid>

                                    {isPhysical ? <>
                                        <Grid item xs={12}>
                                            <Typography variant='h3' >
                                                {`${t('form.fields.card')} ${t('form.fields.deliveryInfo')}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel>{t('cards.deliveryMethod')}</InputLabel>
                                            <Select
                                                displayEmpty={false}
                                                onChange={handleDeliveryChange}
                                                value={selectedDelivery?.proc ?? ''}
                                                renderValue={() => selectedDelivery ? <div> {`${selectedDelivery?.name} (${currency} ${selectedDelivery?.fee})`}</div> : <div></div>}
                                                sx={theme => ({
                                                    bgcolor: theme.palette.transparentBlack,
                                                    display: 'flex',
                                                    flex: 1,
                                                    minHeight: '3rem'
                                                })}
                                            >
                                                {deliveryOptions?.length > 0 &&
                                                    deliveryOptions.map((item, idx) => (
                                                        <MenuItem
                                                            sx={{ padding: 2 }}
                                                            key={idx}
                                                            value={item.proc}>
                                                            <Typography>{`${item.name} (${currency} ${item.fee})`} </Typography>
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                            {(loadingShipments === false && (deliveryOptions.length === 0 || !deliveryOptions)) && <FormHelperText error={true}>{t('cards.noShipment')}</FormHelperText >}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DetailInfo details={cardDelivery} />
                                        </Grid> </>
                                        : null
                                    }
                                    <Grid item xs={12}>
                                        <DetailInfo details={cardPrice} />
                                    </Grid>

                                </Grid>
                                <Grid container justifyContent='flex-end' mt='auto' paddingY={2}>
                                    <Button type='submit'
                                        disabled={isSubmitting || !isEmpty(errors) || disabled} >
                                        {t('cards.confirmReplace')}
                                    </Button>
                                </Grid>
                            </Form>);
                    }}
                </Formik >
            </Grid>
        </Grid>);
}


export default CardReplaceConfirm;

