import { useRoutes } from 'react-router-dom';
import LoginRoutes from './LoginRoutes';
import WlpRoutes from './WlpRoutes';
import OnboardingRoutes from './OnboardingRoutes';
import EmiRoutes from './EmiRoutes';


export default function Routes({ isEmi }: { isEmi?: boolean }) {
    return useRoutes([LoginRoutes, isEmi ? EmiRoutes : WlpRoutes, OnboardingRoutes]);
}
