import DialogTitle from "@/components/DialogTitle";
import DialogWithStatus from "@/components/DialogWithStatus";
import FormPaperLarge from "@/components/FormPaperLarge";
import { Alert, Button, DialogContent, Divider, Grid, List, ListItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BeneficiaryType, CreateConfigType, EditPaymentConfigPayload, PaymentConfig, PaymentConfigPayload } from "@/redux/platformInvoicesAndPayouts/platformInvoicesAndPayoutsTypes";
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { OptionsListType } from "@/types/select";
import { getAlpha3Code, getName, getNames } from "i18n-iso-countries";
import { isValidIBAN } from "ibantools";
import { Form, Formik } from "formik";
import CustomSelect from "@/components/CustomSelect";
import FormTextField from "@/components/form/FormTextField";
import { getDataByIban } from "@/redux/finance/financeSlice";
import { getAccountDetailsByWalletId } from "@/redux/accounts/accountsSlice";
import { showErrorNotification, showSuccessNotification } from "@/utils/errors";
import { Account } from "@/redux/accounts/accountsTypes";
import AmountValueCell from "@/components/dataGrid/renderCell/AmountValueCell";
import { UserType } from "@/redux/user/types";
import { dispatch, useSelector } from "@/store/store";
import { setLoadingDialog } from "@/redux/dialog/dialogSlice";
import DangerTriangle from "@/icons/DangerTriangle";
import FormDivider from "@/components/form/FormDivider";
import { createPaymentsConfig, editPaymentsConfig } from "@/redux/platformInvoicesAndPayouts/platformInvoicesAndPayoutsSlice";
import { getCountryNameFromIso3 } from "@/components/dataGrid/renderCell/IpLocationCell";
import { InvoiceParty } from "@/redux/emiBilling/emiBillingTypes";
import TextWithAutocomplete from "@/components/form/TextWithAutocomplete";

interface Props {
    open: boolean;
    wlpId?: string;
    parties: Array<InvoiceParty>;
    config?: PaymentConfig | null;
    onClose: () => void;
    onComplete: () => void;
};

interface InitialValues {
    configType: CreateConfigType | null;
    partyId: number;
    beneficiaryType: BeneficiaryType | null;
    registrationNumber: string;
    walletId: string;
    beneficiaryName: string;
    beneficiaryIban: string;
    bankName: string;
    bankAddress: string;
    bicswift: string;
    bankCountry: string;
    bankCountryName: string;
    paymentDescription: string;
    sortNumber: string;
    accountNumber: string;
    hidden: boolean;
}

const AddPaymentConfig = ({ open, wlpId, config, parties, onClose, onComplete }: Props) => {
    const { isEmi } = useSelector((state) => state.credentials);

    const { t } = useTranslation();

    const countries = getNames("en");

    const partiesOptions = parties.map(party => ({
        value: party.id,
        label: party.partyName,
        ownerType: party.ownerType,
        ownerValue: party.ownerValue,
        taxCountry: party.taxCountry,
        taxCountryRegion: party.taxCountryRegion
    }));

    const [configType, setConfigType] = useState<CreateConfigType | null>(CreateConfigType.FIAT);

    const [isValidIban, setIsValidIban] = useState(false);

    const selectConfigOptions: OptionsListType = [
        { value: CreateConfigType.FIAT, text: t('emiBilling.configTypes.fiat') },
        // { value: CreateConfigType.CRYPTO, text: t('emiBilling.configTypes.crypto') }
    ];

    const selectBeneficiaryTypeOptions: OptionsListType = [
        { value: BeneficiaryType.INDIVIDUAL, text: t('enum.userType.INDIVIDUAL') },
        { value: BeneficiaryType.BUSINESS, text: t('enum.userType.BUSINESS') }
    ];

    const [account, setAccount] = useState<Account | null>(null);
    const [accountError, setAccountError] = useState<string | null>(null);

    const initialValues: InitialValues = {
        configType: configType,
        partyId: config?.partyId ? config.partyId : 0,
        beneficiaryType: config?.beneficiaryType ? config.beneficiaryType : BeneficiaryType.INDIVIDUAL,
        registrationNumber: config?.beneficiaryIdNumber ? config.beneficiaryIdNumber : '',
        walletId: config ? config.paymentWalletId : '',
        beneficiaryName: config ? config.beneficiaryName : '',
        beneficiaryIban: config ? config.iban : '',
        bankName: config ? config.bankName : '',
        bankAddress: config ? config.bankAddress : '',
        bicswift: config ? config.swiftCode : '',
        bankCountry: config ? config.bankCountry : '',
        bankCountryName: (config && config.bankCountry) ? getCountryNameFromIso3(config.bankCountry) : '',
        paymentDescription: config ? config.paymentDescription : '',
        sortNumber: config && config.sortCode ? config.sortCode : '',
        accountNumber: config && config.accountNumber ? config.accountNumber : '',
        hidden: config ? config.isHidden : false
    };

    const validationSchema = Yup.object({
        partyId: Yup.number()
            .required(t('form.validator.required').toString())
            .moreThan(0, t('form.validator.required').toString())
            .typeError(t('form.validator.required').toString()),
        beneficiaryType: Yup.string()
            .required(t('form.validator.required').toString()),
        walletId: Yup.string()
            .required(t('form.validator.required').toString()),
        paymentDescription: Yup.string()
            .required(t('form.validator.required').toString()),
        beneficiaryName: Yup.string().when('configType', {
            is: CreateConfigType.FIAT,
            then: Yup.string().required(t('form.validator.required').toString()),
            otherwise: Yup.string()
        }),
        registrationNumber: Yup.string().when(['configType', 'beneficiaryType'], {
            is: (configType: CreateConfigType, beneficiaryType: BeneficiaryType) => configType === CreateConfigType.FIAT && beneficiaryType === BeneficiaryType.BUSINESS,
            then: Yup.string().required(t('form.validator.required').toString()),
            otherwise: Yup.string()
        }),
        beneficiaryIban: Yup.string()
            .when('configType', {
                is: CreateConfigType.FIAT,
                then: Yup.string()
                    .test('is-valid-iban', t('form.validator.invalidIban').toString(), value => isValidIBAN(value || ''))
                    .required(t('form.validator.required').toString()),
                otherwise: Yup.string()
            }),
        bankName: Yup.string().when('configType', {
            is: CreateConfigType.FIAT,
            then: Yup.string().required(t('form.validator.required').toString()),
            otherwise: Yup.string()
        }),
        bicswift: Yup.string().when('configType', {
            is: CreateConfigType.FIAT,
            then: Yup.string().required(t('form.validator.required').toString()),
            otherwise: Yup.string()
        }),
        bankCountryName: Yup.string().when('configType', {
            is: CreateConfigType.FIAT,
            then: Yup.string().required(t('form.validator.required').toString()),
            otherwise: Yup.string()
        }),
        sortNumber: Yup.string().nullable(),
        accountNumber: Yup.string().nullable(),
    }).test(
        'sortNumber-accountNumber',
        t('form.validator.required').toString(),
        function (value) {
            const { sortNumber, accountNumber } = value;
            if (sortNumber && !accountNumber) {
                return this.createError({ path: 'accountNumber', message: t('form.validator.required').toString() });
            } else if (!sortNumber && accountNumber) {
                return this.createError({ path: 'sortNumber', message: t('form.validator.required').toString() });
            }
            return true;
        }
    );

    useEffect(() => {
        if (config && config.paymentWalletId && !account) {
            setIsValidIban(isValidIBAN(config.iban));
            getAccountDetails(config.paymentWalletId);
        }
    }, [config, account])

    const submit = async (formData: InitialValues) => {
        try {
            const { partyId, registrationNumber, walletId, beneficiaryName, beneficiaryType, beneficiaryIban, bankName, bankAddress, bicswift, bankCountry, paymentDescription, sortNumber, accountNumber, hidden } = formData;
            if (beneficiaryType && !config) {
                const payload: PaymentConfigPayload = {
                    partyId: partyId,
                    beneficiaryType: beneficiaryType,
                    payerWalletId: walletId,
                    bankName: bankName,
                    bankCountry: bankCountry,
                    bankAddress: bankAddress,
                    swiftCode: bicswift,
                    beneficiaryName: beneficiaryName,
                    iban: beneficiaryIban,
                    sortCode: sortNumber,
                    accountNumber: accountNumber,
                    registrationNumber: registrationNumber,
                    description: paymentDescription,
                };
                if (isEmi) {
                    payload.wlpId = wlpId;
                };
                await createPaymentsConfig(payload);
                showSuccessNotification(t('emiBilling.messages.paymentConfigCreated'));
                onComplete();
            } else if (beneficiaryType && config) {
                const payload: EditPaymentConfigPayload = {
                    configId: config.id,
                    beneficiaryType: beneficiaryType,
                    bankName: bankName,
                    bankCountry: bankCountry,
                    bankAddress: bankAddress,
                    swiftCode: bicswift,
                    beneficiaryName: beneficiaryName,
                    beneficiaryAddress: config.beneficiaryAddress,
                    cryptoAddress: config.cryptoAddress,
                    cryptoNetwork: config.cryptoNetwork,
                    iban: beneficiaryIban,
                    sortCode: sortNumber,
                    accountNumber: accountNumber,
                    registrationNumber: registrationNumber,
                    description: paymentDescription,
                    hidden: hidden
                };
                await editPaymentsConfig(payload);
                showSuccessNotification(t('emiBilling.messages.paymentConfigUpdated'));
                onComplete();
            };
            handleClose();
        }
        catch (e) {
            showErrorNotification(e)
        }
    };

    const getAccountDetails = async (walletId: string) => {
        if (walletId) {
            try {
                dispatch(setLoadingDialog(true));
                const accountData = await getAccountDetailsByWalletId(walletId);
                setAccount(accountData);
                setAccountError(null);
            } catch (e) {
                setAccountError(walletId);
                setAccount(null);
            } finally {
                dispatch(setLoadingDialog(false));
            }
        }
    };

    const getUserDisplayName = (account: Account) => {
        if (account.userType === UserType.INDIVIDUAL) {
            return `${account.firstName} ${account.lastName}`;
        } else if (account.userType === UserType.BUSINESS && account.companyName) {
            return account.companyName;
        } else {
            return account.clientId;
        }
    };

    const handleClose = () => {
        onClose()
        setAccount(null);
    };

    return (
        <DialogWithStatus
            open={open}
            onClose={handleClose}
            fullWidth
            PaperComponent={FormPaperLarge}>
            <DialogTitle onClose={handleClose}>
                {t('form.buttons.createConfig')}
            </DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
                <Grid container pt={2}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={submit}>
                        {({ isSubmitting, ...formik }) => {
                            return (
                                <Form>
                                    <Grid item container alignContent='start' xs={12} rowGap='10px'>

                                        <Grid item xs={12}>
                                            <CustomSelect
                                                name='configType'
                                                displayEmpty={true}
                                                label={t('emiBilling.configType')}
                                                value={configType}
                                                required={true}
                                                onChange={async (event: any) => {
                                                    setConfigType(event.target.value);
                                                    await formik.setFieldValue("configType", event.target.value, false);
                                                    await formik.setFieldValue("registrationNumber", '', false);
                                                    await formik.setFieldValue("walletId", '', false);
                                                    await formik.setFieldValue("paymentDescription", '', false);
                                                }}
                                                optionsList={selectConfigOptions} />
                                        </Grid>

                                        {configType === CreateConfigType.FIAT &&
                                            <>
                                                <Grid item xs={12} mt={2}>
                                                    <FormDivider text={t('form.fields.remitterAccount')} />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormTextField
                                                        name="walletId"
                                                        fullWidth
                                                        label={t('form.fields.fromAccountId')}
                                                        onBlur={async (event: React.FocusEvent<HTMLInputElement>) => {
                                                            const selectedWalletId = event.target.value;
                                                            if (selectedWalletId) {
                                                                getAccountDetails(selectedWalletId)
                                                            } else {
                                                                setAccount(null);
                                                            }
                                                            await formik.setFieldTouched('walletId', true, true);
                                                        }}
                                                    />
                                                </Grid>

                                                {accountError &&
                                                    <Grid item xs={12} mt={1}>
                                                        <Alert severity="info" icon={<DangerTriangle />}>
                                                            <Typography>{t('emiBilling.messages.accountWasNotFound', { walletId: accountError })}</Typography>
                                                        </Alert>
                                                    </Grid>
                                                }

                                                {account && <Grid item xs={12} pl='20px' mt={1}
                                                    sx={(theme) => ({
                                                        borderRadius: '10px',
                                                        border: `1px solid ${theme.palette.transparentBlack}`,
                                                        backgroundColor: theme.palette.transparentBlack
                                                    })} >
                                                    <List dense={true}
                                                        sx={{
                                                            listStyleType: 'disc',
                                                            pl: 2,
                                                            '& .MuiListItem-root': {
                                                                display: 'list-item',
                                                                pl: 0,
                                                            },
                                                        }}>
                                                        <ListItem>
                                                            <Typography sx={{ wordBreak: 'break-all' }}>
                                                                <strong>{t('form.fields.client')}:</strong> {getUserDisplayName(account)}
                                                            </Typography>
                                                        </ListItem>
                                                        <ListItem>
                                                            <Typography sx={{ wordBreak: 'break-all' }}>
                                                                <strong>{t('form.fields.clientId')}:</strong> {account.clientId}
                                                            </Typography>
                                                        </ListItem>
                                                        <ListItem>
                                                            <Typography sx={{ wordBreak: 'break-all' }}>
                                                                <strong>{t('form.fields.email')}:</strong> {account.email}
                                                            </Typography>
                                                        </ListItem>
                                                        <ListItem>
                                                            <Grid container>
                                                                <Typography pr='4px'>
                                                                    <strong>{t('form.fields.balance')}: </strong>
                                                                </Typography>
                                                                <AmountValueCell value={account.balance} currency={account.currency} />
                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                </Grid>}

                                                <Grid item xs={12} mt={2}>
                                                    <FormDivider text={t('form.fields.beneficiaryDetails')} />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextWithAutocomplete
                                                        name='partyId'
                                                        options={partiesOptions}
                                                        fullWidth
                                                        label={t('form.fields.beneficiary')}
                                                        onChangeSelectedOption={(option) => {
                                                            if (!option) {
                                                                formik.setFieldValue("partyId", 0, true)
                                                            }
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <CustomSelect
                                                        name='beneficiaryType'
                                                        displayEmpty={true}
                                                        label={t('emiBilling.beneficiaryType')}
                                                        value={formik.values.beneficiaryType}
                                                        required={true}
                                                        onChange={async (event: any) => {
                                                            await formik.setFieldValue("beneficiaryType", event.target.value, true);
                                                            await formik.setFieldValue("beneficiaryName", "", false);
                                                            await formik.setFieldTouched("beneficiaryName", false, false);
                                                            await formik.setFieldValue("registrationNumber", "", false);
                                                            await formik.setFieldTouched("registrationNumber", false, false);
                                                        }}
                                                        optionsList={selectBeneficiaryTypeOptions} />
                                                </Grid>

                                                {formik.values.beneficiaryType === BeneficiaryType.BUSINESS ?
                                                    <Grid container item xs={12} columnSpacing={2}>
                                                        <Grid item xs={6}>
                                                            <FormTextField
                                                                name="beneficiaryName"
                                                                fullWidth
                                                                label={t('form.fields.beneficiaryName')}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormTextField
                                                                name="registrationNumber"
                                                                fullWidth
                                                                label={t('form.fields.registrationNumber')}
                                                            />
                                                        </Grid>
                                                    </Grid> :
                                                    <Grid item xs={12}>
                                                        <FormTextField
                                                            name="beneficiaryName"
                                                            fullWidth
                                                            label={t('form.fields.beneficiaryName')}
                                                        />
                                                    </Grid>}

                                                <Grid item xs={12}>
                                                    <FormTextField
                                                        name="beneficiaryIban"
                                                        fullWidth
                                                        label={t('form.fields.beneficiaryIban')}
                                                        onBlur={
                                                            async () => {
                                                                const iban = formik.values.beneficiaryIban;
                                                                if (!isValidIBAN(iban)) {
                                                                    setIsValidIban(false);
                                                                    await formik.setFieldValue("bankName", '', true);
                                                                    await formik.setFieldValue("bicswift", '', true);
                                                                    await formik.setFieldValue("bankCountry", '', true);
                                                                    await formik.setFieldValue("bankCountryName", '', true);
                                                                } else {
                                                                    const beneficiaryData = await getDataByIban(iban.replace(/\s/g, ''));

                                                                    const country = getAlpha3Code(countries[beneficiaryData.country] || beneficiaryData.country, 'en');
                                                                    const countryName = getName(beneficiaryData.country, 'en');

                                                                    await formik.setFieldValue("bankName", beneficiaryData.bank ?? '', true);
                                                                    await formik.setFieldTouched('bankName', false, true);

                                                                    await formik.setFieldValue("bankAddress", beneficiaryData.address ?? '', true);
                                                                    await formik.setFieldTouched('bankAddress', false, true);

                                                                    await formik.setFieldValue("bicswift", beneficiaryData.bic ?? '', true);
                                                                    await formik.setFieldTouched('bicswift', false, true);

                                                                    await formik.setFieldValue("bankCountry", country ?? '', true);
                                                                    await formik.setFieldTouched('bankCountry', false, true);

                                                                    await formik.setFieldValue("bankCountryName", countryName ?? '', true);
                                                                    await formik.setFieldTouched('bankCountryName', false, true);

                                                                    setIsValidIban(true);
                                                                }
                                                                formik.setFieldTouched('beneficiaryIban', true, true);
                                                            }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormTextField
                                                        name="bankName"
                                                        fullWidth
                                                        label={t('form.fields.bankName')}
                                                    />
                                                </Grid>

                                                <Grid container item xs={12} columnSpacing={2}>
                                                    <Grid item xs={6}>
                                                        <FormTextField
                                                            name="bicswift"
                                                            fullWidth
                                                            label={t('form.fields.bankBicSwift')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormTextField
                                                            name="bankCountryName"
                                                            fullWidth
                                                            label={t('form.fields.bankCountry')}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container item xs={12} columnSpacing={2}>
                                                    <Grid item xs={6}>
                                                        <FormTextField
                                                            name={'sortNumber'}
                                                            fullWidth
                                                            label={t('form.fields.sortCode')}
                                                            type="number"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormTextField
                                                            name={'accountNumber'}
                                                            fullWidth
                                                            label={t('form.fields.accountNumber')}
                                                            type="number"
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormTextField
                                                        name="paymentDescription"
                                                        fullWidth
                                                        label={t('form.fields.paymentDescription')}
                                                        multiline
                                                        rows={2}
                                                        inputProps={{
                                                            maxLength: 100
                                                        }}
                                                    />
                                                </Grid>
                                            </>}

                                        {configType === CreateConfigType.CRYPTO &&
                                            <>
                                                <Divider sx={{ width: '100%', mt: 1 }} />
                                                <Grid item xs={12}>
                                                    <FormTextField
                                                        name="walletId"
                                                        fullWidth
                                                        label={t('form.fields.fromWalletId')}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormTextField
                                                        name="paymentDescription"
                                                        fullWidth
                                                        label={t('form.fields.paymentDescription')}
                                                        multiline
                                                        rows={2}
                                                        inputProps={{
                                                            maxLength: 100
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                        }
                                    </Grid>

                                    <Grid container mt='auto' pt={2} pb={2}>
                                        <Grid container item xs={12} justifyContent='space-between'>
                                            <Button color='secondary' variant='outlined' onClick={handleClose}>{t('form.buttons.cancel')}</Button>
                                            <Button type='submit' disabled={isSubmitting || !isValidIban}>{t('form.buttons.create')}</Button>
                                        </Grid>
                                    </Grid>

                                </Form>
                            );

                        }}

                    </Formik>
                </Grid>
            </DialogContent >
        </DialogWithStatus>
    )
};

export default AddPaymentConfig;