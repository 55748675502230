// theme constant
export const gridSpacing = 3;
export const drawerWidth = 220;
export const appBarHeight = 50;
export const sidebarIconSize = 16;
export const sidebarLargeIconSize = 20;
export const sidebarLargeIconViewbox = "0 0 20 20"
export const appCollapsedDrawerWidth = sidebarLargeIconSize + 40;
export const appMobileDrawerWidth = sidebarLargeIconSize + 15;
export const defaultPageOptions = [5, 10, 20, 30, 50, 100];
export const defaultPageSize = 20;
export const pageTopMargin = 30;
export const mainPaddingLeft = 30;
export const mainPaddingRight = 30;
export const iconSidebarSizeLarge = 24;
export const feesRowsPerPageOptions = [10, 15, 20, 25, 30];