import { Autocomplete, TextField, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useField } from "formik";

interface CountryType {
  name: string;
  alpha2: string;
  alpha3: string;
}

type Props = {
  fieldName: string;
  fieldLabel?: string;
  countryList: CountryType[],
  onDropdownChange?: (value: any) => void;
} & TextFieldProps;


const CountryDropdown = ({
  fieldName,
  fieldLabel,
  countryList,
  onDropdownChange,
  ...otherProps
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(fieldName);
  const { setValue } = helpers;
  const { value } = meta;
  const { t } = useTranslation(["translations"]);


  const handleCountryChange = (event: any, newValue: any) => {
    setValue(newValue);
    onDropdownChange && onDropdownChange(newValue);
  };

  const configTextfield = {
    ...field,
    ...otherProps,
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }


  const displayValue = () => {
    const inList = countryList.some(p => p.alpha3 === value?.alpha3);
    return inList && value && Object.keys(value).length !== 0 ? value : '';
  }

  return (
    <Autocomplete
      options={countryList}
      value={displayValue()}
      onChange={handleCountryChange}
      getOptionLabel={(option) => option.name || ''}
      disableClearable
      fullWidth
      sx={{ width: "100%" }}
      isOptionEqualToValue={(option, v) => {
        return v === undefined || option.alpha3 === v.alpha3 || option.alpha2 === v.alpha2 || option.name === value;
      }}
      /*PaperComponent={({ children }) => (
        <StyledPaper >{children}</StyledPaper>
      )}*/
      renderInput={(params) => (
        <TextField
          {...configTextfield}
          name={fieldName}
          {...params}
          label={fieldLabel ?? t("form.fields.country")}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default CountryDropdown;
