import { SvgIcon } from '@mui/material';

const Purchase = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#2B2B2B" fillOpacity="0.06" />
            <path d="M16.6673 17.3335V13.3335M16.6673 13.3335L18.0007 14.6668M16.6673 13.3335L15.334 14.6668" stroke="#EFB930" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.6673 11.9998C18.6673 9.48568 18.6673 8.2286 17.8863 7.44755C17.1052 6.6665 15.8481 6.6665 13.334 6.6665H10.6673C8.15316 6.6665 6.89608 6.6665 6.11503 7.44755C5.33398 8.2286 5.33398 9.48568 5.33398 11.9998C5.33398 14.514 5.33398 15.7711 6.11503 16.5521C6.89608 17.3332 8.15316 17.3332 10.6673 17.3332H13.334" stroke="#EFB930" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M10.6667 14.6665H8" stroke="#EFB930" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M12.6673 14.6665H12.334" stroke="#EFB930" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M5.33398 10.6665L18.6673 10.6665" stroke="#EFB930" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default Purchase;