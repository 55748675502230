import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '@/utils/errors';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { EarnPlannedTransferDetailsStore, EarnPlannedTransferHeaderItem, EarnPlannedTransferItem } from './earnPlannedTransferDetailsTypes';

export const earnPlannedTransferDetailspaginationParamsInit = {
    filter: '',
    orderBy: 'amount',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: EarnPlannedTransferDetailsStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    header: null,
    pagination: earnPlannedTransferDetailspaginationParamsInit
};

const slice = createSlice({
    name: 'earnPlannedTransferDetails',
    initialState,
    reducers: {
        setList(state, action: PayloadAction<Array<EarnPlannedTransferItem>>) {
            state.list = action.payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
            state.pagination = payload;
        },
        setHeader: (state, { payload }: PayloadAction<EarnPlannedTransferHeaderItem>) => {
            state.header = payload;
        },
    }
});

export const { setList, setLoading, setError, setCount, setPagination, setHeader } = slice.actions;

export const getEarnPlannedTransferList = (
    type: 'INTEREST' | 'REPAYMENT',
    proc: string,
    walletId: string,
    transferDate: string,
    payload: PaginateParams
) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/vaults/planned-transfer/${type}/${proc}/${walletId}/${transferDate}`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setList(data.list));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            dispatch(setHeader({
                transferDate: data.transferDate,
                systemAccountWalletId: data.systemAccountWalletId,
                totalAmount: data.totalAmount,
                totalAmountString: data.totalAmountString,
                ccyDecimals: data.ccyDecimals,
                currency: data.currency
            }));
            dispatch(setLoading(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading(false));
        }
    };
};



export default slice.reducer;