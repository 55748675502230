import { PaginateParams } from "@/types/pagination"
import { UserType } from "../user/types";

export interface UserGroupStore {
	loading: boolean;
	list: Array<UserGroup>;
	error: string | null;
	count: number;
	pagination: PaginateParams;
	tabs: { [key: string]: any };
};

export interface UserGroup {
	id: number;
	name: string;
	profileType: UserType | null;
	usersCount: number;
};

export interface AddGroupToUserPayload {
	userGroupId: number,
	clientId: string
};

export interface RemovUserFromGroupPayload {
	userGroupId: number,
	userId: number
};

export interface CreateGroupPayload {
	name: string;
	userType: UserType;
	wlpId?: string;
};

export enum UserGroupTab {
	FEES = 'FEES'
};
