import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { DASHBOARD_PATH } from 'config';
import { gridSpacing } from '@/utils/constant';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import { useErrorBoundary } from "react-error-boundary";
import { useTranslation } from 'react-i18next';

const ErrorWrapper = styled('div')({
    maxWidth: 350,
    margin: '0 auto',
    textAlign: 'center'
});

const ErrorCard = styled(Card)({
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});


interface Props {
    error: any
}
const ErrorFallback = ({ error }: Props) => {
    const { resetBoundary } = useErrorBoundary();
    const navigate = useNavigate();
    const { t } = useTranslation()

    const handleHome = () => {
        resetBoundary();
        navigate(DASHBOARD_PATH);
    };

    return (
        <ErrorCard>
            <CardContent>
                <Grid container justifyContent="center" spacing={gridSpacing}>

                    <Grid item xs={12}>
                        <ErrorWrapper>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Typography variant="h1" component="div">
                                        {t('errors.somethingWentWrong')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        {t('errors.errorOnPage')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" size="large" onClick={handleHome}>
                                        <HomeTwoToneIcon sx={{ fontSize: '1.3rem', mr: 0.75 }} />
                                        {t('form.buttons.home')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </ErrorWrapper>
                    </Grid>
                </Grid>
            </CardContent>
        </ErrorCard>
    );
};

export default ErrorFallback;
