import CustomSwitch from "@/components/CustomSwitch";
import { FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
    checked: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    hideLabel?: boolean;
    onLabel?: string;
    offLabel?: string;
}

const SwitchCell = ({ checked, onChange, disabled, hideLabel, onLabel, offLabel }: Props) => {
    const { t } = useTranslation();
    return (
        <FormControlLabel control={<CustomSwitch
            checked={checked}
            disabled={disabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange && onChange(event.target.checked)}
            sx={{ m: 1 }}
        />}
            label={hideLabel ? undefined : checked ? onLabel ?? t('form.fields.on') : offLabel ?? t('form.fields.off')} />
    );
};

export default SwitchCell;