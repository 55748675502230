import { CardMedia, Stack, Typography, useTheme } from "@mui/material";
import fallbackWlp from '../assets/images/fallbackWlp.png';

interface Props {
    chainId: number;
    withLogo?: boolean;
}

const NetworkLabel = ({ chainId, withLogo = false }: Props) => {
    const theme = useTheme();
    const getNetworkData = () => {
        switch (chainId) {
            case 1:
                return {
                    imgSrc: 'https://abcmob-public.s3.eu-central-1.amazonaws.com/networks_logo/ethereum_(erc-20).png',
                    name: 'Ethereum'
                };
            case 137:
                return {
                    imgSrc: 'https://abcmob-public.s3.eu-central-1.amazonaws.com/networks_logo/polygon.png',
                    name: 'Polygon'
                };
            case 42161:
                return {
                    imgSrc: 'https://abcmob-public.s3.eu-central-1.amazonaws.com/networks_logo/arbitrum.png',
                    name: 'Arbitrum'
                };
            case 10:
                return {
                    imgSrc: 'https://abcmob-public.s3.eu-central-1.amazonaws.com/networks_logo/optimism.png',
                    name: 'Optimism'
                };
            default:
                return {
                    imgSrc: 'https://abcmob-public.s3.eu-central-1.amazonaws.com/networks_logo/unknown.png', name: 'Unknown'
                };
        }
    }

    const imgSrc = getNetworkData().imgSrc;
    const name = getNetworkData().name;

    return (
        <Stack direction="row" alignItems="center">
            {withLogo && <CardMedia image={imgSrc}
                onError={(e) => (e.currentTarget.src = fallbackWlp)}
                component="img"
                alt={name}
                sx={{ width: '16px', height: '16px' }}
            />}

            <Typography pl={withLogo ? 0.5 : 0} color={theme.palette.text.secondary}>{name}</Typography>
        </Stack>
    );
}

export default NetworkLabel;