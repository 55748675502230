import { UserType } from "@/redux/user/types";
import { UserLevelPrice } from "@/redux/userLevels/userLevelsTypes";
import { getCurrencySign } from "@/utils/currency";
import { Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

const PlansPriceCell = (params: GridRenderCellParams) => {
    const { t } = useTranslation();

    const defaultPrice: UserLevelPrice = params.row.prices.find((price: UserLevelPrice) =>
        price.userId === null &&
        price.countryCode === null &&
        price.riskGroupCode === null &&
        price.userProfileType === null &&
        !price.disabled
    );

    const individualPrice: UserLevelPrice = params.row.prices.find((price: UserLevelPrice) =>
        price.userId === null &&
        price.countryCode === null &&
        price.riskGroupCode === null &&
        price.userProfileType === UserType.INDIVIDUAL &&
        !price.disabled
    );

    const businessPrice: UserLevelPrice = params.row.prices.find((price: UserLevelPrice) =>
        price.userId === null &&
        price.countryCode === null &&
        price.riskGroupCode === null &&
        price.userProfileType === UserType.BUSINESS &&
        !price.disabled
    );

    if (params.row.defaultLevel) {
        return <></>;
    };

    return (
        <Grid container direction='column' rowGap={0.3}>
            <Grid item>
                <Typography>
                    {`${t('enum.userType.INDIVIDUAL')}: `}
                    <NumericFormat
                        displayType={'text'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandsGroupStyle='thousand'
                        thousandSeparator={true}
                        value={individualPrice ? individualPrice.price : defaultPrice ? defaultPrice.price : 0}
                        prefix={individualPrice ? `${getCurrencySign(individualPrice.ccy)} ` : defaultPrice ? `${getCurrencySign(defaultPrice.ccy)} ` : `${getCurrencySign('EUR')} `} />
                </Typography>
            </Grid>
            <Grid item>
                <Typography>
                    {`${t('enum.userType.BUSINESS')}: `}
                    <NumericFormat
                        displayType={'text'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandsGroupStyle='thousand'
                        thousandSeparator={true}
                        value={businessPrice ? businessPrice.price : defaultPrice ? defaultPrice.price : 0}
                        prefix={businessPrice ? `${getCurrencySign(businessPrice.ccy)} ` : defaultPrice ? `${getCurrencySign(defaultPrice.ccy)} ` : `${getCurrencySign('EUR')} `} />
                </Typography>
            </Grid>
        </Grid>
    )
};

export default PlansPriceCell;