import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LogsType, LogsStore, ConsoleType } from './logsTypes';
import { AppDispatch } from '@/store/store';
import { showErrorNotification } from '@/utils/errors';
import request from '../../services/request';

export const paginationParamsInit = {
    filter: '',
    orderBy: 'requestedDate',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: LogsStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    pagination: paginationParamsInit,
    openedSubTab: ConsoleType.CONSOLE
};

const slice = createSlice({
    name: 'activityLogs',
    initialState,
    reducers: {
        setLogs(state, action: PayloadAction<Array<LogsType>>) {
            state.list = action.payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
            state.pagination = payload;
        },
        setSubTab(state, action: PayloadAction<{ subTab: ConsoleType }>) {
            state.openedSubTab = action.payload.subTab;
        },
    }
});

export default slice.reducer;

export const { setLogs, setLoading, setError, setCount, setPagination, setSubTab } = slice.actions;

export const getLogs = (payload: PaginateParams, type: ConsoleType) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get('/api/console/activity-log/list', {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take,
                    type: type
                }
            });
            const { data } = response;
            dispatch(setLogs(data.list));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            dispatch(setLoading(false));
            return data;
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading(false));
        }
    };
};