import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import MinusDeleteRemoveRectangle from '@/icons/MinusDeleteRemoveRectangle';
import { useTranslation } from 'react-i18next';
import Logo from '@/components/Logo';
import LineStepper from '@/components/LineStepper';
import { dispatch, useSelector } from '@/store/store';
import { logout } from '@/redux/user/userSlice';

const OnboardingLayout = () => {
    const theme = useTheme();;
    const { t } = useTranslation();
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const { step, totalStepCount } = useSelector((state) => state.menu);

    const handleLogout = async () => await dispatch(logout());

    return (
        <Box>
            <CssBaseline />
            <AppBar
                enableColorOnDark
                position="static"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                }}
            >
                <Container>
                    <Grid container justifyContent='space-between' alignItems='center' py={2} >
                        <Logo style={{
                            height: '30px',
                            width: 'auto',
                            cursor: 'pointer',
                            display: small ? 'none' : 'inherit'
                        }} />
                        <Typography variant='h1'> {t('onbooarding.title')} </Typography>
                        <IconButton onClick={handleLogout}   >
                            <MinusDeleteRemoveRectangle
                                sx={{ fontSize: 16 }} />
                            <Typography variant='body2' ml={1}>{t('form.buttons.logout')}</Typography>
                        </IconButton>
                    </Grid>
                    <LineStepper stepCount={totalStepCount} activeStep={step} />
                </Container>
            </AppBar>
            <Container component='main' maxWidth="lg">
                <Grid container justifyContent='center' mt={2}>
                    <Outlet />
                </Grid>
            </Container>
        </Box>
    );
};

export default OnboardingLayout;
