
import { Avatar, AvatarProps, useTheme } from "@mui/material";

interface Props extends AvatarProps { logoUrl?: string };

const CurrencyLogo = (props: Props) => {

    const theme = useTheme();

    const { logoUrl, sx, ...others } = props;

    return (
        <Avatar sx={{
            ...theme.typography.largeAvatar,
            ...sx
        }} src={logoUrl}  {...others} />);
};

export default CurrencyLogo;
