import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '@/utils/errors';
import { LimitStore, TurnoverLimit } from './platformLimitsTypes';
import { UserType } from '../user/types';


const initialState: LimitStore = {
    loading: false,
    limits: [],
    error: null,
};

const slice = createSlice({
    name: 'platformLimits',
    initialState,
    reducers: {
        setPlatfrormLimits(state, action: PayloadAction<Array<TurnoverLimit>>) {
            state.limits = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        }
    }
});

export const { setLoading, setPlatfrormLimits } = slice.actions;

export const getPlatformLimits = (wlpId?: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));

            const userProfileTypes = [UserType.INDIVIDUAL, UserType.BUSINESS];

            const requests = userProfileTypes.map(type =>
                request.get(`/api/console/limits/users/profile/${type}`, { params: { wlpId: wlpId } })
                    .then(response => ({
                        ...response.data,
                        userProfileType: type
                    }))
            );

            const limits = await Promise.all(requests);

            dispatch(setPlatfrormLimits(limits));
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLoading(false));
        }
    }
};

export const createPlatformLimit = async (wlpId: string, payload: any): Promise<TurnoverLimit> => {
    const response = await request.post(`/api/console/limits/create/wlp/${wlpId}`, payload)
    const { data } = response;
    return data;
};

export const deleteUsersLimits = async (wlpId: string, payload: { reason: string }) => {
    const response = await request.post(`/api/console/limits/users/detele/wlp/${wlpId}`, payload);
    const { data } = response;
    return data;
};

export default slice.reducer;