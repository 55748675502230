export interface TwoFAPayload {
	open: boolean,
	originalRequest: any
}

export interface TwoFaStore {
	open: boolean,
	loading: boolean,
	setupMissing: boolean,
	message: string | null,
	errorCode: string | null
}


export interface TwoFaGenerateResponse {
	qrCodeImage: string,
	backupKey: string
}

export enum TwoFaErrorParamType {
	AUTH_CODE = 'authenticatorCode', 
	SETUP_AUTH_CODE='two_factor_auth'
}
