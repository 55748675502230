import { useMemo, ReactNode } from 'react';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeOptions, ThemeProvider, Theme } from '@mui/material/styles';
import useConfig from 'hooks/useConfig';
import Palette from './palette';
import Typography from './typography';

import componentStyleOverrides from './compStyleOverride';
import { TypographyOptions } from '@mui/material/styles/createTypography';

interface Props {
    children: ReactNode;
}

export default function ThemeCustomization({ children }: Props) {
    const { borderRadius, fontFamily, navType } = useConfig();

    const theme: Theme = useMemo<Theme>(() => Palette(navType), [navType]);

    const themeTypography: TypographyOptions = useMemo<TypographyOptions>(
        () => Typography(theme, fontFamily),
        [theme, fontFamily]
    );

    const themeOptions: ThemeOptions = useMemo(
        () => ({
            palette: theme.palette,
            mixins: {
                toolbar: {
                    minHeight: '48px',
                    padding: '16px',
                    '@media (min-width: 600px)': {
                        minHeight: '48px'
                    }
                }
            },
            typography: themeTypography
        }),
        [theme, themeTypography]
    );

    const themes: Theme = createTheme(themeOptions);
    const themeWithOverrides = useMemo(() => componentStyleOverrides(themes, borderRadius), [themes, borderRadius]);


    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themeWithOverrides}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
