import { addSubPath, removeLastSubPath } from "@/redux/menu/menuSlice";
import { useDispatch } from "@/store/store";
import { useCallback } from "react";
import { useNavigate } from "react-router";

export type CustomNavigate = (args: Props) => void;

interface Props {
    path: string;
    title: string;
    state?: any;
    removePrev?: boolean;
};

export const useCustomNavigate = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const customNavigate = useCallback(({ path, title, state, removePrev }: Props) => {
        removePrev && dispatch(removeLastSubPath());
        navigate(path, { state });
        dispatch(addSubPath({ title, path, state }));
    }, [navigate, dispatch]);


    return customNavigate;
};