import { SvgIcon } from '@mui/material';

const VaultDeposit = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#2B2B2B" fillOpacity="0.06" />
            <g clipPath="url(#clip0_10686_68654)">
                <path d="M5.33398 12.0002C5.33398 8.85747 5.33398 7.28612 6.3103 6.30981C7.28661 5.3335 8.85795 5.3335 12.0007 5.3335C15.1433 5.3335 16.7147 5.3335 17.691 6.30981C18.6673 7.28612 18.6673 8.85747 18.6673 12.0002C18.6673 15.1429 18.6673 16.7142 17.691 17.6905C16.7147 18.6668 15.1433 18.6668 12.0007 18.6668C8.85795 18.6668 7.28661 18.6668 6.3103 17.6905C5.33398 16.7142 5.33398 15.1429 5.33398 12.0002Z" stroke="#40927E" strokeWidth="1.2" />
                <path d="M8 8.6665L8 15.3332" stroke="#40927E" strokeWidth="1.2" strokeLinecap="round" />
                <path d="M11.334 12C11.334 10.8954 12.2294 10 13.334 10C14.4386 10 15.334 10.8954 15.334 12C15.334 13.1046 14.4386 14 13.334 14C12.2294 14 11.334 13.1046 11.334 12Z" stroke="#40927E" strokeWidth="1.2" />
                <path d="M15 10.3335L16 9.3335" stroke="#40927E" strokeWidth="1.2" strokeLinecap="round" />
                <path d="M10.666 14.6665L11.666 13.6665" stroke="#40927E" strokeWidth="1.2" strokeLinecap="round" />
                <path d="M11.666 10.3335L10.666 9.3335" stroke="#40927E" strokeWidth="1.2" strokeLinecap="round" />
                <path d="M16 14.6665L15 13.6665" stroke="#40927E" strokeWidth="1.2" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_10686_68654">
                    <rect width="16" height="16" fill="white" transform="translate(4 4)" />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);
export default VaultDeposit;