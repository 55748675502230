import { createSlice } from '@reduxjs/toolkit';
import { SnackbarProps } from './snackbarTypes';
import { t } from '@/utils/translate';

const initialState: SnackbarProps = {
  action: false,
  open: false,
  message:  t('errors.somethingWentWrong'),
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  alert: {
    color: 'error',
    variant: 'standard'
  },
  transition: 'Fade',
  close: true,
  actionButton: false
};


const snackbar = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar(state, action) {
      const { open, message, anchorOrigin, alert, transition, close, actionButton } = action.payload;

      state.action = !state.action;
      state.open = open || initialState.open;
      state.message = message || initialState.message;
      state.anchorOrigin = anchorOrigin || initialState.anchorOrigin;
      state.alert = {
        color: alert?.color || initialState.alert.color,
        variant: alert?.variant || initialState.alert.variant
      };
      state.transition = transition || initialState.transition;
      state.close = close === false ? close : initialState.close;
      state.actionButton = actionButton || initialState.actionButton;
    },

    closeSnackbar(state) {
      state.open = false;
    }
  }
});

export default snackbar.reducer;

export const { closeSnackbar, openSnackbar } = snackbar.actions;
