import { lazy } from 'react';
import GuestGuard from '@/utils/routeGuard/GuestGuard';
import Loadable from 'components/Loadable';
import { Outlet } from 'react-router-dom';

const AuthLogin = Loadable(lazy(() => import('@/pages/authentication/Login')));
const ForgotPassword = Loadable(lazy(() => import('@/pages/authentication/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('@/pages/authentication/ResetPassword')));

const LoginRoutes = {
    path: '/',
    element: (
        <GuestGuard>
            <Outlet />
        </GuestGuard>
    ),
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/forgot-password',
            element: <ForgotPassword />
        },
        {
            path: '/reset-password/:code',
            element: <ResetPassword />
        },
    ]
};

export default LoginRoutes;
