import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { MonitoringUser, ClientsStore, StateType } from './clientsMonitoringTypes';
import { transformFilterToArray } from '@/components/dataGrid/SearchAdvanced/SearchAdvanced';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { Wlp } from '../user/types';
import { getWlpName } from '@/components/dataGrid/utils/Platform';

export const getPaginationParamsInit = (state: StateType) => {
	return {
		filter: `state=${state}`,
		orderBy: 'createdDate',
		sort: SortDirectionEnum.DESC,
		take: 25,
		skip: 0
	}
}

const initialState: ClientsStore = {
	[StateType.BLOCKED]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: getPaginationParamsInit(StateType.BLOCKED),
		filters: {
			[HeaderFilterType.WLPS]: [],
		},
		filtersSet: false
	},
	[StateType.FLAGGED]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: getPaginationParamsInit(StateType.FLAGGED),
		filters: {
			[HeaderFilterType.WLPS]: [],
		},
		filtersSet: false
	},
	[StateType.RISKED]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: getPaginationParamsInit(StateType.RISKED),
		filters: {
			[HeaderFilterType.WLPS]: [],
		},
		filtersSet: false
	},
	[StateType.SUSPENDED]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: getPaginationParamsInit(StateType.SUSPENDED),
		filters: {
			[HeaderFilterType.WLPS]: [],
		},
		filtersSet: false
	}
};


const slice = createSlice({
	name: 'clientsMonitoring',
	initialState,
	reducers: {
		setClients(state, action: PayloadAction<{ stateType: StateType, list: Array<MonitoringUser> }>) {
			state[action.payload.stateType].list = action.payload.list;
		},
		setLoading: (state, action: PayloadAction<{ stateType: StateType, loading: boolean }>) => {
			state[action.payload.stateType].loading = action.payload.loading;
		},
		setError: (state, action: PayloadAction<{ stateType: StateType, error: string }>) => {
			state[action.payload.stateType].error = action.payload.error;
		},
		setCount: (state, action: PayloadAction<{ stateType: StateType, count: number }>) => {
			state[action.payload.stateType].count = action.payload.count;
		},
		setPagination: (state, action: PayloadAction<{ stateType: StateType, pagination: PaginateParams }>) => {
			state[action.payload.stateType].pagination = action.payload.pagination;
		},
		setFilters: (state, action: PayloadAction<{ stateType: StateType, wlps: Array<Wlp>, }>) => {
			const wlpsOptions = action.payload.wlps.map(item => ({
				value: item.wlpId,
				text: getWlpName(item.wlpId),
				enabled: true
			}));
			state[action.payload.stateType].filters[HeaderFilterType.WLPS] = wlpsOptions;
			state[action.payload.stateType].filtersSet = true;
		},
		setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, stateType: StateType, options: Array<FilterOptionType> }>) => {
			state[action.payload.stateType].filters[action.payload.filterType] = action.payload.options;
		},
		setFiltersSet(state, action: PayloadAction<{ stateType: StateType, set: boolean }>) {
			state[action.payload.stateType].filtersSet = action.payload.set;
		}
	}
});

export const { setClients, setLoading, setError, setCount, setPagination, setFilters, setFilter, setFiltersSet } = slice.actions;

export const getMonitoredClients = (stateType: StateType, payload: PaginateParams) => {
	return async (dispatch: AppDispatch) => {
		try {
			const filterArray = transformFilterToArray(payload.filter);
			const wlpIdFilter = filterArray.find(p => p.field.toLowerCase() === 'wlpid');
			dispatch(setLoading({ stateType, loading: true }));
			const response = await request.get('/api/console/users/list', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take,
					...(wlpIdFilter?.value && { wlpId: wlpIdFilter.value.toUpperCase() })
				}
			});
			const { data } = response;
			dispatch(setClients({ stateType, list: data.list }));
			dispatch(setCount({ stateType, count: data.count }));
			dispatch(setPagination({ stateType, pagination: payload }));
			dispatch(setLoading({ stateType, loading: false }));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading({ stateType, loading: false }));
		}
	};
};

export default slice.reducer;
