import { PaginateParams } from "@/types/pagination";

export interface LogsStore {
    loading: boolean,
    list: Array<LogsType>,
    error: string | null,
    count: number,
    pagination: PaginateParams,
    openedSubTab: ConsoleType
};

export interface LogsType {
    id: number;
    activity: string;
    body: string;
    params: string;
    statusCode: string;
    aal_agent_code: string;
    method: string;
    aal_region: string;
    location: string;
    ipAddress: string;
    requestedDate: string;
    requestedBy: number;
    clientId: string;
    role: string;
    firstName: string;
    lastName: string;
};

export enum ConsoleType {
    CONSOLE = "CONSOLE",
    SMC = "SMC"
};