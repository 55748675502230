import * as React from "react";
import { SvgIcon } from '@mui/material';

const AttentionIcon = (props: any) => (
  <SvgIcon {...props}>
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.5}
        d="M12 0C9.227 0 7.476 3.105 3.974 9.314l-.436.774c-2.91 5.16-4.366 7.74-3.05 9.626C1.803 21.6 5.056 21.6 11.564 21.6h.872c6.508 0 9.761 0 11.076-1.886 1.316-1.887-.14-4.467-3.05-9.626l-.436-.774C16.524 3.104 14.773 0 12 0z"
        fill="#EFB930"
      />
      <path
        d="M12 5.1a.9.9 0 01.9.9v6a.9.9 0 01-1.8 0V6a.9.9 0 01.9-.9zM12 16.8a1.2 1.2 0 100-2.4 1.2 1.2 0 000 2.4z"
        fill="#EFB930"
      />
    </svg>
  </SvgIcon>
);
export default AttentionIcon;
