
import { Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";

const CardDetailsCell = (params: GridRenderCellParams) => {
    const details = params.value;
    if (!details || details === 'null, null, null')
        return <></>

    return (
        <Grid container alignItems='center' >
            <Typography>{details}</Typography>
        </Grid>
    );
};

export default CardDetailsCell;