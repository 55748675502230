import { SvgIcon } from '@mui/material';

const CardOrder = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#2B2B2B" fillOpacity="0.06" />
            <path d="M5.33398 11.9998C5.33398 9.48568 5.33398 8.2286 6.11503 7.44755C6.89608 6.6665 8.15316 6.6665 10.6673 6.6665H13.334C15.8481 6.6665 17.1052 6.6665 17.8863 7.44755C18.6673 8.2286 18.6673 9.48568 18.6673 11.9998C18.6673 14.514 18.6673 15.7711 17.8863 16.5521C17.1052 17.3332 15.8481 17.3332 13.334 17.3332H10.6673C8.15316 17.3332 6.89608 17.3332 6.11503 16.5521C5.33398 15.7711 5.33398 14.514 5.33398 11.9998Z" stroke="#40927E" strokeWidth="1.2" />
            <path d="M10.6667 14.6665H8" stroke="#40927E" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M13.334 14.6665H12.334" stroke="#40927E" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M5.33398 10.6665L18.6673 10.6665" stroke="#40927E" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default CardOrder;