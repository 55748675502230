import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import ChevronLeft from '@/icons/ChevronLeft';
import { dispatch, useSelector } from '@/store/store';
import { removeLastSubPath } from '@/redux/menu/menuSlice';

const GoBackTitle = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const { subPaths } = useSelector((state) => state.menu);

    const handleBack = (e: any) => {
        if (subPaths && subPaths.length > 1) {
            const lastSubPath = subPaths[subPaths.length - 2];
            dispatch(removeLastSubPath())
            navigate(lastSubPath.path, { ...lastSubPath.state && { state: lastSubPath.state } });
        } else {
            navigate(-1);
        }
    };

    return (
        <Grid container item
            xs='auto'
            alignItems='center'
            sx={{ cursor: 'pointer' }}
            onClick={handleBack}>
            <ChevronLeft fontSize='12px' color={theme.palette.primary.light} />
            <Typography
                pl='10px'
                fontSize='20px'
                onClick={(e) => { e.preventDefault(); }}
                component={Link}
                to={'..'}
                sx={{
                    textDecoration: 'none',
                    color: '#82918E',
                    fontWeight: 500
                }}>
                {subPaths && subPaths.length > 1
                    ? (subPaths.length === 2
                        ? t('pages.' + subPaths[subPaths.length - 2].title)
                        : subPaths[subPaths.length - 2].title)
                    : `${t('form.buttons.back')}`}
            </Typography>
        </Grid >
    );
}

export default GoBackTitle;
