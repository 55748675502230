import { FilterOptionType } from "@/components/dataGrid/CustomFilterHeader";
import { PaginateParams } from "@/types/pagination"

export interface EarnConfigStore {
    [key: string]: {
        loading: boolean,
        list: Array<EarnItem>,
        error: string | null,
        count: number,
        pagination: PaginateParams,
        filters: {
            [key: string]: Array<FilterOptionType>
        },
        filtersSet: boolean;
    }
}

export interface EarnItem {
    proc: string;
    serviceOwner: string;
    wlpId: string;
    vaultName: string;
    vaultDescription: string;
    yearlyInterestRate: number;
    depositPeriodMonths: number;
    ccy: string;
    createdAt: number;
    logoLink: string;
    vaultCount: number;
    activeVaultCount: number;
    vaultHolding: number;
    vaultDepositPaid: number;
    vaultDepositPaidString: number;
    vaultInterestPaid: number;
    vaultInterestPaidString: number;
    vaultDepositLiabilities: number;
    vaultDepositLiabilitiesString?: string;
    vaultInterestLiabilities: number;
    vaultInterestLiabilitiesString?: string;
    vaultDepositAutoReturns: number;
    vaultDepositAutoReturnsString?: string;
    vaultAccountBalance: number;
    vaultAccountBalanceString?: string;
    vaultInterestSystemAccountBalance?: number;
    vaultInterestSystemAccountBalanceString?: string;
    vaultInterestLiabilitiesNextMonth?: number;
    vaultInterestLiabilitiesNextMonthString?: string;
    vaultInterestLiabilitiesLate?: number;
    vaultInterestLiabilitiesLateString?: string;
    vaultRepaymentsSystemAccountBalance?: number;
    vaultRepaymentsSystemAccountBalanceString?: string;
    vaultDepositLiabilitiesNextMonth?: number;
    vaultDepositLiabilitiesNextMonthString?: string;
    vaultDepositLiabilitiesLate?: number;
    vaultDepositLiabilitiesLateString?: string;
    ccyDecimals?: number;
    accountIntegration?: string;
    processEnabled?: 'ENABLED' | 'DISABLED';
}

export enum EarnSectionType {
    CONFIGURATION = "CONFIGURATION",
    DEPOSITS = "DEPOSITS",
    INTEREST = "INTEREST",
    REPAYMENTS = "REPAYMENTS",
}

export const depositsIntegrations = ['FIREBLOCKS']
export interface CreatePlanPayload {
    generalInformation: {
        name?: string;
        platform?: string;
        logo?: File;
        currency?: string;
        description?: string;
    },
    configuration: {
        serviceProvider?: string;
        yearlyInterestRate?: number;
        depositPeriodMonths?: number;
        interestPaymentPeriod?: InterestPaymentPeriod;
        compoundInterest?: boolean;
        depositTrade?: boolean;
        dynamicVault?: boolean;
        autoExtendDays?: number;
        minDepositAmount?: number;
        integration?: string;
        interestType?: InterestType;
        maxDynamicInterestRate?: number;
        maxDepositCount?: number;
        targetAmount?: number;
        link?: string;
    },
    systemAccounts: {
        depositSystemAccount?: string;
        interestSystemAccount?: string;
        repaymentSystemAccount?: string;
    }

}

export enum InterestPaymentPeriod {
    DAY = "DAY",
    WEEK = "WEEK",
    MONTH = "MONTH",
    YEAR = "YEAR",
}

export enum InterestType {
    PERIODIC_INTEREST = "PERIODIC_INTEREST",
    DYNAMIC = "DYNAMIC",
    PERIODIC_INTEREST_PLUS_DYNAMIC = "PERIODIC_INTEREST_PLUS_DYNAMIC"
}

export interface CreateVaultPlanPayload {
    name: string | undefined;
    description: string | undefined;
    yearlyInterestRate: number | undefined;
    compoundInterest: boolean | undefined;
    interestPaymentPeriod: string | undefined;
    depositPeriodMonths: number | undefined;
    autoExtendDays: number | undefined;
    currency: string | undefined;
    minDepositAmount: number | undefined;
    depositTrade: boolean | undefined;
    serviceProvider: string | undefined;
    platform: string | undefined;
    repaymentSystemAccount?: string | null;
    interestSystemAccount?: string | null;
    depositSystemAccount?: string | null;
    interestType?: InterestType;
    maxDynamicInterestRate?: number;
    maxDepositCount?: number;
    targetAmount?: number;
    link?: string;
};

export interface CryptoAddresses {
    accountAddresses: {
        address: string,
        network: string,
    }[]
}
export interface EarnPlanConfigurationDetails {
    proc: string;
    autoExtendDays: number;
    compoundInterest: boolean;
    createdBy: number;
    createdByName: string;
    createdDate: string;
    currency: string;
    depositPeriodMonths: string;
    depositTrade: boolean;
    description: string;
    interestPaymentPeriod: InterestPaymentPeriod;
    logoLink: string;
    minDepositAmount: number;
    name: string;
    serviceProvider: string;
    systemAccountIntegration: string;
    updatedBy: number;
    updatedByName: string;
    updatedDate: string;
    wlpId: string;
    yearlyInterestRate: string;
    vaultAccount: EarnPlanConfigurationSystemAccount;
    systemVaultInterestAccount: EarnPlanConfigurationSystemAccount;
    vaultRepaymentAccount: EarnPlanConfigurationSystemAccount;
    hidden: boolean;
    interestType: InterestType;
    maxDynamicInterestRate?: number;
    maxDepositCount: number;
    targetAmount: number;
    link: string;
    depositDisabled: boolean;
}

export interface EarnPlanConfigurationSystemAccount {
    walletId: string;
    balanceString: string;
    currencyCode: string;
    displayDecimals: number;
}

export interface UserGroup {
    name: string;
    id: number;
    usersCount: number;
}

export interface VaultPlanProcess {
    wlpProcessId: number;
    userGroupId: number | null;
    userLevelId: number | null;
    userProfileType: string | null;
    config: {
        disabled: boolean;
    }
}

export interface VaultPlanAvailabilityPayload {
    status: VaultPlanAvailabilityStatus;
    userGroupId?: number;
    userLevelId?: number;
    userProfileType?: string;
    disabled?: boolean;
}

export enum VaultPlanAvailabilityStatus {
    ENABLED_FOR_ALL = "ENABLED_FOR_ALL",
    DISABLED_FOR_ALL = "DISABLED_FOR_ALL",
    ENABLED_PARTIALLY = "ENABLED_PARTIALLY"
}