import { Grid, Typography, useTheme } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import Copy from "@/icons/Copy";
import { showSuccessNotification } from "@/utils/errors";
import { t } from "@/utils/translate";

interface Props {
    chainId: number;
    trxHash: string | null;
}

const TransactionHash = ({ chainId, trxHash }: Props) => {
    const theme = useTheme();
    const getNetworkData = () => {
        switch (chainId) {
            case 1:
                return {
                    link: 'https://etherscan.io/tx/',
                    name: 'View on etherscan'
                };
            case 137:
                return {
                    link: 'https://polygonscan.com/tx/',
                    name: 'View on polygonscan'
                };
            case 42161:
                return {
                    link: 'https://arbiscan.io/tx/',
                    name: 'View on arbiscan'
                };
            case 10:
                return {
                    link: 'https://optimistic.etherscan.io/tx/',
                    name: 'View on optimistic'
                };
            default:
                return {
                    link: null, name: '-'
                };
        }
    }


    const link = getNetworkData().link;
    const name = getNetworkData().name;

    const navigateToExternalLink = () => {
        window.open(`${link}${trxHash}`, '_blank')
    }


    return (
        <>
            {trxHash ? <Grid container alignItems='center'>
                <Typography sx={{ cursor: 'pointer' }} onClick={navigateToExternalLink} variant="body1" color={theme.palette.blue} mr={1}>{name}</Typography>
                <CopyToClipboard text={trxHash} onCopy={() => showSuccessNotification(t('form.buttons.copiedSuccessfully'))}>
                    <Copy onClick={(e: any) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }} sx={{ fontSize: '0.813rem', cursor: 'pointer' }} />
                </CopyToClipboard >
            </Grid> :
                <Typography variant="body1">-</Typography>
            }
        </>

    );
}

export default TransactionHash;