import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BalanceType, SubTabsEnum, SystemBalanceGraphic } from "../emiFinance/emiFinanceTypes";
import { CryptoUserBreakdownType, EmiFinanceCryptoStore } from "./emiFinanceCryptoTypes";
import { AppDispatch } from "@/store/store";
import request from "@/services/request";
import { Payment, PaymentsSplit } from "../emiFinanceBanking/emiFinanceBankingTypes";
import { paginationParamsInit } from "../emiFinanceBanking/emiFinanceBankingSlice";
import { PaginateParams } from "@/types/pagination";

const initialState: EmiFinanceCryptoStore = {
    activeTab: SubTabsEnum.ALL,
    userBalances: [],
    fireblocks: [],
    exchanges: [],
    custody: [],
    loading: {
        userBalancesLoading: true,
        fireblocksLoading: true,
        exchangeOwnedLoading: true,
        exchangeUtilizedLoading: true,
        unassignedPaymentsLoading: true
    },
    userBreakdown: [],
    unassignedPaymentsSplit: [],
    unassignedPayments: {
        loadingPayments: false,
        count: 0,
        list: [],
        error: null,
        pagination: paginationParamsInit
    },
};

const slice = createSlice({
    name: 'emiFinanceCrypto',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<{ [key: string]: boolean }>) {
            state.loading = { ...state.loading, ...action.payload };
        },
        setUserBalances(state, action: PayloadAction<Array<BalanceType>>) {
            state.userBalances = action.payload;
        },
        setFireblocks(state, action: PayloadAction<Array<BalanceType>>) {
            state.fireblocks = action.payload;
        },
        setExchanges(state, action: PayloadAction<Array<BalanceType>>) {
            state.exchanges = action.payload;
        },
        setCustody(state, action: PayloadAction<Array<BalanceType>>) {
            state.custody = action.payload;
        },
        setUnassignedPayments(state, action: PayloadAction<Array<PaymentsSplit>>) {
            state.unassignedPaymentsSplit = action.payload;
        },
        setUserBreakdown(state, action: PayloadAction<Array<CryptoUserBreakdownType>>) {
            state.userBreakdown = action.payload;
        },
        setActiveTab(state, action: PayloadAction<SubTabsEnum>) {
            state.activeTab = action.payload;
        },
        // Unassigned payments
        setPayments(state, action: PayloadAction<Array<Payment>>) {
            state.unassignedPayments.list = action.payload;
        },
        setCount(state, action: PayloadAction<number>) {
            state.unassignedPayments.count = action.payload;
        },
        setLoadingPayments(state, action: PayloadAction<boolean>) {
            state.unassignedPayments.loadingPayments = action.payload;
        },
        setPagination: (state, action: PayloadAction<PaginateParams>) => {
            state.unassignedPayments.pagination = action.payload;
        }
    }
});

export const {
    setLoading,
    setUserBalances,
    setUserBreakdown,
    setFireblocks,
    setExchanges,
    setCustody,
    setActiveTab,
    setUnassignedPayments,
    setPayments,
    setCount,
    setLoadingPayments,
    setPagination
} = slice.actions;

export default slice.reducer;

export const getCryptoClientGraphic = async (integration: string, dateFrom: string, dateTo: string): Promise<Array<SystemBalanceGraphic>> => {
    const response = await request.get(`/api/console/emi-finance/crypto/client/graphic/${integration}?dateFrom=${dateFrom}&dateTo=${dateTo}`);
    return response?.data;
};

export const getCryptoExchangeGraphic = async (integration: string, dateFrom: string, dateTo: string, uiType: string): Promise<Array<SystemBalanceGraphic>> => {
    const response = await request.get(`/api/console/emi-finance/crypto/exchange/graphic/${uiType}/${integration}?dateFrom=${dateFrom}&dateTo=${dateTo}`);
    return response?.data;
};

export const getCryptoCustomerBalancesAll = () => {
    return async (dispatch: AppDispatch) => {
        try {

            dispatch(setLoading({ customerBalancesLoading: true }));
            const response = await request.get(`/api/console/emi-finance/crypto/all/customers`);
            const data = response?.data || [];
            dispatch(setUserBalances(data));
        }
        catch (e) {
            dispatch(setUserBalances([]));
        }
        finally {
            dispatch(setLoading({ customerBalancesLoading: false }));
        }
    }
};

export const getCryptoCustomerBreakdown = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ userBalancesLoading: true }));
            const response = await request.get(`/api/console/emi-finance/crypto/customers/breakdown`);
            const data = response?.data || [];
            dispatch(setUserBreakdown(data));
        }
        catch (e) {
            dispatch(setUserBreakdown([]));
        }
        finally {
            dispatch(setLoading({ userBalancesLoading: false }));
        }
    }
};

export const getCryptoFireblocksAll = () => {
    return async (dispatch: AppDispatch) => {
        try {

            dispatch(setLoading({ fireblocksLoading: true }));
            const response = await request.get(`/api/console/emi-finance/crypto/all/fireblocks`);
            const data = response?.data || [];
            dispatch(setFireblocks(data));
        }
        catch (e) {
            dispatch(setFireblocks([]));
        }
        finally {
            dispatch(setLoading({ fireblocksLoading: false }));
        }
    }
};

export const getCryptoExchangeOwned = () => {
    return async (dispatch: AppDispatch) => {
        try {

            dispatch(setLoading({ exchangeOwnedLoading: true }));
            const response = await request.get(`/api/console/emi-finance/crypto/exchanges/owned`);
            const data = response?.data || [];
            dispatch(setExchanges(data));
        }
        catch (e) {
            dispatch(setExchanges([]));
        }
        finally {
            dispatch(setLoading({ exchangeOwnedLoading: false }));
        }
    }
};

export const getCryptoExchangeUtilized = () => {
    return async (dispatch: AppDispatch) => {
        try {

            dispatch(setLoading({ exchangeUtilizedLoading: true }));
            const response = await request.get(`/api/console/emi-finance/crypto/exchanges/utilized`);
            const data = response?.data || [];
            dispatch(setCustody(data));
        }
        catch (e) {
            dispatch(setCustody([]));
        }
        finally {
            dispatch(setLoading({ exchangeUtilizedLoading: false }));
        }
    }
};

export const getCryptoUnassignedPayments = (integration: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ unassignedPaymentsLoading: true }));
            const response = await request.get(`/api/console/emi-finance/banking/payments/split/${integration}`);
            const data = response?.data || [];
            dispatch(setUnassignedPayments(data));
        }
        catch (e) {
            dispatch(setUnassignedPayments([]));
        }
        finally {
            dispatch(setLoading({ unassignedPaymentsLoading: false }));
        }
    }
};

export const getCryptoPaymentsList = (params: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            const integration = 'COINBASE';
            dispatch(setLoadingPayments(true));
            const response = await request.get(`/api/console/emi-finance/banking/payments/list/${integration}`, {
                params: {
                    filter: params.filter,
                    orderBy: params.orderBy,
                    sort: params.sort.toUpperCase(),
                    skip: params.skip.toString(),
                    take: params.take,
                }
            });
            const { data } = response;
            dispatch(setPayments(data.list));
            dispatch(setCount(data.count));
            dispatch(setPagination(params));
        }
        catch (e) {
            dispatch(setPayments([]));
        }
        finally {
            dispatch(setLoadingPayments(false));
        }
    }
};