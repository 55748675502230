import { Filter } from "@/components/filter/CustomDataGridFilter";
import { InvoiceParty, OwnerTypeEnum } from "../emiBilling/emiBillingTypes";

export interface FinancialConfigurationsStore {
    openedSubTab: FinancialConfigurationsTab;
    filtersConfig: {
        [key: string]: {
            filtersSet: boolean
            filters: Array<Filter>
        }
    }
}

export enum FinancialConfigurationsTab {
    COUNTERPARTIES = 'COUNTERPARTIES',
    INVOICE_CONFIG = 'INVOICE_CONFIG',
    TAX_RATES = 'TAX_RATES',
    PAYMENTS_CONFIG = 'PAYMENTS_CONFIG'
};

export interface PartyDetailsPayload {
    id: number;
    partyClientId?: string;
    partyName: string;
    partyRegNumber: string;
    partyInvoiceAddress: string;
    invoiceCountry: string;
    invoicePostalCode: string;
    emiWlpId: string;
    wlpId: string;
    payable: boolean;
    receivable: boolean;
    country: string;
    taxCountry: string;
    taxCountryRegion: string;
    logoUrl?: string;
};

export interface InvoiceConfigPayload {
    configId: number;
    ownerType: OwnerTypeEnum;
    ownerValue: string;
    legalNotice: string;
    otherDetails: string;
    bankDetails: BankDetails;
};

export interface BankAccount {
    bic: string;
    iban: string;
    name: string;
    bankCountry: string;
    sortNumber?: string;
    accountNumber?: string;
};

interface BankDetails {
    bankAccounts: Array<BankAccount>;
}

export interface InvoiceConfigDetails {
    id: number;
    ownerType: OwnerTypeEnum;
    ownerValue: string;
    invoiceSide: string;
    invoiceCategory: string;
    invoiceType: string;
    currencyCode: string;
    issuerPartyId: InvoiceParty;
    billToPartyId: InvoiceParty;
    legalNotice: string;
    otherDetails: string;
    bankDetails: BankDetails;
    createOppositeInv: boolean;
};