import * as React from "react";
import { SvgIcon } from '@mui/material';

const Cards = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path d="M1.66797 9.9987C1.66797 6.856 1.66797 5.28465 2.64428 4.30834C3.62059 3.33203 5.19194 3.33203 8.33464 3.33203H11.668C14.8107 3.33203 16.382 3.33203 17.3583 4.30834C18.3346 5.28465 18.3346 6.856 18.3346 9.9987C18.3346 13.1414 18.3346 14.7127 17.3583 15.6891C16.382 16.6654 14.8107 16.6654 11.668 16.6654H8.33464C5.19194 16.6654 3.62059 16.6654 2.64428 15.6891C1.66797 14.7127 1.66797 13.1414 1.66797 9.9987Z" stroke="currentColor" strokeWidth="1.2" />
            <path opacity="0.5" d="M8.33333 13.332H5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path opacity="0.5" d="M11.668 13.332H10.418" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path opacity="0.5" d="M1.66797 8.33203L18.3346 8.33203" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default Cards;