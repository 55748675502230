import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '@/utils/errors';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { EarnPendingRequestsStore } from './earnPendingRequestsTypes';

export const paginationParamsInit = {
    filter: '',
    orderBy: 'createdAt',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: EarnPendingRequestsStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    pagination: paginationParamsInit
};

const slice = createSlice({
    name: 'earnPendingRequests',
    initialState,
    reducers: {
        setList(state, action: PayloadAction<Array<any>>) {
            state.list = action.payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
            state.pagination = payload;
        },
    }
});

export const { setList, setLoading, setError, setCount, setPagination } = slice.actions;

export const getEarnPendingRequestsList = (payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/vaults/request/pending`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setList(data.list));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            dispatch(setLoading(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading(false));
        }
    };
};

export const toggleEarnPendingRequest = async (vaultId: number, approve: boolean): Promise<string[]> => {
    const response = await request.get(`/api/console/vaults/request/pending/${vaultId}`, {
        params: {
            aeOff: approve
        }
    });
    const { data } = response;
    return data;
};




export default slice.reducer;