import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { ReferralStore, RefereeType, ReferralPaymentType } from './referralTypes';

export const refereePaginationParamsInit = {
	filter: '',
	orderBy: 'referee',
	sort: SortDirectionEnum.DESC,
	take: 25,
	skip: 0
};

export const paymentsPaginationParamsInit = {
	filter: '',
	orderBy: 'referee',
	sort: SortDirectionEnum.DESC,
	take: 25,
	skip: 0
};

const initialState: ReferralStore = {
	referrals: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: refereePaginationParamsInit
	},
	payments: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: paymentsPaginationParamsInit
	}
};

const slice = createSlice({
	name: 'referrals',
	initialState,
	reducers: {
		setReferrals(state, action: PayloadAction<Array<RefereeType>>) {
			state.referrals.list = action.payload;
		},
		setLoadingReferrals: (state, { payload }: PayloadAction<boolean>) => {
			state.referrals.loading = payload;
		},
		setErrorReferrals: (state, { payload }: PayloadAction<string>) => {
			state.referrals.error = payload;
		},
		setCountReferrals: (state, { payload }: PayloadAction<number>) => {
			state.referrals.count = payload;
		},
		setPaginationReferrals: (state, { payload }: PayloadAction<PaginateParams>) => {
			state.referrals.pagination = payload;
		},
		setReferralPayments(state, action: PayloadAction<Array<ReferralPaymentType>>) {
			state.payments.list = action.payload;
		},
		setLoadingPayments: (state, { payload }: PayloadAction<boolean>) => {
			state.payments.loading = payload;
		},
		setErrorPayments: (state, { payload }: PayloadAction<string>) => {
			state.payments.error = payload;
		},
		setCountPayments: (state, { payload }: PayloadAction<number>) => {
			state.payments.count = payload;
		},
		setPaginationPayments: (state, { payload }: PayloadAction<PaginateParams>) => {
			state.payments.pagination = payload;
		}
	}
});

export const {
	setReferrals,
	setLoadingReferrals,
	setErrorReferrals,
	setCountReferrals,
	setPaginationReferrals,
	setReferralPayments,
	setLoadingPayments,
	setErrorPayments,
	setCountPayments,
	setPaginationPayments } = slice.actions;

export const getUserReferrals = (userId: number, payload: PaginateParams) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoadingReferrals(true));
			const response = await request.get(`/api/console/referrals/list/${userId}`, {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setReferrals(data.list));
			dispatch(setCountReferrals(data.count));
			dispatch(setPaginationReferrals(payload));
			dispatch(setLoadingReferrals(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoadingReferrals(false));
		}
	};
};


export const getReferralPayments = (payload: PaginateParams) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoadingPayments(true));
			const response = await request.get(`/api/console/referrals/payments`, {
				params: {
					isSystem: false,
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setReferralPayments(data.list));
			dispatch(setCountPayments(data.count));
			dispatch(setPaginationPayments(payload));
			dispatch(setLoadingPayments(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoadingPayments(false));
		}
	};
};


export default slice.reducer;
