import ChevronLeft from "@/icons/ChevronLeft";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

interface Props {
    title: string,
    onClick?: () => void
}

const FormBack = ({ title, onClick }: Props) => {

    return (
        <Stack direction='row' onClick={onClick} sx={{ cursor: onClick ? 'pointer' : 'auto' }}>
            {onClick && <ChevronLeft />}
            <Typography variant="h2" ml={2} >{title}
            </Typography>
        </Stack>);
}

export default FormBack;
