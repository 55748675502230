import TypographyWithEllipsis from "@/components/TypographyWithEllipsis";
import { getTrimmedValue } from "@/components/dataGrid/renderCell/TransactionHashCell";
import { getIsCrypto } from "@/redux/currencies/currenciesSlice";
import { PaymentConfig } from "@/redux/platformInvoicesAndPayouts/platformInvoicesAndPayoutsTypes";
import { getIsFiat } from "@/utils/currency";
import { Grid, Tooltip, Typography } from "@mui/material";

interface Props {
    paymentConfig: PaymentConfig;
}

const PayeeConfigCell = ({ paymentConfig }: Props) => {
    const currencyCode = paymentConfig.currencyCode;

    const isFiat = getIsFiat(currencyCode);
    const isCrypto = getIsCrypto(currencyCode);

    const upperValue = isFiat ? paymentConfig.iban : (isCrypto ? paymentConfig.cryptoAddress : '');
    const lowerValue = isCrypto ? paymentConfig.cryptoNetwork : '';

    return (
        <Grid container alignItems='center' columnGap={1}>
            <Grid container item >
                {upperValue &&
                    <Grid container item xs={12}>
                        {isCrypto ? (
                            <Grid item>
                                <Tooltip
                                    title={upperValue}
                                    placement="top"
                                    PopperProps={{
                                        modifiers: [
                                            {
                                                name: 'offset',
                                                options: {
                                                    offset: [0, -5],
                                                },
                                            },
                                        ],
                                    }}>
                                    <Typography variant="body2" color='text.primary' sx={{ fontWeight: 500 }} component="span">
                                        {getTrimmedValue(upperValue)}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        ) : (
                            <TypographyWithEllipsis variant="body2" color='text.primary' sx={{ fontWeight: 500 }}>
                                {upperValue}
                            </TypographyWithEllipsis>
                        )}
                    </Grid>}
                {lowerValue &&
                    <Grid item xs={12}>
                        <TypographyWithEllipsis variant="body2" color='text.secondary'>
                            {lowerValue}
                        </TypographyWithEllipsis>
                    </Grid>}
            </Grid>
        </Grid>
    );
};

export default PayeeConfigCell;