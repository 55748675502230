import { useEffect, useMemo, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { styled, useTheme, Theme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import Header from './Header';
import Sidebar from './Sidebar/Sidebar';
import useConfig from 'hooks/useConfig';
import { appCollapsedDrawerWidth, drawerWidth } from '@/utils/constant';
import { openDrawer } from '@redux/menu/menuSlice';
import { useDispatch, useSelector } from '@/store/store';

interface MainStyleProps {
    theme: Theme;
    open: boolean
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        width: `calc(100% - ${appCollapsedDrawerWidth}px)`,
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),

        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
    }),
}));


const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const { container } = useConfig();

    useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const header = useMemo(
        () => (
            <Toolbar sx={{
                padding: 0,
                paddingLeft: '0!important',
                paddingRight: '0!important'
            }} >
                <Header />
            </Toolbar>
        ),
        []
    );

    const windowScrollRef = useRef<any>();
    const drawerScrollRef = useRef<any>();

    const drawerPaper = document.querySelector('#side-bar .MuiDrawer-paper');
    drawerScrollRef.current = drawerPaper;

    useEffect(() => {
        let prevScrollY = window.scrollY;
        const handleWindowScroll = () => {
            if (drawerScrollRef.current) {
                const scrollDiff = window.scrollY - prevScrollY;
                drawerScrollRef.current.scrollTop += scrollDiff;
                prevScrollY = window.scrollY;
            }
        };
        window.addEventListener('scroll', handleWindowScroll);
        windowScrollRef.current = window;
        return () => {
            window.removeEventListener('scroll', handleWindowScroll);
        };
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.primary.dark,
                    borderBottom: `1px solid ${theme.palette.white.light}`,
                }}
            >
                {header}
            </AppBar>
            <Sidebar />
            <Main theme={theme} open={drawerOpen}>
                {container && (
                    <Container maxWidth="lg">
                        <Outlet />
                    </Container>
                )}
                {!container && (
                    <>
                        <Outlet />
                    </>
                )}
            </Main>
        </Box >
    );
};

export default MainLayout;
