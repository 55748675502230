import React, { useEffect } from 'react';
import { useNavigate } from "react-router";
import request from '@services/request';
import { useDispatch } from '@/store/store';
import { TwoFaErrorParamType } from '@/redux/twoFa/twoFaTypes';
import { open2FAModal, request2FA, set2FALoading, set2FAMessage, setSetupMissing } from '@/redux/twoFa/twoFaSlice';
import { useTranslation } from 'react-i18next';
import { dismissAllNotifications } from '@/utils/errors';
import { logout } from '@/redux/user/userSlice';

const Interceptors = (): React.ReactElement => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const getStatus = (err: any) => {
		const response = err?.response;
		if (response) {
			const status = response?.status;
			return status;
		} else {
			const status = err?.status;
			return status;
		}
	};


	const getErrorAndParam = (err: any) => {

		if (!err) return { error: null, errorParam: null, message: null };

		const data = err?.response ? err?.response?.data : err?.data;
		if (!data) return { error: null, errorParam: null, message: null };

		if (data?.errors && data?.errors?.length > 0) {
			return { error: data.errors[0].error, errorParam: data.errors[0]?.error_param, message: data?.message }
		}
		else if (data?.error) {
			return { error: data.error, errorParam: data?.error_param, message: data?.message }
		}
		else {
			return { error: null, errorParam: null, message: null };
		}
	}

	useEffect(() => {
		// Register intersectors
		request.interceptors.request.use(
			(config) => {
				// Get token and add it to header "Authorization"
				const credentialsKey = process.env.REACT_APP_CREDENTIALS_KEY ?? 'credentials';
				try {
					const key = sessionStorage.getItem(credentialsKey);
					const credentials = key ? JSON.parse(key) : undefined;
					const isAwsDownload = config?.params?.isAwsDownload ?? false;
					if (isAwsDownload) {
						config.params = {};
						delete config.headers.Authorization;
						return config;
					}
					// If user not authorized just forward the request further
					if (!credentials) {
						return config;
					}
					config.headers.Authorization = credentials.token;
				}
				catch (e) {
					console.log(e);
				}
				if (config.headers['Content-Type'] !== 'multipart/form-data') {
					config.headers['Content-Type'] = 'application/json';
				}

				return config;
			},
			(error) => Promise.reject(error)
		);
		request.interceptors.response.use(undefined, (err) => {

			try {
				//abort of axios call
				//console.log(err);

				if (err?.code === 'ERR_CANCELED') {
					return Promise.reject(err);
				}



				const response = err?.response ? err.response : err;
				const status = getStatus(err);
				const errorData = getErrorAndParam(err);
				const originalRequest = err?.config;

				if (errorData.error === 'required' && errorData.errorParam === TwoFaErrorParamType.AUTH_CODE) {
					dispatch(set2FAMessage(t('form.twofa.mandatory')));
					dispatch(open2FAModal());
					return new Promise((resolve, reject) => {
						request2FA(originalRequest, resolve, reject, response);
					});
				}
				else if (errorData.error === 'required' && errorData.errorParam === TwoFaErrorParamType.SETUP_AUTH_CODE) {
					dispatch(setSetupMissing(true));
					dispatch(open2FAModal());
					return new Promise((resolve, reject) => {
						//do not call catch block
						request2FA(originalRequest, resolve, reject, response);
					});
				}
				else if ((errorData.error === 'invalid' && errorData.errorParam === TwoFaErrorParamType.AUTH_CODE) ||
					errorData.message === 'wrong two factor code') {
					setTimeout(() => dispatch(set2FALoading(false)), 1000);
					dispatch(set2FAMessage(t('form.twofa.invalid')));
					if (originalRequest.url !== '/api/console/auth/2fa/enable') {
						dispatch(open2FAModal());
					}
					if (originalRequest.url === '/api/console/auth/2fa/enable') {
						return Promise.reject(response);
					}
					else {
						return new Promise((resolve, reject) => {
							request2FA(originalRequest, resolve, reject, response);
						});
					}
				}

				if (status === 403) {
					navigate('not-permitted');
				}

				if (status === 401) {
					dismissAllNotifications();
					dispatch(logout());
				}

				if (status === 503) {
					dismissAllNotifications();
				}
				return Promise.reject(response);

			} catch (e) {
				return Promise.reject(e);
			}
		});
		// eslint-disable-next-line 
	}, [dispatch, navigate]);

	return (
		<div id="interceptors"></div>
	);
};

export default Interceptors; 