import { PaginateParams } from '@/types/pagination';
import { UserType } from '../user/types';
import { FilterOptionType } from '@/components/dataGrid/CustomFilterHeader';

export interface Client {
    clientId: string;
    email: string;
    emailConfirmed: boolean;
    createdDate: string;
    type: string;
    disabled: boolean;
    detained: boolean;
    firstName: string;
    lastName: string;
    country: string;
    companyName: string;
    companyRegNumber: string;
    kycTier: number;
    lastLoginDate: string;
    id: number;
    detainedReason: string;
    riskGroupCode: string;
    riskGroupFixed: boolean;
    twoFactorAuthEnabled: boolean;
    mobilePinEnabled: boolean;
    role: string;
    profile: ClientProfile;
    adminRole: ClientRole;
    usedInvitationCode?: string;
    kyc?: Array<KYC>;
    confirmedMobileNumber: string;
    mobileNumber: string;
    mobileNumberConfirmed?: boolean;
    poaStatus: { rejectionReason: string; status: string };
    userLevel: any;
    source: string;
    wlpAdminWlpId: string;
    status: string;
    referralUserId: number;
    tag: string;
    referralUser: {
        clientId: string;
        source: string;
        tag: string;
        status: string;
        profile: {
            firstName: string;
            lastName: string;
            companyName: string;
            type: string;
        };
    };
    userId: number;
    externalId?: string,
}

export interface KYC {
    id: number;
    status: string;
    rejectionReason: string;
    tier: number;
    checkCount: number;
    kycIdentity: {
        service: string;
        serviceId: string;
    }
}

export interface ClientRole {
    code: string;
    id: number;
    label: string;
    ownerRole: boolean;
}

export interface ClientProfile {
    address: string;
    birthDate: string;
    companyName: string;
    companyAddress: string;
    companyCountry: string;
    companyRegNumber: string;
    country: string;
    addressCountry: string;
    city: string;
    postalCode: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    politicalExposed: boolean;
    type: UserType;
    usCitizen: boolean;
    sumSubChanged: boolean;
}

export interface ClientsStore {
    [key: string]: {
        loading: boolean;
        list: Array<Client>;
        error: string | null;
        count: number;
        pagination: PaginateParams;
        filters: {
            [key: string]: Array<FilterOptionType>
        };
        filtersSet: boolean;
    }
}

export enum ClientStatusEnum {
    VERIFIED = 'VERIFIED',
    DISABLED = 'DISABLED',
    UNVERIFIED = 'UNVERIFIED',
    SUSPENDED = 'SUSPENDED',
    SKIP_KYC = 'SKIP_KYC'
}
