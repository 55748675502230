import * as React from "react";
import { SvgIcon } from '@mui/material';

const UserAccount = (props:any) => (
  <SvgIcon {...props}>
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse
      cx={12}
      cy={17.5}
      rx={7}
      ry={3.5}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <circle
      cx={12}
      cy={7}
      r={4}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </svg>
  </SvgIcon>
);
export default UserAccount;
