import { Theme, Drawer as MuiDrawer, DrawerProps, Grid } from '@mui/material';
import { memo } from 'react';
import { styled, CSSObject } from '@mui/material/styles';
import { appCollapsedDrawerWidth, appMobileDrawerWidth, drawerWidth } from '@/utils/constant';
import DrawerContent from './DrawerContent';
import { useDispatch } from 'react-redux';
import { useSelector } from '@/store/store';
import { openDrawer } from '@/redux/menu/menuSlice';

const drawerTransition = (theme: Theme, property: string): CSSObject => ({
    transition: theme.transitions.create(property, {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.short,
    }),
});

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    overflowX: 'hidden',
    ...drawerTransition(theme, 'width'),
});

const closedMixin = (theme: Theme): CSSObject => ({
    overflowX: 'hidden',
    width: `${appCollapsedDrawerWidth}px`,
    [theme.breakpoints.down('sm')]: {
        width: `${appMobileDrawerWidth}px`,
    },
    ...drawerTransition(theme, 'width'),
});


const DrawerStyled = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({

        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const CustomDrawer = (props: DrawerProps) => {
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const handleDrawerCloseClose = () => {
        dispatch(openDrawer(!drawerOpen))
    };

    const handleDrawerClose = () => {
        dispatch(openDrawer(false))
    };

    return (
        <Grid>
            <DrawerStyled {...props}
                variant="permanent"
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerClose}
                color="inherit"
                sx={theme => ({
                    '& .MuiDrawer-paper': {
                        background: theme.palette.primary.dark,
                        borderRight: `1px solid ${theme.palette.white.light}`,
                    }
                })}
            >
                <DrawerContent drawerOpen={drawerOpen} onClick={handleDrawerCloseClose} />
            </DrawerStyled>
        </Grid >
    );
};

export default memo(CustomDrawer);