import DialogTitle from "@/components/DialogTitle";
import AccountDropdown2 from "@/components/form/AccountDropdown2";
import FormTextField from "@/components/form/FormTextField";
import AmountField from "@/pages/finance/Transfer/components/AmountField";
import { getClientAccounts } from "@/redux/accounts/accountsSlice";
import { Account } from "@/redux/accounts/accountsTypes";
import { doActionWithCard } from "@/redux/cards/cardsSlice";
import { CardDetails } from "@/redux/cards/cardsTypes";
import { useAmount } from "@/utils/customHooks/useValidations";
import { showErrorNotification, showSuccessNotification } from "@/utils/errors";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';

interface Props {
    open: boolean,
    type: 'CARD_SUSPEND' | 'CARD_TOP_UP' | 'CARD_BLOCK' | 'CARD_PULL_OUT' | 'CARD_BLOCK_AND_PULL_OUT'
    onClose: (withRefetch?: boolean) => void,
    card: CardDetails,
}

const EmiCardActionsDialog = ({ open, card, onClose, type }: Props) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [accountsList, setAccountsList] = useState<any[]>([]);

    const [amount, setAmount] = useState<string>('');
    const [activeAccount, setActiveAccount] = useState<Account | undefined>();

    const validAmount = useAmount(activeAccount?.balance);
    const validAmountCard = useAmount(card?.availableAmount);

    const fetchData = useCallback(() => {
        if (!card.wlpId || !card.userId || !card.currency) return;

        const get = async () => {
            setLoading(true);
            try {
                const data = await getClientAccounts(card.wlpId, card.userId, card.currency);
                setAccountsList(data.list);
                // if (data.list.length > 0) {
                //     setActiveAccount(data.list[0] as Account);
                // }

            } catch (e) {
                showErrorNotification(e);
            } finally {
                setLoading(false);
            }
        };
        get();
    }, [card.wlpId, card.userId, card.currency]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const validationSchema = Yup.object({
        account: type === 'CARD_SUSPEND' || type === 'CARD_BLOCK' ? Yup.number().optional().nullable() : Yup.number().required(t('form.validator.required').toString()).nullable(),
        amount: type === 'CARD_PULL_OUT' ? validAmountCard : type === 'CARD_TOP_UP' ? validAmount : Yup.number().optional().nullable(),
        reason: Yup.string()
            .min(6, t('form.validator.minChar', { chars: 6 }).toString())
            .max(140, t('form.validator.maxChar', { chars: 140 }).toString())
            .required(t("form.validator.required").toString()),
    });

    const amountChange = (value: string, setFieldValue: any, setFieldTouched: any) => {
        setFieldValue('amount', value);
        setAmount(value);
        setTimeout(() => setFieldTouched('amount', true));
    };

    const handleAmountChange = (event: ChangeEvent<HTMLInputElement>, setFieldValue: any, setFieldTouched: any) => {
        amountChange(event.target.value, setFieldValue, setFieldTouched);
    };

    const handleAccountAccountChange = (item: Account | undefined) => {
        setActiveAccount(item)
    }

    const setAmountToMax = async (setFieldValue: any, setFieldTouched: any) => {
        if (type === 'CARD_PULL_OUT') {
            amountChange(`${card?.availableAmount ?? 0}`, setFieldValue, setFieldTouched);
        } else {
            amountChange(`${activeAccount?.balance ?? 0}`, setFieldValue, setFieldTouched);
        }
    };


    const handleSubmit = async (formData: any, formikProps: any) => {
        const { setSubmitting } = formikProps;
        const { reason } = formData;
        if ((type === 'CARD_BLOCK_AND_PULL_OUT' || type === 'CARD_PULL_OUT' || type === 'CARD_TOP_UP') && !activeAccount) return;
        if ((type === 'CARD_PULL_OUT' || type === 'CARD_TOP_UP') && !amount) return;

        let _payload = {};
        try {
            setSubmitting(true);
            switch (type) {
                case 'CARD_BLOCK':
                    _payload = {
                        isReplaced: false,
                        reason: reason,
                    }
                    break;
                case 'CARD_BLOCK_AND_PULL_OUT':
                    _payload = {
                        accountToId: activeAccount?.id,
                        note: reason,
                    }
                    break;
                case 'CARD_PULL_OUT':
                    _payload = {
                        accountFromToId: activeAccount?.id,
                        amount: parseFloat(amount),
                        details: reason,
                    }
                    break;
                case 'CARD_TOP_UP':
                    _payload = {
                        accountFromToId: activeAccount?.id,
                        amount: parseFloat(amount),
                        details: reason,
                    }
                    break;
                case 'CARD_SUSPEND':
                    _payload = {
                        blockReason: reason,
                    }
                    break;
            }
            await doActionWithCard(card.id, type, _payload);
            showSuccessNotification(t(`cards.actions.notification.${type}`));
        } catch (e) {
            showErrorNotification(e);
        } finally {
            onClose(true);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: "600px",
                    maxHeight: "600px",
                },
            }}
        >
            <DialogTitle onClose={() => onClose(false)} >{t(`cards.actions.title.${type}`)}</DialogTitle>
            <Formik
                initialValues={{}}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                {({ isSubmitting, setFieldValue, setFieldTouched, errors, dirty }) => {
                    return (
                        <Form>
                            {loading ? <DialogContent>
                                <CircularProgress />
                            </DialogContent>
                                : <DialogContent>
                                    <Grid container alignContent='flex-start' rowGap={2}>
                                        {(type !== 'CARD_SUSPEND' && type !== 'CARD_BLOCK') && <Grid item xs={12}>
                                            <AccountDropdown2
                                                fieldName='account'
                                                activeAccount={activeAccount}
                                                fieldlabel={type === 'CARD_TOP_UP' ? t('form.fields.from').toString() : t('form.fields.to').toString()}
                                                accountList={accountsList}
                                                onChange={handleAccountAccountChange} />
                                        </Grid>}
                                        {(type === 'CARD_TOP_UP' || type === 'CARD_PULL_OUT') && <Grid item xs={12}  >
                                            <AmountField precision={2}
                                                amount={amount}
                                                ccy={activeAccount?.currency}
                                                onChange={(event) => handleAmountChange(event, setFieldValue, setFieldTouched)}
                                                setAmountToMax={() => setAmountToMax(setFieldValue, setFieldTouched)}
                                            />
                                        </Grid>}
                                        <Grid item xs={12}>
                                            <FormTextField
                                                name="reason"
                                                fullWidth
                                                label={t("form.fields.reason")}
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>}
                            <DialogActions
                                sx={{ padding: '20px' }}
                            >
                                <Grid item xs={12} container justifyContent='space-between' >
                                    <Grid item xs="auto">
                                        <Button variant='outlined' onClick={() => onClose(false)}>{t('form.buttons.close')}</Button>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Button type='submit' disabled={isSubmitting || !isEmpty(errors) || !dirty}>{t(`cards.actions.buttons.${type}`)}</Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik >
        </Dialog >);
}






export default EmiCardActionsDialog;

