import request from '../../services/request';
import { ReportAccount, ReportClient, SarStore, SarStoreType, UserSecurityReport } from './sarTypes';
import { PaginateParams, defaultPagination } from '@/types/pagination';
import { AppDispatch } from '@/store/store';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const paginationParamsInit = defaultPagination;

const initialState: SarStore = {
    [SarStoreType.REPORTS]: {
        assignedToMe: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit
        },
        notAssignedToMe: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit
        }
    },
    [SarStoreType.USER_REPORTS]: {
        assignedToMe: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit
        },
        notAssignedToMe: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit
        }
    }
};

const slice = createSlice({
    name: 'sar',
    initialState,
    reducers: {
        setReports(state, action: PayloadAction<{ storeKey: SarStoreType, list: Array<UserSecurityReport>, assignedToMe: boolean }>) {
            if (action.payload.assignedToMe) state[action.payload.storeKey].assignedToMe.list = action.payload.list;
            else state[action.payload.storeKey].notAssignedToMe.list = action.payload.list;
        },
        setLoading(state, action: PayloadAction<{ storeKey: SarStoreType, loading: boolean, assignedToMe: boolean }>) {
            if (action.payload.assignedToMe) state[action.payload.storeKey].assignedToMe.loading = action.payload.loading;
            else state[action.payload.storeKey].notAssignedToMe.loading = action.payload.loading;
        },
        setError: (state, action: PayloadAction<{ storeKey: SarStoreType, error: string, assignedToMe: boolean }>) => {
            if (action.payload.assignedToMe) state[action.payload.storeKey].assignedToMe.error = action.payload.error;
            else state[action.payload.storeKey].notAssignedToMe.error = action.payload.error;
        },
        setCount: (state, action: PayloadAction<{ storeKey: SarStoreType, count: number, assignedToMe: boolean }>) => {
            if (action.payload.assignedToMe) state[action.payload.storeKey].assignedToMe.count = action.payload.count;
            else state[action.payload.storeKey].notAssignedToMe.count = action.payload.count;
        },
        setPagination: (state, action: PayloadAction<{ storeKey: SarStoreType, pagination: PaginateParams, assignedToMe: boolean }>) => {
            if (action.payload.assignedToMe) state[action.payload.storeKey].assignedToMe.pagination = action.payload.pagination;
            else state[action.payload.storeKey].notAssignedToMe.pagination = action.payload.pagination;
        },
    }
});

export const { setReports, setLoading, setError, setCount, setPagination } = slice.actions;

export const getUsersForReport = async (): Promise<Array<ReportClient>> => {
    const response = await request.get(`/api/console/sar/users/list/`);
    const { data } = response;
    return data;
};

export const getUserAccounts = async (userId: number): Promise<Array<ReportAccount>> => {
    const response = await request.get(`/api/console/sar/accounts/list/${userId}`);
    const { data } = response;
    return data;
};

export const acknowledgeReport = async (payload: any): Promise<UserSecurityReport> => {
    const response = await request.post(`/api/console/sar/ackn`, payload);
    const { data } = response;
    return data;
};

export const rejectReport = async (payload: any): Promise<UserSecurityReport> => {
    const response = await request.post(`/api/console/sar/reject`, payload);
    const { data } = response;
    return data;
};

export const createSecurityReport = async (payload: any): Promise<UserSecurityReport> => {
    const response = await request.put('/api/console/sar/create', payload);
    const { data } = response;
    return data;
};

export const updateUserReport = async (payload: any): Promise<UserSecurityReport> => {
    const response = await request.post(`/api/console/sar/update`, payload);
    const { data } = response;
    return data;
};

export const getReport = async (reportId: number): Promise<UserSecurityReport> => {
    const response = await request.get(`/api/console/sar/document/${reportId}`);
    const { data } = response;
    return data;
};

export const getReports = (storeKey: SarStoreType, payload: PaginateParams, assignedToMe: boolean = false) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ storeKey, loading: true, assignedToMe }));
            const response = await request.get(`/api/console/sar/list/${assignedToMe}`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setReports({ storeKey, list: data.list, assignedToMe }));
            dispatch(setCount({ storeKey, count: data.count, assignedToMe }));
            dispatch(setPagination({ storeKey, pagination: payload, assignedToMe }));
            dispatch(setLoading({ storeKey, loading: false, assignedToMe }));
            return data;
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading({ storeKey, loading: false, assignedToMe }));
        }
    };
};

export default slice.reducer;
