import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '@/utils/errors';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { ChatsStatistics, ChatsType, SupportStore } from './supportTypes';
import { addFiles } from '@/utils/files';

export const paginationParamsInit = {
    filter: '',
    orderBy: 'lastMessageDate',
    sort: SortDirectionEnum.DESC,
    take: 10,
    skip: 0
};

const initialState: SupportStore = {
    [ChatsType.ALL]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit
    },
    [ChatsType.NEW]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit
    },
    [ChatsType.SOLVED]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit
    },
    [ChatsType.ACTIVE]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit
    },
    [ChatsType.COMPLIANCE_REQUEST]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit
    }
};

const slice = createSlice({
    name: 'support',
    initialState,
    reducers: {
        setList(
            state,
            action: PayloadAction<{ sectionType: ChatsType; list: Array<any> }>
        ) {
            state[action.payload.sectionType].list = action.payload.list;
        },
        setLoading: (
            state,
            action: PayloadAction<{ sectionType: ChatsType; loading: boolean }>
        ) => {
            state[action.payload.sectionType].loading = action.payload.loading;
        },
        setError: (
            state,
            action: PayloadAction<{ sectionType: ChatsType; error: string }>
        ) => {
            state[action.payload.sectionType].error = action.payload.error;
        },
        setCount: (
            state,
            action: PayloadAction<{ sectionType: ChatsType; count: number }>
        ) => {
            state[action.payload.sectionType].count = action.payload.count;
        },
        setPagination: (
            state,
            action: PayloadAction<{ sectionType: ChatsType; pagination: PaginateParams }>
        ) => {
            state[action.payload.sectionType].pagination = action.payload.pagination;
        },
        refresh: (
            state,
            action: PayloadAction<{ sectionType: ChatsType }>
        ) => {
            state[action.payload.sectionType].pagination = paginationParamsInit;
        }
    }
});

export const { setList, setLoading, setError, setCount, setPagination, refresh } = slice.actions;


export const getChatsList = (sectionType: ChatsType, payload: PaginateParams, withLoading: boolean = true) => {
    return async (dispatch: AppDispatch) => {
        try {
            withLoading && dispatch(setLoading({ sectionType, loading: true }));
            const response = await request.get(`/api/console/chat/list/${sectionType}`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setList({ sectionType, list: data.list }));
            dispatch(setCount({ sectionType, count: data.count }));
            dispatch(setPagination({ sectionType, pagination: payload }));
            dispatch(setLoading({ sectionType, loading: false }));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading({ sectionType, loading: false }));
        }
    };
};


export const getChatsStatistics = async (): Promise<ChatsStatistics> => {
    const response = await request.get(`/api/console/chat/statistics`);
    const { data } = response;
    return data;
};


export const sendMessage = async (chatId: number, formData: FormData) => {
    const response = await request.post(
        `/api/console/chat/message/${chatId}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const getMessageAttachment = async (key: string, mimeType: string) => {
    const file = await request.get(`/api/console/chat/message/attachment/${key}`, { responseType: 'arraybuffer' }).then((response) => {
        const blob = new Blob([response.data], { type: mimeType });
        return blob;
    });

    return file;
};

export const toggleChat = async (chatId: number, connect: boolean): Promise<any> => {
    const response = await request.post(`/api/console/chat/toggle`, {
        chatId,
        connect,
    });
    const { data } = response;
    return data;
};

export const deleteChat = async (chatId: number): Promise<any> => {
    const response = await request.delete(`/api/console/chat/${chatId}`);
    const { data } = response;
    return data;
};

export const deleteChatMessage = async (chatId: number, messageId: number): Promise<any> => {
    const response = await request.delete(`/api/console/chat/${chatId}/message/${messageId}`);
    const { data } = response;
    return data;
};

export const editChatMessage = async (messageId: number, message: string): Promise<any> => {
    const response = await request.post(`/api/console/chat/message/edit/${messageId}`, { message });
    const { data } = response;
    return data;
};

export const solveChat = async (chatId: number): Promise<any> => {
    const response = await request.post(`/api/console/chat/solve`, { chatId });
    const { data } = response;
    return data;
};

export const flagChat = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("chatId", payload.chatId.toString());
    formData.append("reason", payload.reason);
    formData.append("enable", payload.enable);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/chat/flag",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export default slice.reducer;