import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '@/utils/errors';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { CreateVaultPlanPayload, EarnConfigStore, EarnItem, EarnSectionType, InterestType, UserGroup, VaultPlanAvailabilityPayload, VaultPlanProcess } from './earnTypes';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { getWlpName } from '@/components/dataGrid/utils/Platform';
import { Wlp } from '../user/types';

export const paginationParamsInit = {
    filter: '',
    orderBy: 'createdAt',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: EarnConfigStore = {
    [EarnSectionType.CONFIGURATION]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit,
        filters: {
            [HeaderFilterType.WLPS]: [],
        },
        filtersSet: false
    },
    [EarnSectionType.DEPOSITS]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit,
        filters: {
            [HeaderFilterType.WLPS]: [],
        },
        filtersSet: false
    },
    [EarnSectionType.INTEREST]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit,
        filters: {
            [HeaderFilterType.WLPS]: [],
        },
        filtersSet: false
    },
    [EarnSectionType.REPAYMENTS]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit,
        filters: {
            [HeaderFilterType.WLPS]: [],
        },
        filtersSet: false
    }
};

const slice = createSlice({
    name: 'earn',
    initialState,
    reducers: {
        setList(state, action: PayloadAction<{ sectionType: EarnSectionType; list: Array<EarnItem> }>) {
            state[action.payload.sectionType].list = action.payload.list;
        },
        setLoading: (state, action: PayloadAction<{ sectionType: EarnSectionType; loading: boolean }>) => {
            state[action.payload.sectionType].loading = action.payload.loading;
        },
        setError: (state, action: PayloadAction<{ sectionType: EarnSectionType; error: string }>) => {
            state[action.payload.sectionType].error = action.payload.error;
        },
        setCount: (state, action: PayloadAction<{ sectionType: EarnSectionType; count: number }>) => {
            state[action.payload.sectionType].count = action.payload.count;
        },
        setPagination: (state, action: PayloadAction<{ sectionType: EarnSectionType; pagination: PaginateParams }>) => {
            state[action.payload.sectionType].pagination = action.payload.pagination
        },
        // Filters
        setFilters: (state, action: PayloadAction<{ sectionType: EarnSectionType, wlps: Array<Wlp> }>) => {
            const wlpsOptions = action.payload.wlps.map(item => ({
                value: item.wlpId,
                text: getWlpName(item.wlpId),
                enabled: true
            }));
            state[action.payload.sectionType].filters[HeaderFilterType.WLPS] = wlpsOptions;
            state[action.payload.sectionType].filtersSet = true;
        },
        setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, sectionType: EarnSectionType, options: Array<FilterOptionType> }>) => {
            state[action.payload.sectionType].filters[action.payload.filterType] = action.payload.options;
        },
        setFiltersSet(state, action: PayloadAction<{ sectionType: EarnSectionType, set: boolean }>) {
            state[action.payload.sectionType].filtersSet = action.payload.set;
        }
    }
});

export const { setList, setLoading, setError, setCount, setPagination, setFilters, setFilter, setFiltersSet } = slice.actions;

// as vault creation document isn’t really planned transfer, 
// it is added after request for planned transfers, so first page will always be take + 1 items

export const getEarnConfigurationList = (sectionType: EarnSectionType, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ sectionType, loading: true }));
            const response = await request.get(`/api/console/vaults/list`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setList({ sectionType, list: data.list }));
            dispatch(setCount({ sectionType, count: data.count }));
            dispatch(setPagination({ sectionType, pagination: payload }));
            dispatch(setLoading({ sectionType, loading: false }));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading({ sectionType, loading: false }));
        }
    };
};

export const getEarnPlanDetails = async (proc: string): Promise<EarnItem> => {
    const response = await request.get(`/api/console/vaults/plan/${proc}`);
    const { data } = response;
    return data;
};

export const getPlanServiceProviders = async (platform: string): Promise<any[]> => {
    const response = await request.get(`/api/console/vaults/service-provider/${platform}`);
    const { data } = response;
    return data;
};

export const createVaultPlan = async (payload: CreateVaultPlanPayload, file: File | null): Promise<any> => {
    const formData = new FormData();
    if (payload.name) {
        formData.append("name", payload.name);
    }

    if (payload.description) {
        formData.append("description", payload.description);
    }

    if (payload.yearlyInterestRate !== undefined) {
        formData.append("yearlyInterestRate", payload.yearlyInterestRate?.toString());
    }

    if (payload.compoundInterest !== undefined) {
        formData.append("compoundInterest", payload.compoundInterest?.toString());
    }

    if (payload.interestPaymentPeriod) {
        formData.append("interestPaymentPeriod", payload.interestPaymentPeriod);
    }

    if (payload.depositPeriodMonths) {
        formData.append("depositPeriodMonths", payload.depositPeriodMonths.toString());
    }

    if (payload.autoExtendDays) {
        formData.append("autoExtendDays", payload.autoExtendDays.toString());
    }

    if (payload.currency) {
        formData.append("currency", payload.currency);
    }

    if (payload.minDepositAmount) {
        formData.append("minDepositAmount", payload.minDepositAmount.toString());
    }

    if (payload.depositTrade) {
        formData.append("depositTrade", payload.depositTrade.toString());
    }

    if (payload.serviceProvider) {
        formData.append("serviceProvider", payload.serviceProvider);
    }

    if (payload.platform) {
        formData.append("platform", payload.platform);
    }

    if (payload.depositSystemAccount) {
        formData.append("depositSystemAccount", payload.depositSystemAccount);
    }

    if (payload.interestSystemAccount) {
        formData.append("interestSystemAccount", payload.interestSystemAccount);
    }

    if (payload.repaymentSystemAccount) {
        formData.append("repaymentSystemAccount", payload.repaymentSystemAccount);
    }

    if (payload.maxDepositCount !== null && payload.maxDepositCount !== undefined) {
        formData.append("maxDepositCount", payload.maxDepositCount.toString());
    }

    if (payload.targetAmount !== null && payload.targetAmount !== undefined) {
        formData.append("targetAmount", payload.targetAmount.toString());
    }

    if (payload.link) {
        formData.append("link", payload.link);
    }

    if (file) {
        formData.append("file", file);
    }

    if (payload.interestType !== undefined) {
        formData.append("interestType", payload.interestType?.toString());
    }
    if (payload.maxDynamicInterestRate !== undefined && payload.interestType === InterestType.PERIODIC_INTEREST_PLUS_DYNAMIC) {
        formData.append("maxDynamicInterestRate", payload.maxDynamicInterestRate?.toString());
    }

    const response = await request.post(
        '/api/console/vaults/configuration/create',
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const getEarnPlanConfigDetails = async (proc: string): Promise<any> => {
    const response = await request.get(`/api/console/vaults/configuration/${proc}`);
    const { data } = response;
    return data;
};

export const getBalanceTransferConfig = async (integration: string, currency: string, variable: string, isProc: boolean = true): Promise<any[]> => {
    const response = await request.get(`/api/console/vaults/balance-transfer/config/${integration}/${currency}/${variable}`, {
        params: {
            isProc
        }
    });
    const { data } = response;
    return data;
};

export const executeBalanceTransfer = async (payload: any): Promise<any> => {
    const response = await request.post(`/api/console/vaults/balance-transfer/execute`, payload);
    const { data } = response;
    return data;
};

export const getPlanSystemAccounts = async (wlpId: string, serviceProvider: string, ccy: string): Promise<any> => {
    const response = await request.get(`/api/console/vaults/vault/system-accounts/${wlpId}/${serviceProvider}/${ccy}`);
    const { data } = response;
    return data;
};

export const getSysAccCryptoAddresses = async (variable: string, sysAccType: string, isProc: boolean): Promise<any> => {
    const response = await request.get(`/api/console/vaults/system-accounts/crypto/addresses?variable=${variable}&sysAccType=${sysAccType}&isProc=${isProc}`);
    const { data } = response;
    return data;
};


export const updateVaultPlan = async (formData: FormData): Promise<any> => {
    const response = await request.put(
        '/api/console/vaults/configuration/update',
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const getWlpUserGroups = async (wlpId: string): Promise<UserGroup[]> => {
    const response = await request.get(`/api/console/vaults/configuration/user-groups/list/${wlpId}`);
    const { data } = response;
    return data.list;
};

export const getVaultPlanUserAvailability = async (proc: string): Promise<VaultPlanProcess[]> => {
    const response = await request.get(`/api/console/vaults/configuration/user-availability/${proc}`);
    const { data } = response;
    return data;
};

export const postVaultPlanUserAvailability = async (proc: string, payload: VaultPlanAvailabilityPayload): Promise<any> => {
    const response = await request.post(`/api/console/vaults/configuration/user-availability/${proc}`, payload);
    const { data } = response;
    return data;
};

export const deleteVaultPlanUserAvailability = async (proc: string, id: number): Promise<any> => {
    const response = await request.delete(`/api/console/vaults/configuration/user-availability/${proc}/${id}`);
    const { data } = response;
    return data;
};

export const initiateDynamicInterestPayment = async (proc: string, wlpId: string, amount: number, paymentDate?: string): Promise<any> => {
    const response = await request.post(`/api/console/vaults/interest-payment/dynamic/${proc}/${wlpId}`, {
        amount,
        ...(paymentDate && { paymentDate })
    });
    const { data } = response;
    return data;
};

export const changeVisibility = async (proc: string, wlpId: string, action: 'hide' | 'unhide'): Promise<any> => {
    const response = await request.post('/api/console/vaults/plan/visibility', {
        proc,
        wlpId,
        action
    });
    const { data } = response;
    return data;
};






export default slice.reducer;