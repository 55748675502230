import Calendar from '@/icons/Calendar';
import LightBulbSolid from '@/icons/LightBuldSolid';
import { Theme, ThemeOptions, createTheme } from '@mui/material/styles';
import { CSSProperties } from 'react';

export const CustomInputStyle: CSSProperties = {
    boxSizing: `border-box`,
    position: 'static'
}

export default function componentStyleOverrides(theme: Theme, borderRadius: number) {
    const mode = theme.palette.mode;
    const menuSelectedBack = '#FFFFFF0F';
    const menuSelected = theme.palette.primary.contrastText;
    const menuIcon = theme.palette.primary.contrastText;

    return createTheme(theme, {
        typography: {
            link: {
                color: theme.palette.primary.main,
                fontSize: "0.813rem",
                textDecoration: "underline",
                cursor: "pointer",
                whiteSpace: "pre-line",
                lineHeight: "0.813rem",
            }
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    '.MuiDialog-container form': {
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1
                    }
                }
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                },
                styleOverrides: {
                    root: {
                        fontWeight: 700,
                        borderRadius: '10px',
                        padding: '0.72rem 1rem',
                        height: '2.25rem',

                        '&.Mui-disabled': {
                            opacity: 0.5,
                        },
                        textTransform: 'none',
                    },
                    textPrimary: {
                        background: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                        },
                        '&.Mui-disabled': {
                            color: theme.palette.primary.contrastText,
                        },
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.primary.contrastText,
                        },

                    },
                    textSecondary: {
                        background: 'transparent',
                        color: theme.palette.text.secondary,
                        border: '1px solid',
                        borderColor: theme.palette.primary.main,
                        '&:hover': {
                            borderColor: 'transparent',
                        },
                        '&.Mui-disabled': {
                            color: theme.palette.text.secondary,
                            opacity: 0.6,
                        },
                    },
                    containedPrimary: {
                        background: theme.palette.transparentBlack,
                        color: theme.palette.text.secondary,
                        fontWeight: 700,
                        fontSize: '0.813rem',
                        '&:hover': {
                            backgroundColor: theme.palette.transparentBlack,
                        },
                    },
                    outlinedPrimary: {
                        color: theme.palette.text.primary,
                        fontWeight: 500,
                        fontSize: '0.813rem',
                        borderColor: theme.palette.disabledGreen,
                        '&:hover': {
                            borderColor: theme.palette.primary.main
                        },
                    },
                    outlinedSecondary: {
                        color: theme.palette.text.secondary,
                        fontWeight: 500,
                        fontSize: '0.938rem',
                        '&:hover': {
                            backgroundColor: theme.palette.transparentBlack,
                        },

                    },
                }
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: theme.palette.text.secondary
                        //color: menuIcon
                    }
                }

            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            opacity: 0.5,
                            backgroundColor: 'transparent',
                        },
                    },
                }
            },
            MuiToggleButtonGroup: {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.transparentBlack,
                        borderRadius: 10,


                    },
                }
            },
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        height: '1.875rem',
                        borderRadius: 10,
                        fontWeight: 400,
                        lineHeight: '1.25rem',
                        fontSize: '0.813rem',
                        border: 'none',
                        color: theme.palette.text.secondary,
                        margin: '3px 0px',
                        '&:hover': {
                            backgroundColor: theme.palette.transparentBlack,
                        },
                        '&.Mui-selected': {
                            color: theme.palette.primary.light,
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: '10px!important',
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main
                            },
                        }

                    },
                }
            },
            MuiPaper: {
                defaultProps: {
                    elevation: 0
                },
                styleOverrides: {
                    root: {
                        backgroundImage: 'none'
                    },
                    rounded: {
                        borderRadius: `${borderRadius}px`
                    }
                }
            },
            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        color: theme.palette.text.dark,
                        padding: '24px'
                    },
                    title: {
                        fontSize: '1.125rem'
                    }
                }
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        padding: '24px'
                    }
                }
            },
            MuiCardActions: {
                styleOverrides: {
                    root: {
                        padding: '24px'
                    }
                }
            },
            MuiAlert: {
                defaultProps: {
                    iconMapping: {
                        info: <LightBulbSolid fontSize='inherit' />,
                    }
                },
                styleOverrides: {
                    root: {
                        alignItems: 'center'
                    },
                    outlined: {
                        border: '1px dashed'
                    },
                    standardInfo: {
                        background: theme.palette.transparentBlack,
                        '& .MuiAlert-icon': {
                            alignSelf: 'flex-start'
                        }
                    },
                    standardError: {
                        background: theme.palette.transparentBlack,
                        '& .MuiAlert-icon': {
                            alignSelf: 'flex-start'
                        },
                        color: '#014361'
                    }
                }
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        color: theme.palette.primary.contrastText,
                        paddingTop: '3px',
                        paddingBottom: '3px',
                        '& .MuiSvgIcon-root': {
                            color: menuIcon,
                        },
                        '&.Mui-selected': {
                            color: menuSelected,
                            backgroundColor: menuSelectedBack,
                        },
                        '&:hover': {
                            backgroundColor: menuSelectedBack,
                            color: menuSelected,
                            '& .MuiSvgIcon-root': {
                                color: menuSelected,
                            },
                        },
                    }
                }
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        color: theme.palette.text.primary,
                        minWidth: '36px'
                    },
                }
            },
            MuiListItemText: {
                styleOverrides: {
                    primary: {
                        color: theme.palette.text.dark
                    }
                }
            },

            MuiSlider: {
                styleOverrides: {
                    root: {
                        '&.Mui-disabled': {
                            color: theme.palette.grey[300]
                        },
                        '& .MuiSlider-rail': {
                            height: '4px'
                        },
                        '& .MuiSlider-track': {
                            background: theme.palette.green,
                            height: '4px'
                        },
                    },
                    mark: {
                        backgroundColor: theme.palette.background.paper,
                        width: '4px'
                    },
                    valueLabel: {
                        color: theme.palette.primary.light
                    },

                }
            },
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-root': {
                            paddingTop: 0,
                            paddingBottom: 0
                        },
                        '&.MuiAutocomplete-tag': {
                            background: theme.palette.secondary.light,
                            borderRadius: 4,
                            color: theme.palette.text.dark,
                            '.MuiChip-deleteIcon': {
                                color: theme.palette.lightGrey
                            }
                        },
                    },
                    '& .MuiAutocomplete-tag': {
                        background: theme.palette.secondary.light,
                        borderRadius: 4,
                        color: theme.palette.text.dark,
                        '.MuiChip-deleteIcon': {
                            color: theme.palette.lightGrey
                        }
                    },
                    popper: {
                        borderRadius: `${borderRadius}px`,
                        boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)'
                    },
                    input: {
                        paddingTop: '0!important',
                        paddingBottom: '0!important'
                    },
                }
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        borderColor: theme.palette.transparentGreen,
                    }
                }
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        color: theme.palette.primary.contrastText,
                        background: theme.palette.primary.main,
                        fontWeight: 700,
                        fontSize: '0.938rem',
                        borderRadius: '10px',
                        width: '2.75rem',
                        height: '2.75rem',
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        fontWeight: 700,
                        fontSize: '0.625rem',
                        lineHeight: '0.625rem',
                        height: '1.375rem',
                        '&.MuiChip-deletable .MuiChip-deleteIcon': {
                            color: 'inherit'
                        },
                        '& .MuiChip-icon': {
                            path: {
                                stroke: theme.palette.primary.contrastText
                            }
                        },
                        '&.MuiChip-colorWarning .MuiChip-label': {
                            color: theme.palette.primary.contrastText
                        }
                    },
                }
            },
            MuiTimelineContent: {
                styleOverrides: {
                    root: {
                        color: theme.palette.text.dark,
                        fontSize: '16px'
                    }
                }
            },
            MuiTreeItem: {
                styleOverrides: {
                    label: {
                        marginTop: 14,
                        marginBottom: 14
                    }
                }
            },
            MuiTimelineDot: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none'
                    }
                }
            },
            MuiInternalDateTimePickerTabs: {
                styleOverrides: {
                    tabs: {
                        backgroundColor: mode === 'dark' ? theme.palette.darkGrey : theme.palette.primary.light,
                        '& .MuiTabs-flexContainer': {
                            borderColor: mode === 'dark' ? theme.palette.text.primary + 20 : theme.palette.lightGrey
                        },
                        '& .MuiTab-root': {
                            color: mode === 'dark' ? theme.palette.text.secondary : theme.palette.grey[900]
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: theme.palette.primary.dark
                        },
                        '& .Mui-selected': {
                            color: theme.palette.primary.dark
                        }
                    }
                }
            },
            MuiTabs: {
                styleOverrides: {
                    flexContainer: {
                        borderBottom: '3px solid',
                        borderColor: theme.palette.transparentBlack
                    },
                    indicator: {
                        height: '3px',
                        backgroundColor: theme.palette.primary.dark,
                        borderRadius: '2px'
                    },
                }
            },
            MuiTab: {
                defaultProps: {
                    disableRipple: true
                },
                styleOverrides:
                {
                    root: {
                        fontSize: '0.938rem',
                        color: theme.palette.text.primary,
                        fontWeight: 400,
                        '&.Mui-selected': {
                            color: theme.palette.text.primary,
                        },
                    },

                }
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        color: theme.palette.primary.main,
                        background: theme.palette.grey[300],
                        fontWeight: 600
                    }
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        fontSize: '1.25rem',
                        background: theme.palette.transparentBlack,
                        marginBottom: '20px',
                        color: 'black',
                        fontWeight: 500
                    }
                }
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        display: 'flex',
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderColor: mode === 'dark' ? theme.palette.text.primary + 15 : theme.palette.grey[200],
                        '&.MuiTableCell-head': {
                            fontSize: '0.875rem',
                            color: theme.palette.grey[600],
                            background: theme.palette.transparentBlack,
                            fontWeight: 400,
                        }
                    }
                }
            },
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        fontSize: '0.938rem',
                        lineHeight: '1.375rem',
                        fontWeight: 400,
                        borderColor: theme.palette.transparentBlack,
                        border: 'none',
                        // borderRadius: borderRadius,
                        '& .MuiDataGrid-row': {
                            borderStyle: 'solid',
                            borderWidth: '0.5px',
                            borderColor: theme.palette.transparentBlack,
                            '& .MuiDataGrid-cell:first-of-type': {
                                paddingLeft: '30px !important'
                            },
                            '& .MuiDataGrid-cell:last-of-type': {
                                paddingRight: '30px !important'
                            },
                        },
                        '& .MuiDataGrid-cell .MuiDataGrid-actionsCell .MuiButtonBase-root': {
                            paddingLeft: '0px',
                            paddingRight: '0px'
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-cell .MuiGridActionsCellItem .MuiButtonBase-root': {
                            paddingLeft: '0px',
                            paddingRight: '0px'
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            borderRadius: '0px',
                            background: theme.palette.transparentBlack,
                            padding: '0!important',
                            '& .MuiDataGrid-columnHeader:first-of-type': {
                                paddingLeft: '30px'
                            },
                            '& .MuiDataGrid-columnHeader:last-of-type': {
                                paddingRight: '30px'
                            },
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 400,
                        },
                        '& .MuiDataGrid-cell--editable.MuiDataGrid-cell': {
                            borderRadius: '10px',
                            minHeight: '2.25rem!important',
                            maxHeight: '2.25rem!important',
                            margin: 'auto',
                            opacity: 0.5,
                            boxShadow: 'none',
                            outline: 'none',
                            border: `solid ${theme.palette.primary.main} 1px!important`,
                        },
                        '& .MuiDataGrid-cell--editing.MuiDataGrid-cell': {
                            opacity: 1,
                            outline: 'none!important',
                        },
                        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                            outline: 'none'
                        },
                        //custom styles
                        '.MuiDataGrid-row.fee-data-grid--header': {
                            borderBottom: 'none',
                            borderStyle: 'none',
                        },
                        '.MuiDataGrid-row.fee-data-grid--row': {
                            borderStyle: 'none',
                        },
                        '.MuiDataGrid-row.fee-data-grid--row.grid-editable-row': {
                            cursor: 'pointer'
                        },
                        '.MuiDataGrid-row.fee-data-grid--row .MuiDataGrid-cell[data-colindex="0"]': {
                            color: theme.palette.primary.main
                        },
                        '.MuiDataGrid-row.fee-data-grid--emi-fee-row': {
                            borderStyle: 'none',
                        },
                        '.MuiDataGrid-row.fee-data-grid--emi-fee-row.grid-editable-row': {
                            cursor: 'pointer'
                        },
                        '.MuiDataGrid-row.fee-data-grid--emi-fee-group-row': {
                            borderStyle: 'none',
                        },
                        '.MuiDataGrid-row.fee-data-grid--emi-fee-group-row.grid-editable-row': {
                            cursor: 'pointer'
                        },
                        '.MuiDataGrid-row.fee-data-grid--emi-fee-group-row .MuiDataGrid-cell[data-colindex="0"]': {
                            paddingLeft: '50px !important'
                        }
                    },
                }
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& input::placeholder': {
                            fontSize: '0.688rem',
                            fontWeight: 400
                        },
                        legend: { display: 'none' },
                        fieldset: { top: 0 }
                    },
                }
            },
            MuiInputLabel: {
                //places label on top of an input field 
                defaultProps: {
                    shrink: true
                },
                styleOverrides: {
                    root: {
                        position: CustomInputStyle.position,
                        transform: 'none',
                        color: theme.palette.text.secondary,
                        fontSize: '0.688rem',
                        fontWeight: 400,
                        marginBottom: '2px',
                        paddingTop: '0.5rem',
                        '&.Mui-focused': {
                            color: theme.palette.text.secondary,
                        },
                        '&.Mui-disabled': {
                            color: theme.palette.text.secondary
                        },
                        '&.Mui-error': {
                            color: theme.palette.text.secondary
                        }
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {

                        boxSizing: CustomInputStyle.boxSizing,
                        '&.MuiOutlinedInput-root': { //default height of input fields, except multile fiels
                            ':not(.MuiInputBase-multiline)':
                            {
                                height: '2.25rem',
                            }
                        },
                        // remove arrows up/down on Number input
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                            display: 'none',
                        },
                        '& input[type=number]': {
                            MozAppearance: 'textfield',
                        },
                        '& input.Mui-disabled': {
                            'WebkitTextFillColor': '#82918E !important', // text fill color for disabled input (webkit specific)
                        },
                    },
                    input: {
                        color: theme.palette.text.dark,
                        '&::placeholder': {
                            color: theme.palette.text.secondary,
                            fontSize: '0.875rem'
                        },
                        '&:-webkit-autofill': {
                            transitionDelay: '9999s',
                            transitionProperty: 'background-color, color',
                            'WebkitBoxShadow': '0 0 0 1000px transparent inset !important'

                        },
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: `${borderRadius}px`,
                        border: 'red',
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.transparentGreen
                        },
                        "&:hover:not(.Mui-disabled)": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: theme.palette.primary.main,
                            },
                        },
                        // Mac input
                        "&.Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: theme.palette.primary.main,
                                borderWidth: '1px',
                                borderRadius: `${borderRadius}px`,
                                boxShadow: '0px 0px 0px 3px rgba(0, 122, 255, 0.50), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.05), 0px 0.5px 2.5px 0px rgba(0, 0, 0, 0.30)'
                            }
                        },
                    },
                    input: {
                        fontWeight: 400,
                        color: theme.palette.text.primary,
                        //    padding: '15.5px 14px',
                        '&.MuiInputBase-inputSizeSmall': {
                            //   padding: '10px 14px',
                            '&.MuiInputBase-inputAdornedStart': {
                                paddingLeft: 0
                            }
                        }
                    },
                    inputAdornedStart: {
                        paddingLeft: 4
                    },
                }
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        cursor: 'pointer',
                        fontSize: '0.813rem',
                        lineHeight: '20px',
                        fontWeight: 400,
                        color: '#0A348F',
                        textDecoration: 'none',
                    }
                }
            },
            MuiDatePicker: {
                defaultProps: {
                    slots: {
                        openPickerIcon: Calendar,
                    },
                    slotProps: {
                        openPickerIcon: { sx: { fontSize: '1rem' } },
                    }
                },
            },
            MuiRadio: {
                defaultProps: {
                    size: 'small',
                    color: "primary"
                },
                styleOverrides: {
                    root: {
                        '&.Mui-checked .MuiSvgIcon-root': {
                            color: theme.palette.primary.main,
                        },
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.secondary.main
                        },
                        '&.Mui-disabled .MuiSvgIcon-root': {
                            color: theme.palette.transparentGreen,
                        },
                    }
                }
            },
            MuiCheckbox: {
                defaultProps: {
                    size: 'small',
                    color: "primary"
                },
                styleOverrides: {
                    root: {
                        '&.Mui-checked .MuiSvgIcon-root': {
                            color: theme.palette.primary.main,
                        },
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.secondary.main,
                        },
                        '&.Mui-checked.Mui-disabled .MuiSvgIcon-root': {
                            color: theme.palette.disabledGreen,
                        },
                    }
                }
            }
        }
    } as ThemeOptions);
}
