import DetailCard from "@/components/cards/DetailCard";
import { CardDetails, CardOptionType, CardShipmentCountry } from "@/redux/cards/cardsTypes";
import { CardMedia, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import fallback from '../../../assets/images/fallbackCard.png';
import { getCardOptions, getDeliveryCountries, sumMonthlyFees } from "@/redux/cards/cardsSlice";
import Loader from "@/components/Loader";
import CardReplaceConfirm from "./CardReplaceConfirm";
import DialogTitle from "@/components/DialogTitle";
import { getClientAccounts } from "@/redux/accounts/accountsSlice";
import { showErrorNotification } from "@/utils/errors";
import { Account } from "@/redux/accounts/accountsTypes";
import FormBack from "@/components/FormBack";
import CardReplaceDelivery from "./CardReplaceDelivery";

export interface CardDeliveryAddressType {
    address: string,
    postalCode: string,
    city: string,
    countryName: string,
    countryIso2: string,
    countryIso3: string,
}
interface Props {
    open: boolean,
    onClose: (withRefetch?: boolean) => void,
    card: CardDetails,
}
const  enum StepEnum {
    CHOOSE = 'CHOOSE',
    DELIVERY = 'DELIVERY',
    CONFIRM = 'CONFIRM',
}
const EmiCardReplaceDialog = ({ open, card, onClose }: Props) => {
    const { t } = useTranslation();
    const [accountList, setAccountList] = useState<Account[]>([]);
    const [activeOption, setActiveOption] = useState<CardOptionType>();
    const [cardOptions, setCardOptions] = useState<CardOptionType[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadingAccounts, setLoadingAccounts] = useState(false);
    const [loadingCountries, setLoadingCountries] = useState(false);
    const [step, setStep] = useState<StepEnum>(StepEnum.CHOOSE);
    const [deliveryAddress, setDeliveryAddress] = useState<CardDeliveryAddressType>();
    const [deliveryCountries, setDeliveryCountries] = useState<CardShipmentCountry[]>();
    const isNewCardPhysical = activeOption?.processProperties?.virtual === false;
    const allowedDeliveryCountries = deliveryCountries?.filter(p => p.integration === activeOption?.integration) || [];

    const fetchAccounts = useCallback(() => {
        if (!card.wlpId || !card.userId || !card.currency) return;

        const get = async () => {
            setLoadingAccounts(true);
            try {
                const data = await getClientAccounts(card.wlpId, card.userId, card.currency);
                setAccountList(data.list || []);

            } catch (e) {
                setAccountList([]);
                showErrorNotification(e);
            } finally {
                setLoadingAccounts(false);
            }
        };
        get();
    }, [card.wlpId, card.userId, card.currency]);

    useEffect(() => {
        fetchAccounts();
    }, [fetchAccounts]);


    useEffect(() => {
        const getCountries = async () => {
            try {
                setLoadingCountries(true);
                const data = await getDeliveryCountries() || [];
                setDeliveryCountries(data);
                setLoadingCountries(false);
            }
            catch (e) {
                setDeliveryCountries([]);
            }
            finally { setLoadingCountries(false); }

        };
        getCountries();
    }, [])


    useEffect(() => {
        const getNewCardOptions = async () => {
            try {
                setLoading(true);
                const data = await getCardOptions(card.userId) || [];
                const filtered = data.filter(d => d.integration === 'DECTA') || [];
                setCardOptions(filtered);
                setLoading(false);
            }
            catch (e) {
                setCardOptions([]);
            }
            finally { setLoading(false); }

        };
        getNewCardOptions();
    }, [card.userId])

    const onChoose = (item: CardOptionType) => {
        setActiveOption(item);
        const isVirtual = item?.processProperties?.virtual;
        setStep(isVirtual ? StepEnum.CONFIRM : StepEnum.DELIVERY)
    }

    const renderContent = () => {

        if (step === StepEnum.CHOOSE) {
            return <ChooseCard
                cardOptions={cardOptions}
                onClick={onChoose} />;
        };
        if (step === StepEnum.DELIVERY && activeOption) {
            return <CardReplaceDelivery
                oldCard={card}
                deliveryAddress={deliveryAddress}
                countryList={allowedDeliveryCountries}
                onConfirm={(item) => { setDeliveryAddress(item); setStep(StepEnum.CONFIRM) }} />;
        };

        if (step === StepEnum.CONFIRM && activeOption) {
            return <CardReplaceConfirm
                oldCard={card}
                isPhysical={isNewCardPhysical}
                deliveryAddress={deliveryAddress}
                cardOption={activeOption}
                accountList={accountList}
                onClose={onClose}

            />;
        };

    }

    if (loading || loadingAccounts || loadingCountries) { return <Loader />; }

    const goBack = () => {
        if (step === StepEnum.CHOOSE) { setStep(StepEnum.CHOOSE) }
        if (step === StepEnum.DELIVERY) { setStep(StepEnum.CHOOSE) }
        if (step === StepEnum.CONFIRM) { setStep(isNewCardPhysical ? StepEnum.DELIVERY : StepEnum.CHOOSE) }
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: "700px",
                    maxHeight: "700px",
                },
            }}
        >
            <DialogTitle onClose={() => onClose(false)}>
                <FormBack onClick={goBack} title={t('cards.actions.title.REPLACE')} />
            </DialogTitle>
            <DialogContent>
                <Grid container mt={1} >
                    {renderContent()}
                </Grid>
            </DialogContent>
        </Dialog >);
}


const ChooseCard = ({ cardOptions, onClick }: { cardOptions: CardOptionType[], onClick: (item: any) => void }) => {
    return <Grid container rowGap={2} alignContent='flex-start'>{cardOptions.map((item: any) =>
        <Grid item xs={12} key={item.proc}>
            <NewCardOption item={item} onClick={onClick} />
        </Grid>
    )}</Grid>;
}

const NewCardOption = ({ item, onClick }: { item: CardOptionType, onClick: (item: any) => void }) => {
    const { t } = useTranslation();

    const montlyFee = item?.fees ? sumMonthlyFees(item?.fees) : 0;
   // const creationFee = item?.fees ? sumCreationFees(item?.fees) : 0;
    const currency = item?.processProperties?.ccy;
    const feeList = [/* {
        title: t('cards.fee.issuanceFee'),
        value: creationFee
    }, */
    {
        title: t('cards.fee.monthlyFee'),
        value: montlyFee
    }];


    return (
        <DetailCard onClick={() => onClick(item)}
            sx={{ ':hover': { boxShadow: 10, cursor: 'pointer' } }}>
            <Grid container columnGap={2}>
                <Grid item xs='auto'>
                    <CardMedia image={item.config.serviceProviderLogo ?? fallback}
                        onError={(e) => (e.currentTarget.src = fallback)}
                        component="img"
                        sx={{ borderRadius: '5px', maxWidth: '164px' }} />
                </Grid>
                <Grid item xs>
                    <Typography variant='body2' color='text.secondary'>{item?.name}</Typography>
                    <Typography variant='body2'>{t(item.processProperties.virtual ? 'form.fields.virtual' : 'form.fields.physical')}</Typography>
                    <Typography variant='body2'>{item.integration}</Typography>
                    {feeList.map((p, idx) => <Grid container key={idx}>
                        <Grid item xs={6}><Typography variant='body2' color='text.secondary'>{p.title}</Typography> </Grid>
                        <Grid item xs={6}><Typography variant='body2'>{`${currency} ${p.value}`}</Typography>  </Grid></Grid>)}

                </Grid>
            </Grid>
        </DetailCard>);
}



export default EmiCardReplaceDialog;

