import { Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { format } from "date-fns";

const DateCell = (params: GridRenderCellParams, hideTime?: boolean, customFieldName?: string) => {

    let value = customFieldName ? params.row[customFieldName] : params.value;

    if (value === null || value === undefined || value === '')
        return '';

    const date = new Date(value);
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="body2">{format(date, 'dd/MM/yyyy')} </Typography>
            </Grid>
            {!hideTime && <Grid item xs={12}>
                <Typography variant="body2" color='text.secondary'>{format(date, 'HH:mm:ss')} </Typography>
            </Grid>}
        </Grid>);
};

export default DateCell;