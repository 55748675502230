import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '@/utils/errors';
import { PlannedTransferType, UserEarnStore } from './userEarnTypes';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { EarnPaymentsEnum } from '../emiFinanceEarn/emiFinanceEarnTypes';

export const plannedTransfersParamsInit = {
    filter: '',
    orderBy: 'createdDate',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: UserEarnStore = {
    [EarnPaymentsEnum.DEPOSIT_REPAYMENT]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: plannedTransfersParamsInit
    },
    [EarnPaymentsEnum.INTEREST_PAYMENT]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: plannedTransfersParamsInit
    },
};

const slice = createSlice({
    name: 'userEarn',
    initialState,
    reducers: {
        setTransfers(state, action: PayloadAction<{ storeKey: EarnPaymentsEnum, list: Array<PlannedTransferType> }>) {
            state[action.payload.storeKey].list = action.payload.list;
        },
        setLoading: (state, action: PayloadAction<{ storeKey: EarnPaymentsEnum, loading: boolean }>) => {
            state[action.payload.storeKey].loading = action.payload.loading
        },
        setError: (state, action: PayloadAction<{ storeKey: EarnPaymentsEnum, error: string }>) => {
            state[action.payload.storeKey].error = action.payload.error;
        },
        setCount: (state, action: PayloadAction<{ storeKey: EarnPaymentsEnum, count: number }>) => {
            state[action.payload.storeKey].count = action.payload.count;
        },
        setPagination: (state, action: PayloadAction<{ storeKey: EarnPaymentsEnum, pagination: PaginateParams }>) => {
            state[action.payload.storeKey].pagination = action.payload.pagination;
        },
    }
});

export const { setTransfers, setLoading, setError, setCount, setPagination } = slice.actions;

// as vault creation document isn’t really planned transfer, 
// it is added after request for planned transfers, so first page will always be take + 1 items

export const getPlannedTransfers = (storeKey: EarnPaymentsEnum, userId: number, vaultAccountId: number, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ storeKey, loading: true }));
            const response = await request.get(`/api/console/vaults/planned/transfers/${userId}/${vaultAccountId}`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setTransfers({ storeKey, list: data.list }));
            dispatch(setCount({ storeKey, count: data.count }));
            dispatch(setPagination({ storeKey, pagination: payload }));
            dispatch(setLoading({ storeKey, loading: false }));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading({ storeKey, loading: false }));
        }
    };
};

export const getVaultDetails = async (userId: number, vaultAccountId: number) => {
    const response = await request.get(`/api/console/vaults/details/${userId}/${vaultAccountId}`);
    const { data } = response;
    return data;
}


export const getVaultByCcy = async (ccy: string): Promise<any> => {
    const pagination: PaginateParams = { orderBy: 'ccy', sort: SortDirectionEnum.DESC, skip: 0, filter: '', take: 25 };
    pagination.filter = `ccy=${ccy}`;
    const response = await request.get('/api/console/vaults/processes', {
        params: {
            filter: pagination.filter,
            orderBy: pagination.orderBy,
            sort: pagination.sort.toUpperCase(),
            skip: pagination.skip.toString(),
            take: pagination.take
        }
    });
    const { data } = response;
    return data;
};


export const transferVault = async (userId: number, accountId: number, newVaultProc: string, reason: string) => {
    const response = await request.post(`/api/console/vaults/transfer/${userId}/${accountId}`, { proc: newVaultProc, reason: reason });
    const { data } = response;
    return data;
};


export default slice.reducer;