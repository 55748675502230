import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { SupportMessageStore } from './supportMessageType';
import { Message } from '../support/supportTypes';
import { AppDispatch } from '@/store/store';

const initialState: SupportMessageStore = {
    messages: [],
    loading: false,
    error: false,
};

const slice = createSlice({
    name: 'supportMessage',
    initialState,
    reducers: {
        setMessages(state, action: PayloadAction<Array<Message>>) {
            state.messages = action.payload;
        },
        addMessages(state, action: PayloadAction<Array<Message>>) {
            if (state.messages.length > 0 && action.payload.length > 0
                && BigInt(state.messages[0].chatId) === BigInt(action.payload[0].chatId)
            ) {
                state.messages = [...state.messages, ...action.payload];
            }
        },
        setMarkAsRead(state, action: PayloadAction<{
            chatId: number;
            maxMessageId: string;
        }>) {

            if (state.messages.length > 0 &&
                BigInt(state.messages[0].chatId) === BigInt(action.payload.chatId)
            ) {
                state.messages = state.messages.map(p => (BigInt(action.payload.maxMessageId) >= BigInt(p.id) ? { ...p, readByClient: true } : { ...p }));
            }
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setError(state, action: PayloadAction<boolean>) {
            state.error = action.payload;
        },

    }
});

export const { addMessages, setMessages, setLoading, setError, setMarkAsRead } = slice.actions;



export const getChatMessages = (chatId: number) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setMessages([]));
            dispatch(setError(false));
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/chat/messages/${chatId}`);
            const { data } = response;
            dispatch(setMessages(data?.list || []));
        } catch (e) {
            dispatch(setError(true));
        }
        finally {
            dispatch(setLoading(false));
        }
    };
};

export const getMessage = (id: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await request.get(`/api/console/chat/message/${id}`);
            const { data } = response;
            data?.list && dispatch(addMessages(data?.list || []));
        } catch (e) {
            console.log(e);
        }
    };
};


export default slice.reducer;