import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '@/utils/errors';
import { TurnoverLimit } from '../platformLimits/platformLimitsTypes';
import { UserLevelLimitsStore } from './userLevelLimitsTypes';
import { CreateUpdateLimitPayload } from '../userGroupLimits/userGroupLimitsTypes';
import { UserType } from '../user/types';


const initialState: UserLevelLimitsStore = {
    loading: false,
    limits: [],
    error: null,
};

const slice = createSlice({
    name: 'userLevelLimits',
    initialState,
    reducers: {
        setUserLevelLimits(state, action: PayloadAction<Array<TurnoverLimit>>) {
            state.limits = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        }
    }
});

export const { setLoading, setUserLevelLimits } = slice.actions;

export const getUserLevelLimits = (userLevelId: number, wlpId?: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/limits/users-level/${userLevelId}`, { params: { wlpId: wlpId } });
            const data = response.data;
            const combinedLimits = [
                { ...data.individualUserLevelTurnoverLimit, userProfileType: UserType.INDIVIDUAL },
                { ...data.businessUserLevelTurnoverLimit, userProfileType: UserType.BUSINESS }
            ];
            dispatch(setUserLevelLimits(combinedLimits));
        } catch (e) {
            showErrorNotification(e);
        }
        finally { dispatch(setLoading(false)); }
    }
};

export const createUserLevelLimit = async (payload: CreateUpdateLimitPayload): Promise<TurnoverLimit> => {
    const response = await request.post(`/api/console/limits/create-update`, payload)
    const { data } = response;
    return data;
};


export default slice.reducer;