export interface Status {
    loggedIn: boolean;
    source: string;
    permissions: Array<Permission>;
    restricted: string;
    adminUserId: number;
    userRole: UserRole;
};

export interface Permission {
    permission: string;
    writePermission: boolean;
};

export interface UserRole {
    agentCode: string;
    assignable: boolean;
    code: string;
    disabled: boolean;
    editable: boolean;
    id: number;
    label: string;
};

export interface MaintenanceMode {
    mode: boolean;
    reason: string;
};

export enum UserType {
    INDIVIDUAL = "INDIVIDUAL",
    BUSINESS = "BUSINESS",
    EMPLOYEE = "EMPLOYEE",
    ADMIN = "ADMIN",
    SYSTEM = "SYSTEM",
    WLP_ADMIN = "WLP_ADMIN",
    EMI_ADMIN = "EMI_ADMIN",
    EXTERNAL_INDIVIDUAL = "EXTERNAL_INDIVIDUAL",
    EXTERNAL_BUSINESS = "EXTERNAL_BUSINESS",
};

export enum AdminType {
    EMI_ADMIN = "EMI_ADMIN",
    WLP_ADMIN = "WLP_ADMIN",
};

export interface UserError {
    error: string;
    error_param: string;
};

export interface UserStore {
    status: Status | null;
    error: UserError | null;
    maintenanceMode: MaintenanceMode;
    permissions: Array<Permission>;
    emiSections: Array<EmiSectionType>;
    wlps: Array<Wlp>;
    twoFaEnabled?: boolean;
};

export interface Wlp {
    wlpId: string;
    name: string;
}
;
export interface DeviceInfo {
    browser: string;
    platform: string;
    hash: string;
};

export interface LoginBody {
    email: string;
    password: string;
    deviceInfo: DeviceInfo | null;
};

export interface EmiSectionType {
    lookupCode: string;
    enabled: boolean;
    tabs: string;
    subTabs: Record<string, string>;
};

export interface Credentials {
    token: string | null;
    email: string | null;
    userId: number | null;
    userClientId: string | null;
    userFirstName: string | null;
    userLastName: string | null;
    isEmi?: boolean;
    agent?: string;
    wlp?: string;
    expireTime?: number;
    ownerRole: boolean;
};

export interface UserLevel {
    userLevelId: number;
    name: string;
    description: string;
    priority: number;
    defaultLevel: boolean;
};

/* Permissions used by console */
export enum PermissionType {
    DASHBOARD = "DASHBOARD",
    UNASSIGNED_PAYMENTS = "UNASSIGNED_PAYMENTS",
    FILE_UPLOAD = "FILE_UPLOAD",
    SYSTEM_ERRORS = "SYSTEM_ERRORS",
    ALL_TRANSACTIONS = "ALL_TRANSACTIONS",
    BANK_TRANSACTIONS = "BANK_TRANSACTIONS",
    CARD_TRANSACTIONS = "CARD_TRANSACTIONS",
    ACCOUNTS = "ACCOUNTS",
    COMPLIANCE = "COMPLIANCE",
    SUPPORT = "SUPPORT",
    SYSTEM_USERS = 'SYSTEM_USERS',

    NOTIFICATIONS_TRIGGERS = "NOTIFICATIONS_TRIGGERS",
    ACTION_CENTER = "ACTION_CENTER",
    PENDING_TRANSACTIONS = "PENDING_TRANSACTIONS",
    PENDING_CARDS = "PENDING_CARDS",
    PENDING_KYC = "PENDING_KYC",
    PENDING_TICKETS = "PENDING_TICKETS",
    LANGUAGES = "LANGUAGES",
    ROLES = "ROLES",
    COUNTRY_LIMITS = "COUNTRY_LIMITS",
    ACTIVITY_LOGS = "ACTIVITY_LOGS",
    CARDS = "CARDS",
    USERS = "USERS",
    BANK_DOCUMENTS = "BANK_DOCUMENTS",
    CARD_DOCUMENTS = "CARD_DOCUMENTS",
    CRYPTO_DOCUMENTS = "CRYPTO_DOCUMENTS",
    TRUST_DOCUMENTS = "TRUST_DOCUMENTS",
    POOLING_DOCUMENTS = "POOLING_DOCUMENTS",
    TOKEN_DOCUMENTS = "TOKEN_DOCUMENTS",
    POA_COMPLIANCE = "POA_COMPLIANCE",
    TOKENS = "TOKENS",
    RECONCILIATIONS = "RECONCILIATIONS",
    TRUST = "TRUST",
    CURRENCY_EXCHANGE = "CURRENCY_EXCHANGE",
    CHANGE_PHONE = "CHANGE_PHONE",
    RESET_PASSCODE = "RESET_PASSCODE",
    REFERRALS = "REFERRALS",
    ANALYTICS = "ANALYTICS",

    USER_FEES = "USER_FEES",
    PLATFORMS_FEES = "PLATFORMS_FEES",
    USER_GROUPS_FEES = "USER_GROUPS_FEES",
    USER_LEVELS_FEES = "USER_LEVELS_FEES",

    LIMITS = "LIMITS",
    USER_LIMITS = "USER_LIMITS",
    FINANCE = "FINANCE",
    DOCUMENTS = "DOCUMENTS",
    EARN = "EARN",

    VAULTS = "VAULTS",
    SYSTEM_PAYMENTS = "SYSTEM_PAYMENTS",
    BUSINESS_PAYMENTS = "BUSINESS_PAYMENTS",
    USER_APPROVE = "USER_APPROVE",
    SETTLEMENT_EDIT = "SETTLEMENT_EDIT",
    SETTLEMENT_UNLIMITED_EDIT = "SETTLEMENT_UNLIMITED_EDIT",
    PLATFORMS = "PLATFORMS",
    FEES_PAY_OUT = "FEES_PAY_OUT",
    ESCROW = "ESCROW",
    REPORTING = "REPORTING",
    CONFIGURATION_AND_FEES = "CONFIGURATION_AND_FEES",
    RESTART_PENDING_ACTIVITIES = "RESTART_PENDING_ACTIVITIES",
    USER_GROUPS = "USER_GROUPS",
    BLOCK_CARD = 'BLOCK_CARD',
    USER_LEVEL = 'USER_LEVEL',
    DEFI_WALLETS = 'DEFI_WALLETS',
    REPORTS = 'REPORTS',
    MAINTENANCE = 'MAINTENANCE',
    GENERAL_SETTINGS = 'GENERAL_SETTINGS',
    FEE_ADJUSTMENT = 'FEE_ADJUSTMENT',

    MERCHANT_STORES = "MERCHANT_STORES",
    MERCHANT_PRODUCTS = "MERCHANT_PRODUCTS"
};

export enum EmiSection {
    FINANCES = 'FINANCES',
    PAYOUTS_INVOICES = 'PAYOUTS_INVOICES',
    TRANSFERS = 'TRANSFERS',
    TRADES = 'TRADES',
    USERS = 'USERS',
    ACCOUNTS = 'ACCOUNTS',
    CARDS = 'CARDS',
    WALLETS = 'WALLETS',
    COMPLIANCE = 'COMPLIANCE',
    ANALYTICS = 'ANALYTICS',
    PLATFORMS = 'PLATFORMS',
    MERCHANTS = 'MERCHANTS',
    EARN = 'EARN',
    TOKENS = 'TOKENS',
    SETTINGS = 'SETTINGS',
    SUPPORT = 'SUPPORT',
    REPORTS = 'REPORTS',
    USER_DETAILS = 'USER_DETAILS'
};
