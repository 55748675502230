import * as React from "react";
import { SvgIcon } from '@mui/material';

const Compliance = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path opacity="0.5" d="M13.3333 3.33203C15.1459 3.34212 16.1274 3.4225 16.7678 4.06282C17.5 4.79505 17.5 5.97356 17.5 8.33058V13.3306C17.5 15.6876 17.5 16.8661 16.7678 17.5984C16.0355 18.3306 14.857 18.3306 12.5 18.3306H7.5C5.14298 18.3306 3.96447 18.3306 3.23223 17.5984C2.5 16.8661 2.5 15.6876 2.5 13.3306V8.33058C2.5 5.97356 2.5 4.79505 3.23223 4.06282C3.87255 3.4225 4.85414 3.34212 6.66667 3.33203" stroke="currentColor" strokeWidth="1.2" />
            <path d="M7.5 11.168L8.92857 12.5013L12.5 9.16797" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.66797 2.91797C6.66797 2.22761 7.22761 1.66797 7.91797 1.66797H12.0846C12.775 1.66797 13.3346 2.22761 13.3346 2.91797V3.7513C13.3346 4.44166 12.775 5.0013 12.0846 5.0013H7.91797C7.22761 5.0013 6.66797 4.44166 6.66797 3.7513V2.91797Z" stroke="currentColor" strokeWidth="1.2" />
        </svg>
    </SvgIcon>
);
export default Compliance;