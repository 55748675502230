import { useTranslation } from "react-i18next";
import { Grid, Theme, Tooltip, Typography, useTheme } from "@mui/material";
import { FeeCategoryCodeType } from "@/redux/platforms/platformsTypes";
import { dispatch, useSelector } from "@/store/store";
import CustomDataGrid, { CustomDataGridRef } from "@/components/dataGrid/CustomDataGrid";
import { useEffect, useMemo, useRef, useState } from "react";
import { PaginateParams } from "@/types/pagination";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { getPaginationParamsInit } from "@/redux/platformFees/platformFeesSlice";
import { ServiceFee } from "@/redux/platformFees/platformFeesTypes";
import { FeeScopeEnum } from "@/redux/wlpFees/wlpFeesTypes";
import TypographyWithEllipsis from "@/components/TypographyWithEllipsis";
import EventIcon from '@mui/icons-material/Event';
import AmountValueCell from "@/components/dataGrid/renderCell/AmountValueCell";
import { getWlpServiceFees } from "@/redux/wlpFees/wlpFeesSlice";


const PriceFeeCell = ({ fee, t, theme }: { fee: ServiceFee, t: any, theme: Theme }) => {
    const isNotEditable = fee.scope === FeeScopeEnum.VISIBLE_NOT_EDITABLE;
    const price = fee.servicePrice || 0;
    const priceCcy = fee.serviceCcy || "EUR";
    const color = !isNotEditable ? theme.palette.primary.dark : theme.palette.secondary.dark;

    return (
        <Grid container display='flex' alignItems='center'>
            <Grid item>
                <AmountValueCell value={price} currency={priceCcy} color={color} />
            </Grid>
            {
                fee.futureFeeId &&
                <Grid item display='flex' alignItems='center' pl={2}>
                    <Tooltip title={
                        `${t('platforms.fees.futureFeeScheduledOn', {
                            date: new Date(fee.futureStartDate + 'Z').toLocaleString('en-GB', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            })
                        })}`
                    }>
                        <EventIcon sx={{ mr: 1 }} />
                    </Tooltip>
                </Grid>
            }
        </Grid >
    );
};


const getColumn = (t: any, theme: Theme) => {
    const columns: GridColDef[] = [
        {
            field: 'serviceCode',
            headerName: t('form.fields.process'),
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <TypographyWithEllipsis variant="body1">
                                {params.row.upperName}
                            </TypographyWithEllipsis>
                        </Grid>
                        {params.row.lowerName && (
                            <Grid item xs={12}>
                                <Typography variant="body2" color="primary">
                                    {params.row.lowerName}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                );
            },
        },
        {
            field: 'servicePrice',
            headerName: t('form.fields.yourCost'),
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                return <PriceFeeCell fee={params.row as ServiceFee} t={t} theme={theme} />
            }
        },
    ];

    return columns;
};

interface Props {
    feeType: FeeCategoryCodeType;
};


const WlpServiceFeesTab = ({ feeType }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { [feeType]: { list, count, loading, pagination, error } } = useSelector((state) => state.wlpFees.serviceFees);

    const [loadingTable, setLoadingTable] = useState(true);

    useEffect(() => {
        setLoadingTable(loading);
    }, [loading])

    const ref = useRef<CustomDataGridRef>(null);

    const paginationParamsInit = getPaginationParamsInit(feeType);

    const fetch = async (searchParams: PaginateParams) => {
        await dispatch(getWlpServiceFees(searchParams));
    };

    const columns = useMemo(() => {
        return getColumn(t, theme)
    }, [t, theme]);

    return (
        <>
            <CustomDataGrid
                ref={ref}
                rows={list}
                getRowId={(row) => `${row.uuid}`}
                rowCount={count}
                fetch={fetch}
                loading={loadingTable}
                paginationParams={pagination}
                paginationParamsInit={paginationParamsInit}
                columns={columns}
                loadingError={error}
                withSearch
                rowHeight={70}
                withRefreshBtn
            />
        </>
    );
};

export default WlpServiceFeesTab;