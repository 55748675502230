import { KYC } from "../clients/clientsTypes";
import { UserHistoryTypeEnum } from "../userHistory/userHistoryTypes";

export interface ComplianceStore {
    [key: string]: {
        openedSubTab: any;
    };
}

export enum ComplianceTab {
    CHECKS = "checks",
    ASSIGNED_TO_ME = "assignedToMe",
    TRANSACTIONS = "transactions",
    MONITORING = "monitoring",
    USER_RISK_GROUPS = "userRiskGroups",
    USER_RISK_LEVELS = "userRiskLevels",
    REPORTING = 'reporting'
}

export interface ComplianceType {
    id: number;
}

export enum UserHistoryEntityTypeEnum {
    DOCUMENT = "document",
    USER = "user",
    SUPPORT = "support",
}

export interface UserFlaggedHistory {
    createdBy: number;
    createdDate: string;
    id: string;
    meta: string;
    reason: string;
    type: UserHistoryTypeEnum;
    userId: number;
    entityId: number;
    userHistoryAttachments: userHistoryAttachment[];
    createdByData: {
        firstName: string;
        lastName: string;
    };
    userData: {
        firstName: string;
        lastName: string;
    };
}

export interface userHistoryAttachment {
    key: string;
    mimetype: string;
    name: string;
    size: string;
}

export interface ComplianceOfficersResponse {
    currentAdminId: number;
    list: ComplianceOfficer[];
}

export interface ComplianceOfficer {
    id: number;
    firstName: string;
    lastName: string;
    color?: string;
}

export enum ComplianceAssignmentTypes {
    BUSINESS = "BUSINESS",
    RISKS = "RISKS",
    POA = "POA",
    DOCUMENT_VERIFICATION = "DOCUMENT_VERIFICATION",
    TRANSACTIONS = "TRANSACTIONS",
    SECURITY_REPORTS = "SECURITY_REPORTS"
}

export interface UserComplianceData {
    riskEvalution: RiskEvalution[];
    user: UserData;
    status: UserComplianceStatus;
    userPoa: UserDataPoa[];
}

export enum UserComplianceStatus {
    VERIFIED = "VERIFIED",
    UNVERIFIED = "UNVERIFIED",
    SKIPPED = "SKIPPED",
    EMPLOYEE = "EMPLOYEE",
}

export enum BusinessComplianceStatus {
    VERIFIED = "VERIFIED",
    SUBMITTED = "SUBMITTED",
    IN_REVIEW = "IN_REVIEW",
    REJECTED = "REJECTED",
    WAITING_FOR_PAYMENT = "WAITING_FOR_PAYMENT",
    CREATED = "CREATED",
}

export interface UserData {
    id: number;
    email: string;
    mobileNumber: string;
    clientId: string;
    disabled: boolean;
    detained: boolean;
    profile: UserDataProfile;
    kyc: UserDataKyc[];
    kycIdentity: UserDataKycIdentity;
    flagged: boolean;
    poa: UserDataPoa[];
    risksVerified: boolean;
    source: string;
    risksVerifiedByUser: {
        firstName: string;
        lastName: string;
    };
}

export interface UserDataProfile {
    firstName: string;
    lastName: string;
    addressCountry: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    birthDate: string;
}

export interface UserDataKyc {
    id: number;
    status: string;
    rejectionReason: string;
    createdDate: string;
    tier: number;
}

export interface UserDataKycIdentity {
    id: number;
    kycId: number;
    service: string;
    serviceId: string;
    checkId: string;
    issueDate: string;
    expiryDate: string;
    documentType: string;
    documentNumber: string;
    placeOfBirth: string;
    issuingCountry: string;
    gender: string;
    identificationNumber: string;
    createdDate: string;
    updatedByAdmin: boolean;
    firstName: string,
    lastName: string,
    birthDate: string;
    addressCountry: string;
    address: string;
    address2: string;
    city: string;
    postalCode: string;
}

export interface RiskEvalution {
    id: number;
    lookupType: string;
    typeDescription: string;
    lookupCode: string;
    codeDescription: string;
    value: string;
    valueDescription: string;
    score: number;
    approvedBy: string;
}

export interface UserDataPoa {
    adminInProgress: boolean;
    createdBy: number;
    createdDate: string;
    id: number;
    key: string;
    mimeType: string;
    name: string;
    rejectionReason: string;
    size: string;
    status: string;
    updatedBy: string;
    updatedDate: string;
    userId: number;
    poaUpdatedByUser?: {
        firstName: string;
        lastName: string;
    };
}

export enum BusinessComplianceProvider {
    ADVISIONARY = "ADVISIONARY",
    ADVISIONARY_V3 = "ADVISIONARY_V3",
    ADVISIONARY_BRIDGY = "ADVISIONARY_BRIDGY",
}

export interface BusinessComplianceData {
    user: UserData;
    provider: BusinessComplianceProvider;
    form: V3AdvisionaryFormSections[] | any;
    kycPayment?: KycPayment;
    kyc: any;
    riskEvalution?: RiskEvalution[]
}

export interface V3AdvisionaryFormSections {
    name: string;
    status: "PENDING" | "APPROVED" | "REJECTED";
    rejectionReason: string;
    updatedByAdmin: boolean;
    values: any;
    formId: number;
    id: number;
}

export enum SectionType {
    BUSINESS_INFORMATION = "BUSINESS_INFORMATION",
    DOCUMENTS = "DOCUMENTS",
    DIRECTORS = "DIRECTORS",
    SHAREHOLDERS = "SHAREHOLDERS",
    PARTNERS = "PARTNERS",
    TRANSFERS = "TRANSFERS",
}

export enum BrigySectionType {
    BASIC_INFORMATION = "BASIC_INFORMATION",
    BUSINESS_INFORMATION = "BUSINESS_INFORMATION",
    REPRESENTERS = "REPRESENTERS",
    SHAREHOLDERS = "SHAREHOLDERS",
    ORDERS = "ORDERS",
    HISTORY = "HISTORY",
    MERCHANT_DETAILS = "MERCHANT_DETAILS",
    BANK_ACCOUNT_DETAILS = "BANK_ACCOUNT_DETAILS",
    DOCUMENTS = "DOCUMENTS",
}

export interface KycPayment {
    amount: string;
    ccy: string;
    reference: string;
    cancelled: boolean;
    paid: boolean;
    createdDate: string;
    id: string;
    proofOfPayment: string;
}

export interface ComplianceOverviewStatistics {
    pendingBusiness: number;
    pendingComplianceRequests: number;
    pendingRisks: number;
    pendingTransactions: number;
}


export interface BusinessRepresenativeComplianceData {
    kyc: KYC,
    kycIdentity: UserDataKycIdentity,
    user: UserData;
    provider: string,
}