import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@/store/store';
import { FeeCategoryCodeType } from '../platforms/platformsTypes';
import { showErrorNotification } from '@/utils/errors';
import { CreateOrUpdateWlpFeePayload, CreateOrUpdateWlpFeesPayload, WlpFee, WlpFeeStore } from './wlpFeesTypes';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { ProcessConfiguration, ProcessesFilterConfiguration, ServiceFee } from '../platformFees/platformFeesTypes';
import { getProcessFilterOptions, getRegionCountries, getRegionsOptions, getRiskGroupCodeOptions, getUserTypesOptions, getWlpCountries } from '../platformFees/platformFeesSlice';

export const getPaginationParamsInit = (feeCategoryCode: FeeCategoryCodeType) => {
    if (feeCategoryCode === FeeCategoryCodeType.SOFTWARE)
        return {
            filter: '',
            orderBy: "id",
            sort: SortDirectionEnum.DESC,
            take: 25,
            skip: 0
        }
    else {
        return {
            filter: `categoryCode=${feeCategoryCode};processDisabled=false`,
            orderBy: "id",
            sort: SortDirectionEnum.DESC,
            take: 25,
            skip: 0
        }
    };
};

const initialState: WlpFeeStore = {
    commonFilterOptions: {
        regionsOptions: [],
        loadingRegions: false
    },
    fees: {
        [FeeCategoryCodeType.COMPLIANCE]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: getPaginationParamsInit(FeeCategoryCodeType.COMPLIANCE),
            loadingProcesses: false,
            uniqueCcy: [],
            selectedCurrency: "EUR",
            processesConfigurations: null,
            filters: {
                [HeaderFilterType.PROCESSES]: [],
                [HeaderFilterType.USER_TYPES]: [],
                [HeaderFilterType.RISK_GROUPS]: [],
                [HeaderFilterType.REGION_CODE]: [],
            },
            filtersSet: false
        },
        [FeeCategoryCodeType.BANKING]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: getPaginationParamsInit(FeeCategoryCodeType.BANKING),
            loadingProcesses: false,
            uniqueCcy: [],
            selectedCurrency: "EUR",
            processesConfigurations: null,
            filters: {
                [HeaderFilterType.PROCESSES]: [],
                [HeaderFilterType.USER_TYPES]: [],
                [HeaderFilterType.RISK_GROUPS]: [],
                [HeaderFilterType.REGION_CODE]: [],
            },
            filtersSet: false
        },
        [FeeCategoryCodeType.CARD]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: getPaginationParamsInit(FeeCategoryCodeType.CARD),
            loadingProcesses: false,
            uniqueCcy: [],
            selectedCurrency: "EUR",
            processesConfigurations: null,
            filters: {
                [HeaderFilterType.PROCESSES]: [],
                [HeaderFilterType.USER_TYPES]: [],
                [HeaderFilterType.RISK_GROUPS]: [],
                [HeaderFilterType.REGION_CODE]: [],
            },
            filtersSet: false
        },
        [FeeCategoryCodeType.CRYPTO]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: getPaginationParamsInit(FeeCategoryCodeType.CRYPTO),
            loadingProcesses: false,
            uniqueCcy: [],
            selectedCurrency: "USDT",
            processesConfigurations: null,
            filters: {
                [HeaderFilterType.PROCESSES]: [],
                [HeaderFilterType.USER_TYPES]: [],
                [HeaderFilterType.RISK_GROUPS]: [],
                [HeaderFilterType.REGION_CODE]: [],
            },
            filtersSet: false
        }
    },
    serviceFees: {
        [FeeCategoryCodeType.SOFTWARE]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: getPaginationParamsInit(FeeCategoryCodeType.SOFTWARE)
        },
    }
};

const slice = createSlice({
    name: 'wlpFees',
    initialState,
    reducers: {
        setFees(state, action: PayloadAction<{ type: FeeCategoryCodeType, list: Array<WlpFee> }>) {
            state.fees[action.payload.type].list = action.payload.list;
        },
        setLoading: (state, action: PayloadAction<{ type: FeeCategoryCodeType, loading: boolean }>) => {
            state.fees[action.payload.type].loading = action.payload.loading
        },
        setError: (state, action: PayloadAction<{ type: FeeCategoryCodeType, error: string }>) => {
            state.fees[action.payload.type].error = action.payload.error;
        },
        setCount: (state, action: PayloadAction<{ type: FeeCategoryCodeType, count: number }>) => {
            state.fees[action.payload.type].count = action.payload.count;
        },
        setPagination: (state, action: PayloadAction<{ type: FeeCategoryCodeType, pagination: PaginateParams }>) => {
            state.fees[action.payload.type].pagination = action.payload.pagination;
        },
        // Service fees
        setServiceFees(state, action: PayloadAction<{ type: FeeCategoryCodeType, list: Array<ServiceFee> }>) {
            state.serviceFees[action.payload.type].list = action.payload.list;
        },
        setLoadingService: (state, action: PayloadAction<{ type: FeeCategoryCodeType, loading: boolean }>) => {
            state.serviceFees[action.payload.type].loading = action.payload.loading
        },
        setErrorService: (state, action: PayloadAction<{ type: FeeCategoryCodeType, error: string }>) => {
            state.serviceFees[action.payload.type].error = action.payload.error;
        },
        setCountService: (state, action: PayloadAction<{ type: FeeCategoryCodeType, count: number }>) => {
            state.serviceFees[action.payload.type].count = action.payload.count;
        },
        setPaginationService: (state, action: PayloadAction<{ type: FeeCategoryCodeType, pagination: PaginateParams }>) => {
            state.serviceFees[action.payload.type].pagination = action.payload.pagination;
        },
        // Filters
        setUniqueCCy(state, action: PayloadAction<{ type: FeeCategoryCodeType, list: Array<string> }>) {
            state.fees[action.payload.type].uniqueCcy = action.payload.list;
        },
        setProcessesLoading: (state, action: PayloadAction<{ type: FeeCategoryCodeType, loading: boolean }>) => {
            state.fees[action.payload.type].loadingProcesses = action.payload.loading
        },
        setSelectedCurrency(state, action: PayloadAction<{ type: FeeCategoryCodeType, currency: string }>) {
            state.fees[action.payload.type].selectedCurrency = action.payload.currency;
        },
        setProcessesConfigurations(state, action: PayloadAction<{ type: FeeCategoryCodeType, processesConfigurations: Array<ProcessesFilterConfiguration> }>) {
            state.fees[action.payload.type].processesConfigurations = action.payload.processesConfigurations;
        },
        setFilters: (state, action: PayloadAction<{ type: FeeCategoryCodeType }>) => {
            const riskGroupOptions = getRiskGroupCodeOptions();
            const userTypesOptions = getUserTypesOptions();
            state.fees[action.payload.type].filters[HeaderFilterType.RISK_GROUPS] = riskGroupOptions;
            state.fees[action.payload.type].filters[HeaderFilterType.USER_TYPES] = userTypesOptions;
            state.fees[action.payload.type].filters[HeaderFilterType.REGION_CODE] = state.commonFilterOptions.regionsOptions;
            state.fees[action.payload.type].filtersSet = true;
        },
        setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, type: FeeCategoryCodeType, options: Array<FilterOptionType> }>) => {
            state.fees[action.payload.type].filters[action.payload.filterType] = action.payload.options;
        },
        // Common filters
        setRegionsLoading: (state, action: PayloadAction<boolean>) => {
            state.commonFilterOptions.loadingRegions = action.payload
        },
        setRegionsOptions: (state, action: PayloadAction<Array<FilterOptionType>>) => {
            state.commonFilterOptions.regionsOptions = action.payload
        }
    },
});

export const {
    setFees,
    setLoading,
    setError,
    setCount,
    setPagination,
    setUniqueCCy,
    setProcessesLoading,
    setFilter,
    setFilters,
    setRegionsLoading,
    setRegionsOptions,
    setSelectedCurrency,
    setServiceFees,
    setLoadingService,
    setErrorService,
    setCountService,
    setPaginationService,
    setProcessesConfigurations
} = slice.actions;

export const getAndSetProcessesConfigurations = (type: FeeCategoryCodeType, selectedCurrency: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setProcessesLoading({ type, loading: true }));

            const filter = `hasIntegrationConfig=true;deleted=false;disabled=false;notIntegration=SYSTEM,TOKENS;categoryCode=${type}`;
            const response = await request.get(`/api/console/platforms/process/configurations/v2`, {
                params: {
                    filter: filter
                }
            });

            const { data } = response as { data: Array<ProcessConfiguration> };
            const processesConfigurations = data.map(item => {
                return {
                    process: item.process,
                    processName: item.name,
                    ccys: item.ccys,
                    integration: item.integration
                };
            });
            dispatch(setProcessesConfigurations({ type, processesConfigurations: processesConfigurations }));

            const uniqueCcy = [...new Set(data.flatMap(process => process.ccys))];
            dispatch(setUniqueCCy({ type, list: uniqueCcy }));

            if (uniqueCcy.length > 0) {
                if (type === FeeCategoryCodeType.CRYPTO) {
                    if (uniqueCcy.includes('USDT')) dispatch(setSelectedCurrency({ type, currency: 'USDT' }));
                    else if (uniqueCcy.includes('BTC')) dispatch(setSelectedCurrency({ type, currency: 'BTC' }));
                    else dispatch(setSelectedCurrency({ type, currency: uniqueCcy[0] }));
                } else {
                    if (uniqueCcy.includes('EUR')) dispatch(setSelectedCurrency({ type, currency: 'EUR' }));
                    else dispatch(setSelectedCurrency({ type, currency: uniqueCcy[0] }));
                }
            };

            const filteredProcessesConfigurations = getProcessFilterOptions(processesConfigurations, selectedCurrency);
            dispatch(setFilter({ filterType: HeaderFilterType.PROCESSES, type: type, options: filteredProcessesConfigurations }));
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setProcessesLoading({ type, loading: false }));
        }
    };
};


export const getRegions = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setRegionsLoading(true));
            const dataCountries = await getWlpCountries();
            const allowedCountries = dataCountries.map(obj => obj.countryCode);

            const dataRegions = await getRegionCountries();
            const filteredRegions = dataRegions.filter(country => allowedCountries.includes(country.country));

            const regionOptions = getRegionsOptions(filteredRegions);

            dispatch(setRegionsOptions(regionOptions));
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setRegionsLoading(false));
        }
    };
};

export const getWlpFeesByProcesses = (type: FeeCategoryCodeType, ccy: string, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ type, loading: true }));
            const response = await request.get(`/api/console/wlp-fee/processes/emi-and-wlp-fees/${ccy}`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take,
                }
            });
            const { data } = response;
            dispatch(setFees({ type, list: data.wlpAndEmiProcessFees }));
            dispatch(setCount({ type, count: data.count }));
            dispatch(setPagination({ type, pagination: payload }));
            return data;
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLoading({ type, loading: false }));
        }
    };
};

export const getWlpServiceFees = (payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingService({ type: FeeCategoryCodeType.SOFTWARE, loading: true }));
            const response = await request.get(`/api/console/wlp-fee/service/fees`, {
                params: {
                    filter: payload.filter,
                    skip: payload.skip.toString(),
                    take: payload.take,
                }
            });
            const { data } = response;
            dispatch(setServiceFees({ type: FeeCategoryCodeType.SOFTWARE, list: data.serviceFees }));
            dispatch(setCountService({ type: FeeCategoryCodeType.SOFTWARE, count: data.count }));
            dispatch(setPaginationService({ type: FeeCategoryCodeType.SOFTWARE, pagination: payload }));
            return data;
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLoadingService({ type: FeeCategoryCodeType.SOFTWARE, loading: false }));
        }
    };
};

export const createOrUpdateWlpFee = async (wlpId: string, payload: CreateOrUpdateWlpFeePayload) => {
    const response = await request.post(`/api/console/wlp-fee/fee/create-or-update/${wlpId}`, payload);
    return response?.data;
};

export const createOrUpdateWlpFeesList = async (wlpId: string, payload: CreateOrUpdateWlpFeesPayload) => {
    const response = await request.post(`/api/console/wlp-fee/fees/create-or-update/${wlpId}`, payload);
    return response?.data;
};

export default slice.reducer;