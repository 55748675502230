import { SvgIcon } from '@mui/material';

const ManualAdjustment = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#2B2B2B" fillOpacity="0.06" />
            <ellipse cx="11.9993" cy="15.3333" rx="0.666667" ry="0.666666" transform="rotate(-180 11.9993 15.3333)" fill="#EF3030" />
            <path d="M12 13.3335L12 8.66683M7.55499 7.14062L10.9139 5.57629C11.609 5.25256 12.4024 5.25256 13.0975 5.57629L16.443 7.13442C17.4214 7.59006 18.0635 8.61413 17.995 9.73371C17.7321 14.0281 16.5604 15.9695 13.3932 18.2217C12.5574 18.816 11.4548 18.8146 10.6184 18.2212C7.46094 15.9811 6.24581 14.0669 6.00411 9.71865C5.94229 8.60642 6.58343 7.59311 7.55499 7.14062Z" stroke="#EF3030" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);
export default ManualAdjustment;