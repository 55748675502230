import FormTextField from "@/components/form/FormTextField";
import { InputAdornment, useTheme } from "@mui/material";
import { ChangeEvent } from "react";
import { NumericFormat } from "react-number-format";


interface Props {
    precision: number,
    ccy?: string,
    amount?: string,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    setAmountToMax: () => void
}

const AmountField = ({ ccy, amount, precision, onChange, setAmountToMax }: Props) => {

    const theme = useTheme();

    return (<NumericFormat
        name="amount"
        customInput={FormTextField}
        onChange={(event) => onChange(event)}
        value={amount}
        displayType={'input'}
        placeholder={'0.00'}
        decimalScale={precision}
        fullWidth
        sx={{
            minHeight: '4.6rem',
            '& .MuiInputAdornment-root p': {
                fontSize: '1.3rem'
            },
            '& input::placeholder': {
                fontSize: '1.3rem',
            }
        }}
        inputProps={{ autoComplete: 'off', maxLength: 300, sx: { fontSize: '1.3rem' } }}
        InputProps={{
            style: { fontSize: '1.3rem' },
            sx: { background: theme.palette.lightGrey, height: '74px!important', fontSize: '1.3rem' },
            startAdornment:
                <InputAdornment position="start">  {ccy}   </InputAdornment>,
            endAdornment:
                <InputAdornment position='end'>
                    <span style={{ height: '0.8rem', cursor: 'pointer', textDecoration: 'underline', fontSize: '0.8rem', lineHeight: '0.8rem' }}
                        onClick={setAmountToMax}
                    >MAX</span>
                </InputAdornment>,
        }}
    />)
}

export default AmountField;