
import { UserLimitType } from '@/redux/limit/limitTypes';
import bigDecimal from 'js-big-decimal';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useAmount = (maxAmount?: number) => {
	const { t } = useTranslation();
	const maxAmountNumber = Number(maxAmount || 0);
	return Yup.number().required(t('form.validator.required').toString()).nullable()
		.moreThan(0, t('form.validator.moreThanZero').toString())
		.test('amount', t('form.validator.insufficient').toString(), (value) => (value ?? 0) <= maxAmountNumber).typeError(t('form.validator.numberFormat').toString());
};

export const useYouPay = (youpay?: any, availableBalance?: any) => {
	const { t } = useTranslation();
	const youPayNumber = Number(youpay || 0);
	const balanceNumber = Number(availableBalance || 0);

	return Yup.number()
		.moreThan(0, t('form.validator.moreThanZero').toString())
		.test('youpay', t('form.validator.insufficient').toString(), () => bigDecimal.compareTo(youPayNumber, balanceNumber) <= 0)
}


export const useAmountWithLimit = (maxAmount?: number, userLimits?: UserLimitType | null) => {
	const { t } = useTranslation();
	const validAmount = useAmount(maxAmount);
	return validAmount.test('amount', t('finance.limitReached',
		{
			ccy: userLimits?.uiCcy,
			availableBalanceNow: userLimits?.uiAvailableBalanceFormatted,
		}).toString(),
		(value) => (value ?? 0) <= (userLimits?.availableBeforeLimitCcy ?? value ?? 0))
};

export const usePaymentReference = () => {
	const { t } = useTranslation();
	return Yup.string()
		.required(t('form.validator.required').toString())
		.test('reference', t('form.validator.minChars', { field: t('transfers.reference').toString(), chars: '6' }).toString(), (value) => value ? value.replace(/ /g, '').length >= 6 : true)
		.max(140, t('form.validator.maxChar', { chars: '140' }).toString())
};