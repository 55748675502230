import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import DialogTitle from '@/components/DialogTitle';
import { showErrorNotification, showSuccessNotification } from "@/utils/errors";
import { getWlpName } from "@/components/dataGrid/utils/Platform";
import { deleteUsersLimits } from "@/redux/platformLimits/platformLimitsSlice";
import FormTextField from "@/components/form/FormTextField";

interface Props {
    wlpId: string;
    open: boolean;
    onClose: () => void;
}

const RemoveUserLimitsForm = ({ wlpId, open, onClose }: Props) => {
    const { t } = useTranslation();

    const initialValues = {
        reason: ""
    };

    const validationSchema = Yup.object({
        reason: Yup.string().required(t("form.validator.required").toString()),
    });

    const handleClose = () => {
        onClose();
    };

    const submit = async (formData: any) => {
        try {
            const { reason } = formData;
            const payload = { reason }
            await deleteUsersLimits(wlpId, payload);
            showSuccessNotification(t('limits.userLimitsDeleted'));
        } catch (e) {
            showErrorNotification(e);
        } finally {
            onClose();
        }
    };

    return (
        <Dialog
            id="payment-form"
            open={open}
            onClose={handleClose}
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: '300px',
                    maxHeight: '500px',
                    maxWidth: '600px'
                }
            }}>
            <DialogTitle onClose={handleClose}>
                {t('compliance.sar.confirmationRequired')}
            </DialogTitle>

            <DialogContent sx={{ pb: 1.5, mt: 1 }}>
                <Grid container direction='column' wrap='nowrap'>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={submit}>
                        {({ isSubmitting }) => {
                            return (
                                <Form>
                                    <Grid container item mt={1}>
                                        <Typography>
                                            {t('limits.wishProceedRemoveUserLimits', { wlpName: getWlpName(wlpId) })}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormTextField
                                            name="reason"
                                            fullWidth
                                            label={t("compliance.userFlag.commentLbl")}
                                            multiline
                                            rows={4}
                                        />
                                    </Grid>

                                    <Grid container item justifyContent='space-between' mt='auto' pt={2}>
                                        <Button onClick={handleClose} variant='outlined' color='secondary' disabled={isSubmitting}>{t('form.buttons.no')}</Button>
                                        <Button type='submit'>{t('form.buttons.yes')}</Button>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik >

                </Grid>
            </DialogContent>
        </Dialog >
    );
}

export default RemoveUserLimitsForm;