import { useSelector } from '@/store/store';
import { showErrorNotification } from '@/utils/errors';
import { Button, CardMedia, Grid, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import Loader from '@/components/Loader';
import DetailInfo from '../../components/DetailInfo';
import { CardDetails } from '@/redux/cards/cardsTypes';
import { getCard } from '@/redux/cards/cardsSlice';
import fallback from '../../assets/images/fallbackCard.png';
import StatusChip from '@/components/chips/StatusChip';
import CardTransaction from './CardTransaction';
import CardPendingActivities from './CardPendingActivities';
import { convertDate } from '@/utils/date';
import { getCurrencySign } from '@/utils/currency';
import { alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import { checkWritePermission } from '@/utils/permissions';
import { PermissionType } from '@/redux/user/types';
import EmiCardActionsDialog from './components/EmiCardActionsDialog';
import SHCardTopupTransactions from './SHCardTopupTransactions';
import EmiCardReplaceDialog from './components/EmiCardReplaceDialog';
import { hasSubprocessWithType } from '@/utils/processes';

const BankCardDetails = () => {
    const { t } = useTranslation();
    const { isEmi } = useSelector((state) => state.credentials);
    const { id } = useParams();
    const cardId = id ? parseInt(id) : -1;
    const location = useLocation();
    const logo = location.state?.logo ?? null;
    const countries = getNames("en");

    const theme = useTheme();

    const [card, setCard] = useState<CardDetails | null>(null);
    const [openEmiActionsDialog, setOpenEmiActionsDialog] = useState<boolean>(true);
    const [openCardReplaceDialog, setOpenCardReplaceDialog] = useState<boolean>(false);
    const [emiActionsDialogType, setEmiActionsDialogType] = useState<'CARD_SUSPEND' | 'CARD_TOP_UP' | 'CARD_BLOCK' | 'CARD_PULL_OUT' | 'CARD_BLOCK_AND_PULL_OUT' | null>(null);
    const [loading, setLoading] = useState(true);

    const { permissions } = useSelector((state) => state.user);
    const blockCardWritePermission = checkWritePermission(permissions, PermissionType.BLOCK_CARD);

    const isNegativeBalance = (card?.availableAmount ?? 0) < 0;


    const isCardSuspendAllowed = hasSubprocessWithType('CARD_SUSPEND', card?.subProcesses);
    const isCardTopupAllowed = hasSubprocessWithType('CARD_TOP_UP', card?.subProcesses);
    const isCardBlockAllowed = hasSubprocessWithType('CARD_BLOCK', card?.subProcesses);
    const isCardPulloutAllowed = hasSubprocessWithType('CARD_PULL_OUT', card?.subProcesses);
    const isCardBlockAndPulloutAllowed = hasSubprocessWithType('CARD_BLOCK_AND_PULL_OUT', card?.subProcesses) && card?.balanceOnHold === 0;
    const isCardReplaceAllowed = hasSubprocessWithType('REPLACE_CARD', card?.subProcesses);

    const fetchData = useCallback(() => {
        const get = async () => {
            setLoading(true);
            try {
                const data = await getCard(cardId);
                setCard(data);
            } catch (e) {
                showErrorNotification(e);
            }
            finally { setLoading(false); }
        };
        get();
    }, [cardId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    const balanceDetails = [
        {
            field:
                <CardMedia image={logo ?? fallback}
                    onError={(e) => (e.currentTarget.src = fallback)}
                    component="img"
                    sx={{ borderRadius: '5px', maxWidth: '164px' }} />,
            value: <Grid container>
                <Grid item xs={5} container flexDirection='column'>
                    <Grid item flex={1} container pb='10px'>
                        <Grid item xs={12}>
                            <Typography variant='caption' marginTop='auto'> {t('form.fields.balance')}</Typography>
                            <Typography variant='h2'> {`${getCurrencySign(card?.currency ?? 'EUR')} ${card?.balance}`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='caption' marginTop='auto'> {t('form.fields.type')}</Typography>
                            <Typography variant='h2'> {t(card?.virtual ? 'form.fields.virtual' : 'form.fields.physical')}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={7} textAlign='right'>
                    {card?.status && <StatusChip label={card.status} />}
                </Grid>

            </Grid>,
        },
        {
            field: t('form.fields.cardMask'),
            value: card?.cardNumberMask ?? ''
        },
        {
            field: t('form.fields.expirationDate'),
            value: card?.expirationDate ? convertDate(new Date(card?.expirationDate), 'MM/yy') : '-',
        },
        {
            field: t('form.fields.balanceOnHold'),
            value: card?.balanceOnHold ? `${getCurrencySign(card?.currency ?? 'EUR')}${card.balanceOnHold}` : `${getCurrencySign(card?.currency ?? 'EUR')}0`
        },
        {
            field: t('form.fields.balanceFrozen'),
            value: card?.balanceFrozen ? `${getCurrencySign(card?.currency ?? 'EUR')}${card.balanceFrozen}` : `${getCurrencySign(card?.currency ?? 'EUR')}0`
        },
        {
            field: t('form.fields.trackingCode'),
            value: card?.trackingNumber ?? t('form.fields.noTrackingCode')
        }
    ];

    const accountDetails = [
        {
            field: t('form.fields.clientId'),
            value: card?.clientId ?? ''
        }
        , {
            field: t('form.fields.clientEmail'),
            value: card?.email ?? ''
        },
        {
            field: t('form.fields.firstName'),
            value: card?.firstName ?? ''
        },
        {
            field: t('form.fields.lastName'),
            value: card?.lastName ?? ''
        },
        {
            field: t('form.fields.integration'),
            value: card?.integration ? t(`integrations.${card.integration}`) : ''
        }
    ];

    const handleDialogOpen = (type: 'CARD_SUSPEND' | 'CARD_TOP_UP' | 'CARD_BLOCK' | 'CARD_PULL_OUT' | 'CARD_BLOCK_AND_PULL_OUT'): void => {
        setEmiActionsDialogType(type);
        setOpenEmiActionsDialog(true);
    };

    const handleDialogClose = (refetch?: boolean): void => {
        setEmiActionsDialogType(null);
        setOpenEmiActionsDialog(false);
        refetch && fetchData();
    };


    if (loading) return <Loader />;

    return (
        <Grid sx={{ ...theme.typography.mainContentPadding }}>
            {openEmiActionsDialog && emiActionsDialogType && card && <EmiCardActionsDialog
                open={openEmiActionsDialog}
                card={card}
                onClose={handleDialogClose}
                type={emiActionsDialogType}
            />}
            {openCardReplaceDialog && card && <EmiCardReplaceDialog
                open={openCardReplaceDialog}
                card={card}
                onClose={() => setOpenCardReplaceDialog(false)}
            />}
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} container flexWrap='nowrap' direction='column'>
                    <Grid item xs>
                        <Typography variant='h2' mb='10px' >
                            {`${t('form.fields.card')} ${t('form.fields.overview')}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DetailInfo details={balanceDetails} />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} container flexWrap='nowrap' direction='column' >
                    <Grid item xs>
                        <Typography variant='h2' mb='10px'  >
                            {`${t('form.fields.card')} ${t('form.fields.information')}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <DetailInfo details={accountDetails} />
                    </Grid>
                </Grid>

                {card?.deliveryAddress && <Grid item xs={12} md={6} container flexWrap='nowrap' direction='column' >
                    <Grid item xs>
                        <Typography variant='h2' mb='10px'  >
                            {`${t('form.fields.card')} ${t('form.fields.deliveryInfo')}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <DetailInfo details={[
                            {
                                field: t('form.fields.address'),
                                value: card.deliveryAddress.address
                            },
                            {
                                field: t('form.fields.city'),
                                value: card.deliveryAddress.city
                            },
                            {
                                field: t('form.fields.country'),
                                value: countries[alpha3ToAlpha2(card.deliveryAddress.country)] || card.deliveryAddress.country
                            },
                            {
                                field: t('form.fields.postalCode'),
                                value: card.deliveryAddress.zip
                            },
                            {
                                field: t('form.fields.phoneNumber'),
                                value: card.deliveryAddress.phone
                            },
                        ]} />
                    </Grid>
                </Grid>}


                {isEmi && <Grid item xs={12} md={6} container flexWrap='nowrap' direction='column' >
                    <Grid item xs>
                        <Typography variant='h2' mb='10px'  >
                            {`${t('form.fields.card')} ${t('form.fields.actions')}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <DetailInfo details={[
                            ...(isCardTopupAllowed ? [{
                                field: t('cards.actions.title.CARD_TOP_UP').toString(),
                                value: <Button
                                    sx={{ backgroundColor: theme.palette.success.main }}
                                    disabled={!blockCardWritePermission || !isCardTopupAllowed}
                                    onClick={() => handleDialogOpen('CARD_TOP_UP')}
                                >{t('cards.actions.buttons.CARD_TOP_UP')}</Button>
                            }] : []),
                            ...(isCardBlockAllowed ? [{
                                field: t('cards.actions.title.CARD_BLOCK').toString(),
                                value: <Button
                                    sx={{ backgroundColor: theme.palette.error.main }}
                                    disabled={!blockCardWritePermission || !isCardBlockAllowed}
                                    onClick={() => handleDialogOpen('CARD_BLOCK')}
                                >{t('cards.actions.buttons.CARD_BLOCK')}</Button>
                            }] : []),
                            ...(isCardBlockAndPulloutAllowed ? [{
                                field: t('cards.actions.title.CARD_BLOCK_AND_PULL_OUT').toString(),
                                value: <Button
                                    sx={{ backgroundColor: theme.palette.error.main }}
                                    disabled={!blockCardWritePermission || !isCardBlockAndPulloutAllowed}
                                    onClick={() => handleDialogOpen('CARD_BLOCK_AND_PULL_OUT')}
                                >{t('cards.actions.buttons.CARD_BLOCK_AND_PULL_OUT')}</Button>
                            }] : []),
                            ...(isCardPulloutAllowed ? [{
                                field: t('cards.actions.title.CARD_PULL_OUT').toString(),
                                value: <Button
                                    sx={{ backgroundColor: theme.palette.blue }}
                                    disabled={!blockCardWritePermission || !isCardPulloutAllowed}
                                    onClick={() => handleDialogOpen('CARD_PULL_OUT')}
                                >{t('cards.actions.buttons.CARD_PULL_OUT')}</Button>
                            }] : []),
                            ...(isCardSuspendAllowed ? [{
                                field: t('cards.actions.title.CARD_SUSPEND').toString(),
                                value: <Button
                                    sx={{ backgroundColor: theme.palette.blue }}
                                    disabled={!blockCardWritePermission || !isCardSuspendAllowed}
                                    onClick={() => handleDialogOpen('CARD_SUSPEND')}
                                >{t('cards.actions.buttons.CARD_SUSPEND')}</Button>
                            }] : []),
                            {
                                field: t('cards.actions.title.REPLACE').toString(),
                                value: <Button
                                    sx={{ backgroundColor: theme.palette.grey1 }}
                                    disabled={!blockCardWritePermission || isNegativeBalance || !isCardReplaceAllowed}
                                    onClick={() => setOpenCardReplaceDialog(true)}
                                >{t('cards.actions.buttons.REPLACE')}</Button>
                            },

                        ]} />
                    </Grid>
                </Grid>}

            </Grid>
            {card &&
                <Grid container item xs={12} mt={2} rowGap={2}>
                    <CardTransaction accountId={card?.accountId} userId={card.userId} />
                </Grid>
            }
            {card && card.integration === 'SH_FINANCE' &&
                <Grid container item xs={12} mt={2} rowGap={2}>
                    <SHCardTopupTransactions userId={card.userId} />
                </Grid>
            }
            {card && card.agentType === 'DEFI' &&
                <Grid mt={2}>
                    <CardPendingActivities userId={card.userId} accountId={card.accountId} />
                </Grid >}
        </ Grid>
    );
};

export default BankCardDetails;
