import { FilterOptionType } from '@/components/dataGrid/CustomFilterHeader';
import { PaginateParams } from '@/types/pagination';
import { ProcessFeeType } from '../process/processTypes';

export interface Card {
	id: number;
	name: string;
	label: string;
	wlpId: string;
	status: string;
	userId: number;
	email: string;
	firstName: string;
	lastName: string;
	accountId: number;
	deliveryAddress: {
		country: string;
		city: string;
		address: string;
		zip: string;
		phone: number;
	},
	balance: number;
	balanceString: number;
	balanceOnHold: number;
	balanceOnHoldString: string;
	balanceFrozen: number;
	balanceFrozenString: string;
	availableAmount: number;
	availableAmountString: string;
	lockedAmount: number;
	lockedAmountString: string;
	currency: string;
	approvedBy: number;
	cardholder: string;
	expirationDate: Date;
	cardNumberMask: string;
	approvalRequired: boolean;
	clientId: string;
	virtual: boolean;
	productName: string;
	logo?: string;
}

export interface CardDetails {
	id: number;
	name: string;
	label: string;
	wlpId: string;
	status: string;
	userId: number;
	email: string;
	firstName: string;
	lastName: string;
	accountId: number;
	deliveryAddress: {
		country: string;
		city: string;
		address: string;
		phone: string;
		zip: string;
	};
	balance: number;
	balanceString: string;
	balanceOnHold: number;
	balanceOnHoldString: string;
	balanceFrozen: number;
	balanceFrozenString: string;
	availableAmount: number;
	availableAmountString: string;
	lockedAmount: number;
	lockedAmountString: string;
	currency: string;
	cardholder: string;
	expirationDate: string;
	cardNumberMask: string;
	approvalRequired: boolean;
	clientId: string;
	logo: string;
	logoTextColor: string;
	trackingNumber: string;
	documentNumber: string;
	documentStatus: string;
	virtual: boolean;
	agentType: string;
	integration: string;
	subProcesses: any[];
}

export interface CardsStore {
	[key: string]: {
		loading: boolean,
		list: Array<Card>,
		error: string | null,
		count: number,
		pagination: PaginateParams
		filters: {
			[key: string]: Array<FilterOptionType>
		},
		filtersSet: boolean;
	}
}


export interface DectaCardTransactionsPayload {
	intercashSortBy?: string,
	intercashSkip?: number,
	intercashSort?: string,
	dectaNextId?: number,
	take: number,
	accountId: number,
	userId: number
}

export interface CardTransactionType {
	transactionNumber: string,
	activity: string,
	remitterName: string,
	asset: string,
	currencySymbol: string,
	date: string,
	status: string,
	amount: number,
	attachments?: [{ fileId: string, filename: string }],
	beneficiaryAccount?: string,
	remitterAccount?: string,
	details?: string,
	beneficiary?: string,
	documentId?: number,
	currency?: string,
	conversionCurrency?: string,
	beneficiaryWalletId?: string,
	cardNumberMask?: string,
	docNumber: string,
	fees?: Array<{
		amount: number,
		ccy: string,
		details?: string,
		transactionType?: string
	}>,
	messageCode?: string
}

export enum CardsTypeEnum {
	VIRTUAL = 'VIRTUAL',
	PHYSICAL = 'PHYSICAL',
	DELIVERIES = 'DELIVERIES',
	ALL = 'ALL'
}

export enum CardStatusEnum {
	UNKNOWN = 'UNKNOWN',
	REQUESTED = 'REQUESTED',
	PENDING = 'PENDING',
	ORDERED = 'ORDERED',
	ORDER_COMPLETED = 'ORDER_COMPLETED',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	BLOCKED = 'BLOCKED',
	SUSPENDED = 'SUSPENDED',
	FROZEN = 'FROZEN',
	ACTIVE = 'ACTIVE',
	ACTIVATING = 'ACTIVATING',
	SUSPENDING = 'SUSPENDING',
	INACTIVE = 'INACTIVE',
	LOST = 'LOST',
	STOLEN = 'STOLEN',
	EXPIRED = 'EXPIRED'
}

export interface CardOptionType {
	proc: string,
	type: string,
	integration: string,
	providerType: string,
	name: string,
	description: string,
	processProperties: {
		virtual: boolean,
		feeScopeEmi: string,
		ccy: string,
		metal: boolean,
		currencySymbol: string,
		subCategoryCode: string,
		feeScopeWlp: string,
		categoryCode: string
	},
	subProcesses: any,
	config: {
		serviceProviderLogo: string
		validateAddress: boolean,
	},
	fees: ProcessFeeType[],
}



export interface CardReplacelPayload {
	userId: number,
	replacedCardId: number,
	feeAccountId: number,
	cardProduct: string,
	smsPhoneNumber?: string,
	shipProc?: string,
	city?: string,
	country?: string,
	address?: string,
	zip?: string,
}

export interface CardShipmentCountry {
	key: string;
	integration: string;
	name: string;
	alpha2: string;
	alpha3: string;
}
