import * as React from "react";
import { SvgIcon } from '@mui/material';
const Search2 = (props: any) => (
  <SvgIcon {...props}>
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.5 18.5 22 22m-1-10.5a9.5 9.5 0 1 0-19 0 9.5 9.5 0 0 0 19 0Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
export default Search2;
