import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BalanceType, IntegrationDetailsType, SubTabsEnum, SystemBalanceGraphic } from "../emiFinance/emiFinanceTypes";
import { BankingBreakdownType, EmiFinanceBankingStore, PaymentsSplit, Payment, MatchPaymentPayload, MathingSystemAccount } from "./emiFinanceBankingTypes";
import { AppDispatch } from "@/store/store";
import request from "@/services/request";
import { PaginateParams, SortDirectionEnum } from "@/types/pagination";
import { LookupValueType } from "../fnd/fndTypes";

export const paginationParamsInit: PaginateParams = {
    filter: '',
    orderBy: 'transactionDate',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: EmiFinanceBankingStore = {
    payments: {
        loadingPayments: false,
        count: 0,
        list: [],
        error: null,
        pagination: paginationParamsInit
    },
    balances: [],
    tabs: [],
    activeTab: SubTabsEnum.ALL,
    loading: true
};

const slice = createSlice({
    name: 'emiFinanceBanking',
    initialState,
    reducers: {
        setPayments(state, action: PayloadAction<Array<Payment>>) {
            state.payments.list = action.payload;
        },
        setCount(state, action: PayloadAction<number>) {
            state.payments.count = action.payload;
        },
        setLoadingPayments(state, action: PayloadAction<boolean>) {
            state.payments.loadingPayments = action.payload;
        },
        setPagination: (state, action: PayloadAction<PaginateParams>) => {
            state.payments.pagination = action.payload;
        },
        setBalances(state, action: PayloadAction<Array<IntegrationDetailsType>>) {
            state.balances = action.payload;
        },
        setActiveTab(state, action: PayloadAction<string>) {
            state.activeTab = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
    }
});

export const {
    setPayments,
    setLoadingPayments,
    setCount,
    setBalances,
    setActiveTab,
    setPagination,
    setLoading } = slice.actions;

export default slice.reducer;


export const getBankingPaymentsList = (integration: string, params: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingPayments(true));
            const response = await request.get(`/api/console/emi-finance/banking/payments/list/${integration}`, {
                params: {
                    filter: params.filter,
                    orderBy: params.orderBy,
                    sort: params.sort.toUpperCase(),
                    skip: params.skip.toString(),
                    take: params.take,
                }
            });
            const { data } = response;
            dispatch(setPayments(data.list));
            dispatch(setCount(data.count));
            dispatch(setPagination(params));
        }
        catch (e) {
            dispatch(setPayments([]));
        }
        finally {
            dispatch(setLoadingPayments(false));
        }
    }
};

export const getBakingPaymentsWalletsUuids = async (lookupType: string, integration: string, params: PaginateParams): Promise<Array<LookupValueType>> => {
    const response = await request.get(`/api/console/emi-finance/banking/payments/walletsuuids/${lookupType}/${integration}`,
        {
            params: {
                filter: params.filter,
                orderBy: params.orderBy,
                sort: params.sort.toUpperCase(),
                skip: params.skip.toString(),
                take: params.take,
            }
        });
    return response?.data;
}



export const getBankingClientGraphic = async (integration: string, dateFrom: string, dateTo: string): Promise<Array<SystemBalanceGraphic>> => {
    const response = await request.get(`/api/console/emi-finance/banking/graphic/${integration}?dateFrom=${dateFrom}&dateTo=${dateTo}`);
    return response?.data;
};

export const getBankingCustomerBreakdown = async (integration: string): Promise<Array<BankingBreakdownType>> => {
    const response = await request.get(`/api/console/emi-finance/banking/breakdown/${integration}`);
    return response?.data;
};

export const getBankingBalancesAll = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/emi-finance/banking/all/balances`);
            const data = response?.data || [];
            dispatch(setBalances(data));
        }
        catch (e) {
            dispatch(setBalances([]));
        }
        finally {
            dispatch(setLoading(false));
        }
    }
};

let abortController1: AbortController | null = null;
let abortController2: AbortController | null = null;
let abortController3: AbortController | null = null;

export const getBankingPaymentsSplit = async (integration: string): Promise<Array<PaymentsSplit>> => {
    if (abortController1) {
        abortController1.abort();
    }

    abortController1 = new AbortController();
    const { signal } = abortController1;

    const response = await request.get(`/api/console/emi-finance/banking/payments/split/${integration}`, { signal });
    return response?.data;
}

export const getBankingBalances = async (integration: string): Promise<Array<BalanceType>> => {
    if (abortController2) {
        abortController2.abort();
    }

    abortController2 = new AbortController();
    const { signal } = abortController2;

    const response = await request.get(`/api/console/emi-finance/banking/balances/${integration}`, { signal });
    return response?.data;
};

export const getBankingLedgerBalances = async (integration: string): Promise<Array<BalanceType>> => {
    if (abortController3) {
        abortController3.abort();
    }

    abortController3 = new AbortController();
    const { signal } = abortController3;

    const response = await request.get(`/api/console/emi-finance/banking/ledger/balance/${integration}`, { signal });
    return response?.data;
};

export const matchUnassignedPayment = async (payload: MatchPaymentPayload) => {
    const response = await request.post(`/api/console/emi-finance/banking/match/payment`, payload);
    return response?.data;
}

export const getMatchingAccounts = async (currencyCode: string, integration: string, transactionType: string): Promise<Array<MathingSystemAccount>> => {
    const response = await request.get(`/api/console/emi-finance/banking/matching/accounts/${currencyCode}/${integration}/${transactionType}`);
    return response?.data;
}