import CustomDataGrid from "@/components/dataGrid/CustomDataGrid";
import { getPendingActivitiesByAccount, paginationParamsInit, reset, restartPendingActivitiesByAccount, setStoredData } from "@/redux/pendingActivities/pendingActivitiesSlice";
import { PendingActivitiesEnum } from "@/redux/pendingActivities/pendingActivitiesTypes";
import { dispatch, useSelector } from "@/store/store";
import { PaginateParams } from "@/types/pagination";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import DateCell from "@/components/dataGrid/renderCell/DateCell";
import StatusCell from "@/components/dataGrid/renderCell/StatusCell";
import { useTranslation } from "react-i18next";
import { normalizeString } from "@/utils/stringFormat";
import { Button, Grid, Stack, Typography } from "@mui/material";
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import { checkWritePermission } from "@/utils/permissions";
import { PermissionType } from "@/redux/user/types";
import { showErrorNotification, showSuccessNotification } from "@/utils/errors";
import AmountValueCell from "@/components/dataGrid/renderCell/AmountValueCell";
import NetworkLabel from "@/components/NetworkLabel";
import { getActualDecimals } from "@/utils/currency";
import TypographyWithEllipsis from "@/components/TypographyWithEllipsis";
import TransactionHash from "@/components/TransactionHash";
import AddressOnScan from "@/components/AddressOnScan";


const getColumn = (t: any) => {
    const columns: GridColDef[] = [
        {
            field: 'createdDate',
            headerName: t('form.fields.date').toString(),
            flex: 0.8,
            renderCell: (params: GridRenderCellParams) => DateCell(params),
        },
        {
            field: 'amount',
            headerName: t('form.fields.amount'),
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => <Grid container>
                <Grid container item xs={12} alignItems='center' flexWrap='nowrap'>
                    <AmountValueCell
                        value={params.row.meta.requestedAmount}
                        currency={params.row.meta.ccy}
                        withColor={false}
                        fontWeight={500}
                        decimalScale={getActualDecimals(params.row.meta.requestedAmount)}
                        withDash={true}
                        withMinusSign={false}
                        withLogo />
                </Grid>
                <Grid container item xs={12} alignItems='center' flexWrap='nowrap'>
                    <NetworkLabel chainId={params.row.meta.chainId} />
                </Grid>
            </Grid>
        },
        {
            field: 'action',
            headerName: t('form.fields.type'),
            flex: 1,
            renderCell: (params: GridRenderCellParams) => <Grid container>
                <Grid container item xs={12} alignItems='center' flexWrap='nowrap'>
                    <Typography>{t('enum.pendingActivityTypeEnum.' + params.value, normalizeString(params.value))}</Typography>
                </Grid>
                <Grid container item xs={12} alignItems='center' flexWrap='nowrap'>
                    <TypographyWithEllipsis color='text.secondary'>{params.row.proc}</TypographyWithEllipsis>
                </Grid>
            </Grid>
        },
        {
            field: 'txHash',
            headerName: t('form.fields.transactionHash'),
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params: GridRenderCellParams) => <TransactionHash chainId={params.row.meta.chainId} trxHash={params.row.txHash ?? null} />
        },
        {
            field: 'sourceAddress',
            headerName: t('form.fields.sourceAddress'),
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params: GridRenderCellParams) => <AddressOnScan chainId={params.row.meta.chainId} address={params.row.sourceAddress ?? null} />
        },
        {
            field: 'status',
            headerName: t('form.fields.status'),
            flex: 0.8,
            sortable: false,
            renderCell: StatusCell
        }
    ];
    return columns;
};


interface Props {
    userId: number;
    accountId: number;
};

const CardPendingActivities = ({ userId, accountId }: Props) => {
    const { t } = useTranslation();
    const storeKey = PendingActivitiesEnum.USER_CARD;

    const { permissions } = useSelector((state) => state.user);
    const restartPermission = checkWritePermission(permissions, PermissionType.RESTART_PENDING_ACTIVITIES)

    const { [storeKey]: { list, error, count, loading, pagination, storedUserId, storedAccountId } } = useSelector((state) => state.pendingActivities);

    const fetch = async (searchParams: PaginateParams) => {
        await dispatch(getPendingActivitiesByAccount(storeKey, userId, accountId, searchParams))
    };

    const columns = useMemo(() => {
        return getColumn(t);
    }, [t]);

    const [loadingRestart, setLoadingRestart] = useState(false);

    const restartActivies = async () => {
        setLoadingRestart(true);
        try {
            await restartPendingActivitiesByAccount(accountId);
            await fetch(paginationParamsInit);
            showSuccessNotification(t('form.fields.pendingActivitiesRestarted'));
        } catch (e) {
            showErrorNotification(e);
        }
        finally {
            setLoadingRestart(false);
        }
    };

    const createUserBtn = (
        <Button variant="outlined" color='secondary' disabled={!restartPermission || loadingRestart || count === 0} onClick={() => restartActivies()}>
            <Stack direction='row' columnGap={1} alignItems='center'>
                <RestartAltRoundedIcon sx={{ fontSize: '1.125rem' }} />
                {t('form.buttons.restartActivities')}
            </Stack>
        </Button>
    );

    useEffect(() => {
        if (storedUserId !== userId) {
            dispatch(reset(storeKey));
            dispatch(setStoredData({ type: storeKey, userId: userId, accountId: accountId }));
        }
    }, [userId, storedUserId, storeKey, accountId]);

    return (
        <CustomDataGrid
            title={t('form.fields.pendingActivities').toString()}
            rows={list}
            getRowId={(row) => row.id}
            rowCount={count}
            readyToFetchCondition={userId === storedUserId && storedAccountId === accountId}
            fetch={fetch}
            loading={loading}
            customButton={createUserBtn}
            paginationParams={pagination}
            paginationParamsInit={paginationParamsInit}
            columns={columns}
            loadingError={error}
            withRefreshBtn={true}
            withSearch={false} />
    )
};

export default CardPendingActivities;
