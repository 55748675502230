import * as React from "react";
import { SvgIcon } from '@mui/material';

const Platforms = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path opacity="0.5" d="M1.66602 10C1.66602 6.87522 1.66602 5.31283 2.46178 4.21756C2.71878 3.86383 3.02985 3.55276 3.38358 3.29576C4.47885 2.5 6.04124 2.5 9.16602 2.5H10.8327C13.9575 2.5 15.5198 2.5 16.6151 3.29576C16.9688 3.55276 17.2799 3.86383 17.5369 4.21756C18.3327 5.31283 18.3327 6.87522 18.3327 10C18.3327 13.1248 18.3327 14.6872 17.5369 15.7824C17.2799 16.1362 16.9688 16.4472 16.6151 16.7042C15.5198 17.5 13.9575 17.5 10.8327 17.5H9.16602C6.04124 17.5 4.47885 17.5 3.38358 16.7042C3.02985 16.4472 2.71878 16.1362 2.46178 15.7824C1.66602 14.6872 1.66602 13.1248 1.66602 10Z" stroke="currentColor" strokeWidth="1.2" />
            <path d="M9.16602 11.668C9.16602 10.8914 9.16602 10.5031 9.29288 10.1968C9.46204 9.78845 9.7865 9.46399 10.1949 9.29484C10.5012 9.16797 10.8894 9.16797 11.666 9.16797H12.4993C13.2759 9.16797 13.6642 9.16797 13.9705 9.29484C14.3789 9.46399 14.7033 9.78845 14.8725 10.1968C14.9993 10.5031 14.9993 10.8914 14.9993 11.668C14.9993 12.4445 14.9993 12.8328 14.8725 13.1391C14.7033 13.5475 14.3789 13.8719 13.9705 14.0411C13.6642 14.168 13.2759 14.168 12.4993 14.168H11.666C10.8894 14.168 10.5012 14.168 10.1949 14.0411C9.7865 13.8719 9.46204 13.5475 9.29288 13.1391C9.16602 12.8328 9.16602 12.4445 9.16602 11.668Z" stroke="currentColor" strokeWidth="1.2" />
        </svg>
    </SvgIcon>
);
export default Platforms;