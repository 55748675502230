import * as React from "react";
import { SvgIcon } from '@mui/material';

const Tokens = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path opacity="0.4" d="M10.0013 1.66797C14.6037 1.66797 18.3346 5.39893 18.3346 10.0013C18.3346 14.6037 14.6037 18.3346 10.0013 18.3346C5.39893 18.3346 1.66797 14.6037 1.66797 10.0013C1.66797 7.67014 2.62517 5.56254 4.16797 4.05011" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path opacity="0.7" d="M4.16797 10.0013C4.16797 13.223 6.77964 15.8346 10.0013 15.8346C13.223 15.8346 15.8346 13.223 15.8346 10.0013C15.8346 6.77964 13.223 4.16797 10.0013 4.16797" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M10 13.3346C11.8409 13.3346 13.3333 11.8423 13.3333 10.0013C13.3333 8.16035 11.8409 6.66797 10 6.66797" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default Tokens;