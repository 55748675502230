import { t } from "i18next";
import { startCase } from "lodash";

export const DEFAULT_CCY_SYMBOL = '€';
export const supportedCurrencies = ['EUR', 'GBP', 'USD'];

export const getIsFiat = (currency: string): boolean => {
	return supportedCurrencies.includes(currency);
};

export const getCurrencySign = (currency: string): string => {
	switch (currency) {
		case 'EUR': return '€';
		case 'GBP': return '£';
		case 'USD': return '$';
		// case 'BTC': return '₿';
		// case 'USDT': return '₮';
		// case 'DOGE': return 'Ð';
		// case 'ETH': return 'Ξ';
		// case 'ADA': return '₳';
		default: return currency;
	}
};

export const getFiatPaymentTitle = (currency: string): string => {
	switch (currency) {
		case 'EUR': return t('enum.PaymentTypeEnum.SEPA_STEP2').toString();
		case 'GBP': return t('enum.PaymentTypeEnum.SWIFT').toString();
		case 'USD': return t('enum.PaymentTypeEnum.UK_CHAPS').toString();
		default: return '';
	}
};

export const getCurrencyDescription = (currency: string) => {

	if (!currency) return '';
	const name = new Intl.NumberFormat('en-US', { style: 'currency', currency: currency, currencyDisplay: 'name' }).formatToParts(0).find(p => p.type === 'currency')?.value || '';
	return startCase(name.toLowerCase());

}

export const getCurrencyLogo = (currency: string) => {
	return `https://abcmob-public.s3.eu-central-1.amazonaws.com/currency_logo/${currency.toUpperCase()}.png`
}

export const removeTrailingZeros = (value: string) => {
	let trailed = value.replace(/(\.\d*?)0+$/, '$1');

	if (trailed.endsWith('.')) {
		return trailed.slice(0, -1);
	} else {
		return trailed;
	}
};

export const getActualDecimals = (value: string, minDecimals: number = 2) => {
	if (!value) return minDecimals;
	const trailed = removeTrailingZeros(value);
	const trailedArr = trailed.split('.');

	if (trailedArr.length === 1) {
		return minDecimals;
	} else {
		const actualDecimals = trailedArr[1].length;
		return actualDecimals > minDecimals ? actualDecimals : minDecimals;
	}
};

