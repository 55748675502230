
import { Paper, styled } from '@mui/material';

const FormPaperLarge = styled(Paper)(() => ({

	height: '700px',
	maxHeight: '100vh',
	minHeight: '80%',
	minWidth: '600px',
	maxWidth: '600px',
	width: '600px',
	display: 'flex',
	flex: 1,
	'@media only screen and (max-height: 400px)': {
		maxHeight: 'unset',
		minHeight: 'unset',
		minWidth: 'unset',
		maxWidth: '100%',
		width: '100%',
		height: '100%',
		padding: '1rem',
		margin: 0,
		borderRadius: 0,
	},
	'@media only screen and (max-width:600px)': {
		maxHeight: 'unset',
		minHeight: 'unset',
		minWidth: 'unset',
		maxWidth: '100%',
		width: '100%',
		height: '100%',
		padding: '1rem',
		margin: 0,
		borderRadius: 0,
	},

}));

export default FormPaperLarge;
