import { PaginateParams } from '@/types/pagination';
import { UserType } from '../user/types';
import { FilterOptionType } from '@/components/dataGrid/CustomFilterHeader';

export interface ClientRole {
    code: string,
    id: number,
    label: string
}
export interface ClientsStore {
    [key: string]: {
        loading: boolean,
        list: Array<MonitoringUser>,
        error: string | null,
        count: number,
        pagination: PaginateParams,
        filters: {
            [key: string]: Array<FilterOptionType>
        },
        filtersSet: boolean;
    }
};

export enum StateType {
    FLAGGED = 'FLAGGED',
    BLOCKED = 'DISABLED',
    SUSPENDED = 'DETAINED',
    RISKED = 'RISKED',
};

interface KycIdentity {
    service: string;
    serviceId: string;
}

interface KYC {
    id: number;
    status: string;
    rejectionReason: string | null;
    tier: number;
    checkCount: number;
    kycIdentity: KycIdentity;
}

interface Profile {
    type: UserType,
    address: string;
    birthDate: string;
    companyName: string | null;
    companyAddress: string | null;
    companyCountry: string | null;
    companyRegNumber: string | null;
    country: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    politicalExposed: boolean;
    usCitizen: boolean;
    sumSubChanged: boolean;
}

interface History {
    reason: string;
    createdDate: string;
    createdByName: string;
    createdByLastName: string;
}

export interface MonitoringUser {
    userId: number;
    clientId: string;
    email: string;
    firstName: string;
    lastName: string;
    companyName: string | null;
    type: string;
    wlpId: string;
    emailConfirmed: boolean;
    disabled: boolean;
    detained: boolean;
    riskGroupFixed: boolean;
    twoFactorAuthEnabled: boolean;
    mobilePinEnabled: boolean;
    mobileNumberConfirmed: boolean;
    createdDate: string;
    country: string;
    companyRegNumber: string | null;
    lastLoginDate: string;
    detainedReason: string;
    riskGroupCode: string;
    role: string;
    usedInvitationCode: string | null;
    confirmedMobileNumber: string;
    mobileNumber: string;
    poaStatus: string | null;
    kycTier: number;
    kycRiskLevel: string;
    status: string;
    kyc: Array<KYC>;
    profile: Profile;
    adminRole: string | null;
    history: History;
    risksVerified: boolean;
    flaggedBy: number;
    flaggedByFirstName: string;
    flaggedByLastName: string;
    flaggedReason: string;
    kycDocumentNumber: string;
    kycDocumentType: string;
    kycExpiryDate: string;
    kycIssueDate: string;
    kycIssuingCountry: string;
    ushareId: string | null;
    flagged: boolean;
}