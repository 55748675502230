import * as React from "react";
import { SvgIcon } from '@mui/material';

const Home = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path opacity="0.5" d="M1.97136 10.7995C1.65516 8.60198 1.49706 7.50321 1.94744 6.56376C2.39782 5.6243 3.35646 5.05325 5.27373 3.91114L6.42776 3.22369C8.16884 2.18654 9.03937 1.66797 10.0013 1.66797C10.9632 1.66797 11.8338 2.18654 13.5748 3.22369L14.7289 3.91114C16.6461 5.05325 17.6048 5.6243 18.0552 6.56376C18.5055 7.50321 18.3474 8.60198 18.0312 10.7995L17.7989 12.414C17.3928 15.2368 17.1897 16.6483 16.2104 17.4915C15.2312 18.3346 13.7952 18.3346 10.923 18.3346H9.07957C6.20744 18.3346 4.77138 18.3346 3.79216 17.4915C2.81294 16.6483 2.60985 15.2368 2.20367 12.414L1.97136 10.7995Z" stroke="currentColor" strokeWidth="1.2" />
            <path d="M10 12.5L10 15" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default Home;