import * as React from "react";
import { SvgIcon } from '@mui/material';

const Money = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path d="M1.66602 8.33203C1.66602 5.97501 1.66602 4.7965 2.39825 4.06426C3.13048 3.33203 4.30899 3.33203 6.66602 3.33203H10.8327C13.1897 3.33203 14.3682 3.33203 15.1004 4.06426C15.8327 4.7965 15.8327 5.97501 15.8327 8.33203C15.8327 10.6891 15.8327 11.8676 15.1004 12.5998C14.3682 13.332 13.1897 13.332 10.8327 13.332H6.66602C4.30899 13.332 3.13048 13.332 2.39825 12.5998C1.66602 11.8676 1.66602 10.6891 1.66602 8.33203Z" stroke="currentColor" strokeWidth="1.2" />
            <path opacity="0.5" d="M15.8337 5.89844C16.6463 5.97836 17.1932 6.159 17.6015 6.56724C18.3337 7.29947 18.3337 8.47798 18.3337 10.835C18.3337 13.192 18.3337 14.3705 17.6015 15.1028C16.8692 15.835 15.6907 15.835 13.3337 15.835H9.16704C6.81002 15.835 5.63151 15.835 4.89927 15.1028C4.49103 14.6945 4.31039 14.1476 4.23047 13.335" stroke="currentColor" strokeWidth="1.2" />
            <path d="M10.8327 8.33333C10.8327 9.48393 9.89994 10.4167 8.74935 10.4167C7.59876 10.4167 6.66602 9.48393 6.66602 8.33333C6.66602 7.18274 7.59876 6.25 8.74935 6.25C9.89994 6.25 10.8327 7.18274 10.8327 8.33333Z" stroke="currentColor" strokeWidth="1.2" />
            <path opacity="0.5" d="M13.334 10L13.334 6.66667" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path opacity="0.5" d="M4.16602 10L4.16602 6.66667" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default Money;