import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { getErrorMessageCode } from '@/utils/errors';
import { UserComplianceStore, UserRiskLevel } from './userComplianceTypes';

const initialState: UserComplianceStore = {
    loading: false,
    userRiskLevel: {
        currentRiskLevel: '',
        newRiskLevel: '',
        riskLivelId: '',
        turnover: [],
        turnoverEvaluation: {
            lookupType: '',
            lookupCode: '',
            value: '',
            score: 0,
            typeDescription: '',
            codeDescription: '',
            valueDescription: '',
            id: 0
        }
    },
    error: ''
};

const slice = createSlice({
    name: 'userCompliance',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setUserRiskLevel(state, action: PayloadAction<UserRiskLevel>) {
            state.userRiskLevel = action.payload;
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload;
        },
        reset: () => ({ ...initialState })
    }
});

export const { setLoading, setUserRiskLevel, setError, reset } = slice.actions;

export const getUserRiskLevel = (userId: number) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            dispatch(setError(''));
            const response = await request.get(`/api/console/compliance/user/risk-level/turnover/${userId}`);
            dispatch(setUserRiskLevel(response.data || {}));
        } catch (e) {
            dispatch(reset());
            dispatch(setError(getErrorMessageCode(e)));
        }
        finally { dispatch(setLoading(false)); }
    }
};

export default slice.reducer;