import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '@/utils/errors';
import { TurnoverLimit } from '../platformLimits/platformLimitsTypes';
import { CreateUpdateLimitPayload, UserGroupLimitsStore } from './userGroupLimitsTypes';


const initialState: UserGroupLimitsStore = {
    loading: false,
    limits: [],
    error: null,
};

const slice = createSlice({
    name: 'userGroupLimits',
    initialState,
    reducers: {
        setUserGroupLimits(state, action: PayloadAction<Array<TurnoverLimit>>) {
            state.limits = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        }
    }
});

export const { setLoading, setUserGroupLimits } = slice.actions;

export const getUserGroupLimits = (userGroupId: number, wlpId?: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/limits/users-group/${userGroupId}`, { params: { wlpId: wlpId } });
            dispatch(setUserGroupLimits([response.data] || []));
        } catch (e) {
            showErrorNotification(e);
        }
        finally { dispatch(setLoading(false)); }
    }
};

export const createUserGroupLimit = async (payload: CreateUpdateLimitPayload): Promise<TurnoverLimit> => {
    const response = await request.post(`/api/console/limits/create-update`, payload)
    const { data } = response;
    return data;
};


export default slice.reducer;