import { PaginateParams } from "@/types/pagination"

export interface SupportStore {
    [key: string]: {
        loading: boolean,
        list: Array<Chat>,
        error: string | null,
        count: number,
        pagination: PaginateParams
    }
}

export enum ChatsType {
    NEW = "NEW",
    ALL = "ALL",
    ACTIVE = "ACTIVE",
    SOLVED = "SOLVED",
    PENDING_ON_CLIENT = "PENDING_ON_CLIENT",
    COMPLIANCE_REQUEST = "COMPLIANCE_REQUEST",
}

export interface ChatsStatistics {
    activeChats: number;
    complianceRequests: number;
    solvedChats: number;
    newChats: number;
    allChats: number;
}

export interface Chat {
    id: number;
    userId: number;
    wlpId: string;
    status: ChatsType;
    flagged: boolean;
    takenBy: string;
    lastMessage: string;
    takenByName: string;
    clientName: string;
    clientId: string;
    email: string;
    updatedDate: string;
    lastMessageDate: string;
}

export interface Message {
    id: number;
    authorId: number;
    chatId: number;
    clientMessage: boolean;
    createdDate: string;
    deleted: boolean;
    deletedBy: number;
    editedBy: number;
    message: string;
    readByAdmin: boolean;
    readByClient: boolean;
    replyOn: number;
    updatedDate: string;
    attachments: Attachment[];
    status: 'SUSPENDED' | 'UNSUSPENDED' | 'COMPLIANCE_REQUEST' | null;
    authorData: {
        type: string;
        firstName: string;
        lastName: string;
        companyName: string;
        tag: string;
    }
}

export interface Attachment {
    chatId: number;
    chatMessageId: number;
    createdDate: string;
    id: number;
    key: string;
    mimetype: string;
    name: string;
    size: number;
}

  