import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import translations from './locales/Translations';

const languages = Object.keys(translations);
const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE ? process.env.REACT_APP_DEFAULT_LANGUAGE : 'en';


i18n.use(initReactI18next) // passes i18n down to react-i18next
	.use(XHR)
	.init({
		lng: defaultLanguage,
		compatibilityJSON: 'v3',
		defaultNS: 'translations',
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
		debug: false,
		react: {
			useSuspense: false,
		},

	} 
	);


languages.forEach(language => {
	// @ts-expect-error: Let's ignore a compile error like this unreachable code 
	i18n.addResourceBundle(language, 'translations', translations[language]);
});

export default i18n;
