import { SortDirectionEnum } from "@/types/pagination";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "@/store/store";
import request from "@/services/request";
import { showErrorNotification } from "@/utils/errors";
import { Store } from "../merchants/merchantsTypes";
import { Category, MerchantDetailsStore, SubTabsEnum } from "./merchantDetailsTypes";

export const paginationParamsInit = {
    filter: '',
    orderBy: 'createdDate',
    sort: SortDirectionEnum.DESC,
    take: 15,
    skip: 0
};

const initialState: MerchantDetailsStore = {
    detailsSubTab: SubTabsEnum.INFORMATION,
    storedMerchantId: null,
    details: {
        merchantDetails: null,
        loading: false
    },
    products: {
        merchantProducts: [],
        loading: false
    }
};

const slice = createSlice({
    name: 'merchantDetails',
    initialState,
    reducers: {
        reset: () => initialState,
        setDetailsSubTab(state, action: PayloadAction<SubTabsEnum>) {
            state.detailsSubTab = action.payload;
        },
        setStoredMerchantId: (state, action: PayloadAction<number>) => {
            state.storedMerchantId = action.payload
        },
        //  Details
        setMerchantDetails(state, action: PayloadAction<Store>) {
            state.details.merchantDetails = action.payload;
        },
        setLoadingDetails: (state, action: PayloadAction<boolean>) => {
            state.details.loading = action.payload;
        },
        // Products
        setMerchantProducts: (state, action: PayloadAction<Array<Category>>) => {
            state.products.merchantProducts = action.payload;
        },
        setLaodingProducts: (state, action: PayloadAction<boolean>) => {
            state.products.loading = action.payload;
        }
    }
});

export const { reset, setDetailsSubTab, setMerchantDetails, setLoadingDetails, setStoredMerchantId, setLaodingProducts, setMerchantProducts } = slice.actions;

export const getStoreDetails = (storeId: number) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingDetails(true));
            const response = await request.get(`/api/console/merchants/store/details/${storeId}`);
            dispatch(setMerchantDetails(response?.data));
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLoadingDetails(false));
        }
    };
};

export const getStoreProducts = (storeId: number) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLaodingProducts(true));
            const response = await request.get(`/api/console/merchants/products/en/${storeId}`);
            dispatch(setMerchantProducts(response?.data));
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLaodingProducts(false));
        }
    };
};

export default slice.reducer;