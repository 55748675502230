import { dispatch, useSelector } from '@/store/store';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { RowActionType } from '@/types/rowActions';
import ArrowRight1 from '@/icons/ArrowRight1';
import { useCustomNavigate } from '@/utils/customHooks/useCustomNavigate';
import { getPlatfromUserLevels, paginationParamsInit, setPlans } from '@/redux/userLevels/userLevelsSlice';
import HomeSolid from '@/icons/HomeSolid';
import PlansPriceCell from '@/components/dataGrid/renderCell/PlansPriceCell';
import CustomDataGrid, { CustomDataGridRef } from '@/components/dataGrid/CustomDataGrid';
import { checkPermission, checkWritePermission } from '@/utils/permissions';
import { PaginateParams } from '@/types/pagination';
import EditChangePencil from '@/icons/EditChangePencil';
import Plus from '@/icons/Plus';
import EditPlan from '@/pages/platformDetails/platformPlans/EditPlan';
import { UserLevel } from '@/redux/userLevels/userLevelsTypes';
import { Permission, PermissionType } from '@/redux/user/types';

const getUserPlanUrl = (permissions: Array<Permission>): string => {
    if (checkPermission(permissions, PermissionType.USER_LEVELS_FEES)) {
        return '/user-plan/fees';
    }
    return '/not-permitted';
};

const getColumn = (
    actionItems: Array<RowActionType<UserLevel>>,
    t: any
) => {
    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('form.fields.name'),
            flex: 0.5,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Grid container item columnGap={0.6}>
                        {params.row.defaultLevel && <HomeSolid sx={{ fontSize: '19px' }} />}
                        <Typography>
                            {params.value}
                        </Typography>
                    </Grid>
                );
            }
        },
        {
            field: 'description',
            headerName: t('form.fields.description'),
            flex: 1.3,
            sortable: false
        },
        {
            field: 'numberOfUsers',
            headerName: t('form.fields.size'),
            flex: 0.5,
        },
        {
            field: 'price',
            headerName: t('form.fields.price'),
            flex: 1,
            sortable: false,
            renderCell: PlansPriceCell
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: t('form.fields.actions'),
            headerAlign: 'center',
            align: 'center',
            flex: 0.5,
            sortable: false,
            getActions: (params: GridRowParams) => actionItems.map(item => {
                return (
                    <GridActionsCellItem
                        icon={item.icon}
                        onClick={() => { item.onClick(params.row) }}
                        label=""
                    />
                )
            })
        }
    ];
    return columns;
}

const WlpPlans = () => {
    const ref = useRef<CustomDataGridRef>(null);
    const customNavigate = useCustomNavigate();

    const { list, error, count, loading, pagination } = useSelector((state) => state.userLevels);

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<UserLevel | null>(null);

    const { permissions } = useSelector((state) => state.user);
    const writePermission = checkWritePermission(permissions, PermissionType.USER_LEVEL);

    const fetch = async (searchParams: PaginateParams) => {
        await dispatch(getPlatfromUserLevels(searchParams))
    };

    useEffect(() => {
        return () => {
            dispatch(setPlans([]));
        };
    }, []);

    const columns = useMemo(() => {
        const actionItems = [
            {
                key: 'edit',
                icon: <EditChangePencil />,
                iconOpposite: <EditChangePencil color="#82918E66" />,
                onClick: (row: UserLevel) => {
                    setSelectedRow(row);
                    setOpen(true);
                }
            },
            {
                key: "menu",
                icon: <ArrowRight1 viewBox="0 0 16 16" />,
                onClick: (row: UserLevel) => {
                    customNavigate(
                        {
                            title: row.name,
                            path: getUserPlanUrl(permissions),
                            state: { userLevelId: row.userLevelId, planName: row.name, defaultLevel: row.defaultLevel }
                        }
                    );
                },
            },
        ];
        return getColumn(actionItems, t)
    }, [customNavigate, t, permissions]);

    const createLevelBtn = (
        <Button variant="outlined" color='secondary' onClick={() => setOpen(true)} disabled={!writePermission}>
            <Stack direction='row' columnGap={1}>
                <Plus sx={{
                    fontSize: '0.813rem',
                    margin: 'auto',
                    color: 'inherit',
                    'path': { strokeWidth: 3 }
                }} />
                {t('form.buttons.createNewPlan')}
            </Stack>
        </Button>
    );

    const reloadData = () => {
        ref?.current?.reloadData && ref?.current?.reloadData();
    };

    return (
        <>
            {(selectedRow || open) &&
                <EditPlan
                    open={open}
                    userLevel={selectedRow}
                    onClose={() => {
                        setOpen(false);
                        setSelectedRow(null);
                    }}
                    onComplete={reloadData}
                />}
            <CustomDataGrid
                ref={ref}
                rows={list}
                getRowId={(row) => row.userLevelId}
                rowCount={count}
                fetch={fetch}
                rowHeight={80}
                loading={loading}
                paginationParams={pagination}
                paginationParamsInit={paginationParamsInit}
                columns={columns}
                loadingError={error}
                withSearch={false}
                customButton={createLevelBtn}
                withRefreshBtn
            />
        </>
    );
};

export default WlpPlans;