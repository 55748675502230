import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useSelector } from '@/store/store';
import { appBarHeight, appCollapsedDrawerWidth, drawerWidth, appMobileDrawerWidth, mainPaddingLeft, mainPaddingRight } from '@/utils/constant';
import { useTranslation } from 'react-i18next';
import GoBackTitle from '@/components/titles/GoBackTitle';
import { useCustomNavigate } from '@/utils/customHooks/useCustomNavigate';

const Header = () => {
    const theme = useTheme();
    const customNavigate = useCustomNavigate();
    const { t } = useTranslation();

    const { drawerOpen, openItem, subPaths } = useSelector((state) => state.menu);
    const { userFirstName, userLastName, userId, userClientId } = useSelector((state) => state.credentials);

    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const sideBarW = drawerOpen ? drawerWidth : (matchDownSm ? appMobileDrawerWidth : appCollapsedDrawerWidth);

    return (
        <Grid container alignItems='flex-end'>
            <Box
                sx={{
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.shorter,
                    }),
                    width: sideBarW,
                    minHeight: `${appBarHeight}px`,
                    [theme.breakpoints.down('md')]: {
                        alignItems: 'flex-start',
                        bgcolor: 'transparent'
                    }
                }}
            />
            <Box sx={{
                bgcolor: theme.palette.primary.dark,
                flexGrow: 1,
                alignSelf: "center",
                [theme.breakpoints.up('md')]: {
                    paddingLeft: `${mainPaddingLeft}px`,
                    paddingRight: `${mainPaddingRight - 15}px`,
                },
                [theme.breakpoints.down('md')]: {
                    paddingLeft: `${mainPaddingLeft - 20}px`,
                    paddingRight: `${mainPaddingRight - 20}px`,
                },
                [theme.breakpoints.down('sm')]: {
                    paddingLeft: `${mainPaddingLeft - 50}px`,
                    paddingRight: `${mainPaddingRight - 50}px`,
                },
            }}>
                <Grid container item justifyContent='space-between' alignItems="center" wrap="nowrap" >
                    <Grid container item alignItems="center">
                        {(subPaths && subPaths.length > 1) &&
                            <>
                                <GoBackTitle />
                                <Typography
                                    fontSize='20px'
                                    sx={{
                                        px: 1,
                                        textDecoration: 'none',
                                        color: '#82918E',
                                        fontWeight: 500,

                                    }}>/</Typography>
                            </>
                        }
                        <Grid item>
                            <Typography fontSize='20px' sx={{ fontWeight: 500 }} color={theme.palette.primary.light}>
                                {subPaths && subPaths.length > 1 ? subPaths[subPaths.length - 1].title : t('pages.' + openItem)}
                            </Typography>
                        </Grid>

                    </Grid>
                    <Grid item>
                        <Avatar
                            onClick={() => customNavigate({
                                path: `/users/details/${userId}/profile`,
                                title: (!userFirstName && !userLastName) ? (userClientId ?? '') : `${userFirstName ?? ''} ${userLastName ?? ''}`,
                            })}
                            sx={{
                                width: "1.8rem",
                                height: "1.8rem",
                                fontSize: "0.725rem",
                                backgroundColor: '#F2F3F5',
                                color: theme.palette.text.primary,
                                borderRadius: '50%',
                                cursor: 'pointer'
                            }}>
                            {userFirstName?.charAt(0).toUpperCase()}
                            {userLastName?.charAt(0).toUpperCase()}
                        </Avatar>
                    </Grid>
                </Grid>


            </Box >
        </Grid >
    );
};

export default Header;
