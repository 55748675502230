import { defaultPagination } from '@/types/pagination';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ConfigurationAndFeesStore, ConfigurationAndFeesTab } from './configurationAndFeesTypes';
import { FeeCategoryCodeType } from '../platforms/platformsTypes';

export const paginationParamsInit = defaultPagination;

const initialState: ConfigurationAndFeesStore = {
    [ConfigurationAndFeesTab.FEES]: {
        openedSubTab: FeeCategoryCodeType.COMPLIANCE,
    },
};

const slice = createSlice({
    name: 'configurationAndFees',
    initialState,
    reducers: {
        setSubTab(state, action: PayloadAction<{ tab: ConfigurationAndFeesTab; subTab: FeeCategoryCodeType }>) {
            state[action.payload.tab].openedSubTab = action.payload.subTab;
        }
    },
});

export const { setSubTab } = slice.actions;

export default slice.reducer;