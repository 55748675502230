import { UserType } from "@/redux/user/types";
import { UserLevel, UserLevelPrice } from "@/redux/userLevels/userLevelsTypes";
import { Button, Checkbox, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import DialogTitle from '@/components/DialogTitle';
import { showErrorNotification, showSuccessNotification } from "@/utils/errors";
import FormTextField from "@/components/form/FormTextField";
import FormNumericField from "@/components/form/FormNumericField";
import { createPlatformPlan, editPlatformPlan } from "@/redux/userLevels/userLevelsSlice";

interface Props {
    wlpId?: string
    open: boolean,
    userLevel: UserLevel | null;
    onComplete: () => void
    onClose: () => void
};

interface InitialValues {
    name: string;
    description: string;
    individualPrice: number;
    businessPrice: number;
    createForIndividual: boolean;
    createForBusiness: boolean;
};

const EditPlan = ({ wlpId, open, userLevel, onComplete, onClose }: Props) => {
    const { t } = useTranslation();

    const _defaultPrice = userLevel?.prices.find((price: UserLevelPrice) =>
        price.userId === null &&
        price.countryCode === null &&
        price.riskGroupCode === null &&
        price.userProfileType === null &&
        !price.disabled
    );

    const _individualPrice = userLevel?.prices.find((price: UserLevelPrice) =>
        price.userId === null &&
        price.countryCode === null &&
        price.riskGroupCode === null &&
        price.userProfileType === UserType.INDIVIDUAL &&
        !price.disabled
    );

    const _businessPrice = userLevel?.prices.find((price: UserLevelPrice) =>
        price.userId === null &&
        price.countryCode === null &&
        price.riskGroupCode === null &&
        price.userProfileType === UserType.BUSINESS &&
        !price.disabled
    );

    const validationSchema = Yup.object({
        name: Yup.string().required(t("form.validator.required").toString()),
        description: Yup.string().nullable().required(t("form.validator.required").toString()),
        individualPrice: Yup.number()
            .typeError(t('form.validator.required').toString())
            .required(t('form.validator.required').toString())
            .min(0, t('form.validator.nonNegative').toString()),
        businessPrice: Yup.number()
            .typeError(t('form.validator.required').toString())
            .required(t('form.validator.required').toString())
            .min(0, t('form.validator.nonNegative').toString()),
    });

    const initialValues: InitialValues = {
        name: userLevel?.name || '',
        description: userLevel?.description || '',
        individualPrice: _individualPrice?.price || _defaultPrice?.price || 0,
        businessPrice: _businessPrice?.price || _defaultPrice?.price || 0,
        createForIndividual: false,
        createForBusiness: false
    };

    const submit = async (formData: InitialValues) => {
        try {
            const { name, description, individualPrice, businessPrice, createForBusiness, createForIndividual } = formData;
            if (userLevel) {
                const payload = {
                    name,
                    description,
                    priority: userLevel.priority,
                    defaultLevel: userLevel.defaultLevel,
                    ...(wlpId ? { wlpId: wlpId } : {}),
                    prices: [
                        ...(individualPrice !== initialValues.individualPrice ? [{
                            userProfileType: UserType.INDIVIDUAL,
                            price: individualPrice
                        }] : []),
                        ...(businessPrice !== initialValues.businessPrice ? [{
                            userProfileType: UserType.BUSINESS,
                            price: businessPrice
                        }] : [])
                    ]
                };
                await editPlatformPlan(userLevel.userLevelId, payload);
                showSuccessNotification(t('form.fields.planUpdated'));
            } else {
                const payload = {
                    name,
                    description,
                    defaultLevel: false,
                    ...(wlpId ? { wlpId: wlpId } : {}),
                    prices: [
                        ...(createForIndividual ? [{
                            userProfileType: UserType.INDIVIDUAL,
                            price: individualPrice
                        }] : []),
                        ...(createForBusiness ? [{
                            userProfileType: UserType.BUSINESS,
                            price: businessPrice
                        }] : [])
                    ]
                };
                await createPlatformPlan(payload);
                showSuccessNotification(t('form.fields.planCreated'));
            }
        }
        catch (e) {
            showErrorNotification(e);
        }
        finally {
            onComplete();
            onClose();
        }
    };

    return (
        <Dialog
            id="create-group-form"
            open={open}
            onClose={onClose}
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: '500px',
                    maxHeight: '600px',
                    maxWidth: '550px'
                }
            }}>
            <DialogTitle onClose={onClose}>
                {userLevel ? t('form.fields.editUserPlan') : t('form.buttons.createNewPlan')}
            </DialogTitle>

            <DialogContent>
                <Grid container direction='column' wrap='nowrap'>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={submit}>
                        {({ ...formik }) => {
                            return (
                                <Form>
                                    <Grid container item>
                                        <Grid item xs={12}>
                                            <FormTextField
                                                name='name'
                                                fullWidth
                                                label={t('form.fields.name')} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormTextField
                                                name='description'
                                                fullWidth
                                                label={t('form.fields.description')}
                                                multiline
                                                rows={4}
                                                inputProps={{
                                                    maxLength: 200
                                                }} />
                                        </Grid>
                                        {!userLevel?.defaultLevel &&
                                            <>
                                                <Grid item xs={12}>
                                                    <FormNumericField
                                                        name='individualPrice'
                                                        value={formik.values.individualPrice}
                                                        decimalScale={2}
                                                        fullWidth
                                                        disabled={!userLevel && !formik.values.createForIndividual}
                                                        label={t('form.fields.individualPrice')}
                                                        adornmentEnd={'EUR'}
                                                        errors={formik?.errors?.individualPrice} />
                                                    {!userLevel &&
                                                        <Grid container item mt={1}>
                                                            <Checkbox
                                                                sx={{ p: 0 }}
                                                                checked={formik.values.createForIndividual}
                                                                onChange={(event) => {
                                                                    formik.setFieldValue("createForIndividual", event.target.checked, true);
                                                                }}
                                                            />
                                                            <Typography variant="body2" pl={1}>{t('form.fields.createForIndividual')}</Typography>
                                                        </Grid>}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormNumericField
                                                        name='businessPrice'
                                                        value={formik.values.businessPrice}
                                                        decimalScale={2}
                                                        fullWidth
                                                        disabled={!userLevel && !formik.values.createForBusiness}
                                                        label={t('form.fields.businessPrice')}
                                                        adornmentEnd={'EUR'}
                                                        errors={formik?.errors?.businessPrice} />
                                                    {!userLevel &&
                                                        <Grid container item mt={1}>
                                                            <Checkbox
                                                                sx={{ p: 0 }}
                                                                checked={formik.values.createForBusiness}
                                                                onChange={(event) => {
                                                                    formik.setFieldValue("createForBusiness", event.target.checked, true);
                                                                }}
                                                            />
                                                            <Typography variant="body2" pl={1}>{t('form.fields.createForBusiness')}</Typography>
                                                        </Grid>}
                                                </Grid>
                                            </>}
                                    </Grid>
                                    <Grid container item justifyContent='space-between' mt='auto' pt={3}>
                                        <Button onClick={onClose} variant='outlined' color='secondary' disabled={formik.isSubmitting}>{t('form.buttons.cancel')}</Button>
                                        <Button type='submit'>{userLevel ? t('form.buttons.edit') : t('form.buttons.create')}</Button>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </Grid>
            </DialogContent>
        </Dialog>
    );

};

export default EditPlan;