import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { CardTopupTransactionsStore, CardTransaction } from './shCardTopupTransactionsTypes';


export const paginationParamsInit: PaginateParams = {
	filter: '',
	orderBy: 'date',
	sort: SortDirectionEnum.DESC,
	take: 25,
	skip: 0,
}

const initialState: CardTopupTransactionsStore = {
	loading: false,
	list: [],
	count: 0,
	error: null,
	pagination: paginationParamsInit,
	initial: true,
	storedUserId: null
};

const slice = createSlice({
	name: 'cardsTopupTransactions',
	initialState,
	reducers: {
		reset: () => initialState,
		setCardTransactions(state, action: PayloadAction<Array<CardTransaction>>) {
			state.list = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload
		},
		setError: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
		},
		setCount: (state, action: PayloadAction<number>) => {
			state.count = action.payload;
		},
		setPagination: (state, action: PayloadAction<PaginateParams>) => {
			state.pagination = action.payload;
		},
		setInitial: (state, action: PayloadAction<boolean>) => {
			state.initial = action.payload;
		},
		setStoredUserId: (state, action: PayloadAction<number>) => {
			state.storedUserId = action.payload;
		},
	}
});

export const {
	setCardTransactions,
	setLoading,
	setError,
	setCount,
	setPagination,
	setInitial,
	setStoredUserId,
	reset
} = slice.actions;

export const getCardTopUpTransactions = (userId: number, initial: boolean, payload: PaginateParams) => {

	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get(`/api/console/cards/sh/transactions/topup/${userId}`, {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take,
					initial: initial
				}
			});
			const { data } = response;
			dispatch(setCardTransactions(data.list));
			dispatch(setCount(data.count));
			dispatch(setPagination(payload));
			dispatch(setInitial(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export default slice.reducer;