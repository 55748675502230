import request from '../../services/request';
import { CryptoCurrency, CurrenciesStore } from './currenciesTypes';
import { AppDispatch } from '@/store/store';
import { showErrorNotification } from '@/utils/errors';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FilterOptionType } from '@/components/dataGrid/CustomFilterHeader';
import { Currency } from '../emiFinance/emiFinanceTypes';
import { fetchCurrencies } from '../fnd/fndSlice';

const currenciesKey = 'currencies';
const currenciesDecimalsKey = 'currencies_decimals';

const savedCurrencies = sessionStorage.getItem(currenciesKey);
const savedDecimals = sessionStorage.getItem(currenciesDecimalsKey);

const initialState: CurrenciesStore = {
	currencies: {
		crypto: [],
		fiat: [],
		token: [],
	},
	currenciesDecimals: []
};

if (savedCurrencies) {
	try {
		const currenciesParsed = JSON.parse(savedCurrencies);
		initialState.currencies = currenciesParsed.currencies;
	}
	catch (e) {
		console.log(e);
	}
};

if (savedDecimals) {
	try {
		const decimalsParsed = JSON.parse(savedDecimals);
		initialState.currenciesDecimals = decimalsParsed.currenciesDecimals;
	}
	catch (e) {
		console.log(e);
	}
};

const slice = createSlice({
	name: 'currencies',
	initialState,
	reducers: {
		setCurrencies: (state, action: PayloadAction<{ cryptoCurrencies: Array<CryptoCurrency>, fiatCurrencies: Array<FilterOptionType>, tokenCurrencies: Array<FilterOptionType> }>) => {
			const crypto = action.payload.cryptoCurrencies.map(c => ({
				value: c.symbol,
				text: `${c.name} (${c.symbol})`,
				enabled: true
			}));
			state.currencies.crypto = crypto;
			state.currencies.fiat = action.payload.fiatCurrencies;
			state.currencies.token = action.payload.tokenCurrencies;
			sessionStorage.setItem(currenciesKey, JSON.stringify(
				{
					currencies: {
						crypto: crypto,
						fiat: action.payload.fiatCurrencies,
						token: action.payload.tokenCurrencies,
					}
				}
			));
		},
		setDecimals: (state, action: PayloadAction<Array<Currency>>) => {
			state.currenciesDecimals = action.payload
			sessionStorage.setItem(currenciesDecimalsKey, JSON.stringify({ currenciesDecimals: action.payload }
			));
		},
		clearCurrencies() {
			sessionStorage.removeItem(currenciesKey);
			sessionStorage.removeItem(currenciesDecimalsKey);
		},
	}
});

export const { setCurrencies, setDecimals, clearCurrencies } = slice.actions;

export const getIsCrypto = (currency: string) => {
	return initialState.currencies.crypto.map(ccy => ccy.value).includes(currency);
}

export const getCurrencies = () => {
	return async (dispatch: AppDispatch) => {
		try {
			const [cryptoCurrencies, fiatCurrencies, tokenCurrencies] = await Promise.all([
				getCryptoCurrencies(),
				fetchCurrencies('CONSOLE_FIAT_FILTER'),
				getTokenCurrencies()
			]);
			dispatch(setCurrencies({ cryptoCurrencies, fiatCurrencies, tokenCurrencies }));
		} catch (e) {
			showErrorNotification(e);
		}
	};
};

export const getCurrenciesDecimals = () => {
	return async (dispatch: AppDispatch) => {
		try {
			const response = await request.get(`/api/console/currencies/list`);
			dispatch(setDecimals(response?.data))
		} catch (e) {
			showErrorNotification(e);
		}
	};
};

export const getTokenCurrencies = async (): Promise<FilterOptionType[]> => {
	const response = await request.get(`/api/console/tokens/currencies`);
	const { data } = response;

	return data.map((c: { ccy: any; name: any; }) => ({
		value: c.ccy,
		text: c.name,
		enabled: true
	}));;
};

export const getCryptoCurrencies = async (): Promise<Array<CryptoCurrency>> => {
	const response = await request.get(`/api/console/currencies/crypto`);
	return response?.data;
};

export default slice.reducer;