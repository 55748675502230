import { memo } from 'react';
import { Box, } from '@mui/material';
import CustomDrawer from './components/CustomDrawer';

const Sidebar = () => {
    return (
        <Box
            id='side-bar'
            sx={{
                display: 'flex',
                height: '100%',
                '& .MuiDrawer-paper': {
                    overflowY: 'auto',
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    }
                },
            }}
            aria-label="navigation"
        >
            <CustomDrawer />
        </Box>
    );
};

export default memo(Sidebar);
