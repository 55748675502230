import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import request from '../../services/request';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '@/store/store';
import { showErrorNotification } from '@/utils/errors';
import { RisksComplianceStore, UserRiskDetails } from './risksComplianceTypes';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { getWlpName } from '@/components/dataGrid/utils/Platform';
import { Wlp } from '../user/types';


export const paginationParamsInit = {
    filter: '',
    orderBy: 'userId',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: RisksComplianceStore = {
    assignedToMe: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit,
        filters: {
            [HeaderFilterType.WLPS]: [],
        },
        filtersSet: false
    },
    notAssignedToMe: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit,
        filters: {
            [HeaderFilterType.WLPS]: [],
        },
        filtersSet: false
    }
};

const slice = createSlice({
    name: 'compliance',
    initialState,
    reducers: {
        setRisksComplianceList(state, action: PayloadAction<{ list: Array<UserRiskDetails>, assignedToMe: boolean }>) {
            if (action.payload.assignedToMe) state.assignedToMe.list = action.payload.list;
            else state.notAssignedToMe.list = action.payload.list;
        },
        setLoading: (state, action: PayloadAction<{ loading: boolean, assignedToMe: boolean }>) => {
            if (action.payload.assignedToMe) state.assignedToMe.loading = action.payload.loading;
            else state.notAssignedToMe.loading = action.payload.loading;
        },
        setError: (state, action: PayloadAction<{ error: string, assignedToMe: boolean }>) => {
            if (action.payload.assignedToMe) state.assignedToMe.error = action.payload.error;
            else state.notAssignedToMe.error = action.payload.error;
        },
        setCount: (state, action: PayloadAction<{ count: number, assignedToMe: boolean }>) => {
            if (action.payload.assignedToMe) state.assignedToMe.count = action.payload.count;
            else state.notAssignedToMe.count = action.payload.count;
        },
        setPagination: (state, action: PayloadAction<{ pagination: PaginateParams, assignedToMe: boolean }>) => {
            if (action.payload.assignedToMe) state.assignedToMe.pagination = action.payload.pagination;
            else state.notAssignedToMe.pagination = action.payload.pagination;
        },
        setFilters: (state, action: PayloadAction<{ wlps: Array<Wlp>, assignedToMe?: boolean }>) => {
            const wlpsOptions = action.payload.wlps.map(item => ({
                value: item.wlpId,
                text: getWlpName(item.wlpId),
                enabled: true
            }));
            if (action.payload.assignedToMe) {
                state.assignedToMe.filters[HeaderFilterType.WLPS] = wlpsOptions;
                state.assignedToMe.filtersSet = true;
            }
            else {
                state.notAssignedToMe.filters[HeaderFilterType.WLPS] = wlpsOptions;
                state.notAssignedToMe.filtersSet = true;
            }
        },
        setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, assignedToMe?: boolean, options: Array<FilterOptionType> }>) => {
            if (action.payload.assignedToMe) state.assignedToMe.filters[action.payload.filterType] = action.payload.options;
            else state.notAssignedToMe.filters[action.payload.filterType] = action.payload.options;
        },
        setFiltersSet(state, action: PayloadAction<{ assignedToMe?: boolean, set: boolean }>) {
            if (action.payload.assignedToMe) {
                state.assignedToMe.filtersSet = action.payload.set;
            }
            else {
                state.assignedToMe.filtersSet = action.payload.set;
            }
        }
    }
});
export default slice.reducer;

export const { setRisksComplianceList, setLoading, setError, setCount, setPagination, setFilters, setFilter, setFiltersSet } = slice.actions;

export const getPendingRisksCompliance = (payload: PaginateParams, assignedToMe: boolean = false) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ loading: true, assignedToMe }));
            const response = await request.get(`/api/console/compliance/checks/risks/list/${assignedToMe}`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setRisksComplianceList({ list: data.list, assignedToMe }));
            dispatch(setCount({ count: data.count, assignedToMe }));
            dispatch(setPagination({ pagination: payload, assignedToMe }));
            dispatch(setLoading({ loading: false, assignedToMe }));
            return data;
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading({ loading: false, assignedToMe }));
        }
    };
};

