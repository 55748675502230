import { toast } from 'react-toastify';
import i18n from '../i18n';

const t = (value: string, params?: any) => {
    return params ? i18n.t(`translations:${value}`, params).toString() : i18n.t(`translations:${value}`).toString();
};

export const getErrorCode = (error: any) => {
    const { data } = error;
    if (data) {
        const { errors } = data;
        if (errors && errors.length > 0) {
            return errors[0].error;
        }
    }
    return null;
};

export const showSuccessNotification = (content: string) => {
    toast.success(content);
};

export const showErrorNotification = (err: any, content = 'errors.somethingWentWrong') => {
    if (err?.status === 417) {
        const errorCode: string = getErrorCode(err);
        const errorParam: string = err.data ? err.data.error_param : null;

        if ((errorCode === 'required' && errorParam === 'authenticatorCode') ||
            (err.data && err.data.message === 'wrong two factor code')) {
            return;
        } else if (errorCode === 'notFound') {
            content = 'errors.notFoundApproval';
        } else if (errorCode === "email_or_password_invalid") {
            content = 'errors.incorrectEmailPassword';
        } else if (errorCode === "disabled") {
            content = 'errors.userDisabled';
        } else if (errorCode === "notApproved") {
            content = 'errors.userNotApproved';
        } else if (errorCode === "USER_NEW_PROCESS_NOT_AVAILABLE") {
            content = 'errors.userChangePlanNotAvailable';
        }
    };

    toast.error(t(content));
};

export const showWarningNotification = (content: string) => {
    toast.warn(t(content));
};

export const showInfoNotification = (content: string) => {
    toast.info(t(content));
};

export const dismissAllNotifications = () => {
    toast.dismiss();
};

export const getErrorWithParams = (_error: any) => {
    if (!_error?.data) return null;
    const data = _error.data;
    if (data) {
        const { error, errors, error_param } = data;
        if (error && error_param) {
            return { error, error_param };
        } else if (error && !error_param) {
            return error;
        }
        if (errors) {
            const validationInfo = errors[0];
            const { error, error_param } = validationInfo;
            return { error, error_param };
        }
    }
    return null;
};

export const getErrorWithParamValues = (_error: any) => {
    if (!_error?.data) return null;
    const data = _error.data;
    if (data) {
        const { error, errors, error_param, param_value } = data;
        if (error && error_param) {
            return { error, error_param, param_value };
        } else if (error && !error_param) {
            return error;
        }
        if (errors) {
            const validationInfo = errors[0];
            const { error, error_param, param_value } = validationInfo;
            return { error, error_param, param_value };
        }
    }
    return null;
};

export const handleUserCreationErrors = (e: any) => {
    const err = getErrorWithParams(e);
    if (e && err.error === 'duplicate' && err.error_param === 'email') {
        showErrorNotification(err, 'errors.userEmailExits');
    } else if (e && err.error === 'already_exists' && err.error_param === 'role') {
        showErrorNotification(err, 'errors.ownerRoleExits');
    } else if (e && err.error === 'duplicate_admin_approval' && err.error_param === 'email') {
        showErrorNotification(err, 'errors.duplicateAdminApproval');
    }
    else {
        showErrorNotification(e);
    }
}

export const getErrorMessage = (e: any) => {
    const err = getErrorWithParams(e);

    if (err.error.message) {
        const errorMessage = JSON.parse(err.error.message.split(" - ")[1]).errorMessage;
        return errorMessage
    }
    else return null
};

export const getErrorMessageCode = (e: any) => {
    const err = getErrorWithParams(e);

    if (err.error) {
        const errorCode = err.error.error.errorCode;
        return errorCode
    }
    else return null
};