import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { EmiFinanceTransfersStore, TransferDetails } from './emiFinanceTransfersTypes';

export const sysTransfersPaginationParamsInit: PaginateParams = {
    filter: '',
    orderBy: 'transferDate',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: EmiFinanceTransfersStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    pagination: sysTransfersPaginationParamsInit
};

const slice = createSlice({
    name: 'emiFinanceTransfers',
    initialState,
    reducers: {
        setTransfers(state, action: PayloadAction<Array<TransferDetails>>) {
            state.list = action.payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
            state.pagination = payload;
        },
    }
});

export const {
    setTransfers,
    setLoading,
    setError,
    setCount,
    setPagination } = slice.actions;


export const getEmiFinanceSystemTransfers = (payload: PaginateParams, transferCategory?: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get('/api/console/emi-finance/system-transfers/list', {
                params: {
                    filter: payload.filter + (transferCategory ? `&transferCategory=${transferCategory}` : ''),
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setTransfers(data.emiTransferDTO));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            dispatch(setLoading(false));
            return data;
        } catch (e) {
            dispatch(setTransfers([]));
            showErrorNotification(e);
            dispatch(setLoading(false));
        }
    };
};

export default slice.reducer;
