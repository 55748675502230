import { lazy } from 'react'; 
import Loadable from 'components/Loadable';
import AuthGuard from '@/utils/routeGuard/AuthGuard';
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from '@/pages/maintenance/ErrorFallback';
import OnboardingLayout from '@/layout/OnboardingLayout';

const TwoFaSetup = Loadable(lazy(() => import('pages/twoFaSetup/TwoFaSetup')));

const OnboardingRoutes = {
    path: '/',
    element: ( 
        <AuthGuard>
            <ErrorBoundary
                fallback={<ErrorFallback error />}   >
                <OnboardingLayout />
            </ErrorBoundary>
        </AuthGuard>
    ),
    children: [
        {
            path: '/onboarding',
            element: <TwoFaSetup />
        },
    ]
};

export default OnboardingRoutes;
