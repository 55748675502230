import { get, groupBy, head, map } from "lodash";
import { t } from "i18next";
import { Country, FeeEnum } from "@/redux/platformFees/platformFeesTypes";
import { WlpFee } from "@/redux/wlpFees/wlpFeesTypes";

export const getMargin = (fee: WlpFee) => {
    if (!fee) return null;

    let clientFee = 0;
    let emiFee = 0;

    switch (fee.feeType) {
        case FeeEnum.ONE_TIME_FEE:
        case FeeEnum.MANUAL_FEE:
            clientFee = fee.currentFeeAmount;
            emiFee = fee.currentEmiFeeAmount;
            return clientFee - emiFee;
        case FeeEnum.ONE_TIME_PLUS_MONTHLY_FEE:
            clientFee = fee.currentFeeMonth;
            emiFee = fee.currentEmiFeeMonth;
            return clientFee - emiFee;
        case FeeEnum.VOLUME_FEE:
            clientFee = fee.currentMinFee;
            emiFee = fee.currentEmiMinFee;
            return clientFee - emiFee;
        default:
            break;
    }
    return null;
};

export const getRiskGroupCodeColor = (riskGroupCode: string, theme: any) => {
    switch (riskGroupCode) {
        case "HIGH":
            return theme.palette.error.main;
        case "MEDIUM":
            return theme.palette.orange.main;
        case "LOW":
            return theme.palette.success.main;
        default:
            return theme.palette.primary.main;
    }
};

export const formatPercentFee = (feePercent: number, minFee: number, ccy: string) => {
    const _feePercent = feePercent ?? 0;
    const _minFee = minFee ?? 0;

    if (_feePercent === 0 && _minFee === 0) {
        return '%'
    }
    return `% ${_minFee > 0 ? `(${t('fees.min')}. ${_minFee} ${ccy})` : ''}`;
}

export const getRegions = (countries: Array<Country>) => {
    const grouped = groupBy(countries, 'region');

    return map(grouped, (countries, region) => ({
        region: region,
        regionName: get(head(countries), 'regionName') || '',
        countries: countries
    }));
};

export const getSuffix = (feeType: FeeEnum, ccy: string, feePercent: number, minFee: number) => {
    let suffix = '';
    switch (feeType) {
        case FeeEnum.ONE_TIME_PLUS_MONTHLY_FEE:
        case FeeEnum.ONE_TIME_FEE:
            suffix = ccy;
            break;
        case FeeEnum.VOLUME_FEE:
            if (feePercent > 0) {
                suffix = formatPercentFee(feePercent, minFee, ccy);
            }
            else {
                suffix = ccy;
            }
            break;
        default:
            suffix = formatPercentFee(feePercent, minFee, ccy);
            break;
    }
    return `${suffix}`;
};