export interface MaintenanceStore {
	loading: boolean,
	list: Maintenance[],
	isFullyDown: boolean,
	isPartiallyDown: boolean,
	isPlanned: boolean,
	error: string | null,
	isCryptoDown: boolean,
	isTokenDown: boolean,
};

export interface Maintenance {
	id: number,
	startDate: Date,
	endDate: Date,
	status: MaintenanceStatus,
	integration?: string,
	processes?: string[],
	description: string,
	message: string
}

export enum MaintenanceStatus {
	ACTIVE = 'ACTIVE',
	COMPLETED = 'COMPLETED',
	UPCOMING = 'UPCOMING'
};
