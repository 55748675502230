import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import request from '../../services/request';
import { AdminUser, CreateAdminPayload, DisableAdminPayload, EditAdminPayload } from './adminTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminsStore } from './adminTypes';
import { AppDispatch } from '@/store/store';
import { showErrorNotification } from '@/utils/errors';
import { Role } from '../roles/rolesTypes';
import { AdminType } from '../user/types';

export const paginationParamsInit = {
    filter: '',
    orderBy: 'createdDate',
    sort: SortDirectionEnum.DESC,
    take: 15,
    skip: 0
};

const initialState: AdminsStore = {
    openedSubTab: AdminType.EMI_ADMIN,
    [AdminType.EMI_ADMIN]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit,
    },
    [AdminType.WLP_ADMIN]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit,
    }
};

const slice = createSlice({
    name: 'admins',
    initialState,
    reducers: {
        setAdmins(state, action: PayloadAction<{ adminType: AdminType, admins: Array<AdminUser> }>) {
            state[action.payload.adminType].list = action.payload.admins;
        },
        setLoading: (state, action: PayloadAction<{ adminType: AdminType, loading: boolean }>) => {
            state[action.payload.adminType].loading = action.payload.loading;
        },
        setError: (state, action: PayloadAction<{ adminType: AdminType, error: string }>) => {
            state[action.payload.adminType].error = action.payload.error;
        },
        setCount: (state, action: PayloadAction<{ adminType: AdminType, count: number }>) => {
            state[action.payload.adminType].count = action.payload.count;
        },
        setPagination: (state, action: PayloadAction<{ adminType: AdminType, pagination: PaginateParams }>) => {
            state[action.payload.adminType].pagination = action.payload.pagination;
        },
        setSubTab(state, action: PayloadAction<AdminType>) {
            state.openedSubTab = action.payload;
        }
    }
});
export default slice.reducer;

export const { setAdmins, setLoading, setError, setCount, setPagination, setSubTab } = slice.actions;

export const getAdmins = (adminType: AdminType, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ adminType, loading: true }));
            const response = await request.get(`/api/console/system-admins/list/${adminType}`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setAdmins({ adminType, admins: data.list }));
            dispatch(setCount({ adminType, count: data.count }));
            dispatch(setPagination({ adminType, pagination: payload }));
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLoading({ adminType, loading: false }));
        }
    };
};



export const getAdminRoles = async (): Promise<Array<Role>> => {
    const response = await request.get(`/api/console/system-admins/roles`);
    const { data } = response;
    return data;
};

export const addAdmin = async (payload: CreateAdminPayload) => {
    const response = await request.post(`/api/console/system-admins/create/${payload.type}`, payload);
    const { data } = response;
    return data;
};

export const editAdmin = async (payload: EditAdminPayload) => {
    const response = await request.post(`/api/console/system-admins/edit/${payload.type}`, payload);
    const { data } = response;
    return data;
};

export const disableEnableAdmin = async (payload: DisableAdminPayload) => {
    const response = await request.post(`/api/console/system-admins/user/disable`, payload);
    const { data } = response;
    return data;
};

export const rejectUserCreation = async (id: number) => {
    const response = await request.post('/api/console/system-admins/user/reject', { id: id });
    const { data } = response;
    return data;
};

export const approveUserCreation = async (id: number) => {
    const response = await request.post('/api/console/system-admins/user/approve', { id: id });
    const { data } = response;
    return data;
};