import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import request from "@/services/request";
import { PaginateParams, SortDirectionEnum } from "@/types/pagination";
import { CreateTaxRatePayload, EditTaxRatePayload, TaxRate, TaxRatesStore } from "./taxRatesTypes";
import { AppDispatch } from "@/store/store";
import { showErrorNotification } from "@/utils/errors";

export const paginationParamsInit: PaginateParams = {
    filter: 'disabled=false',
    orderBy: 'taxRateCode',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
}

const initialState: TaxRatesStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    pagination: paginationParamsInit,
};

const slice = createSlice({
    name: "taxRates",
    initialState,
    reducers: {
        setTaxRates(state, action: PayloadAction<Array<TaxRate>>) {
            state.list = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setPagination: (state, action: PayloadAction<PaginateParams>) => {
            state.pagination = action.payload;
        },
    },
});

export default slice.reducer;

export const { setTaxRates, setLoading, setError, setCount, setPagination } = slice.actions;

export const getTaxRatesList = (payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const data = await getTaxRates();
            dispatch(setTaxRates(data.taxRates));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            dispatch(setLoading(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading(false));
        }
    };
};

export const getTaxRates = async (): Promise<{ taxRates: Array<TaxRate>, count: number }> => {
    const response = await request.get(`/api/console/billing/tax-rates/list`, {
        params: {
            filter: paginationParamsInit.filter,
            take: 1000
        }
    });
    const { data } = response;
    return data;
};

export const editInvoiceTaxRate = async (payload: EditTaxRatePayload) => {
    const response = await request.post(`/api/console/billing/tax-rate/edit`, payload);
    return response?.data;
};

export const createInvoiceTaxRate = async (payload: CreateTaxRatePayload) => {
    const response = await request.post(`/api/console/billing/tax-rate/create`, payload);
    return response?.data;
};