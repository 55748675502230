import { memo } from 'react';
import { Section } from '../sections/emiSections';
import SectionItem from './SectionItem';

interface Props {
    sections: Array<Section>
    open: boolean
}

const MenuList = ({ sections, open }: Props) => {
    return (
        <>
            {sections?.map((section, index) =>
                <SectionItem key={index} section={section} open={open} />
            )}
        </>
    );
};

export default memo(MenuList);
