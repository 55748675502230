import { Theme } from "@mui/material";
import { CSSProperties } from "react";

export const getStatusColor = (status: string, theme: Theme, isText?: boolean, withBackground: boolean = true) => {

    const successStatus = [
        'COMPLETED',
        'ACTIVE',
        'APPROVED',
        'APPROVED_BY_RISK_MANAGER',
        'APPROVED_BY_COMPLIANCE_MANAGER',
        'PAID',
        'VERIFIED',
        'USED',
        'PRIMARY',
        'SENT'
    ];

    const warningStatus = [
        'FX_PENDING',
        'PENDING',
        'PENDING_FX',
        'INACTIVE',
        'ORDERED',
        'CARD_BLOCKED',
        'CARD_LOST',
        'PARTIAL',
        'PARTIALLY_PAID',
        'SUBMITTED',
        'APPROVAL',
        'LEGAL_APPROVAL',
        'TERMINATED',
        'PENDING_EXTERNALLY',
        'PLANNED',
        'UNVERIFIED',
        'EXPIRED',
        'UNKNOWN',
        'MATCHED',
        'IN_REVIEW',
        'IN REVIEW',
        'DONE',
        'CREATED',
        'SKIP_KYC',
        'UPCOMING',
        'OFFER',
        'COMPLETED_WARNING',
        'PARTIALLY_IMPORTED'
    ];

    const greyStatus = ['PENDING_UNASSIGNED_PAYMENT', 'DRAFT', 'NOT_PAID', 'NEW']

    const errorStatus = ['REJECTED', 'ERROR', 'FAILED', 'DISABLED', 'BLOCKED', 'LATE'];

    const blueStatus = ['CANCELED', 'REVERTED', 'SUSPENDED', 'LOST', 'STOLEN', 'EXECUTED'];


    const result: CSSProperties = {
        backgroundColor: theme.palette.success.main,
        color: isText ? theme.palette.text.primary : 'white'
    };


    if (successStatus.includes(status)) {
        result.backgroundColor = theme.palette.success.light;
        result.color = theme.palette.success.main;
    }

    else if (warningStatus.includes(status)) {
        result.backgroundColor = theme.palette.warning.light;
        result.color = theme.palette.warning.main;
    }

    else if (errorStatus.includes(status)) {
        result.backgroundColor = theme.palette.error.light;
        result.color = theme.palette.error.main;
    }

    else if (blueStatus.includes(status)) {
        result.backgroundColor = theme.palette.ligthBlue;
        result.color = theme.palette.blue;
    }

    else if (greyStatus.includes(status)) {
        result.backgroundColor = theme.palette.transparentGreen;
        result.color = theme.palette.text.primary;
    }

    else {

        if (status.includes('ACTIVE') && !status.includes('INACTIVE')) {
            result.backgroundColor = theme.palette.warning.main;
            if (isText) result.color = theme.palette.warning.main;
        }
        else if (status.includes('BLOCKED')) {
            result.backgroundColor = theme.palette.error.main;
            if (isText) result.color = theme.palette.error.main;
        }
        else {
            result.backgroundColor = theme.palette.transparentBlack;
            result.color = theme.palette.text.primary;

        }

    }

    if (!withBackground) {
        result.backgroundColor = undefined;
    }

    return result;
};