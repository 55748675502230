import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { RiskLevelsType, UsersRiskLevelStore } from './userRiskLevelsTypes';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { getWlpName } from '@/components/dataGrid/utils/Platform';
import { getClientStatusOptions } from '../clients/clientsSlice';
import { Wlp } from '../user/types';
import { MonitoringUser } from '../clientsMonitoring/clientsMonitoringTypes';

export const usersPaginationParamsInit = {
    filter: '',
    orderBy: 'createdDate',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: UsersRiskLevelStore = {
    [RiskLevelsType.RISK_LEVEL_1]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: usersPaginationParamsInit,
        filters: {
            [HeaderFilterType.WLPS]: [],
            [HeaderFilterType.STATUS]: []
        },
        filtersSet: false
    },
    [RiskLevelsType.RISK_LEVEL_2]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: usersPaginationParamsInit,
        filters: {
            [HeaderFilterType.WLPS]: [],
            [HeaderFilterType.STATUS]: []
        },
        filtersSet: false
    },
    [RiskLevelsType.RISK_LEVEL_3]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: usersPaginationParamsInit,
        filters: {
            [HeaderFilterType.WLPS]: [],
            [HeaderFilterType.STATUS]: []
        },
        filtersSet: false
    },
};

const slice = createSlice({
    name: 'userRiskLevels',
    initialState,
    reducers: {
        setUsers(state, action: PayloadAction<{ storeKey: RiskLevelsType, list: Array<MonitoringUser> }>) {
            state[action.payload.storeKey].list = action.payload.list;
        },
        setLoading: (state, action: PayloadAction<{ storeKey: RiskLevelsType, loading: boolean }>) => {
            state[action.payload.storeKey].loading = action.payload.loading
        },
        setError: (state, action: PayloadAction<{ storeKey: RiskLevelsType, error: string }>) => {
            state[action.payload.storeKey].error = action.payload.error;
        },
        setCount: (state, action: PayloadAction<{ storeKey: RiskLevelsType, count: number }>) => {
            state[action.payload.storeKey].count = action.payload.count;
        },
        setPagination: (state, action: PayloadAction<{ storeKey: RiskLevelsType, pagination: PaginateParams }>) => {
            state[action.payload.storeKey].pagination = action.payload.pagination;
        },
        // Filters
        setFilters: (state, action: PayloadAction<{ storeKey: RiskLevelsType, wlps: Array<Wlp> }>) => {
            const wlpsOptions = action.payload.wlps.map(item => ({
                value: item.wlpId,
                text: getWlpName(item.wlpId),
                enabled: true
            }));
            const statusOptions = getClientStatusOptions();
            state[action.payload.storeKey].filters[HeaderFilterType.WLPS] = wlpsOptions;
            state[action.payload.storeKey].filters[HeaderFilterType.STATUS] = statusOptions;
            state[action.payload.storeKey].filtersSet = true;
        },
        setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, storeKey: RiskLevelsType, options: Array<FilterOptionType> }>) => {
            state[action.payload.storeKey].filters[action.payload.filterType] = action.payload.options;
        },
        setFiltersSet(state, action: PayloadAction<{ storeKey: RiskLevelsType, set: boolean }>) {
            state[action.payload.storeKey].filtersSet = action.payload.set;
        }
    }
});

export const { setUsers, setLoading, setError, setCount, setPagination, setFilters, setFilter, setFiltersSet } = slice.actions;

export const getUsersByRiskLevel = (storeKey: RiskLevelsType, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ storeKey, loading: true }));
            const response = await request.get(`/api/console/users/list`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setUsers({ storeKey, list: data.list }));
            dispatch(setCount({ storeKey, count: data.count }));
            dispatch(setPagination({ storeKey, pagination: payload }));
            dispatch(setLoading({ storeKey, loading: false }));
            return data;
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading({ storeKey, loading: false }));
        }
    };
};

export default slice.reducer;