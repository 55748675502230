import { format, parse } from 'date-fns';
const dateFormat = 'dd MMM yy hh:mm:ss';

export const convertDate = (originalDate: Date, pattern: string): string => {
	const date = new Date(originalDate);
	return format(date, pattern);
};

export const formatDate = (originalDate: string, originalFormat?: string): string => {
	if (originalFormat) {
		return format(parse(originalDate, originalFormat, new Date()), dateFormat);
	}
	const date = new Date(originalDate);
	return format(date, dateFormat);
};

export const formatDateWithPattern = (originalDate: string | null, dateFormat: string, originalFormat?: string): string => {
	if (!originalDate) {
		return '';
	}
	if (originalFormat) {
		return format(parse(originalDate, originalFormat, new Date()), dateFormat);
	}
	const date = new Date(originalDate);
	return format(date, dateFormat);
};

export const formatUTCDateWithPattern = (originalDate: string, dateFormat: string, originalFormat?: string): string => {
	if (originalFormat) {
		return format(parse(originalDate, originalFormat, new Date().getUTCDate()), dateFormat);
	}
	const date = new Date(originalDate).getUTCDate();
	return format(date, dateFormat);
};

export const convertDateFromEpoch = (epoch: any) => {
	if (epoch == null) {
		return null;
	}
	return new Date(epoch).toString();
};

export const formatDateFromDate = (date: Date): string => {
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	return `${year}-${month}-${day}`;
};
