import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import request from '../../services/request';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileUpload, FileUploadPayload, FileUploadsStore } from './fileUploadsTypes';
import { showErrorNotification } from '@/utils/errors';
import { AppDispatch } from '@/store/store';

export const fileImportsDefaulsPagination: PaginateParams = {
	filter: '',
	orderBy: 'created',
	sort: SortDirectionEnum.DESC,
	take: 25,
	skip: 0
};

const initialState: FileUploadsStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	pagination: fileImportsDefaulsPagination,
	storedProvider: null
};

const slice = createSlice({
	name: 'fileUploads',
	initialState,
	reducers: {
		reset: () => initialState,
		setList(state, action: PayloadAction<Array<FileUpload>>) {
			state.list = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload
		},
		setError: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
		},
		setCount: (state, action: PayloadAction<number>) => {
			state.count = action.payload;
		},
		setPagination: (state, action: PayloadAction<PaginateParams>) => {
			state.pagination = action.payload;
		},
		setStoredProvider: (state, action: PayloadAction<string>) => {
			state.storedProvider = action.payload;
		}
	},
});

export default slice.reducer;

export const { reset, setList, setLoading, setError, setCount, setPagination, setStoredProvider } = slice.actions;

export const getFilesUploadsList = (provider: string, payload: PaginateParams) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/console/files/list', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take,
					provider: provider
				}
			});
			const { data } = response;
			dispatch(setList(data.list));
			dispatch(setCount(data.count));
			dispatch(setPagination(payload));
		} catch (e) {
			showErrorNotification(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const getCountFileImports = async (provider: string) => {
	const response = await request.get(`/api/console/files/count`, {
		params: {
			provider: provider
		}
	});
	const { data } = response;
	return data;
};

export const uploadFile = async (payload: FileUploadPayload) => {
	const response = await request.post('/api/console/files/upload', payload);
	const { data } = response;
	return data;
};

export const downloadFile = async (fileId: number, name: string) => {
	const response = await request.get(`/api/console/files/download/${fileId}`, { responseType: 'blob' });
	const url = window.URL.createObjectURL(response.data);
	const link = document.createElement('a');
	link.download = `${name}`;
	link.href = url;
	link.className = 'hidden';
	document.body.appendChild(link);

	link.onclick = function () {
		requestAnimationFrame(function () {
			URL.revokeObjectURL(url);
			setTimeout(() => link.remove(), 300);
		});
	};

	link.click();
};