
import { PaginateParams } from "@/types/pagination";
import { FeeEnum, ProcessesFilterConfiguration, ServiceFee } from "../platformFees/platformFeesTypes";
import { FilterOptionType } from "@/components/dataGrid/CustomFilterHeader";

export interface WlpFeeStore {
    commonFilterOptions: CommonFilterOptions,
    fees: {
        [key: string]: {
            loading: boolean,
            list: Array<WlpFee>,
            error: string | null,
            count: number,
            pagination: PaginateParams,
            loadingProcesses: boolean;
            uniqueCcy: Array<string>;
            selectedCurrency: string;
            processesConfigurations: Array<ProcessesFilterConfiguration> | null;
            filters: {
                [key: string]: Array<FilterOptionType>
            },
            filtersSet: boolean;
        }
    },
    serviceFees: {
        [key: string]: {
            loading: boolean,
            list: Array<ServiceFee>,
            error: string | null,
            count: number,
            pagination: PaginateParams,
        }
    },
};

interface CommonFilterOptions {
    regionsOptions: Array<FilterOptionType>;
    loadingRegions: boolean;
}

export interface WlpFee {
    uuid: string;
    emiFeeId: number;
    defaultFeeId: number;
    uiFeeId: number;
    uiFutureFeeId: number;
    uiEmiFeeId: number;
    uiFutureEmiFeeId: number;
    emiWlpId: string;
    wlpId: string;
    process: string;
    userProfileType: string;
    feeType: FeeEnum;
    ccy: string;
    riskGroupCode: string;
    regionCode: string;
    countryCode: string;
    currentFeeId: number;
    currentFeeType: FeeEnum;
    currentFeeAmount: number;
    currentFeeMonth: number;
    currentFeePercent: number;
    currentMinFee: number;
    currentStartDate: string;
    currentEndDate: string;
    currentScope: string;
    futureFeeId: number;
    futureFeeAmount: number;
    futureFeeMonth: number;
    futureFeePercent: number;
    futureMinFee: number;
    futureStartDate: string;
    futureEndDate: string;
    currentEmiFeeId: number;
    currentEmiFeeType: FeeEnum;
    currentEmiFeeAmount: number;
    currentEmiFeeMonth: number;
    currentEmiFeePercent: number;
    currentEmiMinFee: number;
    currentEmiStartDate: string;
    currentEmiEndDate: string;
    currentEmiScope: string;
    futureEmiFeeId: number;
    futureEmiFeeAmount: number;
    futureEmiFeeMonth: number;
    futureEmiFeePercent: number;
    futureEmiMinFee: number;
    futureEmiStartDate: string;
    futureEmiEndDate: string;
    processName: string;
    integration: string;
    categoryCode: string;
    disabledProcess: boolean;
    integrationName: string;
    regionName: string;
    countryName: string;
    countriesFeeIsAppliedTo: Array<string>;
}

export enum FeeScopeEnum {
    VISIBLE_EDITABLE = 'VISIBLE_EDITABLE',
    VISIBLE_NOT_EDITABLE = 'VISIBLE_NOT_EDITABLE',
    NOT_VISIBLE = 'NOT_VISIBLE',
    TECHNICAL = 'TECHNICAL'
}
export interface CreateOrUpdateWlpFeePayload {
    wlpFeePayload: WlpFeePayload;
};

export interface CreateOrUpdateWlpFeesPayload {
    processFeeCreateOrUpdateRequestList: Array<WlpFeePayload>;
};
export interface WlpFeePayload {
    process: string;
    ccy: string;
    fee: FeeEnum;
    userProfileType: string;
    riskGroupCode: string | null;
    countryCode: string | null;
    regionCode: string | null;
    feeAmount: number;
    feeMonth: number;
    feePercent: number;
    minFee: number;
    feeStartDateTime?: string;
};

export interface ProcessFee {
    fee: string;
    name: string;
    description: string;
    feeType: string;
    paymentType: string;
    transactionType: string;
    id: number;
    wlpId: string;
    proc: string;
    userId: number;
    clientId: string;
    ccy: string;
    currencySymbol: string;
    countryCode: string;
    riskGroupCode: string;
    userProfileType: string;
    userLevelId: number;
    userGroupId: number;
    feeAmount: number;
    feeMonth: number;
    minFee: number;
    feePercent: number;
    disabled: boolean;
    config: string;
    startDate: string;
    endDate: string;
};