import { SvgIcon } from '@mui/material';

const CardTransaction = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#2B2B2B" fillOpacity="0.06" />
            <path d="M18.6667 11.3332C18.6606 9.25697 18.595 8.15628 17.8863 7.44755C17.1052 6.6665 15.8481 6.6665 13.334 6.6665H10.6673C8.15316 6.6665 6.89608 6.6665 6.11503 7.44755C5.33398 8.2286 5.33398 9.48568 5.33398 11.9998C5.33398 14.514 5.33398 15.7711 6.11503 16.5521C6.89608 17.3332 8.15316 17.3332 10.6673 17.3332H11.6673" stroke="#EFB930" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M14.3333 13.3335V17.3335M14.3333 17.3335L15.6667 16.0002M14.3333 17.3335L13 16.0002M17.3333 17.3335V13.3335M17.3333 13.3335L18.6667 14.6668M17.3333 13.3335L16 14.6668" stroke="#EFB930" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.6667 14.6665H8" stroke="#EFB930" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M5.33398 10.6665L18.6673 10.6665" stroke="#EFB930" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default CardTransaction;