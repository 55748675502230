import * as React from "react";
import { SvgIcon } from '@mui/material';
const TrashDelete = (props:any) => (
  <SvgIcon {...props}>
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 8v10a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V8m-5 3v6m-4-6v6m6-12-1.406-2.11A2 2 0 0 0 12.93 2h-1.86a2 2 0 0 0-1.664.89L8 5m8 0H8m8 0h5M8 5H3"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  </SvgIcon>
);
export default TrashDelete;
