import * as React from "react";
import { SvgIcon } from '@mui/material';

const Report = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path d="M15.15 6.69814L15.5362 6.31194C16.1761 5.67206 17.2135 5.67206 17.8534 6.31194C18.4933 6.95182 18.4933 7.98926 17.8534 8.62914L17.4672 9.01534M15.15 6.69814C15.15 6.69814 15.1983 7.51881 15.9224 8.24294C16.6466 8.96706 17.4672 9.01534 17.4672 9.01534M15.15 6.69814L11.5995 10.2487C11.359 10.4891 11.2388 10.6094 11.1354 10.742C11.0134 10.8984 10.9088 11.0676 10.8235 11.2466C10.7511 11.3984 10.6974 11.5597 10.5898 11.8824L10.2455 12.9154L10.1341 13.2496M17.4672 9.01534L13.9167 12.5659C13.6762 12.8063 13.556 12.9266 13.4234 13.03C13.267 13.152 13.0978 13.2566 12.9187 13.3419C12.767 13.4142 12.6056 13.468 12.283 13.5755L11.25 13.9199L10.9158 14.0313M10.9158 14.0313L10.5816 14.1427C10.4228 14.1956 10.2478 14.1543 10.1294 14.0359C10.0111 13.9176 9.96976 13.7425 10.0227 13.5838L10.1341 13.2496M10.9158 14.0313L10.1341 13.2496" stroke="currentColor" strokeWidth="1.2" />
            <path d="M6.66797 10.832H8.7513" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M6.66797 7.5H12.0846" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M6.66797 14.168H7.91797" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path opacity="0.5" d="M2.5 8.33463C2.5 5.19194 2.5 3.62059 3.47631 2.64428C4.45262 1.66797 6.02397 1.66797 9.16667 1.66797H10.8333C13.976 1.66797 15.5474 1.66797 16.5237 2.64428C17.5 3.62059 17.5 5.19194 17.5 8.33464V11.668C17.5 14.8107 17.5 16.382 16.5237 17.3583C15.5474 18.3346 13.976 18.3346 10.8333 18.3346H9.16667C6.02397 18.3346 4.45262 18.3346 3.47631 17.3583C2.5 16.382 2.5 14.8107 2.5 11.668V8.33463Z" stroke="currentColor" strokeWidth="1.2" />
        </svg>
    </SvgIcon>
);
export default Report;