import { dispatch, useSelector } from '@/store/store';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import LimitCell from "@/components/dataGrid/renderCell/LimitCell";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { LimitType } from '@/redux/limit/limitTypes';
import { showErrorNotification, showSuccessNotification } from "@/utils/errors";
import Loader from '@/components/Loader';
import { defaultPageOptions, defaultPageSize } from '@/utils/constant';
import { createPlatformLimit, getPlatformLimits, setPlatfrormLimits } from '@/redux/platformLimits/platformLimitsSlice';
import { orderBy } from 'lodash';
import { UserType } from '@/redux/user/types';
import RemoveUserLimitsForm from './RemoveUserLimitsForm';
import { TFunction } from 'i18next';

interface Props {
    wlpId?: string,
}

interface CellActionType {
    handleOpenEditLimit: (limitType: LimitType) => void;
    handleSaveLimit: (editedValue: number, userProfileType: UserType, fieldName: string) => void;
}

const getLimitsColumn = (actionItem: CellActionType, t: TFunction): GridColDef[] => {
    const accountColumns: GridColDef[] = [
        {
            field: 'userProfileType',
            headerName: t('form.fields.type').toString(),
            flex: 1.1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Grid container alignItems="center">
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                                {t(`limits.platfromLimits.${params.value}`)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='caption' color='text.secondary'>EUR</Typography>
                        </Grid>
                    </Grid>
                );
            }
        },
        {
            // Limit for single transfer
            field: 'maxAmount',
            headerName: t('limits.maxAmount').toString(),
            flex: 0.9,
            sortable: false,
            renderCell: (params) => LimitCell(
                {
                    params,
                    limitFieldType: LimitType.SINGLE,
                    fieldName: 'maxAmount',
                    onClick: () => actionItem.handleOpenEditLimit(LimitType.SINGLE),
                    onSave: (editedValue: number) => actionItem.handleSaveLimit(editedValue, params.row.userProfileType, 'maxAmount'),
                    editable: true
                }
            )
        },
        {
            field: 'dayLimit',
            headerName: t('limits.daily').toString(),
            flex: 1,
            sortable: false,
            renderCell: (params) => LimitCell(
                {
                    params,
                    limitFieldType: LimitType.DAILY,
                    fieldName: 'dayLimit',
                    onClick: () => actionItem.handleOpenEditLimit(LimitType.DAILY),
                    onSave: (editedValue: number) => actionItem.handleSaveLimit(editedValue, params.row.userProfileType, 'dayLimit'),
                    editable: true
                }
            )
        },
        {
            field: 'monthLimit',
            headerName: t('limits.monthly').toString(),
            flex: 1,
            sortable: false,
            renderCell: (params) => LimitCell(
                {
                    params,
                    limitFieldType: LimitType.MONTHLY,
                    fieldName: 'monthLimit',
                    onClick: () => actionItem.handleOpenEditLimit(LimitType.MONTHLY),
                    onSave: (editedValue: number) => actionItem.handleSaveLimit(editedValue, params.row.userProfileType, 'monthLimit'),
                    editable: true
                }
            )
        },
        {
            field: 'annualLimit',
            headerName: t('limits.annual').toString(),
            flex: 1,
            sortable: false,
            renderCell: (params) => LimitCell(
                {
                    params,
                    limitFieldType: LimitType.ANNUAL,
                    fieldName: 'annualLimit',
                    onClick: () => actionItem.handleOpenEditLimit(LimitType.ANNUAL),
                    onSave: (editedValue: number) => actionItem.handleSaveLimit(editedValue, params.row.userProfileType, 'annualLimit'),
                    editable: true
                }
            )
        }
    ];
    return accountColumns;
};

const PlatformLimits = ({ wlpId }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { loading, limits } = useSelector((state) => state.platformLimits);

    const [selectedLimitType, setSelectedLimitType] = useState<LimitType | null>(null);

    const [openUserLimits, setOpenUserLimits] = useState(false);

    useEffect(() => {
        dispatch(getPlatformLimits(wlpId));
    }, [wlpId]);

    const handleOpenEditLimit = (limitType: LimitType) => {
        setSelectedLimitType(limitType);
    };

    const handleSaveClick = async (editedValue: number, selectedUserProfileType: UserType, fieldName: string) => {
        if (limits.length > 0 && selectedLimitType !== null) {
            const limit = limits.find(limit => limit.userProfileType === selectedUserProfileType)
            if (limit && wlpId) {
                const updatedLimit = {
                    ...limit,
                    [fieldName]: editedValue
                };
                dispatch(setPlatfrormLimits([...limits.filter(limit => limit.userProfileType !== updatedLimit.userProfileType), updatedLimit]));
                const payload = { createLimitPayload: updatedLimit }
                try {
                    const newLimit = await createPlatformLimit(wlpId, payload);
                    dispatch(setPlatfrormLimits([...limits.filter(limit => limit.userProfileType !== updatedLimit.userProfileType), newLimit]));
                    showSuccessNotification(t('limits.limitChanged'));
                } catch (e) {
                    dispatch(setPlatfrormLimits(limits));
                    showErrorNotification(e);
                }
            }
        }
    };

    const actionItem: CellActionType = {
        handleOpenEditLimit: handleOpenEditLimit,
        handleSaveLimit: handleSaveClick,
    };

    const rows = limits ? orderBy(limits, "userProfileType", "desc") : [];
    const limitColumns = getLimitsColumn(actionItem, t);

    if (loading) return <Loader />;

    return (
        <Grid container item xs={12}
            sx={{
                borderColor: theme.palette.transparentBlack,
                borderRadius: 2,
                borderStyle: 'solid',
                borderWidth: '1px',
                bgcolor: theme.palette.white.main,
            }}>
            {wlpId &&
                <Grid container item xs={12} justifyContent='end'
                    sx={{
                        px: 2,
                        py: 1.2
                    }}>
                    <RemoveUserLimitsForm
                        wlpId={wlpId}
                        open={openUserLimits}
                        onClose={() => {
                            setOpenUserLimits(false);
                        }} />
                    <Button
                        onClick={() => { setOpenUserLimits(true) }}
                        size="large"
                        color="secondary"
                        variant="outlined">
                        {t('form.buttons.removeUserLimits')}
                    </Button>
                </Grid>
            }
            <Box
                sx={{
                    width: '100%',
                    '.MuiDataGrid-root': {
                        '& .MuiDataGrid-columnHeaders': {
                            borderColor: theme.palette.transparentBlack,
                            borderRadius: wlpId ? '' : '7px 7px 0 0',
                        },
                        '& .MuiDataGrid-row:first-of-type': {
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderWidth: '0.5px',
                            borderColor: theme.palette.transparentBlack,
                        },
                        '& .MuiDataGrid-row:last-child': {
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderBottom: 'none',
                            borderWidth: '0.5px',
                            borderColor: theme.palette.transparentBlack,
                            borderRadius: '0 0 7px 7px',
                        }
                    }
                }}>
                <DataGrid
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: defaultPageSize, page: 0 },
                        },
                    }}
                    pageSizeOptions={defaultPageOptions}
                    rows={rows}
                    getRowId={(row) => `${row.id}-${row.userProfileType}`}
                    autoHeight={true}
                    columns={limitColumns}
                    disableRowSelectionOnClick={true}
                    hideFooter={true}
                    disableColumnMenu={true}
                    rowHeight={70}
                />
            </Box>
        </Grid >
    );
};

export default PlatformLimits;
