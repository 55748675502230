import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import request from '../../services/request';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '@/store/store';
import { showErrorNotification } from '@/utils/errors';
import { DynamicInterestData, EarnDynamicPaymentsStore } from './earnDynamicPaymentsTypes';


export const earnDynamicPaymentsPaginationParamsInit = {
    filter: '',
    orderBy: 'paymentDate',
    sort: SortDirectionEnum.DESC,
    take: 10,
    skip: 0
};


const initialState: EarnDynamicPaymentsStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    pagination: earnDynamicPaymentsPaginationParamsInit
};

const slice = createSlice({
    name: 'earnDynamicPayments',
    initialState,
    reducers: {
        setList(state, action: PayloadAction<Array<DynamicInterestData>>) {
            state.list = action.payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
            state.pagination = payload;
        },
    }
});

export const { setList, setLoading, setError, setCount, setPagination } = slice.actions;

export const getDynamicInterestList = (proc: string, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/vaults/interest-payment/dynamic/${proc}/data`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setList(data.list));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            dispatch(setLoading(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading(false));
        }
    };
};


export const getEarnDynamicPaymentsChart = async (proc: string, date: string): Promise<any[]> => {
    const response = await request.get(`/api/console/vaults/interest-payment/dynamic/${proc}/stats/${date}`);
    const { data } = response;
    return data;
};

export default slice.reducer;