import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '@/utils/errors';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { FileDetailsHeader, TokensFileDetailsStore } from './tokensFileDetailsTypes';

export const paginationParamsInit = {
    filter: '',
    orderBy: 'createdAt',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: TokensFileDetailsStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    pagination: paginationParamsInit,
    header: null,
    storedFileId: null
};

const slice = createSlice({
    name: 'tokensFileDetails',
    initialState,
    reducers: {
        reset: () => initialState,
        setList(state, action: PayloadAction<Array<any>>) {
            state.list = action.payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
            state.pagination = payload;
        },
        setHeader(state, action: PayloadAction<FileDetailsHeader>) {
            state.header = action.payload;
        },
        setStoredData: (state, { payload }: PayloadAction<{ filetId: string }>) => {
            state.storedFileId = payload.filetId;
        }
    }
});

export const { setList, setLoading, setError, setCount, setPagination, setHeader, setStoredData, reset } = slice.actions;

export const getTokensFileDetails = (fileId: number, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/tokens/issuance/file/${fileId}/details`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            let header: FileDetailsHeader = {
                createdAt: data.createdAt,
                fileName: data.fileName,
                issuedById: data.issuedById,
                issuedByName: data.issuedByName,
                status: data.status
            }
            dispatch(setHeader(header));
            dispatch(setList(data.list));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            dispatch(setLoading(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading(false));
        }
    };
};


export default slice.reducer;