import TypographyWithEllipsis from "@/components/TypographyWithEllipsis";
import { Box, Grid, SxProps, Theme, Tooltip, Typography, styled } from "@mui/material";
import { sortBy } from "lodash";

interface Props {
    upperField: string | null;
    lowerField: string | Array<string>;
    upperSx?: SxProps<Theme>;
    lowerSx?: SxProps<Theme>;
};

export const CustomScrollbarStack = styled('div')(() => ({
    maxHeight: '300px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
        width: '7px',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#e0e0e0',
        borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#c1c1c1',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: '#7d7d7d',
        }
    }
}));

const UpperLowerValuesCell = ({ upperField, lowerField, upperSx, lowerSx }: Props) => {
    const renderLowerField = () => {
        if (Array.isArray(lowerField)) {
            const sortedLowerField = sortBy(lowerField, [field => field.toLowerCase()]);
            return (
                <Tooltip
                    slotProps={{
                        tooltip: {
                            sx: {
                                paddingRight: '4px',
                            }
                        },
                    }}
                    title={
                        <CustomScrollbarStack>
                            {sortedLowerField.map((item, index) => (
                                <Typography key={index}
                                    sx={{
                                        pr: 1.5,
                                        py: 0.4,
                                        pl: 0.5,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}>
                                    {item}
                                </Typography>
                            ))}
                        </CustomScrollbarStack>
                    }
                    placement="right">

                    <Box sx={{ display: 'inline-block', maxWidth: '100%', overflow: 'hidden' }}>
                        {sortedLowerField.slice(0, 3).map((item, index, arr) => (
                            <Typography
                                noWrap
                                key={index}
                                color='text.secondary'
                                sx={{
                                    ...lowerSx,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {item}{(index < arr.length - 1) ? "" : ""}
                                {index === 2 && sortedLowerField.length > 3 && '...'}
                            </Typography>
                        ))}
                    </Box>

                </Tooltip>
            );
        } else {
            return (
                <Grid item xs={12}>
                    <TypographyWithEllipsis variant="body2" color='text.secondary' sx={{ ...lowerSx }}>
                        {lowerField}
                    </TypographyWithEllipsis>
                </Grid>
            );
        }
    };

    return (
        <Grid container>
            {upperField && upperField !== 'null null' && upperField !== 'null' &&
                <Grid item xs={12}>
                    <TypographyWithEllipsis variant="body2" color='text.primary' sx={{ fontWeight: 500, ...upperSx }}>
                        {upperField}
                    </TypographyWithEllipsis>
                </Grid>
            }
            {renderLowerField()}
        </Grid>
    );
};

export default UpperLowerValuesCell;