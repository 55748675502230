import TypographyWithEllipsis from "@/components/TypographyWithEllipsis";
import BillList from "@/icons/documentsIcons.tsx/BillList";
import BillReceivable from "@/icons/documentsIcons.tsx/BillReceivable";
import CardOrder from "@/icons/documentsIcons.tsx/CardOrder";
import CardTransaction from "@/icons/documentsIcons.tsx/CardTransaction";
import Deactivation from "@/icons/documentsIcons.tsx/Deactivation";
import FeeCalculation from "@/icons/documentsIcons.tsx/FeeCalculation";
import FileUploadTokens from "@/icons/documentsIcons.tsx/FileUploadTokens";
import IncomingPayment from "@/icons/documentsIcons.tsx/IncomingPayment";
import InterestPayment from "@/icons/documentsIcons.tsx/InterestPayment";
import ManualAdjustment from "@/icons/documentsIcons.tsx/ManualAdjustment";
import Money from "@/icons/documentsIcons.tsx/Money";
import PaymentOrder from "@/icons/documentsIcons.tsx/PaymentOrder";
import Purchase from "@/icons/documentsIcons.tsx/Purchase";
import SystemTransfer from "@/icons/documentsIcons.tsx/SystemTransfer";
import Trade from "@/icons/documentsIcons.tsx/Trade";
import TransferOrder from "@/icons/documentsIcons.tsx/TransferOrder";
import UserLevelSubscription from "@/icons/documentsIcons.tsx/UserLevelSubscription";
import VaultDeposit from "@/icons/documentsIcons.tsx/VaultDeposit";
import VaultExtend from "@/icons/documentsIcons.tsx/VaultExtend";
import { BillingHistoryEnum } from "@/redux/emiBilling/emiBillingTypes";
import { EarnPaymentsEnum } from "@/redux/emiFinanceEarn/emiFinanceEarnTypes";
import { CardTransactionsEnum, CryptoTradesEnum, DocumentTypeEnum, SystemTransfersEnum, TokenDocumentStatusEnum, TokenTransfersEnum, TokensFundsInOutEnum } from "@/redux/transfers/transfersTypes";
import { normalizeString } from "@/utils/stringFormat";
import { Grid, Theme, useTheme } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";


const getIcon = (documentType: string) => {
    switch (documentType) {
        case DocumentTypeEnum.INCOMING_PAYMENT:
        case TokenDocumentStatusEnum.DEPOSIT:
            return <IncomingPayment sx={{ fontSize: '21px' }} />
        case TokensFundsInOutEnum.INCOMING_PAYMENT_TOKENS:
            return <FileUploadTokens sx={{ fontSize: '21px', color: '#40927E' }} />
        case TokensFundsInOutEnum.PAYMENT_ORDER_TOKENS:
            return <FileUploadTokens sx={{ fontSize: '21px', color: '#EF3030' }} />
        case DocumentTypeEnum.PAYMENT_ORDER:
        case TokenDocumentStatusEnum.WITHDRAW:
        case TokenTransfersEnum.PAYMENT:
            return <PaymentOrder sx={{ fontSize: '21px' }} />
        case DocumentTypeEnum.TRANSFER_ORDER:
        case TokenTransfersEnum.TRANSFER:
            return <TransferOrder sx={{ fontSize: '21px' }} />
        case CryptoTradesEnum.CRYPTO_TRADE:
        case DocumentTypeEnum.TOKEN_TRADE:
        case DocumentTypeEnum.FX_ORDER:
            return <Trade sx={{ fontSize: '21px' }} />
        case SystemTransfersEnum.SYSTEM_TRANSFER:
            return <SystemTransfer sx={{ fontSize: '21px' }} />
        case DocumentTypeEnum.TOPUP_FROM_CARD:
        case DocumentTypeEnum.CARD_ORDER:
        case DocumentTypeEnum.CARD_PULLOUT:
        case DocumentTypeEnum.CARD_TOPUP:
            return <CardOrder sx={{ fontSize: '21px' }} />
        case DocumentTypeEnum.CARD_TRANSACTION:
        case DocumentTypeEnum.CARD_BLOCK_AND_PULLOUT:
            return <CardTransaction sx={{ fontSize: '21px' }} />
        case DocumentTypeEnum.DEACTIVATION:
            return <Deactivation sx={{ fontSize: '21px' }} />
        case EarnPaymentsEnum.VAULT_EXTEND:
        case EarnPaymentsEnum.VAULT_AUTO_EXTEND:
            return <VaultExtend sx={{ fontSize: '21px' }} />
        case EarnPaymentsEnum.VAULT_DEPOSIT:
            return <VaultDeposit sx={{ fontSize: '21px' }} />
        case DocumentTypeEnum.MONTH_FEE_CALC:
        case SystemTransfersEnum.SYSTEM_DEPOSIT_FEE:
            return <FeeCalculation sx={{ fontSize: '21px' }} />
        case DocumentTypeEnum.MANUAL_ADJUSTMENT:
            return <ManualAdjustment sx={{ fontSize: '21px', }} />
        case DocumentTypeEnum.USER_LEVEL_SUBSCRIPTION:
            return <UserLevelSubscription sx={{ fontSize: '21px' }} />
        case CardTransactionsEnum.CARD_PURCHASE:
            return <Purchase sx={{ fontSize: '21px' }} />
        case EarnPaymentsEnum.DEPOSIT_REPAYMENT:
        case CardTransactionsEnum.CARD_WITHDRAW:
            return <Money sx={{ fontSize: '21px' }} />
        case EarnPaymentsEnum.INTEREST_PAYMENT:
            return <InterestPayment sx={{ fontSize: '21px' }} />
        case BillingHistoryEnum.FEE_WITHDRAWAL:
            return <TransferOrder sx={{ fontSize: '21px' }} />
        case BillingHistoryEnum.INVOICE:
            return <BillReceivable sx={{ fontSize: '21px' }} />
        case BillingHistoryEnum.FEE_PAYOUT:
            return <BillList sx={{ fontSize: '21px' }} />
        default:
            return null;
    }
}

export const getColor = (documentType: string, theme: Theme) => {
    const successColors = ['INCOMING_PAYMENT', 'TOPUP_FROM_CARD', 'CARD_ORDER', 'VAULT_DEPOSIT', 'USER_LEVEL_SUBSCRIPTION', 'DEPOSIT_REPAYMENT', 'INTEREST_PAYMENT', 'CARD_PULLOUT', 'CARD_TOPUP', 'CARD_WITHDRAW', 'INCOMING_PAYMENT_TOKENS', 'DEPOSIT'];
    const warningColors = ['CRYPTO_TRADE', 'TRANSFER', 'TRANSFER_ORDER', 'SYSTEM_TRANSFER', 'CARD_TRANSACTION', 'VAULT_EXTEND', 'VAULT_AUTO_EXTEND', 'TRADE', 'TOKEN_TRADE', 'FX_ORDER', 'MONTH_FEE_CALC', 'SYSTEM_DEPOSIT_FEE', 'CARD_BLOCK_AND_PULLOUT', 'CARD_PURCHASE', 'FEE_WITHDRAWAL'];
    const errorColors = ['WITHDRAW', 'PAYMENT_ORDER', 'DEACTIVATION', 'REVERSAL', 'MANUAL_ADJUSTMENT', 'PAYMENT', 'PAYMENT_ORDER_TOKENS', 'FEE_PAYOUT'];

    if (successColors.includes(documentType)) {
        return theme.palette.success.main;
    } else if (warningColors.includes(documentType)) {
        return theme.palette.warning.main;
    } else if (errorColors.includes(documentType)) {
        return theme.palette.error.main;
    } else return theme.palette.primary.dark;
};

const DocumentTypeCell = (params: GridRenderCellParams) => {
    const { t } = useTranslation();
    const theme = useTheme();

    if (params.value === null || params.value === undefined || params.value === '')
        return <></>;

    // For user earn section correct types
    if (params.value === EarnPaymentsEnum.DEPOSIT_REPAYMENT && params.row.documentType === EarnPaymentsEnum.VAULT_EXTEND) {
        params.value = EarnPaymentsEnum.VAULT_AUTO_EXTEND;
    };

    const icon = getIcon(params.value);
    const color = getColor(params.value, theme);

    return (
        <Grid item display='flex' xs>
            {icon && (
                <Grid item display='flex' alignItems="center">
                    {icon}
                </Grid>
            )}
            <TypographyWithEllipsis
                color={color}
                sx={{ fontWeight: icon ? 600 : 400, fontSize: '13px', pl: 0.5 }}>
                {t('enum.documentTypeEnum.' + params.value, normalizeString(params.value))}
            </TypographyWithEllipsis>
        </Grid>
    );
};

export default DocumentTypeCell;
