import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AddGroupToUserPayload, CreateGroupPayload, RemovUserFromGroupPayload, UserGroup, UserGroupStore, UserGroupTab } from './userGroupsTypes';
import { FeeCategoryCodeType } from '../platforms/platformsTypes';
import { showErrorNotification } from '@/utils/errors';
import { AppDispatch } from '@/store/store';


export const paginationParamsInit = {
	filter: '',
	orderBy: 'name',
	sort: SortDirectionEnum.ASC,
	take: 25,
	skip: 0
};

const initialState: UserGroupStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	pagination: paginationParamsInit,
	tabs: {
		[UserGroupTab.FEES]: {
			openedSubTab: FeeCategoryCodeType.BANKING,
		},
	},
};


const slice = createSlice({
	name: 'userGroups',
	initialState,
	reducers: {
		setGroups(state, action: PayloadAction<Array<UserGroup>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
			state.pagination = payload;
		},
		setSubTab(state, action: PayloadAction<{ tab: UserGroupTab; subTab: FeeCategoryCodeType }>) {
			state.tabs[action.payload.tab].openedSubTab = action.payload.subTab;
		},
	}
});

export const { setGroups, setLoading, setError, setCount, setPagination, setSubTab } = slice.actions;

export const getPlatfromUserGroups = (payload: PaginateParams, wlpId?: string) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get(`/api/console/user-groups/by-platform/list`, {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take,
					...(wlpId ? { wlpId: wlpId } : {})
				}
			});
			const { data } = response;
			dispatch(setGroups(data.list));
			dispatch(setCount(data.count));
			dispatch(setPagination(payload));
		} catch (e) {
			showErrorNotification(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const getAvailableForTheUser = async (userId: number): Promise<Array<UserGroup>> => {
	const response = await request.get(`/api/console/user-groups/available-for-user/list/${userId}`, {
		params: {
			orderBy: 'name',
			sort: 'asc',
			take: 1000
		}
	});
	const { data: { list } } = response;
	return list;
};

export const getUserGroups = async (userId: number) => {
	const response = await request.get(`/api/console/user-groups/user/list/${userId}`, {
		params: {
			orderBy: 'name',
			sort: 'asc'
		}
	});
	const { data: { list } } = response;
	return list;
};

export const addGroupToUser = async ({ userGroupId, clientId }: AddGroupToUserPayload) => {
	const response = await request.post(`/api/console/user-groups/user/add`, { userGroupId, clientId });
	const { data } = response;
	return data;
};

export const removeUserFromGroup = async ({ userGroupId, userId }: RemovUserFromGroupPayload) => {
	const response = await request.delete(`/api/console/user-groups/user/${userGroupId}/${userId}`);
	const { data } = response;
	return data;
};

export const createUserGroup = async (payload: CreateGroupPayload) => {
	const response = await request.post(`/api/console/user-groups/create`, payload);
	const { data } = response;
	return data;
};

export default slice.reducer;