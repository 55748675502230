import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { EmiMaintenanceStore, IntegrationProcesses, Maintenance, ProcessesConfigTypeEnum } from './emiMaintenanceTypes';
import { AppDispatch } from '@/store/store';
import { MaintenanceStatus } from '../maintenance/maintenanceType';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { Process } from '../platformFees/platformFeesTypes';
import { t } from 'i18next';
import { filter, groupBy } from 'lodash';
import { capitalCaseString } from '@/utils/stringFormat';

export const getPaginationParams = (status: MaintenanceStatus): PaginateParams => {
	return {
		filter: '',
		orderBy: status === MaintenanceStatus.COMPLETED ? 'endDate' : 'startDate',
		sort: status === MaintenanceStatus.UPCOMING ? SortDirectionEnum.ASC : SortDirectionEnum.DESC,
		take: 25,
		skip: 0
	};
};

const initialState: EmiMaintenanceStore = {
	[MaintenanceStatus.COMPLETED]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: getPaginationParams(MaintenanceStatus.COMPLETED),
		firstLoad: true
	},
	[MaintenanceStatus.ACTIVE]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: getPaginationParams(MaintenanceStatus.ACTIVE),
		firstLoad: true
	},
	[MaintenanceStatus.UPCOMING]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: getPaginationParams(MaintenanceStatus.UPCOMING),
		firstLoad: true
	}
};

const slice = createSlice({
	name: 'emiMaintenance',
	initialState,
	reducers: {
		setMaintenances(state, action: PayloadAction<{ status: MaintenanceStatus, list: Array<Maintenance> }>) {
			state[action.payload.status].list = action.payload.list;
		},
		setMaintenancesLoading: (state, action: PayloadAction<{ status: MaintenanceStatus, loading: boolean }>) => {
			state[action.payload.status].loading = action.payload.loading
		},
		setMaintenancesError: (state, action: PayloadAction<{ status: MaintenanceStatus, error: string }>) => {
			state[action.payload.status].error = action.payload.error;
		},
		setMaintenancesCount: (state, action: PayloadAction<{ status: MaintenanceStatus, count: number }>) => {
			state[action.payload.status].count = action.payload.count;
		},
		setMaintenancesPagination: (state, action: PayloadAction<{ status: MaintenanceStatus, pagination: PaginateParams }>) => {
			state[action.payload.status].pagination = action.payload.pagination;
		},
		setFirstLoad: (state, action: PayloadAction<{ status: MaintenanceStatus, firstLoad: boolean }>) => {
			state[action.payload.status].firstLoad = action.payload.firstLoad;
		}
	}
});

export const {
	setMaintenances,
	setMaintenancesLoading,
	setMaintenancesError,
	setMaintenancesCount,
	setMaintenancesPagination,
	setFirstLoad
} = slice.actions;

export const getMaintenances = (status: MaintenanceStatus, payload: PaginateParams) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setMaintenancesLoading({ status: status, loading: true }));
			const response = await request.get('/api/console/settings/maintenance/list', {
				params: {
					filter: payload.filter + `;status=${status}`,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take,
				}
			});
			const { data } = response;
			dispatch(setMaintenances({ status: status, list: data.list }));
			dispatch(setMaintenancesCount({ status: status, count: data.count }));
			dispatch(setMaintenancesPagination({ status: status, pagination: payload }));
			dispatch(setFirstLoad({ status: status, firstLoad: false }));
		} catch (e) {
			showErrorNotification(e);
		} finally {
			dispatch(setMaintenancesLoading({ status: status, loading: false }));
		}
	};
};

export const getIntegrationProcessesOptions = (data: Array<Process>) => {
	const groupedIntegration = groupBy(filter(data, (item) => {
		return !item.disabled;
	}), 'integration');

	const integrationOptionTypes = Object.keys(groupedIntegration)
		.map(integrationKey => {
			const processes = groupedIntegration[integrationKey];
			const uniqueProcesses = Array.from(new Map(processes.map(process => [process['process'], process])).values());
			return {
				value: integrationKey,
				text: processes[0].integrationName ?? t('integrations.' + integrationKey, capitalCaseString(integrationKey)),
				enabled: true,
				subValues: uniqueProcesses.map(process => ({
					value: process.process,
					text: process.processName,
					enabled: true
				}))
			};
		});

	if (integrationOptionTypes.length === 1) return integrationOptionTypes[0].subValues;
	return integrationOptionTypes;
};

export const getEmiMaintenanceProcesses = async (type: ProcessesConfigTypeEnum, integration?: string, wlps?: Array<string>): Promise<IntegrationProcesses> => {
	const response = await request.get(`/api/console/settings/maintenance/processes`, {
		params: {
			type: type,
			...(integration && { integration: integration }),
			...(wlps && { wlps: wlps })
		}
	});
	return response?.data;
}

export const createUpdateMaintenance = async (payload: any) => {
	const response = await request.post(`/api/console/settings/maintenance/create-update`, payload);
	const { data } = response;
	return data;
};

export const applyMaintenance = async () => {
	const response = await request.post(`/api/console/settings/maintenance/apply`);
	const { data } = response;
	return data;
};

export default slice.reducer;
