import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import request from '../../services/request';
import { AddRolePayload, ChangeUserRolePayload, DeleteRolePayload, Role, RolePermission, RolesStore, UpdateRolePayload } from './rolesTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '@/store/store';
import { showErrorNotification } from '@/utils/errors';
import { AdminType } from '../user/types';

export const paginationParamsInit = {
    filter: '',
    orderBy: 'createdDate',
    sort: SortDirectionEnum.DESC,
    take: 15,
    skip: 0
};

const initialState: RolesStore = {
    openedSubTab: AdminType.EMI_ADMIN,
    [AdminType.EMI_ADMIN]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit,
    },
    [AdminType.WLP_ADMIN]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit,
    }
};

const slice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        setRoles(state, action: PayloadAction<{ adminType: AdminType, roles: Array<Role> }>) {
            state[action.payload.adminType].list = action.payload.roles;
        },
        setLoading: (state, action: PayloadAction<{ adminType: AdminType, loading: boolean }>) => {
            state[action.payload.adminType].loading = action.payload.loading;
        },
        setError: (state, action: PayloadAction<{ adminType: AdminType, error: string }>) => {
            state[action.payload.adminType].error = action.payload.error;
        },
        setCount: (state, action: PayloadAction<{ adminType: AdminType, count: number }>) => {
            state[action.payload.adminType].count = action.payload.count;
        },
        setPagination: (state, action: PayloadAction<{ adminType: AdminType, pagination: PaginateParams }>) => {
            state[action.payload.adminType].pagination = action.payload.pagination;
        },
        setSubTab(state, action: PayloadAction<AdminType>) {
            state.openedSubTab = action.payload;
        }
    }
});
export default slice.reducer;

export const { setRoles, setLoading, setError, setCount, setPagination, setSubTab } = slice.actions;

export const getRolesList = (payload: PaginateParams, adminType: AdminType) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ adminType, loading: true }));
            const response = await request.get(`/api/console/roles/list`, {
                params: {
                    adminType: adminType,
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setRoles({ adminType, roles: data.list }));
            dispatch(setCount({ adminType, count: data.count }));
            dispatch(setPagination({ adminType, pagination: payload }));
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLoading({ adminType, loading: false }));
        }
    };
};

export const getAllRoles = async () => {
    const response = await request.get(`/api/console/roles/all`);
    const { data } = response;
    return data;
};

export const getRolePermissionsByRoleId = async (roleId: number): Promise<Array<RolePermission>> => {
    const response = await request.get(`/api/console/roles/permissions/${roleId}`);
    const { data } = response;
    return data;
};

export const getPermissions = async (assignable: boolean) => {
    const response = await request.get(`/api/console/roles/permissions?assignable=${assignable}`);
    const { data } = response;
    return data;
};

export const createNewRoleWithPermissions = async (payload: AddRolePayload) => {
    const response = await request.post(`/api/console/roles/add`, payload);
    const { data } = response;
    return data;
};

export const updateRoleWithPermissions = async (payload: UpdateRolePayload) => {
    const response = await request.post(`/api/console/roles/update`, payload);
    const { data } = response;
    return data;
};

export const deleteRoleWithPermissions = async (payload: DeleteRolePayload) => {
    const response = await request.post(`/api/console/roles/delete`, payload);
    const { data } = response;
    return data;
};

export const changeUserRole = async (payload: ChangeUserRolePayload) => {
    const response = await request.post(`/api/console/roles/user/change`, payload);
    const { data } = response;
    return data;
};