import * as React from "react";
import { SvgIcon } from '@mui/material';
const DownloadExport = (props:any) => (
  <SvgIcon {...props}>
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 21h12M12 3v14m0 0 5-5m-5 5-5-5"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
export default DownloadExport;
