import { useNavigate } from 'react-router';
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';
import { GuardProps } from 'types';
import { useEffect } from 'react';


const GuestGuard = ({ children }: GuardProps) => {
    const { isAuthorized } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthorized) {
            navigate(DASHBOARD_PATH, { replace: true });
        }
    }, [isAuthorized, navigate]);

    return children;
};

export default GuestGuard;
