import { SvgIcon } from '@mui/material';

const SystemTransfer = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#2B2B2B" fillOpacity="0.06" />
            <g clipPath="url(#clip0_10686_68699)">
                <path d="M15.4244 15.4469L16.5687 12.014C17.5684 9.01511 18.0682 7.51564 17.2767 6.72413C16.4852 5.93262 14.9857 6.43244 11.9868 7.43208L8.5539 8.57638C6.13347 9.38318 4.92326 9.78659 4.57936 10.3782C4.25219 10.9409 4.25219 11.636 4.57936 12.1987C4.92326 12.7903 6.13347 13.1937 8.55389 14.0005C8.94253 14.13 9.13685 14.1948 9.29927 14.3035C9.45668 14.4089 9.5919 14.5441 9.69727 14.7015C9.806 14.864 9.87078 15.0583 10.0003 15.4469C10.8071 17.8673 11.2105 19.0775 11.8021 19.4215C12.3649 19.7486 13.0599 19.7486 13.6227 19.4215C14.2142 19.0776 14.6176 17.8673 15.4244 15.4469Z" stroke="#EFB930" strokeWidth="1.2" />
                <path d="M14.8598 9.14043L12.0527 11.9165" stroke="#EFB930" strokeWidth="1.2" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_10686_68699">
                    <rect width="16" height="16" fill="white" transform="translate(3 5)" />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);
export default SystemTransfer;