import { AnyAction, combineReducers, Reducer } from "redux";
import menuReducer from "@redux/menu/menuSlice";
import user from "@redux/user/userSlice";
import credentials from "@redux/user/credentialsSice";
import clients from "@redux/clients/clientsSlice";
import clientsMonitoring from "@redux/clientsMonitoring/clientsMonitoringSlice";
import userGroups from "@redux/userGroups/userGroupsSlice";
import accounts from "@redux/accounts/accountsSlice";
import transactions from "@redux/transactions/trasactionsSlice";
import snackbar from "@redux/snackbar/snackbarSlice";
import turnoverChart from "@redux/turnoverChar/turnoverChartSlice";
import cards from "@redux/cards/cardsSlice";
import transfers from "@redux/transfers/transfersSlice";
import vault from "@redux/vault/vaultSlice";
import referrals from "@redux/referrals/referralSlice";
import admins from "@redux/admins/adminSlice";
import activityLogs from "@redux/activityLogs/logsSlice";
import finance from "@redux/finance/financeSlice";
import emiFinance from "@redux/emiFinance/emiFinanceSlice";
import emiFinanceSystem from "@redux/emiFinanceSystem/emiFinanceSystemSlice";
import emiFinanceBanking from "@redux/emiFinanceBanking/emiFinanceBankingSlice";
import emiFinanceProfitAndLoss from "@redux/emiFinanceProfitAndLoss/emiFinanceProfitAndLossSlice";
import twoFa from "@redux/twoFa/twoFaSlice";
import maintenance from "@redux/maintenance/maintenanceSlice";
import dialog from "@redux/dialog/dialogSlice";
import userLimits from "@redux/limit/limitSlice";
import compliance from "@redux/compliance/complianceSlice";
import dataGrid from "@redux/dataGrid/dataGridSlice";
import businessCompliance from "@redux/businessCompliance/businessComplianceSlice";
import poaCompliance from "@/redux/poaCompliance/poaComplianceSlice";
import documentVerificationCompliance from "@redux/documentVerificationCompliance/documentVerificationComplianceSlice";
import risksCompliance from "@redux/risksCompliance/risksComplianceSlice";
import risksRevalidation from "@redux/risksRevalidation/risksRevalidationSlice";
import risksTransaction from "@redux/risksTransactions/risksTransactionSlice";
import userCompliance from "@redux/userCompliance/userComplianceSlice";
import userRiskGroups from "@redux/userRiskGroups/userRiskGroupsSlice";
import userRiskLevels from "@redux/userRiskLevels/userRiskLevelsSlice";
import emiFinanceTransfers from "@redux/emiFinanceTransfers/emiFinanceTransfersSlice";
import emiFinanceEarn from "@redux/emiFinanceEarn/emiFinanceEarnSlice";
import userEarn from "@redux/userEarn/userEarnSlice";
import sar from "@/redux/sar/sarSlice";
import reports from "@/redux/reports/reportsSlice";
import platforms from "@redux/platforms/platformsSlice";
import platformFees from "@redux/platformFees/platformFeesSlice";
import emiFinanceFees from "@redux/emiFinanceFees/emiFinanceFeesSlice";
import emiBilling from "@/redux/emiBilling/emiBillingSlice";
import emiFinanceCrypto from "@/redux/emiFinanceCrypto/emiFinanceCryptoSlice";
import emiFinanceCards from "@/redux/emiFinanceCards/emiFinanceCardsSlice";
import earn from "@/redux/earn/earnSlice";
import documentDetails from "@/redux/documentDetails/documentDetailsSlice";
import support from "@/redux/support/supportSlice";
import supportMessage from "@/redux/supportMessage/supportMessageSlice";
import platformInvoicesAndPayouts from "@/redux/platformInvoicesAndPayouts/platformInvoicesAndPayoutsSlice"
import configurationAndFees from "@/redux/configurationAndFees/configurationAndFeesSlice"
import wlpFees from "@/redux/wlpFees/wlpFeesSlice"
import earnSystemAccounts from "@/redux/earnSystemAccounts/earnSystemAccountsSlice"
import earnPendingRequests from "@/redux/earnPendingRequests/earnPendingRequestsSlice"
import earnInterestRepayments from "@/redux/earnInterestReayments/earnInterestRepaymentsSlice"
import platformsCountries from "@/redux/platformsCountries/platformsCountriesSlice"
import earnPlannedTransferDetails from "@/redux/earnPlannedTransferDetails/earnPlannedTransferDetailsSlice"
import earnVaultPlanDeposits from "@/redux/earnVaultPlanDeposits/earnVaultPlanDepositsSlice"
import earnVaultPlanDepositPaymentSchedule from "@/redux/earnVaultPlanDepositPaymentSchedule/earnVaultPlanDepositPaymentScheduleSlice"
import earnDynamicPayments from "@/redux/earnDynamicPayments/earnDynamicPaymentsSlice"
import emiMaintenance from "@/redux/emiMaintenance/emiMaintenanceSlice"
import emiSettings from "@/redux/emiSettings/emiSettingsSlice"
import userFees from "@/redux/userFees/userFeesSlice"
import earnPlanChangeRequests from "@/redux/earnPlanChangeRequests/earnPlanChangeRequestsSlice"
import cardTransactions from "@/redux/cardTransactions/cardTransactionsSlice"
import pendingActivities from "@/redux/pendingActivities/pendingActivitiesSlice"
import currencies from "@/redux/currencies/currenciesSlice"
import emiUserGroupFees from "@/redux/emiUserGroupFees/emiUserGroupFeesSlice"
import emiUserLevelFees from "@/redux/emiUserLevelFees/emiUserLevelFeesSlice"
import wlpUserGroupFees from "@/redux/wlpUserGroupFees/wlpUserGroupFeesSlice"
import wlpUserLevelFees from "@/redux/wlpUserLevelFees/wlpUserLevelFeesSlice"
import userAccounts from "@/redux/userAccounts/userAccountsSlice"
import tokens from "@/redux/tokens/tokensSlice"
import tokensAccounts from "@/redux/tokensAccounts/tokensAccountsSlice"
import tokensTransactions from "@/redux/tokensTransactions/tokensTransactionsSlice"
import tokensSystemAccount from "@/redux/tokensSystemAccounts/tokensSystemAccountsSlice"
import tokensIssuance from "@/redux/tokensIssuance/tokensIssuanceSlice"
import tokensFileDetails from "@/redux/tokensFileDetails/tokensFileDetailsSlice"
import tokensExchangeRequests from "@/redux/tokensExchangeRequests/tokensExchangeRequestsSlice"
import tokensTradesRequests from "@/redux/tokensTradesRequests/tokensTradesRequestsSlice"
import tokensWithdrawalRequests from "@/redux/tokensWithdrawalRequests/tokensWithdrawalRequestsSlice"
import userGroupClients from "@/redux/userGroupClients/userGroupClientsSlice"
import platformLimits from "@/redux/platformLimits/platformLimitsSlice"
import userLevels from "@/redux/userLevels/userLevelsSlice"
import roles from "@/redux/roles/rolesSlice"
import userGroupLimits from "@/redux/userGroupLimits/userGroupLimitsSlice"
import userLevelLimits from "@/redux/userLevelLimits/userLevelLimitsSlice"
import shCardTopupTransactions from "@/redux/shCardTopupTransactions/shCardTopupTransactionsSlice"
import userFeesOverrides from "@/redux/userFeeOverrides/userFeeOverridesSlice"
import financialConfigurations from "@/redux/financialConfigurations/financialConfigurationsSlice"
import taxRates from "@/redux/taxRates/taxRatesSlice"
import fileUploads from "@/redux/fileUploads/fileUploadsSlice"
import merchants from "@/redux/merchants/merchantsSlice"
import merchantDetails from "@/redux/merchantDetails/merchantDetailsSlice"
import merchantUserDetails from "@/redux/merchantUserDetails/merchantUserDetailsSlice"


const appReducer = combineReducers({
  menu: menuReducer,
  accounts,
  activityLogs,
  admins,
  businessCompliance,
  cards,
  cardTransactions,
  clients,
  clientsMonitoring,
  compliance,
  configurationAndFees,
  credentials,
  currencies,
  dataGrid,
  dialog,
  documentDetails,
  documentVerificationCompliance,
  earnPendingRequests,
  earnSystemAccounts,
  earn,
  emiBilling,
  emiFinance,
  emiFinanceBanking,
  emiFinanceCards,
  emiFinanceCrypto,
  emiFinanceEarn,
  emiFinanceFees,
  emiFinanceProfitAndLoss,
  emiFinanceSystem,
  emiFinanceTransfers,
  emiMaintenance,
  emiSettings,
  emiUserGroupFees,
  emiUserLevelFees,
  fileUploads,
  finance,
  financialConfigurations,
  maintenance,
  merchants,
  merchantDetails,
  merchantUserDetails,
  pendingActivities,
  platformsCountries,
  platformFees,
  platformInvoicesAndPayouts,
  platformLimits,
  platforms,
  poaCompliance,
  reports,
  referrals,
  risksCompliance,
  risksRevalidation,
  risksTransaction,
  roles,
  sar,
  snackbar,
  support,
  supportMessage,
  taxRates,
  transactions,
  transfers,
  turnoverChart,
  twoFa,
  user,
  userAccounts,
  userCompliance,
  userEarn,
  userFees,
  userGroups,
  userLevels,
  userLimits,
  userRiskGroups,
  userRiskLevels,
  userGroupClients,
  userGroupLimits,
  userLevelLimits,
  vault,
  wlpFees,
  earnInterestRepayments,
  earnPlannedTransferDetails,
  earnVaultPlanDeposits,
  earnVaultPlanDepositPaymentSchedule,
  earnDynamicPayments,
  earnPlanChangeRequests,
  tokens,
  tokensAccounts,
  tokensTransactions,
  tokensSystemAccount,
  tokensIssuance,
  tokensFileDetails,
  wlpUserGroupFees,
  wlpUserLevelFees,
  tokensExchangeRequests,
  tokensTradesRequests,
  tokensWithdrawalRequests,
  shCardTopupTransactions,
  userFeesOverrides
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "RESET_APP") {
    const { credentials, user } = state;
    const cleanedCredendials = { ...credentials, token: null, email: null, agent: undefined, expireTime: undefined, wlp: undefined };
    const cleanedUser = { ...user, emiSections: [], permissions: [] };

    state = {} as RootState;
    state = {
      ...state,
      credentials: cleanedCredendials,
      user: cleanedUser
    };
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default rootReducer;
