import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const defaultErrorMessage = 'Something went wrong';
const defaultSuccess = 'Success';

interface DialogStatusStore {
    showResult: boolean,
    result: {
        success: boolean,
        message: string,
    },
    loading: boolean,
    loadingTime: number
}

const initialState: DialogStatusStore = {
    showResult: false,
    result: {
        success: false,
        message: '',
    },
    loading: false,
    loadingTime: 0
};

const menu = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        setSuccess(state, action: PayloadAction<string | undefined>) {
            state.showResult = true;
            state.result.success = true;
            state.result.message = action.payload ? action.payload : defaultSuccess;
        },
        setError(state, action: PayloadAction<string | undefined>) {
            state.showResult = true;
            state.result.success = false;
            state.result.message = action.payload ? action.payload : defaultErrorMessage;
        },
        setLoadingTime(state, action: PayloadAction<number>) {
            if (action.payload !== 0) {
                state.loading = true;
                state.loadingTime = action.payload ? action.payload : 0;
            } else {
                state.loading = false;
                state.loadingTime = 0;
            }
        },
        setLoadingDialog(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setClean(state) {
            state.showResult = false;
            state.result.message = '';
        }
    }
});

export default menu.reducer;

export const { setSuccess, setError, setClean, setLoadingTime, setLoadingDialog } = menu.actions;
