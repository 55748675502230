
import { Tabs as MuiTabs, styled } from "@mui/material";

const TabsButton = styled(MuiTabs)(
    ({ theme }) => ({
        minHeight: '1.875rem',
        '& .MuiTabs-indicator': {
            display: 'none',
        },
        '& .MuiTabs-flexContainer': {
            borderBottom: 'unset',
            columnGap: '10px'
        },

    }),
);

export default TabsButton;
