
import { ReactComponent as Loader } from "@assets/svgLoader.svg";
import { Box } from "@mui/material";
import { BoxProps } from "@mui/system";


const LoaderCircular = (props: BoxProps) => (
    <Box sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    }}
        {...props}>
        <Loader />
    </Box>
);

export default LoaderCircular;
