export enum SortDirectionEnum {
    DESC = 'DESC',
    ASC = 'ASC'
}

export interface PaginateParams {
    filter: string;
    orderBy: string;
    sort: SortDirectionEnum;
    take: number;
    skip: number;
}


export const defaultPagination: PaginateParams = {
    filter: '',
    orderBy: 'createdDate',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};