import * as React from "react";
import { SvgIcon } from '@mui/material';

const Apps = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path opacity="0.5" d="M11.25 12.9154C11.25 11.344 11.25 10.5583 11.7382 10.0702C12.2263 9.58203 13.012 9.58203 14.5833 9.58203C16.1547 9.58203 16.9404 9.58203 17.4285 10.0702C17.9167 10.5583 17.9167 11.344 17.9167 12.9154V14.582C17.9167 16.1534 17.9167 16.9391 17.4285 17.4272C16.9404 17.9154 16.1547 17.9154 14.5833 17.9154C13.012 17.9154 12.2263 17.9154 11.7382 17.4272C11.25 16.9391 11.25 16.1534 11.25 14.582V12.9154Z" stroke="currentColor" strokeWidth="1.2" />
            <path d="M1.66602 7.08203C1.66602 8.65338 1.66602 9.43905 2.15417 9.92721C2.64233 10.4154 3.428 10.4154 4.99935 10.4154C6.5707 10.4154 7.35637 10.4154 7.84453 9.92721C8.33268 9.43905 8.33268 8.65338 8.33268 7.08203V5.41536C8.33268 3.84402 8.33268 3.05834 7.84453 2.57019C7.35637 2.08203 6.5707 2.08203 4.99935 2.08203C3.428 2.08203 2.64233 2.08203 2.15417 2.57019C1.66602 3.05834 1.66602 3.84402 1.66602 5.41536V7.08203Z" stroke="currentColor" strokeWidth="1.2" />
            <path d="M11.25 4.58203C11.25 3.80546 11.25 3.41718 11.3769 3.11089C11.546 2.70251 11.8705 2.37806 12.2789 2.2089C12.5851 2.08203 12.9734 2.08203 13.75 2.08203H15.4167C16.1932 2.08203 16.5815 2.08203 16.8878 2.2089C17.2962 2.37806 17.6206 2.70251 17.7898 3.11089C17.9167 3.41718 17.9167 3.80546 17.9167 4.58203C17.9167 5.3586 17.9167 5.74689 17.7898 6.05317C17.6206 6.46155 17.2962 6.78601 16.8878 6.95516C16.5815 7.08203 16.1932 7.08203 15.4167 7.08203H13.75C12.9734 7.08203 12.5851 7.08203 12.2789 6.95516C11.8705 6.78601 11.546 6.46155 11.3769 6.05317C11.25 5.74689 11.25 5.3586 11.25 4.58203Z" stroke="currentColor" strokeWidth="1.2" />
            <path opacity="0.5" d="M1.66602 15.418C1.66602 16.1945 1.66602 16.5828 1.79288 16.8891C1.96204 17.2975 2.2865 17.6219 2.69488 17.7911C3.00116 17.918 3.38945 17.918 4.16602 17.918H5.83268C6.60925 17.918 6.99754 17.918 7.30382 17.7911C7.7122 17.6219 8.03666 17.2975 8.20581 16.8891C8.33268 16.5828 8.33268 16.1945 8.33268 15.418C8.33268 14.6414 8.33268 14.2531 8.20581 13.9468C8.03666 13.5384 7.7122 13.214 7.30382 13.0448C6.99754 12.918 6.60925 12.918 5.83268 12.918H4.16602C3.38945 12.918 3.00116 12.918 2.69488 13.0448C2.2865 13.214 1.96204 13.5384 1.79288 13.9468C1.66602 14.2531 1.66602 14.6414 1.66602 15.418Z" stroke="currentColor" strokeWidth="1.2" />
        </svg>
    </SvgIcon>
);
export default Apps;