// material-ui
import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import defaultColor from 'assets/scss/_themes-vars.module.scss';
import { ColorProps } from 'types';

const Palette = (navType: PaletteMode) => {
    let colors: ColorProps = defaultColor;

    return createTheme({
        palette: {
            mode: navType,
            common: {
                black: colors.darkPaper
            },
            primary: {
                light: colors.primaryLight,
                main: colors.primaryMain,
                dark: colors.primaryDark,
                contrastText: colors.primaryLight,
            },
            secondary: {
                light: colors.secondaryLight,
                main: colors.secondaryMain,
                dark: colors.secondaryDark,
                contrastText: colors.secondaryDark
            },
            error: {
                light: colors.errorLight,
                main: colors.errorMain,
                dark: colors.errorDark
            },
            warning: {
                light: colors.warningLight,
                main: colors.warningMain,
                dark: colors.warningDark
            },
            success: {
                light: colors.successLight,
                main: colors.successMain,
                dark: colors.successDark
            },
            orange: {
                light: colors.orangeMain,
                main: colors.orangeMain
            },
            grey: {
                50: colors.grey50,
                100: colors.grey100,
                500: colors.grey500,
                600: colors.grey900,
                700: colors.grey700,
                900: colors.grey900
            },
            dark: {
                light: colors.darkTextPrimary,
                main: colors.darkLevel1,
                dark: colors.darkLevel2,
                800: colors.darkBackground,
                900: colors.darkPaper
            },
            text: {
                primary: colors.textPrimary,
                secondary: colors.textSecondary,
                dark: colors.grey900,
                hint: colors.grey100
            },
            white: {
                light: colors.whiteLight,
                main: colors.whiteMain,
                dark: colors.whiteMain
            },
            divider: 'rgba(43, 43, 43, 0.06)',
            background: {
                paper: colors.paper,
                default: colors.paper
            },
            grey1: colors.grey1,
            grey2: colors.grey2,
            blue: colors.blue,
            blue2: colors.blue2,
            ligthBlue: colors.ligthBlue,
            yellow: colors.yellowMain,
            green: colors.greenMain,
            darkGrey: colors.darkGrey,
            lightGrey: colors.lightGrey,
            transparentBlack: colors.transparentBlack,
            transparentGreen: colors.transparentGreen,
            disabledGreen: colors.disabledGreen,
        }
    });
};

export default Palette;
