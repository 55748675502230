import { SyntheticEvent } from 'react';
import { Alert, Fade, Grow, IconButton, Slide, SlideProps } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';

import { KeyedObject } from 'types';
import { useDispatch, useSelector } from '@/store/store';
import { closeSnackbar } from '@/redux/snackbar/snackbarSlice';
import XClose from '@/icons/XClose';

// animation function
function TransitionSlideLeft(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
  return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
  return <Grow {...props} />;
}

const animation: KeyedObject = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade
};


const Snackbar = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.snackbar);
  const { anchorOrigin, alert, message, open, transition } = snackbar;

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeSnackbar());
  };

  return (
    <>
      <MuiSnackbar
        TransitionComponent={animation[transition]}
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          variant={alert.variant}
          color={alert.color}
          action={
            <>
              <IconButton
                size="small" aria-label="close" onClick={handleClose}>
                <XClose />
              </IconButton>
            </>
          }
        >
          {message}
        </Alert>
      </MuiSnackbar>
    </>
  );
};

export default Snackbar;