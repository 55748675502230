import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { Maintenance, MaintenanceStatus, MaintenanceStore } from './maintenanceType';
import { AppDispatch } from '@/store/store';

const initialState: MaintenanceStore = {
	loading: true,
	list: [],
	isFullyDown: false,
	isPartiallyDown: false,
	isPlanned: false,
	error: null,
	isCryptoDown: false,
	isTokenDown: false,
};

const slice = createSlice({
	name: 'maintenance',
	initialState,
	reducers: {
		setMaintenanceList(state, action: PayloadAction<Maintenance[]>) {
			state.list = action.payload;
			state.isFullyDown = action.payload.some(p => p?.integration === 'ALL' && p.status === MaintenanceStatus.ACTIVE);
			state.isPartiallyDown = action.payload.some(p => p.status === MaintenanceStatus.ACTIVE);
			state.isPlanned = action.payload.some(p => p.status === MaintenanceStatus.UPCOMING);
			state.isCryptoDown = action.payload.some(p => p.status === MaintenanceStatus.ACTIVE && p.integration === 'FIREBLOCKS');
			state.isTokenDown = action.payload.some(p => p.status === MaintenanceStatus.ACTIVE && p.integration === 'TOKENS');
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		}
	}
});

export const {
	setMaintenanceList,
	setLoading,
	setError
} = slice.actions;

export const getMaintenance = (status?: MaintenanceStatus) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/console/maintenance?status=ACTIVE', { params: { status: status } });
			if (response?.data) {
				dispatch(setMaintenanceList(response.data));
			}
		} catch (e) {
			dispatch(setError('Failed to get maintenance status'));
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const isIntegrationDown = (list: Maintenance[], integration: string) => {
	if (!integration) return false;
	return list.some(p => p.status === MaintenanceStatus.ACTIVE && p.integration === integration);
};



export const isProcessDown = (proc: any) => {
	if (!proc) return false;
	return proc?.maintenance ? proc.maintenance : false;
};

export const isDown = (list: Maintenance[], process: any) => {
	return isIntegrationDown(list, process?.integration) || isProcessDown(process);

};



export default slice.reducer;
