import { FilterOptionType } from "@/components/dataGrid/CustomFilterHeader";
import { PaginateParams } from "@/types/pagination"

export interface EmiBillingStore {
    payoutsSummary: PayoutsSummary | null;
    invoicesSummary: InvoicesSummary | null;
    platformsSummary: PlatformsSummary | null;
    withdrawalsSumamry: WithdrawalsSummary | null;
    platforms: Array<Platform>;
    invoices: {
        loading: boolean,
        list: Array<EmiInvoice>,
        error: string | null,
        count: number,
        pagination: PaginateParams
        filters: {
            [key: string]: Array<FilterOptionType>
        },
        filtersSet: boolean;
    },
    payouts: {
        loading: boolean,
        list: Array<EmiPayout>,
        error: string | null,
        count: number,
        pagination: PaginateParams,
        filters: {
            [key: string]: Array<FilterOptionType>
        },
        filtersSet: boolean;
    },
    withdrawals: {
        loading: boolean,
        list: Array<PayoutWithdrawal>,
        error: string | null,
        count: number,
        pagination: PaginateParams,
        filters: {
            [key: string]: Array<FilterOptionType>
        },
        filtersSet: boolean;
    },
    platformInvoicesAndPayouts: {
        loading: boolean,
        list: Array<PlatformInvoice>,
        error: string | null,
        count: number,
        pagination: PaginateParams
    },
    loadingSummaries: {
        payoutsSummary: boolean,
        invoicesSummary: boolean,
        platformsSummary: boolean,
        withdrawalsSumamry: boolean
    }
};

export interface PayoutsSummary {
    totalFeePayouts: number;
    unpaidInvoicesAmount: number;
    unpaidPayoutsCount: number;
    amountPendingPayouts: number;
};

export interface InvoicesSummary {
    totalEarnings: number;
    unpaidInvoicesAmount: number;
    unpaidInvoicesCount: number;
};

export interface PlatformsSummary {
    totalPlatformFeesAmount: number;
    serviceFeesAmount: number;
    maintenanceFeesAmount: number;
    activePlatformsCount: number;
};

export interface WithdrawalsSummary {
    totalWithdrawalAmount: number;
    feeSystemAccountBalance: number;
};

export interface Platform {
    platformWlpId: string;
    platformName: string;
    serviceAndSoftwareInvoicesAmount: number;
    feeWithdrawalsAmount: number;
    payoutsAmount: number;
};

export interface EmiInvoice {
    invoiceId: number;
    invoiceNumber: string;
    legalNumber: string;
    ownerType: string;
    ownerWlpId: string;
    invoiceSide: InvoiceSideEnum;
    invoiceCategory: string;
    invoiceType: string;
    partyId: number;
    partyName: string;
    partyBusinessUserId: number;
    partyBusinessUserName: string;
    invoiceDate: string;
    issueDate: string;
    issueStatus: InvoiceStatusEnum;
    paymentStatus: InvoicePaymentStatusEnum;
    currencyCode: string;
    totalAmount: number;
    wlpId: string;
};
export interface EmiPayout {
    payoutId: number;
    payoutNumber: string;
    legalNumber: string;
    ownerType: string;
    ownerWlpId: string;
    payoutSide: InvoiceSideEnum;
    payoutCategory: string;
    payoutType: string;
    partyId: number;
    partyName: string;
    partyBusinessUserId: number;
    partyBusinessUserName: string;
    payoutDate: string;
    issueDate: string;
    payoutStatus: InvoiceStatusEnum;
    issueStatus: string;
    paymentStatus: InvoicePaymentStatusEnum;
    currencyCode: string;
    totalAmount: number;
    referenceAmount: number;
    wlpId: string;
    payoutDocuments: Array<PayoutDocument>;
};

export interface PayoutDocument {
    documentId: number;
    documentNumber: string;
}

export enum InvoiceSideEnum {
    RECEIVABLE = 'RECEIVABLE',
    PAYABLE = 'PAYABLE'
};

export interface FeePayoutData {
    invoiceId: number;
    feeCategory: string;
    transferFrom: string;
    transferTo: string;
    feeFrom: string;
    feeTo: string;
    currencyCode: string;
    withdrawalAmount: number;
    paymentAmount: number;
    paymentLineDescription: string;
    selected: boolean;
};

export interface PayoutInvoice {
    invoiceId: number;
    feeCategory: string;
    feeFrom: string;
    feeTo: string;
    currencyCode: string;
    withdrawalAmount: number;
    paymentAmount: number;
};

export interface CreatePayoutPayload {
    invoiceId: number;
    paymentConfigId: number;
    payoutInvoices: Array<PayoutInvoice>;
}

export interface PayoutWithdrawal {
    id: number;
    emi: string;
    wlpId: string;
    integration: string;
    feeAccountId: number;
    feeCategoryCode: string;
    transferSetId: string;
    transferDocId: number;
    transferDate: string;
    transferDocNumber: string;
    dateFrom: string;
    dateTo: string;
    currencyCode: string;
    amount: number;
    tradeDocumentId: number;
    tradeToCurrencyCode: string;
    tradeToAmount: number;
    withdrawalCurrencyCode: string;
    withdrawalAmount: number;
    payoutInvoiceNumList: string;
};

export enum PlatformInvoiceStatusEnum {
    UNPAID = 'UNPAID',
    PAID = 'PAID',
    PARTIAL = 'PARTIAL'
};

export interface PlatformInvoice {
    id: number;
    number: string;
    date: string;
    category: InvoiceCategoryEnum;
    type: string;
    side: string;
    currencyCode: string;
    amount: number;
    status: string;
};

export enum InvoiceCategoryEnum {
    INVOICE = "INVOICE",
    PAYOUT = "PAYOUT",
};

export enum InvoiceTypeEnum {
    SOFTWARE_INVOICE = "SOFTWARE_INVOICE",
    MANUAL_INVOICE = "MANUAL_INVOICE",
    SERVICE_INVOICE = "SERVICE_INVOICE"
};

export enum BillingHistoryEnum {
    INVOICE = "INVOICE",
    FEE_PAYOUT = "FEE_PAYOUT",
    FEE_WITHDRAWAL = "FEE_WITHDRAWAL"
};

export interface AddLinePayload {
    wlpInvoiceId: number;
    itemCode: string;
    lineDescription: string;
    currencyCode: string;
    price: number;
    unit: string | null;
    quantity: number;
    taxCode: number;
    taxRate: number;
};

export interface InvoiceDetails {
    invoiceId: number;
    invoiceNumber: string;
    legalNumber: string;
    ownerType: string;
    ownerWlpId: string;
    invoiceSide: InvoiceSideEnum;
    invoiceCategory: InvoiceCategoryEnum;
    invoiceType: InvoiceTypeEnum;
    wlpInvoiceOwnerParty: InvoiceParty;
    wlpInvoiceParty: InvoiceParty;
    wlpInvoiceLines: Array<InvoiceLine>;
    partyName: string;
    invoiceDate: string;
    issueDate: string;
    dueDate: string;
    invoiceStatus: string;
    issueStatus: string;
    paymentStatus: InvoicePaymentStatusEnum;
    currencyCode: string;
    currencyLogo: string;
    totalAmount: number;
    createdById: number;
    createdByName: string;
    lastUpdatedById: number;
    lastUpdatedByName: string;
    referenceAmount: number;
    logoUrl: string;
    wlpInvoicePayments: Array<WlpInvoicePayment>;
};

export interface WlpInvoicePayment {
    id: number;
    invoiceId: number;
    documentId: number;
    documentNumber: string;
    paymentType: string;
    ccy: string;
    amount: number;
    description: string;
    createdBy: number;
    updatedBy: number;
};

export interface InvoicePayment {
    id: number;
    invoiceId: number;
    documentId: number;
    documentNumber: string;
    paymentType: string;
    ccy: string;
    amount: number;
    description: string;
    createdBy: number;
    updatedBy: number;
};

export interface InvoiceParty {
    id: number;
    ownerType: string;
    ownerValue: string;
    partyUserId: number;
    partyName: string;
    partyRole: InvoicePartyRoleEnum;
    regNumber: string;
    country: string;
    invoiceAddress: string;
    invoiceCountry: string;
    invoicePostalCode: string;
    emiWlpId: string;
    wlpId: string;
    payable: boolean;
    receivable: boolean;
    taxCountry: string;
    taxCountryRegion: string;
    logoUrl?: string;
};

export enum LineTypeEnum {
    LINE = "LINE",
    DETAIL = "DETAIL"
};

export interface InvoiceLine {
    id: number;
    itemCode: string;
    lineType: LineTypeEnum;
    lineDescription: string;
    currencyCode: string;
    price: number;
    unit: string;
    quantity: number;
    totalAmountWithoutTax: number;
    taxCode: number;
    taxRate: number;
    totalTaxAmount: number;
    totalAmount: number;
    transferAmount: number;
    transferCcy: string;
};

export enum InvoicePartyRoleEnum {
    EMI = 'EMI',
    WLP = 'WLP',
    PARTNER = 'PARTNER',
    LEGAL_ENTITY = 'LEGAL_ENTITY',
    THIRD_PARTY = 'THIRD_PARTY'
};

export const partyRoleOrder = {
    [InvoicePartyRoleEnum.LEGAL_ENTITY]: 1,
    [InvoicePartyRoleEnum.EMI]: 2,
    [InvoicePartyRoleEnum.WLP]: 3,
    [InvoicePartyRoleEnum.PARTNER]: 4,
    [InvoicePartyRoleEnum.THIRD_PARTY]: 5
};

export enum OwnerTypeEnum {
    EMI = 'EMI',
    WLP = 'WLP'
};

export enum InvoiceStatusEnum {
    NEW = 'NEW',
    DRAFT = 'DRAFT',
    COMPLETED = 'COMPLETED',
    PAYMENT_IN_PROCESS = 'PAYMENT_IN_PROCESS'
};

export enum InvoiceIssueEnum {
    NEW = 'NEW',
    SEND = 'SEND'
};

export enum InvoicePaymentStatusEnum {
    PAID = 'PAID',
    PARTLY_PAID = 'PARTLY_PAID',
    NOT_PAID = 'NOT_PAID'
};

export interface CreateInvoiceInvoicePayload {
    ownerType: string;
    ownerWlpId: string;
    invoiceSide: string;
    invoiceCategory: string;
    invoiceType: string;
    wlpInvoicePartyId: number;
    wlpInvoiceLines: Array<Line>;
    invoiceDate: string;
    dueDate: string;
    issueStatus: string;
    currencyCode: string;
};

interface Line {
    wlpInvoiceId: number;
    itemCode: string;
    lineDescription: string;
    currencyCode: string;
    price: number;
    unit: string;
    quantity: number;
    taxCode: number;
    taxRate: number;
};

export interface CreateInvoicePayload {
    invoiceSide: InvoiceSideEnum;
    invoiceCategory: InvoiceCategoryEnum;
    invoiceType: InvoiceTypeEnum;
    ownerPartyId: number;
    wlpInvoicePartyId: number;
    invoiceDate: string;
    dueDate: string;
    issueStatus: string;
    currencyCode: string;
    wlpInvoiceLines: Array<InvoiceDetailsLine>;
};

interface InvoiceDetailsLine {
    itemCode: string;
    lineDescription: string;
    currencyCode: string;
    price: number;
    unit: string | null;
    quantity: number;
    taxCode: number;
    taxRate: number;
};

export interface UpdateInvoicePayload extends CreateInvoicePayload {
    invoiceId: number;
};

export interface SendInvoicePayload {
    invoiceId: number;
    issueDate: string;
};

export interface ConfirmInvoicePayload {
    invoiceId: number;
};

export interface CreateAutomaticInvoicePayload {
    ownerPartyId: number;
    invoiceSide: string;
    invoiceCategory: string;
    invoiceType: string;
    wlpInvoicePartyId: number;
    invoiceDate: string;
    dueDate: string;
    currencyCode: string;
    dataFrom: string;
    dateTo: string;
};

export interface InvoiceManualPaymentPayload {
    invoiceId: number;
    paymentDate: string;
    documentNumber?: string;
    currencyCode: string;
    paymentAmount: number;
    paymentDetails: string;
};

export enum EmiBillingTabEnum {
    INVOICES = 'INVOICES',
    PAYOUTS = 'PAYOUTS',
    WITHDRAWALS = 'WITHDRAWALS'
};