import { PaginateParams } from "@/types/pagination";

export interface PendingActivitiesStore {
    [key: string]: {
        loading: boolean;
        list: Array<Activity>;
        error: string | null;
        count: number;
        pagination: PaginateParams;
        storedUserId: number | null;
        storedAccountId?: number | null;
    }
};

export enum PendingActivitiesEnum {
    USER = 'USER',
    USER_CARD = 'USER_CARD'
};

export interface Activity {
    id: number;
    requestId: string;
    status: string;
    proc: string;
    meta: {
        ccy: string;
        data: {
            ccy: string;
            proc: string;
            details: string;
            accountId: number;
            fiatAmount: string;
            isToBusiness: boolean;
            saveToContacts: boolean;
            beneficiaryIban: string;
            beneficiaryName: string;
            beneficiaryBankName: string;
            beneficiaryBicSwift: string;
            beneficiaryIdentityNo: string;
            beneficiaryBankAddress: string;
            beneficiaryBankCountry: string;
        };
        token: string;
        chainId: number;
        details: string;
        nonceHash: string;
        amountBase: string;
        accessToken: {
            r: string;
            s: string;
            v: number;
        };
        tokenNetwork: string;
        sourceAddress: string;
        amountFieldName: string;
        contractAddress: string;
        requestedAmount: string;
        conversionAccountId: number;
    };
    requestIdHash: string;
    action: string;
    createdDate: string;
    restartable: boolean;
};