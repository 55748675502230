import { ForwardRefExoticComponent, RefAttributes, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography, useMediaQuery } from '@mui/material';
import useConfig from 'hooks/useConfig';
import { useDispatch, useSelector } from '@/store/store';
import { activeItem } from '@redux/menu/menuSlice';
import { LinkTarget, NavItemType } from 'types';

interface NavItemProps {
    item: NavItemType;
    open: boolean;
    onClick?: () => void
}

const NavItem = ({ item, open, onClick }: NavItemProps) => {
    const theme = useTheme();
    const matcheDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    const { borderRadius } = useConfig();
    const dispatch = useDispatch();
    const { openItem } = useSelector((state) => state.menu);

    const btnMargin = open ? 1 : matcheDownSm ? 0 : 1;

    let listItemProps: {
        component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: LinkTarget;
    } = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url!} />) };


    const itemHandler = (item: NavItemType) => {
        if (onClick) onClick();
        if (item.id !== "logout") dispatch(activeItem(item.id));
    };

    return (
        <ListItemButton
            {...listItemProps}
            sx={{
                borderRadius: `${borderRadius}px`,
                mx: btnMargin,
                alignItems: 'flex-start',
                justifyContent: open ? 'flex-start' : 'center',
            }}
            selected={openItem === item.id}
            onClick={() => itemHandler(item!)}
        >
            <Tooltip title={item.title} disableHoverListener={open} placement="right-end">
                <ListItemIcon sx={{ my: open ? 'auto' : '2.5px', minWidth: 'fit-content' }}>{item.icon}</ListItemIcon>
            </Tooltip>
            {open && <ListItemText
                primary={
                    <Typography variant='body1' ml={1}>
                        {item.title}
                    </Typography>
                }
            />
            }
        </ListItemButton>
    );
};

export default NavItem;
