import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { Account, AccountDetailsResponseType, AccountsStore, AccountStatusEnum, AccountSummary, AccountTypeEnum, SummaryTabType } from './accountsTypes';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { t } from '@/utils/translate';
import { AdjustmentType } from '../documentDetails/documentDetailsTypes';
import { Wlp } from '../user/types';
import { getWlpName } from '@/components/dataGrid/utils/Platform';
import { addFiles } from '@/utils/files';
import { UpdateBalancePayload } from '@/pages/accountDetails/UpdateTrustAccountBalanceForm';

export const paginationParamsInit = {
	filter: '',
	orderBy: 'balanceEur',
	sort: SortDirectionEnum.DESC,
	take: 15,
	skip: 0
};

const initialState: AccountsStore = {
	accountTabs: {
		[AccountTypeEnum.BANK_AND_WALLET]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.WLPS]: [],
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
		[AccountTypeEnum.BANK]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.WLPS]: [],
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
		[AccountTypeEnum.WALLET]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.WLPS]: [],
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
		[AccountTypeEnum.CRYPTO]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.WLPS]: [],
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
		[AccountTypeEnum.TRUST]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.WLPS]: [],
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
		[AccountTypeEnum.SYSTEM]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.WLPS]: [],
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
		[AccountTypeEnum.TOKEN]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.WLPS]: [],
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
		[AccountTypeEnum.VAULT]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.WLPS]: [],
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		}
	},
	accountSummaries: {
		[SummaryTabType.STANDARD]: {
			loading: true,
			summary: []
		},
		[SummaryTabType.CRYPTO]: {
			loading: true,
			summary: []
		},
	}
};


const slice = createSlice({
	name: 'accounts',
	initialState,
	reducers: {
		setAccounts(state, action: PayloadAction<{ type: AccountTypeEnum, list: Array<Account> }>) {
			state.accountTabs[action.payload.type].list = action.payload.list;
		},
		setLoading: (state, action: PayloadAction<{ type: AccountTypeEnum, loading: boolean }>) => {
			state.accountTabs[action.payload.type].loading = action.payload.loading
		},
		setError: (state, action: PayloadAction<{ type: AccountTypeEnum, error: string }>) => {
			state.accountTabs[action.payload.type].error = action.payload.error;
		},
		setCount: (state, action: PayloadAction<{ type: AccountTypeEnum, count: number }>) => {
			state.accountTabs[action.payload.type].count = action.payload.count;
		},
		setPagination: (state, action: PayloadAction<{ type: AccountTypeEnum, pagination: PaginateParams }>) => {
			state.accountTabs[action.payload.type].pagination = action.payload.pagination;
		},
		// Summary
		setAccountsSummary(state, action: PayloadAction<{ summaryTabType: SummaryTabType, list: Array<AccountSummary> }>) {
			state.accountSummaries[action.payload.summaryTabType].summary = action.payload.list;
		},
		setLoadingSummary: (state, action: PayloadAction<{ summaryTabType: SummaryTabType, loading: boolean }>) => {
			state.accountSummaries[action.payload.summaryTabType].loading = action.payload.loading;
		},
		// Filters
		setFilters: (state, action: PayloadAction<{ type: AccountTypeEnum, wlps: Array<Wlp>, currencies: Array<FilterOptionType> }>) => {
			const wlpsOptions = action.payload.wlps.map(item => ({
				value: item.wlpId,
				text: getWlpName(item.wlpId),
				enabled: true
			}));
			const statusOptions = getAccountStatusOptions();
			state.accountTabs[action.payload.type].filters[HeaderFilterType.WLPS] = wlpsOptions;
			state.accountTabs[action.payload.type].filters[HeaderFilterType.STATUS] = statusOptions;
			state.accountTabs[action.payload.type].filters[HeaderFilterType.CURRENCY] = action.payload.currencies;
			state.accountTabs[action.payload.type].filtersSet = true;
		},
		setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, type: AccountTypeEnum, options: Array<FilterOptionType> }>) => {
			state.accountTabs[action.payload.type].filters[action.payload.filterType] = action.payload.options;
		},
		setFiltersSet(state, action: PayloadAction<{ type: AccountTypeEnum, set: boolean }>) {
			state.accountTabs[action.payload.type].filtersSet = action.payload.set;
		}
	}
});

export const { setLoading, setError, setCount, setPagination, setAccounts, setLoadingSummary, setAccountsSummary, setFilters, setFilter, setFiltersSet } = slice.actions;

export const getAccounts = (type: AccountTypeEnum, payload: PaginateParams) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading({ type, loading: true }));
			const response = await request.get('/api/console/accounts/list', {
				params: {
					isSystem: false,
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take,
					...({ type: type === AccountTypeEnum.BANK_AND_WALLET ? 'BANK_AND_WALLET' : type })
				}
			});
			const { data } = response;
			dispatch(setAccounts({ type, list: data.list }));
			dispatch(setCount({ type, count: data.count }));
			dispatch(setPagination({ type, pagination: payload }));
			dispatch(setLoading({ type, loading: false }));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading({ type, loading: false }));
		}
	};
};

export const getAccountsSummary = (
	summaryTabType: SummaryTabType,
	accountTypes: Array<AccountTypeEnum>,
	includeSystemAccounts: boolean = false
) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoadingSummary({ summaryTabType, loading: true }));
			const response = await request.get('/api/console/accounts/tabs/summary', {
				params: {
					accountTypes: accountTypes.join(','),
					includeSystemAccounts: includeSystemAccounts
				}
			});
			const { data } = response;
			dispatch(setAccountsSummary({ summaryTabType, list: data }));
			return data;
		} catch (e) {
			showErrorNotification(e);
		} finally {
			dispatch(setLoadingSummary({ summaryTabType, loading: false }));
		}
	};
};

export const getAccount = async (accountId: number): Promise<AccountDetailsResponseType> => {
	const response = await request.get(`/api/console/accounts/${accountId}`);
	const { data } = response;
	return data;
};

export const getAddFeeInfo = async (adjustmentType: AdjustmentType, walletId: string) => {
	const response = await request.get(`/api/console/user-fees/adjustment/add-fee/info?accountWalletId=${walletId}&adjustmentType=${adjustmentType}`);
	const { data } = response;
	return data;
};

export const getBalancingAccounts = async (integration: string, payload: PaginateParams) => {
	const response = await request.get(`/api/console/accounts/balancing/${integration}`, {
		params: {
			filter: payload.filter,
			orderBy: payload.orderBy,
			sort: payload.sort.toUpperCase(),
			skip: payload.skip.toString(),
			take: payload.take,
		}
	});
	const { data } = response;
	return data;
};

export const getAccountStatusOptions = (): Array<FilterOptionType> => {
	return Object.keys(AccountStatusEnum)
		.filter(key => !key.startsWith('CARD'))
		.map(key => {
			return {
				value: key,
				text: t('enum.accountStatusEnum.' + key),
				enabled: true
			};
		});
};


export const getClientBusinessAccounts = async (userId: number) => {
	const response = await request.get(`/api/console/accounts/business/user/${userId}/accounts`);
	const { data } = response;
	return data;
};
export const getClientBusinessAccountCreationOptions = async (userId: number) => {
	const response = await request.get(`/api/console/accounts/create/business/options/${userId}`);
	const { data } = response;
	return data;
};

export const createClientBusinessAccount = async (payload: any) => {
	const response = await request.post(`/api/console/accounts/create/business`, payload);
	const { data } = response;
	return data;
};

export const createClientChildBusinessAccount = async (parentAccountId: number) => {
	const response = await request.post(`/api/console/accounts/create/child`, { parentAccountId });
	const { data } = response;
	return data;
};

export const getClientAccounts = async (wlpId: string, userId: number, ccy: string) => {
	const response = await request.get(`/api/console/accounts/list/user/${wlpId}/${userId}/${ccy}`);
	const { data } = response;
	return data;
};

export const changeAccountStatus = async (accountId: number, newStatus: 'ACTIVE' | 'BLOCKED', payload: any, files: File[]) => {
	const formData = new FormData();
	formData.append("userId", payload.userId.toString());
	formData.append("reason", payload.reason);
	formData.append("wlpId", payload.wlpId);

	addFiles(formData, files);

	const response = await request.post(`/api/console/accounts/${accountId}/status/${newStatus}`,
		formData,
		{ headers: { "Content-Type": "multipart/form-data" } }
	);
	const { data } = response;
	return data;
};


export const getAccountDetailsByWalletId = async (walletId: string): Promise<Account> => {
	const response = await request.get(`/api/console/accounts/details/wallet/${walletId}`);
	const { data } = response;
	return data;
};

export const updateAccountBalance = async (payload: UpdateBalancePayload) => {
	const response = await request.post(`/api/console/accounts/update-balance`, payload);
	const { data } = response;
	return data;
};

export default slice.reducer;
