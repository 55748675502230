import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { Transaction, TransactionsStore } from './transactionsTypes';

export const paginationParamsInit = {
	filter: '',
	orderBy: 'date',
	sort: SortDirectionEnum.DESC,
	take: 25,
	skip: 0
};

const initialState: TransactionsStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	pagination: paginationParamsInit
};


const slice = createSlice({
	name: 'transactions',
	initialState,
	reducers: {
		setTransactions(state, action: PayloadAction<Array<Transaction>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
			state.pagination = payload;
		}
	}
});

export const { setTransactions, setLoading, setError, setCount, setPagination } = slice.actions;

export const getTransactions = (payload: PaginateParams, walletId?: string,) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get(`/api/console/transactions/list`, {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setTransactions(data.list));
			dispatch(setCount(data.count));
			dispatch(setPagination(payload));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export default slice.reducer;
