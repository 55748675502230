import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { UserStore, LoginBody, UserError, MaintenanceMode, Permission, Credentials, Wlp, EmiSectionType } from './types';
import { setCreditials, clearCreditials, setTokenData } from './credentialsSice';
import { AppDispatch } from '@/store/store';
import { activeItem, clearMenu } from '../menu/menuSlice';
import { clearCurrencies, getCurrencies, getCurrenciesDecimals } from '../currencies/currenciesSlice';
import { addFiles } from '@/utils/files';

const permissionKey = process.env.REACT_APP_PERMISSIONS ?? 'scm_permissions';
const wlpKey = 'scm_wlps'
const sectionsKey = 'emi_sections';

const savedPermissions = sessionStorage.getItem(permissionKey);
const savedWlp = sessionStorage.getItem(wlpKey);
const savedSections = sessionStorage.getItem(sectionsKey);

const initialState: UserStore = {
	maintenanceMode: { mode: false, reason: '' },
	status: null,
	error: null,
	permissions: [],
	emiSections: [],
	wlps: [],
	twoFaEnabled: undefined,
};

if (savedPermissions) {
	try {
		const permissions = JSON.parse(savedPermissions);
		initialState.permissions = permissions.list;
	}
	catch (e) {
		console.log(e);
	}
};

if (savedWlp) {
	try {
		const wlps = JSON.parse(savedWlp);
		initialState.wlps = wlps.list;
	}
	catch (e) {
		console.log(e);
	}
};

if (savedSections) {
	try {
		const sections = JSON.parse(savedSections);
		initialState.emiSections = sections.list;
	}
	catch (e) {
		console.log(e);
	}
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setPermissions(state, action: PayloadAction<Array<Permission>>) {
			state.permissions = action.payload;
			sessionStorage.setItem(permissionKey, JSON.stringify({ list: action.payload }));
		},
		setSections(state, action: PayloadAction<Array<EmiSectionType>>) {
			state.emiSections = action.payload;
			sessionStorage.setItem(sectionsKey, JSON.stringify({ list: action.payload }));
		},
		setWlps(state, action: PayloadAction<Wlp[]>) {
			state.wlps = action.payload;
			sessionStorage.setItem(wlpKey, JSON.stringify({ list: action.payload }));
		},
		setUserTwoFa(state, action: PayloadAction<boolean>) {
			state.twoFaEnabled = action.payload;
		},
		updateMaintenance(state, action: PayloadAction<MaintenanceMode>) {
			state.maintenanceMode = action.payload;
		},
		clearPermissions(state) {
			state.permissions = [];
			sessionStorage.removeItem(permissionKey);
		},
		clearSections(state) {
			state.emiSections = [];
			sessionStorage.removeItem(sectionsKey);
		},
		clearWlps(state) {
			state.wlps = [];
			sessionStorage.removeItem(wlpKey);
		},
		clearStatus(state) {
			state.status = null;
		},
		setError(state, action: PayloadAction<UserError | null>) {
			state.error = action.payload;
		}
	}
});

export const {
	updateMaintenance,
	clearStatus,
	setError,
	setPermissions,
	setSections,
	clearPermissions,
	setWlps,
	clearSections,
	setUserTwoFa,
	clearWlps
} = userSlice.actions;

export const selectWlps = (state: { userStore: UserStore }) => state.userStore.wlps;

export const userSelector = (state: { userStore: UserStore }) =>
	state.userStore;

export const login = (payload: LoginBody) => {
	return async (dispatch: AppDispatch) => {
		const response = request.post('/api/console/auth/login',
			payload
		);
		const { data } = await response;

		const credentials: Credentials = {
			token: data.token,
			email: payload.email,
			isEmi: data.isEmiAdmin,
			wlp: data.wlp,
			userId: data.userId,
			ownerRole: data.ownerRole,
			userClientId: data.userClientId,
			userFirstName: data.userFirstName,
			userLastName: data.userLastName
		};

		dispatch(setCreditials(credentials));
		dispatch(activeItem('dashboard'));
		dispatch(setPermissions(data.permissions));
		dispatch(setSections(data.emiSections));
		dispatch(setWlps(data.wlps));
		dispatch(setUserTwoFa(data.twoFaEnabled));
		dispatch(getCurrencies())
		dispatch(getCurrenciesDecimals())
		dispatch(setError(null));
	}
};


export const logout = () => {
	return async (dispatch: AppDispatch) => {
		try {
			await request.get('/api/console/auth/logout');
			dispatch(clearCreditials());
			dispatch(clearCurrencies());
			dispatch(clearMenu());
		} catch (error) {
			console.log(error);
		}
	};
};

export const forgotPassword = (email: string) => {
	return async (dispatch: AppDispatch) => {
		await request.post('/api/console/auth/forgot-password', { email });
		dispatch(setError(null));
	};
};

export const resetPassword = (code: string, password: string) => {
	return async (dispatch: AppDispatch) => {
		await request.post('/api/console/auth/set-up-password', { code, password });
		dispatch(setError(null));
	};
};

export const refreshToken = () => {
	return async (dispatch: AppDispatch) => {
		const response = await request.post('/api/console/auth/refresh-token');
		const { data } = response;
		dispatch(setTokenData(data));
	};
};

export const extendSession = () => {
	return async (dispatch: AppDispatch) => {
		const response = await request.post('/api/console/auth/extend-session');
		const { data } = response;
		dispatch(setTokenData(data));
	};
};

export const dtsFileUpload = async (file: File[]) => {
	const formData = new FormData();

	addFiles(formData, file);

	const response = await request.post(
		"/api/console/user-dts/file",
		formData,
		{ headers: { "Content-Type": "multipart/form-data" } }
	);
	const { data } = response;
	return data;
};

export default userSlice.reducer;
