import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "@/store/store";
import request from "@/services/request";
import { AdjustmentType, AttachFilesPayload, DocumentDetails, DocumentStore } from "./documentDetailsTypes";
import { DocumentTypeWBH } from "../transfers/transfersTypes";
import { showErrorNotification } from "@/utils/errors";
import { getTranferList } from "../transfers/transfersSlice";
import { PaginateParams, SortDirectionEnum } from "@/types/pagination";
import { addFiles } from "@/utils/files";

export const paginationParamsInit: PaginateParams = {
    filter: '',
    orderBy: 'documentDate',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: DocumentStore = {
    document: null,
    documentLoading: false,
    accountDocuments: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: paginationParamsInit
    },
    storedWalletId: null
};

const slice = createSlice({
    name: 'documentDetails',
    initialState,
    reducers: {
        setDocument(state, action: PayloadAction<DocumentDetails | null>) {
            state.document = action.payload;
        },
        setLoadingDocument: (state, { payload }: PayloadAction<boolean>) => {
            state.documentLoading = payload;
        },
        // Account documents
        setDocuments(state, action: PayloadAction<Array<DocumentTypeWBH>>) {
            state.accountDocuments.list = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.accountDocuments.loading = action.payload
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.accountDocuments.error = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.accountDocuments.count = action.payload;
        },
        setPagination: (state, action: PayloadAction<PaginateParams>) => {
            state.accountDocuments.pagination = action.payload;
        },
        setStoredWalletId: (state, action: PayloadAction<string>) => {
            state.storedWalletId = action.payload;
        }
    }
});

export const {
    setLoadingDocument,
    setDocument,
    setDocuments,
    setLoading,
    setError,
    setCount,
    setPagination,
    setStoredWalletId
} = slice.actions;

export default slice.reducer;


export const getTransferDetails = (id: number) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingDocument(true));
            const response = await request.get(`/api/console/documents/id/${id}`);
            dispatch(setDocument(response?.data));
        }
        catch (e) {
            dispatch(setDocument(null));
            showErrorNotification(e);
        }
        finally {
            dispatch(setLoadingDocument(false));

        }
    }
};

export const getDocumentDetailsByNumber = async (documentNumber: string): Promise<DocumentDetails> => {
    const response = await request.get(`/api/console/documents/docNumber/${documentNumber}`);
    const { data } = response;
    return data;
};

export const getCardsDetails = async (walletId: string) => {
    const response = await request.get(`/api/console/documents/card/details/${walletId}`);
    const { data } = response;
    return data;
};

export const getAccountDocuments = (payload: PaginateParams, walletId: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));

            let filters = payload.filter ? payload.filter.split(';') : [];

            // Check if walletId exists in the filters
            const walletIdFilterIndex = filters.findIndex(f => f.startsWith('walletId='));
            if (walletIdFilterIndex !== -1) {
                filters[walletIdFilterIndex] = `walletId=${walletId}`;
            } else {
                filters.push(`walletId=${walletId}`);
            }

            // Reconstruct the filter string
            const filterString = filters.join(';');

            const data = await getTranferList({ ...payload, filter: filterString });

            dispatch(setStoredWalletId(walletId));
            dispatch(setDocuments(data.list));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            dispatch(setLoading(false));
            return data;
        } catch (e: any) {
            if (e?.name && e.name !== 'CanceledError') {
                showErrorNotification(e);
                dispatch(setLoading(false));
            }
        }
    };
};


export const getAddFeeInfo = async (adjustmentType: AdjustmentType, documentNumber: string) => {
    const response = await request.get(`/api/console/user-fees/adjustment/add-fee/info?documentNumber=${documentNumber}&adjustmentType=${adjustmentType}`);
    const { data } = response;
    return data;
};

export const getReturnFeeInfo = async (documentNumber: string) => {
    const response = await request.get(`/api/console/user-fees/adjustment/return-fee/info?documentNumber=${documentNumber}`);
    const { data } = response;
    return data;
};

export const returnFeeAdjustment = async (payload: any) => {
    const response = await request.post(`/api/console/user-fees/adjustment/return-fee`, payload);
    const { data } = response;
    return data;
};

export const createFeeAdjustment = async (payload: any) => {
    const response = await request.post(`/api/console/user-fees/adjustment/add-fee`, payload);
    const { data } = response;
    return data;
};

export const attachFiles = async (payload: AttachFilesPayload, files: Array<File>) => {
    const formData = new FormData();
    formData.append('documentNumber', payload.documentNumber);
    formData.append('documentId', payload.documentId.toString());
    formData.append('subject', payload.subject);
    formData.append('userId', payload.userId.toString());

    addFiles(formData, files)

    const response = await request.post('/api/console/documents/upload/attachments', formData, { headers: { 'Content-Type': 'multipart/form-data', } });
    const { data } = response;
    return data;
};