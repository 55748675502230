import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '@/utils/errors';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { EarnVaultPlanDepositsStore } from './earnVaultPlanDepositsTypes';

export const earnVaultPlanDepositsPaginationParamsInit = {
    filter: '',
    orderBy: 'vaultCreatedAt',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: EarnVaultPlanDepositsStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    pagination: earnVaultPlanDepositsPaginationParamsInit,
    storedProc: null
};

const slice = createSlice({
    name: 'earnVaultPlanDeposits',
    initialState,
    reducers: {
        reset: () => initialState,
        setList(state, action: PayloadAction<Array<any>>) {
            state.list = action.payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
            state.pagination = payload;
        },
        setStoredData: (state, { payload }: PayloadAction<{ proc: string }>) => {
            state.storedProc = payload.proc;
        }
    }
});

export const { setList, setLoading, setError, setCount, setPagination, setStoredData, reset } = slice.actions;

export const getEarnVaultPlanDepositsList = (proc: string, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/vaults/vault/deposit/${proc}/list`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setList(data.list));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            dispatch(setLoading(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading(false));
        }
    };
};

export const terminateVaultProcDeposit = async (wlpId: string, accountId: number, fee: number) => {
    const response = await request.post(`/api/console/vaults/vault/deposit/terminate`, {
        accountId, fee, wlpId
    });
    const { data } = response;
    return data;
};

export const getTerminateVaultProcDepositData = async (wlpId: string, accountId: number) => {
    const response = await request.get(`/api/console/vaults/vault/deposit/terminate/${wlpId}/${accountId}`)
    const { data } = response;
    return data;
};

export const getEarnVaultPlanDepositDetails = async (proc: string, accountId: number) => {
    const response = await request.get(`/api/console/vaults/vault/deposit/${proc}/${accountId}`);
    const { data } = response;
    return data;
};

export const getEarnVaultPlanDepositChangeOptions = async (wlpId: string, accountId: number) => {
    const response = await request.get(`/api/console/vaults/vault/deposit/change-plan/${accountId}/${wlpId}`);
    const { data } = response;
    return data;
};

export const postEarnVaultPlanDepositChange = async (wlpId: string, accountId: number, newVaultProc: string, changeDate?: string) => {
    const response = await request.post(`/api/console/vaults/vault/deposit/change-plan/${accountId}/${wlpId}`, { newVaultProc, changeDate });
    const { data } = response;
    return data;
};

export const postEarnVaultPlanPartialRepayment = async (payload: { wlpId: string, accountId: number, amount: number }) => {
    const response = await request.post(`/api/console/vaults/vault/deposit/partial-repayment`, payload);
    const { data } = response;
    return data;
};

export default slice.reducer;