import { useSelector } from "@/store/store";
import { memo } from "react";
import { ReactComponent as YessyLogoSvg } from "../assets/wlps/yessy.svg";
import { ReactComponent as MonetalLogoSvg } from "../assets/wlps/monetal.svg";
import { ReactComponent as IdealoopLogoSvg } from "../assets/wlps/idealoop.svg";
import { ReactComponent as CustodyfyLogoSvg } from "../assets/wlps/custodyfy.svg";
import { ReactComponent as NeuralLogoSvg } from "../assets/wlps/neural.svg";
import { ReactComponent as XwardLogoSvg } from "../assets/wlps/xward.svg";
import { ReactComponent as LuidoreLogoSvg } from "../assets/wlps/luidore.svg";
import { ReactComponent as DtsLogoSvg } from "../assets/wlps/dts.svg";
import { ReactComponent as CactusLogoSvg } from "../assets/wlps/cactus.svg";
import { ReactComponent as SalazarLogoSvg } from "../assets/wlps/salazar.svg";
import { ReactComponent as DtswapLogoSvg } from "../assets/wlps/dtswap.svg";
import { ReactComponent as EscrowfyLogoSvg } from "../assets/wlps/escrowfy.svg";

const WlpLogoMenu = (props: any) => {

    const { wlp } = useSelector((state) => state.credentials);

    switch (wlp) {
        case "YESSY":
            return <YessyLogoSvg {...props} />
        case "DTSWAP":
            return <DtswapLogoSvg {...props} />
        case "ESCROWFY":
            return <EscrowfyLogoSvg {...props} />
        case "MONETAL":
            return <MonetalLogoSvg {...props} />
        case "IDEALOOP":
            return <IdealoopLogoSvg {...props} />
        case "CUSTODYFY":
            return <CustodyfyLogoSvg {...props} />
        case "NEURAL":
            return <NeuralLogoSvg {...props} />
        case "XWARD":
            return <XwardLogoSvg {...props} />
        case "LUIDORE":
            return <LuidoreLogoSvg {...props} />
        case "DTS":
            return <DtsLogoSvg {...props} />
        case "CACTUS":
            return <CactusLogoSvg {...props} />
        case "SALAZAR":
            return <SalazarLogoSvg {...props} />
        default:
            return <></>
    }

};

export default memo(WlpLogoMenu);