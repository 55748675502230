import { keys } from "lodash";

export const findPaymentProcesses = (subProcesses: any) => {
    return subProcesses ? keys(subProcesses)
        .filter(key => subProcesses[key].type === 'PAYMENT')
        .map(key => subProcesses[key]) : [];
};


//Returns first subprocces  with  passed TYPE 
export const findProcessByType = (subProcesses?: any, processType?: string) => {

    if (!subProcesses || !processType) return undefined;
    return subProcesses ? keys(subProcesses)
        .filter(key => subProcesses[key].type === processType)
        .map(key => subProcesses[key])[0] : null;
};


export const hasSubprocessWithType = (procType: string, subProcesses: any) => {
	if (subProcesses) {
		const tmp = keys(subProcesses).filter(key => subProcesses[key].type === procType);
		return tmp.length > 0;
	}
	else return false;
};