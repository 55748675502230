import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FinancialConfigurationsStore, FinancialConfigurationsTab, InvoiceConfigDetails, InvoiceConfigPayload, PartyDetailsPayload } from "./financialConfigurationsTypes";
import request from "@/services/request";
import { InvoiceParty, OwnerTypeEnum } from "../emiBilling/emiBillingTypes";
import { Filter } from "@/components/filter/CustomDataGridFilter";
import { PaginateParams } from "@/types/pagination";

const initialState: FinancialConfigurationsStore = {
    openedSubTab: FinancialConfigurationsTab.COUNTERPARTIES,
    filtersConfig: {
        [FinancialConfigurationsTab.COUNTERPARTIES]: {
            filtersSet: false,
            filters: []
        },
        [FinancialConfigurationsTab.INVOICE_CONFIG]: {
            filtersSet: false,
            filters: []
        }
    }
};

const slice = createSlice({
    name: "financialConfigurations",
    initialState,
    reducers: {
        setSubTab(state, action: PayloadAction<FinancialConfigurationsTab>) {
            state.openedSubTab = action.payload;
        },
        setFilters(state, action: PayloadAction<{ tab: FinancialConfigurationsTab, filters: Array<Filter> }>) {
            state.filtersConfig[action.payload.tab].filters = action.payload.filters;
        },
        setFiltersSet(state, action: PayloadAction<{ tab: FinancialConfigurationsTab, set: boolean }>) {
            state.filtersConfig[action.payload.tab].filtersSet = action.payload.set;
        },
    },
});

export default slice.reducer;

export const { setSubTab, setFilters, setFiltersSet } = slice.actions;

export const getInvoicePartiesByType = async (ownerType: OwnerTypeEnum): Promise<Array<InvoiceParty>> => {
    const response = await request.get(`/api/console/billing/invoice/parties/by-type/${ownerType}`);
    return response?.data;
};

export const editInvoiceParty = async (payload: PartyDetailsPayload): Promise<PartyDetailsPayload> => {
    const response = await request.post(`/api/console/billing/invoice/party/edit`, payload);
    return response?.data;
};

export const getInvoiceConfig = async (payload: PaginateParams): Promise<{ count: number, invConfigList: Array<InvoiceConfigDetails> }> => {
    const response = await request.get(`/api/console/billing/invoice/config/list`, {
        params: {
            filter: payload.filter,
            orderBy: payload.orderBy,
            sort: payload.sort.toUpperCase(),
            skip: payload.skip.toString(),
            take: payload.take
        }
    });
    return response?.data;
};

export const editInvoiceConfig = async (payload: InvoiceConfigPayload) => {
    const response = await request.post(`/api/console/billing/invoice/config/edit`, payload);
    return response?.data;
};