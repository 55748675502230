import { Grid, ButtonGroup, Button, useTheme } from '@mui/material';
import BtnsDropdown from '@/pages/platformDetails/platformFees/components/BtnsDropdown';

interface Props {
    ccyBtns: Array<string>;
    selectedCurrency: string;
    handleCurrencyChange: (currency: string) => void;
};


const CurrencySelector = ({ ccyBtns, selectedCurrency, handleCurrencyChange }: Props) => {
    const theme = useTheme();

    if (!ccyBtns || ccyBtns.length <= 1) return null;

    const sortedCcyBtns = [...ccyBtns].sort();

    const buttonContent = sortedCcyBtns.length > 3 ? (
        <BtnsDropdown btns={sortedCcyBtns} selectedCurrency={selectedCurrency} setSelectedCurrency={handleCurrencyChange} />
    ) : (
        <ButtonGroup variant="outlined">
            {sortedCcyBtns.map((currency) => (
                <Button
                    key={currency}
                    onClick={() => handleCurrencyChange(currency)}
                    sx={{
                        backgroundColor: selectedCurrency === currency ? theme.palette.transparentBlack : 'primary',
                        color: theme.palette.primary.dark,
                        fontWeight: 500
                    }}
                >
                    {currency}
                </Button>
            ))}
        </ButtonGroup>
    );

    return <Grid item>{buttonContent}</Grid>;
};

export default CurrencySelector;