import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import request from '../../services/request';
import { EarnChartData, EarnChartPeriod, EarnInterestRepaymentsItem, EarnInterestRepaymentsStore } from './earnInterestRepaymentsTypes';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '@/store/store';
import { showErrorNotification } from '@/utils/errors';


export const earnInterestRepaymentsPaginationParamsInit = {
    filter: '',
    orderBy: 'transferDate',
    sort: SortDirectionEnum.DESC,
    take: 10,
    skip: 0
};


const initialState: EarnInterestRepaymentsStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    pagination: earnInterestRepaymentsPaginationParamsInit,
    storedType: null,
    storedProc: null
};

const slice = createSlice({
    name: 'earnInterestRepayments',
    initialState,
    reducers: {
        reset: () => initialState,
        setList(state, action: PayloadAction<Array<EarnInterestRepaymentsItem>>) {
            state.list = action.payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
            state.pagination = payload;
        },
        setStoredData: (state, { payload }: PayloadAction<{ type: string, proc: string }>) => {
            state.storedProc = payload.proc;
            state.storedType = payload.type;
        }
    }
});

export const { reset, setList, setLoading, setError, setCount, setPagination, setStoredData } = slice.actions;

export const getEarnAggInterestRepaymentsList = (type: 'INTEREST' | 'REPAYMENT', proc: string, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/vaults/agg/interest-repayments/${type}/${proc}/list`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setList(data.list));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            dispatch(setLoading(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading(false));
        }
    };
};


export const getEarnInterestPaymentGraph = async (proc: string, type: 'INTEREST' | 'REPAYMENT', period: EarnChartPeriod, date: string): Promise<EarnChartData[]> => {
    const response = await request.get(`/api/console/vaults/interest-payment/chart/${proc}/${type}/${period}/${date}`);
    const { data } = response;
    return data;
};

export const initiatePlanedTransferPayment = async (walletPlannedTransferId?: number): Promise<any> => {
    const response = await request.get(`/api/console/vaults/planned-transfer/payment${walletPlannedTransferId ? `?walletPlannedTransferId=${walletPlannedTransferId}` : ""}`);
    const { data } = response;
    return data;
};


export default slice.reducer;