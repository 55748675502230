import * as React from "react";
import { SvgIcon } from '@mui/material';

const XClose = (props: any) => (
  <SvgIcon {...props}>
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m16.243 7.757-8.486 8.486m8.486 0L7.757 7.757"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
export default XClose;
