import { PaginateParams } from "@/types/pagination";
import { Store } from "../merchants/merchantsTypes";

export interface MerchantUserDetailsStore {
    selectedSubTab: SubTabsEnum;
    storedUserId: number | null;
    stores: {
        initial: boolean,
        loading: boolean,
        list: Array<Store>,
        error: string | null,
        count: number,
        pagination: PaginateParams,
    },
};

export const enum SubTabsEnum {
    STORES = "STORES",
    EMPLOYEES = "EMPLOYEES",
    TRANSACTIONS = "TRANSACTIONS"
};