import { SvgIcon } from '@mui/material';

const IncomingPayment = (props: any) => (
    <SvgIcon {...props}>
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#2B2B2B" fillOpacity="0.06" />
            <path d="M16.6673 12L7.33398 12M7.33398 12L11.334 16M7.33398 12L11.334 8" stroke="#40927E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);
export default IncomingPayment;