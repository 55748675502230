import { MenuProps } from 'types/menu';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const key = 'app_active_menu_item';

const savedMenu = sessionStorage.getItem(key);

const initialState: MenuProps = {
    openItem: 'dashboard',
    pathToItem: '/dashboard',
    drawerOpen: false,
    showBack: false,
    subTab: null,
    step: 0,
    totalStepCount: 0,
    subPaths: []
};

if (savedMenu) {
    try {
        const menu = JSON.parse(savedMenu);
        initialState.openItem = menu.openItem;
        initialState.pathToItem = menu.pathToItem;
        initialState.subPaths = menu.subPaths;
    }
    catch (e) {
        console.log(e);
    }
}

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.openItem = action.payload;
            sessionStorage.setItem(key, JSON.stringify({ openItem: action.payload, pathToItem: null, subPaths: [] }));
        },
        setPathToItem(state, action) {
            state.pathToItem = action.payload;
            state.subPaths = [{
                title: state.openItem,
                path: state.pathToItem
            }]
            sessionStorage.setItem(key, JSON.stringify({ openItem: state.openItem, pathToItem: action.payload, subPaths: state.subPaths }));
        },
        addSubPath(state, action) {
            state.subPaths = [...state.subPaths, action.payload];
            sessionStorage.setItem(key, JSON.stringify({ openItem: state.openItem, pathToItem: state.pathToItem, subPaths: state.subPaths }));
        },
        changeLastSubItem(state, action: PayloadAction<{ path?: string, state?: any }>) {
            if (state.subPaths.length > 0) {
                const lastIndex = state.subPaths.length - 1;
                state.subPaths[lastIndex] = {
                    ...state.subPaths[lastIndex],
                    ...action.payload.path && { path: action.payload.path },
                    state: { ...state.subPaths[lastIndex].state, ...action.payload.state }
                };
            }
            sessionStorage.setItem(key, JSON.stringify({ openItem: state.openItem, pathToItem: state.pathToItem, subPaths: state.subPaths }));
        },
        removeLastSubPath(state) {
            if (state.subPaths.length > 0) {
                state.subPaths.splice(-1);
            }
            sessionStorage.setItem(key, JSON.stringify({ openItem: state.openItem, pathToItem: state.pathToItem, subPaths: state.subPaths }));
        },
        setSubTab(state, action) {
            state.subTab = action.payload;
        },
        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        },
        setCurrentStep(state, action) {
            state.step = action.payload;
        },
        setStepCount(state, action) {
            state.totalStepCount = action.payload;
        },
        clearMenu() {
            sessionStorage.removeItem(key);
        }
    }
});

export default menu.reducer;

export const { activeItem, setPathToItem, addSubPath, removeLastSubPath, changeLastSubItem, openDrawer, setSubTab, setStepCount, setCurrentStep, clearMenu } = menu.actions;
