import { getStatusColor } from "@/utils/statusUtils";
import { normalizeString } from "@/utils/stringFormat";
import { Chip, ChipProps, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "react";


interface Props extends ChipProps {
    label: string;
    isText?: boolean;
    chipColor?: CSSProperties;
};

const StatusChip = ({ label, isText, sx = {}, chipColor, ...others }: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const statusColor = chipColor ? chipColor : getStatusColor(label, theme, isText);

    const statusValue = t('status.' + label, normalizeString(label));

    return (
        <Chip
            label={statusValue}
            sx={{
                borderRadius: '10px',
                minWidth: label === "NEW" || label === "ERROR" ? '70px' : '95px',
                minHeight: '24px',
                '& .MuiChip-label:hover': {
                    color: 'inherit',
                },
                ...statusColor,
                ...sx
            }}
            {...others} />

    );
};

export default StatusChip;
