import { SelectedFilterParam } from '@/types/filterParam';
import { Autocomplete, Grid, IconButton, InputAdornment, Box as MuiBox, TextField, styled } from '@mui/material';
import FilterChip from './FilterChip';
import { useTranslation } from 'react-i18next';
import Search2 from '@/icons/Search2';
import { isEmpty } from 'lodash';


interface Props {
    filter: SelectedFilterParam | null,
    filterValue: string,
    onFilterValueChange: (value: string) => void,
    onAnyFilterValueChange: (value: string) => void,
    onAnySelectedValueChange: (value: string) => void,
    onDelete: (filter: SelectedFilterParam | null) => void,
    onDeleteSelected: (filter: SelectedFilterParam | null) => void,
    onSearch: () => void,
    selectedFilters: Array<SelectedFilterParam>,
    withAnyFilter?: boolean
}


const BoxStyled = styled(MuiBox)(
    ({ theme, }) => ({
        p: 2,
        display: 'flex',
        alignItems: 'center',
        border: '1px solid ' + theme.palette.secondary.main,
        borderRadius: '10px',
    }),
);

const SearchInput = ({ filter, filterValue, selectedFilters, onFilterValueChange, onAnyFilterValueChange, onAnySelectedValueChange, onDelete, onDeleteSelected, onSearch, withAnyFilter }: Props) => {
    const { t } = useTranslation();

    const options = filter?.options
        ? filter.options.map(options => ({
            label: options.text,
            value: options.value
        })) : [];

    return (
        <BoxStyled>
            {
                filter?.type === 'select' ?
                    <Autocomplete
                        key={filter.field}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        disablePortal
                        fullWidth
                        onChange={(event, newValue) => {
                            if (newValue) {
                                onFilterValueChange(newValue.value.toString());
                            }
                        }}
                        options={options}
                        renderInput={(params) =>
                            <TextField {...params}
                                value={filterValue}

                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'unset'
                                    },
                                    '& .MuiAutocomplete-endAdornment': {
                                        display: 'none'
                                    },
                                    minWidth: '350px'
                                }}

                                placeholder={t('form.buttons.search').toString()}
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            {selectedFilters.map(entry =>
                                                <Grid key={entry.field} item mr={1}>
                                                    <FilterChip filter={entry} onDelete={onDeleteSelected} />
                                                </Grid>)}
                                            {filter && <FilterChip filter={filter} onDelete={onDelete} hideValue={true} />}
                                        </InputAdornment>
                                    ),
                                }}
                            />}
                    />
                    :
                    <TextField
                        value={selectedFilters && selectedFilters.length === 1 && selectedFilters[0].field === 'any' ? selectedFilters[0].value : filterValue}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if (filter && filter.field !== "any") {
                                onFilterValueChange(event.target.value);
                            } else if (withAnyFilter && selectedFilters.length === 0) {
                                onAnyFilterValueChange(event.target.value);
                            } else if (withAnyFilter && selectedFilters && selectedFilters.length === 1 && selectedFilters[0].field === 'any') {
                                onAnySelectedValueChange(event.target.value)
                            }
                        }}
                        {...(!isEmpty(selectedFilters) && { placeholder: '' })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    {selectedFilters.map(entry =>
                                        entry.field !== "any" ? (
                                            <Grid key={entry.field} item mr={1}>
                                                <FilterChip filter={entry} onDelete={onDeleteSelected} />
                                            </Grid>
                                        ) : null
                                    )}
                                    {filter && filter.field !== "any" && <FilterChip filter={filter} onDelete={onDelete} hideValue={true} />}
                                </InputAdornment>
                            ),
                        }}
                        onKeyDown={(ev: any) => {
                            if (ev.key === 'Enter') {
                                onSearch();
                            }
                        }}
                        placeholder={(filter || (withAnyFilter && selectedFilters.length < 1)) ? t('form.buttons.search').toString() : t('form.buttons.chooseFilter').toString()}
                        fullWidth
                        type={filter?.inputType ? filter.inputType : ''}
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'unset'
                            },
                            minWidth: '350px',
                            ...filter?.textFieldProps
                        }} />

            }
            <IconButton onClick={onSearch}> <Search2 sx={{ fontSize: '0.813rem' }} /> </IconButton>
        </BoxStyled >);
};


export default SearchInput;

