import { SvgIcon } from '@mui/material';

const TransferOrder = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#2B2B2B" fillOpacity="0.06" />
            <path d="M8.66667 12.6668L6 10.0002M6 10.0002L8.66667 7.3335M6 10.0002H14.6667C16.5076 10.0002 18 11.4925 18 13.3335C18 15.1744 16.5076 16.6668 14.6667 16.6668H11.3333" stroke="#EFB930" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);
export default TransferOrder;