import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "@/store/store";
import request from "@/services/request";
import { EmiBillingStore, EmiInvoice, CreatePayoutPayload, FeePayoutData, InvoiceDetails, InvoiceParty, InvoicesSummary, PayoutWithdrawal, PayoutsSummary, Platform, PlatformInvoice, PlatformsSummary, WithdrawalsSummary, AddLinePayload, CreateInvoicePayload, SendInvoicePayload, ConfirmInvoicePayload, UpdateInvoicePayload, CreateAutomaticInvoicePayload, InvoiceManualPaymentPayload, EmiPayout, EmiBillingTabEnum } from "./emiBillingTypes";
import { PaginateParams, SortDirectionEnum } from "@/types/pagination";
import { showErrorNotification } from "@/utils/errors";
import { FilterOptionType, HeaderFilterType } from "@/components/dataGrid/CustomFilterHeader";
import { Wlp } from "../user/types";
import { getWlpName } from "@/components/dataGrid/utils/Platform";

export const invoiceDefaultPagination: PaginateParams = {
    filter: 'invoiceCategory=INVOICE;invoiceSide=RECEIVABLE',
    orderBy: 'invoiceDate;invoiceId',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

export const payoutsDefaultPagination: PaginateParams = {
    filter: '',
    orderBy: 'invoiceDate;payoutId',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

export const withdrawalsDefaultPagination: PaginateParams = {
    filter: '',
    orderBy: 'transferDate',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

export const platformInvoiceDefaultPagination: PaginateParams = {
    filter: '',
    orderBy: 'date',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: EmiBillingStore = {
    payoutsSummary: null,
    invoicesSummary: null,
    platformsSummary: null,
    withdrawalsSumamry: null,
    platforms: [],
    invoices: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: invoiceDefaultPagination,
        filters: {
            [HeaderFilterType.WLPS]: []
        },
        filtersSet: false
    },
    payouts: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: payoutsDefaultPagination,
        filters: {
            [HeaderFilterType.WLPS]: []
        },
        filtersSet: false
    },
    withdrawals: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: withdrawalsDefaultPagination,
        filters: {
            [HeaderFilterType.WLPS]: []
        },
        filtersSet: false
    },
    platformInvoicesAndPayouts: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: platformInvoiceDefaultPagination
    },
    loadingSummaries: {
        payoutsSummary: false,
        invoicesSummary: false,
        platformsSummary: false,
        withdrawalsSumamry: false
    },
};

const slice = createSlice({
    name: 'emiBilling',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<{ [key: string]: boolean }>) {
            state.loadingSummaries = { ...state.loadingSummaries, ...action.payload };
        },
        // Summaries
        setPayoutsSummary(state, action: PayloadAction<PayoutsSummary | null>) {
            state.payoutsSummary = action.payload;
        },
        setInvoicesSummary(state, action: PayloadAction<InvoicesSummary | null>) {
            state.invoicesSummary = action.payload;
        },
        setPlatformsSummary(state, action: PayloadAction<PlatformsSummary | null>) {
            state.platformsSummary = action.payload;
        },
        // Invoices
        setIncomeInvoices(state, action: PayloadAction<Array<EmiInvoice>>) {
            state.invoices.list = action.payload;
        },
        setLoadingIncomeInvoices(state, action: PayloadAction<boolean>) {
            state.invoices.loading = action.payload;
        },
        setCountIncomeInvoices(state, action: PayloadAction<number>) {
            state.invoices.count = action.payload;
        },
        setPaginationIncomeInvoices(state, action: PayloadAction<PaginateParams>) {
            state.invoices.pagination = action.payload;
        },
        // Payouts
        setPayouts(state, action: PayloadAction<Array<EmiPayout>>) {
            state.payouts.list = action.payload;
        },
        setLoadingPayouts(state, action: PayloadAction<boolean>) {
            state.payouts.loading = action.payload;
        },
        setCountPayouts(state, action: PayloadAction<number>) {
            state.payouts.count = action.payload;
        },
        setPaginationPayouts(state, action: PayloadAction<PaginateParams>) {
            state.payouts.pagination = action.payload;
        },
        // Platforms
        setPlatforms(state, action: PayloadAction<Array<Platform>>) {
            state.platforms = action.payload;
        },
        // Platform Invoices
        setPlatformInvoicesAndPayouts(state, action: PayloadAction<Array<PlatformInvoice>>) {
            state.platformInvoicesAndPayouts.list = action.payload;
        },
        setLoadingPlatformInvoicesAndPayouts(state, action: PayloadAction<boolean>) {
            state.platformInvoicesAndPayouts.loading = action.payload;
        },
        setCountPlatformInvoicesAndPayouts(state, action: PayloadAction<number>) {
            state.platformInvoicesAndPayouts.count = action.payload;
        },
        setPaginationPlatformInvoicesAndPayouts(state, action: PayloadAction<PaginateParams>) {
            state.platformInvoicesAndPayouts.pagination = action.payload;
        },
        // Fee withdrawals
        setWithdrawals(state, action: PayloadAction<Array<PayoutWithdrawal>>) {
            state.withdrawals.list = action.payload;
        },
        setLoadingWithdrawals(state, action: PayloadAction<boolean>) {
            state.withdrawals.loading = action.payload;
        },
        setCountWithdrawals(state, action: PayloadAction<number>) {
            state.withdrawals.count = action.payload;
        },
        setPaginationWithdrawals(state, action: PayloadAction<PaginateParams>) {
            state.withdrawals.pagination = action.payload;
        },
        setWithdrawalsSummary(state, action: PayloadAction<WithdrawalsSummary | null>) {
            state.withdrawalsSumamry = action.payload;
        },
        // Filters
        setFilters: (state, action: PayloadAction<{ tab: EmiBillingTabEnum, wlps: Array<Wlp> }>) => {
            const wlpsOptions = action.payload.wlps.map(item => ({
                value: item.wlpId,
                text: getWlpName(item.wlpId),
                enabled: true
            }));
            switch (action.payload.tab) {
                case EmiBillingTabEnum.INVOICES:
                    state.invoices.filters[HeaderFilterType.WLPS] = wlpsOptions;
                    state.invoices.filtersSet = true;
                    break;
                case EmiBillingTabEnum.PAYOUTS:
                    state.payouts.filters[HeaderFilterType.WLPS] = wlpsOptions;
                    state.payouts.filtersSet = true;
                    break;
                case EmiBillingTabEnum.WITHDRAWALS:
                    state.withdrawals.filters[HeaderFilterType.WLPS] = wlpsOptions;
                    state.withdrawals.filtersSet = true;
                    break;
                default:
                    break;
            }
        },
        setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, tab: EmiBillingTabEnum, options: Array<FilterOptionType> }>) => {
            switch (action.payload.tab) {
                case EmiBillingTabEnum.INVOICES:
                    state.invoices.filters[action.payload.filterType] = action.payload.options;
                    break;
                case EmiBillingTabEnum.PAYOUTS:
                    state.payouts.filters[action.payload.filterType] = action.payload.options;
                    break;
                case EmiBillingTabEnum.WITHDRAWALS:
                    state.withdrawals.filters[action.payload.filterType] = action.payload.options;
                    break;
                default:
                    break;
            }
        },
        setFiltersSet(state, action: PayloadAction<{ tab: EmiBillingTabEnum, set: boolean }>) {
            switch (action.payload.tab) {
                case EmiBillingTabEnum.INVOICES:
                    state.invoices.filtersSet = action.payload.set;
                    break;
                case EmiBillingTabEnum.PAYOUTS:
                    state.payouts.filtersSet = action.payload.set;
                    break;
                case EmiBillingTabEnum.WITHDRAWALS:
                    state.withdrawals.filtersSet = action.payload.set;
                    break;
                default:
                    break;
            }
        }
    }
});

export const {
    setLoading,
    setPayoutsSummary,
    setInvoicesSummary,
    setPlatformsSummary,
    setLoadingIncomeInvoices,
    setCountIncomeInvoices,
    setIncomeInvoices,
    setPaginationIncomeInvoices,
    setPayouts,
    setLoadingPayouts,
    setCountPayouts,
    setPaginationPayouts,
    setPlatforms,
    setPlatformInvoicesAndPayouts,
    setLoadingPlatformInvoicesAndPayouts,
    setCountPlatformInvoicesAndPayouts,
    setPaginationPlatformInvoicesAndPayouts,
    setWithdrawals,
    setLoadingWithdrawals,
    setCountWithdrawals,
    setPaginationWithdrawals,
    setWithdrawalsSummary,
    setFilters,
    setFilter,
    setFiltersSet
} = slice.actions;

export default slice.reducer;

export const getPayoutsSummary = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ payoutsSummary: true }));
            const response = await request.get('/api/console/billing/payouts/summary');
            const data = response?.data;
            dispatch(setPayoutsSummary(data));
        }
        catch (e) {
            dispatch(setPayoutsSummary(null));
        }
        finally {
            dispatch(setLoading({ payoutsSummary: false }));
        }
    }
};

export const getInvoicesSummary = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ invoicesSummary: true }));
            const response = await request.get('/api/console/billing/invoices/summary');
            const data = response?.data;
            dispatch(setInvoicesSummary(data));
        }
        catch (e) {
            dispatch(setInvoicesSummary(null));
        }
        finally {
            dispatch(setLoading({ invoicesSummary: false }));
        }
    }
};

export const getPlatformsSummary = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ platformsSummary: true }));
            const response = await request.get('/api/console/billing/platforms/summary');
            const data = response?.data;
            dispatch(setPlatformsSummary(data));
        }
        catch (e) {
            dispatch(setPlatformsSummary(null));
        }
        finally {
            dispatch(setLoading({ platformsSummary: false }));
        }
    }
};

export const getInvoicesList = (payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingIncomeInvoices(true));
            const response = await request.get(`/api/console/billing/invoices`, {
                params: {
                    isSystem: false,
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setIncomeInvoices(data.emiBillingInvoices));
            dispatch(setCountIncomeInvoices(data.count));
            dispatch(setPaginationIncomeInvoices(payload));
            dispatch(setLoadingIncomeInvoices(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoadingIncomeInvoices(false));
        }
    };
};

export const getPayoutsList = (payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingPayouts(true));
            const response = await request.get(`/api/console/billing/payouts`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setPayouts(data.emiBillingPayouts));
            dispatch(setLoadingPayouts(data.count));
            dispatch(setPaginationPayouts(payload));
            dispatch(setLoadingPayouts(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoadingPayouts(false));
        }
    };
};

export const getPlatforms = async () => {
    const response = await request.get('/api/console/billing/platforms');
    const { data } = response;
    return data.emiBillingPlatforms;
};

export const getPlatformInvoicesAndPayouts = (wlpId: string, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingPlatformInvoicesAndPayouts(true));
            const response = await request.get(`/api/console/billing/platform/invoices-and-payouts/${wlpId}`, {
                params: {
                    isSystem: false,
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setPlatformInvoicesAndPayouts(data.platformInvoicesAndPayouts));
            dispatch(setCountPlatformInvoicesAndPayouts(data.count));
            dispatch(setPaginationPlatformInvoicesAndPayouts(payload));
            dispatch(setLoadingPlatformInvoicesAndPayouts(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoadingPlatformInvoicesAndPayouts(false));
        }
    };
};

export const getPayoutsWithdrawals = (payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingWithdrawals(true));
            const response = await request.get(`/api/console/billing/payouts/withdrawals`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setWithdrawals(data.withdrawaList));
            dispatch(setLoadingWithdrawals(data.count));
            dispatch(setPaginationWithdrawals(payload));
            dispatch(setLoadingWithdrawals(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoadingWithdrawals(false));
        }
    };
};

export const getWithdrawalsSummary = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ withdrawalsSumamry: true }));
            const response = await request.get('/api/console/billing/withdrawals/summary');
            const data = response?.data;
            dispatch(setWithdrawalsSummary(data));
        }
        catch (e) {
            dispatch(setWithdrawalsSummary(null));
        }
        finally {
            dispatch(setLoading({ withdrawalsSumamry: false }));
        }
    }
};

export const getInvoiceDetails = async (invoiceId: number): Promise<InvoiceDetails> => {
    const response = await request.get(`/api/console/billing/invoice/lines/${invoiceId}`);
    const { data } = response;
    return data;
};

export const downloadInvoice = async (invoiceId: number, invoiceNumber: string) => {
    const response = await request.post(`/api/console/billing/invoice/template`,
        { invoiceId: invoiceId },
        {
            responseType: "blob",
        });

    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.download = `invoice-${invoiceNumber}`;
    link.href = url;
    link.className = "hidden";
    document.body.appendChild(link);

    link.onclick = function () {
        requestAnimationFrame(function () {
            URL.revokeObjectURL(url);
            setTimeout(() => link.remove(), 300);
        });
    };

    link.click();
};

export const getInvoiceParties = async (): Promise<Array<InvoiceParty>> => {
    const response = await request.get(`/api/console/billing/invoice/parties`);
    return response?.data;
};

export const getFeePayoutData = async (invoiceId: number): Promise<Array<FeePayoutData>> => {
    const response = await request.get(`/api/console/billing/fee-payout/data/${invoiceId}`);
    const { data } = response;
    return data;
};

export const createPayoutInvoice = async (payload: CreatePayoutPayload) => {
    const response = await request.post(`/api/console/billing/create/payout/invoice`, payload);
    const { data } = response;
    return data;
};

export const deleteInvoiceLine = async (invoiceId: number, lineId: number) => {
    const response = await request.delete(`/api/console/billing/invoice/line/delete/${invoiceId}/${lineId}`);
    return response?.data;
};

export const addInvoiceLine = async (payload: AddLinePayload) => {
    const response = await request.post(`/api/console/billing/invoice/line/add`, payload);
    return response?.data;
};

export const createInvoice = async (payload: CreateInvoicePayload) => {
    const response = await request.post(`/api/console/billing/invoice/create`, payload);
    return response?.data;
};

export const sendInvoice = async (payload: SendInvoicePayload) => {
    const response = await request.put(`/api/console/billing/invoice/send`, payload);
    return response?.data;
};

export const confirmInvoice = async (payload: ConfirmInvoicePayload) => {
    const response = await request.put(`/api/console/billing/invoice/confirm`, payload);
    return response?.data;
};

export const updateInvoice = async (payload: UpdateInvoicePayload) => {
    const response = await request.post(`/api/console/billing/invoice/update`, payload);
    return response?.data;
};

export const createAutomaticInvoice = async (payload: CreateAutomaticInvoicePayload) => {
    const response = await request.post(`/api/console/billing/invoice/automatic/create`, payload);
    return response?.data;
};

export const createManualInvoicePayment = async (payload: InvoiceManualPaymentPayload) => {
    const response = await request.post(`/api/console/billing/invoice/manual/payment`, payload);
    return response?.data;
};