import { getIsCrypto } from "@/redux/currencies/currenciesSlice";
import { useSelector } from "@/store/store";
import { getIsFiat } from "@/utils/currency";
import { normalizeString } from "@/utils/stringFormat";
import { Avatar, Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";

const AssetCellWithUrl = (params: GridRenderCellParams) => {
    const { currencies } = useSelector((state) => state.currencies);

    const getDescription = (currencyCode: string): string => {
        const isFiat = getIsFiat(currencyCode);
        const isCrypto = getIsCrypto(currencyCode);

        if (isFiat) {
            const fiatCurrency = currencies.fiat.find(currency => currency.value === currencyCode);
            if (fiatCurrency) {
                return fiatCurrency.text;
            }
        } else if (isCrypto) {
            const cryptoCurrency = currencies.crypto.find(currency => currency.value === currencyCode);
            if (cryptoCurrency) {
                return cryptoCurrency.text;
            }
        }

        return normalizeString(currencyCode);
    };

    const currencyCode = params.value as string;
    const logo = `https://abcmob-public.s3.eu-central-1.amazonaws.com/currency_logo/${currencyCode.toUpperCase()}.png`;

    const description = getDescription(currencyCode);

    return (
        <Grid container alignItems='center' >
            <Grid item >
                <Avatar sx={theme => ({ ...theme.typography.mediumAvatar })} src={logo} />
            </Grid>
            <Grid container item xs pl='0.5rem' alignItems='center'  >
                <Grid item xs={12} height='1rem'>
                    <Typography variant="body1">{currencyCode}</Typography>
                </Grid>
                <Grid item xs={12} height='1rem' >
                    <Typography variant='caption' color='text.secondary'>{description}</Typography>
                </Grid>
            </Grid>
        </Grid>);
};

export default AssetCellWithUrl;