import { Box, useTheme, useMediaQuery, Grid, IconButton } from '@mui/material';
import { logout } from '@/redux/user/userSlice';
import { dispatch, useSelector } from '@/store/store';
import { useTranslation } from 'react-i18next';
import { NavItemType } from '@/types';
import { memo } from 'react';
import { appBarHeight, iconSidebarSizeLarge, pageTopMargin, sidebarLargeIconSize, sidebarLargeIconViewbox } from '@/utils/constant';
import MenuList from './MenuList';
import NavItem from './NavItem';
import { isEmpty } from 'lodash';
import LogoutExit from '@/icons/LogoutExit';
import LogoMenu from '@/components/LogoMenu';
import CloseSidebar from '@/icons/menuIcons/CloseSidebar';
import OpenSidebar from '@/icons/menuIcons/OpenSidebar';
import getWlpSections from '../sections/wlpSections';
import WlpLogoMenu from '@/components/WlpLogo';
import getEmiSections from '../sections/emiSections';


interface Props {
    drawerOpen: boolean,
    onClick: () => void
}

const DrawerContent = ({ drawerOpen, onClick }: Props) => {
    const theme = useTheme();

    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const { permissions, emiSections } = useSelector((state) => state.user);
    const { isEmi } = useSelector((state) => state.credentials);

    const { t } = useTranslation('translations');

    const iconSize = drawerOpen ? sidebarLargeIconSize :
        matchDownSm ? sidebarLargeIconSize - 5 : sidebarLargeIconSize;

    const sections = isEmi ? getEmiSections(permissions, t, iconSize, sidebarLargeIconViewbox, emiSections) : getWlpSections(permissions, t, iconSize, sidebarLargeIconViewbox, emiSections);
    const filteredSections = sections
        .filter(section =>
            section.items && section.items.some(item => item.allowed === true)
        )
        .map(section => ({
            ...section,
            items: section.items.filter(item => item.allowed === true)
        }));

    const allPagesExists = !isEmpty(filteredSections);

    const logoutNavItem: NavItemType = {
        id: 'logout',
        title: t('form.buttons.logout'),
        type: 'item',
        icon: <LogoutExit sx={{ fontSize: 20 }} />,
        allowed: true
    };

    const iconSidebarSize = drawerOpen ? iconSidebarSizeLarge : matchDownSm ? iconSidebarSizeLarge - 5 : iconSidebarSizeLarge;

    return (
        <Grid>
            {drawerOpen &&
                <Grid item
                    sx={{
                        borderBottom: `1px solid ${theme.palette.white.light}`,
                    }}>
                    <Box sx={{
                        pl: 3,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        minHeight: `${appBarHeight}px`,
                    }}>
                        {isEmi ? <LogoMenu /> : <WlpLogoMenu />}
                        <IconButton aria-label="close" onClick={onClick}>
                            <CloseSidebar sx={{ fontSize: iconSidebarSize }} viewBox="0 0 20 20" />
                        </IconButton>
                    </Box>
                </Grid>
            }

            {!drawerOpen &&
                <Grid item
                    sx={{
                        borderBottom: `1px solid ${theme.palette.white.light}`,
                    }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: `${appBarHeight}px`,
                    }}>
                        <IconButton aria-label="close" onClick={onClick}>
                            <OpenSidebar sx={{ fontSize: iconSidebarSize }} viewBox="0 0 20 20" />
                        </IconButton>
                    </Box>
                </Grid>
            }

            {
                filteredSections &&
                <Box sx={{ background: theme.palette.primary.dark, ...(!allPagesExists && { pt: `${pageTopMargin}px` }) }}>
                    <MenuList sections={filteredSections} open={drawerOpen} />
                </Box>
            }

            <Grid container sx={{
                background: theme.palette.primary.dark,
                py: 1,
                flex: 1,
                alignItems: 'flex-end'
            }}>
                <Grid item xs={12} sx={{ borderTop: `1px solid ${theme.palette.white.light}` }}>
                    <Grid item my={1}>
                        <NavItem key={logoutNavItem.id} item={logoutNavItem} open={drawerOpen}
                            onClick={async () => {
                                await dispatch(logout())
                            }} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid >)
};

export default memo(DrawerContent);