import { Grid, IconButton, InputAdornment, Box as MuiBox, TextField, styled } from '@mui/material';
import Search2 from '@/icons/Search2';
import { useTranslation } from 'react-i18next';
import XClose from '@/icons/XClose';

const BoxStyled = styled(MuiBox)(
    ({ theme, }) => ({
        p: 2,
        display: 'flex',
        alignItems: 'center',
        border: '1px solid ' + theme.palette.secondary.main,
        borderRadius: '10px',
    }),
);

interface Props {
    inputValue: string;
    setInputValue: (value: string) => void;
    fullSize?: boolean;
}

const Search = ({ inputValue, setInputValue, fullSize = false }: Props) => {
    const { t } = useTranslation();

    const clear = () => {
        setInputValue('');
    };

    return (
        <Grid container item>
            <BoxStyled width={fullSize ? '100%' : 'auto'}>
                <TextField
                    value={inputValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setInputValue(event.target.value)
                    }}
                    placeholder={t('form.buttons.search').toString()}
                    fullWidth
                    sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'unset',
                        },
                        '& .MuiInputBase-root': {
                            px: 0
                        },
                        minWidth: '330px',
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <Search2 sx={{ fontSize: '0.813rem' }} />
                                </IconButton>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            inputValue ? (
                                <InputAdornment sx={{ mr: 0.5 }} position="end">
                                    <IconButton onClick={clear}>
                                        <XClose sx={{ fontSize: '1.063rem' }} />
                                    </IconButton>
                                </InputAdornment>
                            ) : null
                        )
                    }}
                />
            </BoxStyled >
        </Grid >
    );
}

export default Search;