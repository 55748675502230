
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Credentials } from './types';
import { decodeJWT } from '@/utils/decoder';

export const credentialsKey = process.env.REACT_APP_CREDENTIALS_KEY ?? 'credentials';

const savedCredentials = sessionStorage.getItem(credentialsKey);

const initialState: Credentials = {
	email: null,
	token: null,
	ownerRole: false,
	userId: null,
	userClientId: null,
	userFirstName: null,
	userLastName: null
};

if (savedCredentials) {
	try {
		const credentials = JSON.parse(savedCredentials);
		initialState.email = credentials.email;
		initialState.token = credentials.token;
		initialState.userId = credentials.userId;
		initialState.userClientId = credentials.userClientId;
		initialState.userFirstName = credentials.userFirstName;
		initialState.userLastName = credentials.userLastName;
		initialState.isEmi = credentials.isEmi;
		initialState.wlp = credentials.wlp;
		initialState.agent = decodeJWT(credentials.token).agent
		initialState.ownerRole = credentials.ownerRole;
	}
	catch (e) {
		console.log(e);
	}
}

const credentialsSlice = createSlice({
	name: 'credentials',
	initialState,
	reducers: {
		setCreditials(state, action: PayloadAction<Credentials>) {
			state.token = action.payload.token;
			state.email = action.payload.email;
			state.isEmi = action.payload.isEmi;
			state.agent = decodeJWT(action.payload.token ?? '').agent;
			state.wlp = action.payload.wlp;
			state.userId = action.payload.userId;
			state.userClientId = action.payload.userClientId;
			state.userFirstName = action.payload.userFirstName;
			state.userLastName = action.payload.userLastName;
			state.expireTime = action.payload.expireTime;
			state.ownerRole = action.payload.ownerRole;
			sessionStorage.setItem(credentialsKey, JSON.stringify(
				{
					email: action.payload.email,
					token: action.payload.token,
					isEmi: action.payload.isEmi,
					wlp: action.payload.wlp,
					userId: action.payload.userId,
					ownerRole: action.payload.ownerRole
				}
			));
		},
		clearCreditials(state) {
			state.token = null;
			state.email = null;
			state.userId = null;
			state.userClientId = null;
			state.userFirstName = null;
			state.userLastName = null;
			sessionStorage.removeItem(credentialsKey);
		},
		clearEmi(state) {
			state.isEmi = false;
		},
		setTokenData(state, action: PayloadAction<Credentials>) {
			state.token = action.payload.token;
			state.expireTime = action.payload.expireTime;
			sessionStorage.setItem(credentialsKey, JSON.stringify(
				{
					...state,
					token: action.payload.token,
				}
			));
		},
	}
});

export const { setCreditials, clearCreditials, clearEmi, setTokenData } = credentialsSlice.actions;

export const credentialsSelector = (state: { credentialsStore: Credentials }) =>
	state.credentialsStore;

export default credentialsSlice.reducer;
