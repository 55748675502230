import { useSelector } from '@/store/store';
import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { GuardProps } from 'types';
import { checkAtLeastOnePermission, checkAtLeastOneWritePermission, checkPermission, checkWritePermission } from '../permissions';
import PageInDevelopment from '@/pages/maintenance/PageInDevelopment';

interface Props extends GuardProps {
    permission?: string,
    userPermissions?: Array<string>,
    inDevelopment?: boolean
}

const PermissionGuard = ({ children, permission, userPermissions, inDevelopment }: Props) => {
    const navigate = useNavigate();

    const { permissions } = useSelector(
        (state) => state.user
    );
    const hasPermission = permission ? checkPermission(permissions, permission) : userPermissions ? checkAtLeastOnePermission(permissions, userPermissions) : false;
    const hasWritePermission = permission ? checkWritePermission(permissions, permission) : userPermissions ? checkAtLeastOneWritePermission(permissions, userPermissions) : false;

    useEffect(() => {
        if (!hasPermission) {
            navigate('/not-permitted');
        }
    }, [hasPermission, navigate]);

    if (inDevelopment) {
        return <PageInDevelopment />;
    }

    return <>
        {React.isValidElement(children) &&
            React.cloneElement(children as React.ReactElement<any>,
                { writePermission: hasWritePermission })
        }
    </>
};

export default PermissionGuard;
