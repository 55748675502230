import { PaginateParams } from "@/types/pagination"
import { UserType } from "../user/types";


export interface UserLevelsStore {
    loading: boolean;
    list: Array<UserLevel>;
    error: string | null;
    count: number;
    pagination: PaginateParams;
    tabs: { [key: string]: any };
};

export interface UserLevelPrice {
    userLevelPriceId: number;
    userLevelId: number;
    price: number;
    ccy: string;
    currencySymbol: string;
    disabled: boolean;
    userId: number;
    countryCode: string;
    riskGroupCode: string;
    userProfileType: null | UserType.INDIVIDUAL | UserType.BUSINESS;
};

export interface UserLevel {
    userLevelId: number;
    name: string;
    description: string;
    priority: number;
    defaultLevel: boolean;
    numberOfUsers: number;
    prices: Array<UserLevelPrice>;
};

export enum UserLevelTab {
    FEES = 'FEES'
};

export interface EditPlatformPlanPayload {
    name: string;
    description: string;
    priority: number,
    defaultLevel: boolean,
    prices: Array<EditPrice>;
};

export interface CreatePlatformPlanPayload {
    name: string;
    description: string;
    defaultLevel: boolean,
    prices: Array<EditPrice>;
};

interface EditPrice {
    userProfileType: UserType;
    price: number;
};