import { FormHelperText, InputAdornment, TextField, TextFieldProps, useTheme } from "@mui/material";
import { useField } from "formik";
import { NumericFormat } from "react-number-format";

type Props = {
    name: string;
    label: string;
    adornmentStart?: string;
    adornmentEnd?: string;
    decimalScale?: number;
    value?: number | string;
    disabled?: boolean;
    errors?: any;
    maxLimit?: number;
    minLimit?: number;
    fixedDecimalScale?: boolean;
    inputPropsSx?: object;
    onChange?: () => void
} & TextFieldProps;

const FormNumericField = ({ name, label, adornmentStart, adornmentEnd, decimalScale, value, disabled = false, errors, maxLimit, minLimit, fixedDecimalScale = false, inputPropsSx, onChange, ...otherProps }: Props) => {
    const theme = useTheme()

    const [field, meta, helpers] = useField(name);

    const configTextfield = {
        ...field,
        ...otherProps,
    };
    if (meta && meta.touched && meta.error) {
        configTextfield.error = true;
        configTextfield.helperText = meta.error;
    }

    const handleChange = (event: { target: { value: number } }) => {
        helpers.setValue(event.target.value);
        if (onChange) onChange();
    };

    const inputProps = {
        ...(adornmentStart && {
            startAdornment: (
                <InputAdornment position="start">
                    {adornmentStart}
                </InputAdornment>
            ),
        }),
        ...(adornmentEnd && {
            endAdornment: (
                <InputAdornment position="end">
                    {adornmentEnd}
                </InputAdornment>
            ),
        }),
        ...(inputPropsSx && {
            sx: inputPropsSx,
        }),
    };

    return (
        <>
            <NumericFormat
                fullWidth
                label={label}
                value={value}
                customInput={TextField}
                displayType={'input'}
                fixedDecimalScale={fixedDecimalScale}
                decimalScale={decimalScale}
                thousandsGroupStyle='thousand'
                disabled={disabled}
                thousandSeparator={true}
                InputProps={inputProps}
                isAllowed={(values) => {
                    const { floatValue } = values;
                    if (maxLimit !== undefined && minLimit !== undefined) {
                        return floatValue === undefined || (floatValue <= maxLimit && floatValue >= minLimit);
                    } else if (maxLimit !== undefined) {
                        return floatValue === undefined || floatValue <= maxLimit;
                    } else if (minLimit !== undefined) {
                        return floatValue === undefined || floatValue >= minLimit;
                    }
                    return true;
                }}
                sx={{
                    ...(errors && {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.error.main,
                        },
                    }),
                }}
                onValueChange={(values) => {
                    handleChange({ target: { value: parseFloat(values.value) } });
                }} />
            {errors &&
                <FormHelperText error sx={{ mx: '14px' }}>{errors}</FormHelperText>}
        </>
    );
};

export default FormNumericField;