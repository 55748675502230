
import { ConfigProps } from 'types/config';
export const BASE_PATH = '/';
export const DASHBOARD_PATH = '/dashboard';
export const ONBOARDING_PATH = '/onboarding';

const config: ConfigProps = {
    fontFamily: `'CoFoSans', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light',
    presetColor: 'default',
    locale: 'en',
    rtlLayout: false,
    container: false
};

export default config;
