import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step, { stepClasses } from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Grid } from '@mui/material';

export const stepperHeight = 5;

const FullLineConnector = styled(StepConnector)(({ theme }) => ({

	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			background: theme.palette.primary.main,

		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			background: theme.palette.primary.main,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderTopWidth: '0px',
	},
}));


const StypledStep = styled(Step)(({ theme }) => ({

	[`&.${stepClasses.root}`]: {
		background: theme.palette.secondary.main,
		height: stepperHeight + 'px'
	},
	[`&.${stepClasses.completed}`]: {
		background: theme.palette.primary.main,
	},
}));


interface Props {
	stepCount: number,
	activeStep: number
}

export default function LineStepper({ stepCount, activeStep }: Props) {
	return (
		<Grid container id='line-stepper'>
			<Grid item xs={12}>
				<Stepper alternativeLabel
					sx={{ width: '100%' }}
					activeStep={activeStep ?? 0}
					connector={<FullLineConnector />}>
					{Array(stepCount).fill(1).map((el, idx) => (
						<StypledStep key={idx} />
					))}
				</Stepper>
			</Grid>
		</Grid>
	);
}
