import { PaginateParams } from "@/types/pagination";
import { TransferDetails } from "../emiFinanceTransfers/emiFinanceTransfersTypes";
import { IntegrationDetailsType } from "../emiFinance/emiFinanceTypes";
import { PaymentTypeEnum } from "@/types/paymentTypes";

export interface EmiFinanceCardsStore {
    integrations: Array<CardsIntegartion>;
    integrationsLoading: boolean;
    activeTab: string;

    integrationBalances: IntegrationBalanceRecord;
    cardTopUpData: CardTopUpData;
    cardsTransfersActiveTab: IntergrationTransfersBreakdownTabsEnum;
    cardsTransfers: {
        [key: string]: {
            loading: boolean;
            list: Array<TransferDetails>;
            error: string | null;
            count: number;
            pagination: PaginateParams;
        };
    }
};

export interface CardTopUpData {
    [integrationKey: string]: {
        loading: boolean;
        cardsTopUpAvailability: Array<IntegrationDetailsType>;
        cardsSettlements: Array<IntegrationDetailsType>;
        cardsTotalReconciledTopUp: Array<IntegrationDetailsType>;
        cardsTotalTopUp: Array<IntegrationDetailsType>;
        isEmiPartner: boolean;
        integrationDescription: string;
        partner: CardsIntegartionPartner
        currencyList: string;
        serviceOwner: string;
        emi: string;
        integrationType: string;
    };
}

export interface IntegrationBalanceRecord {
    [integrationKey: string]: {
        loading: boolean;
        integrationDescription: string;
        integrationBalance: Array<IntegrationDetailsType>;
    };
}

export interface CardsIntegartion {
    id: number;
    emi: string;
    serviceOwner: string;
    integration: string;
    integrationDescription: string;
    dataSourceType: string;
    partnerList: Array<CardsIntegartionPartner>;
    currencyList: string;
};

export interface CardsIntegartionPartner {
    id: number;
    emi: string;
    serviceOwner: string;
    integration: string;
    integrationDescription: string;
    dataSourceType: string;
};

export interface CardsCustomerBreakdownBalances {
    id: number;
    emi: string;
    emiWlpId: string;
    uiType: string;
    integration: string;
    ownerKey: string;
    intGroup: string;
    groupHeader: string;
    description: string;
    isVisible: string;
    cpNameCode: string;
    cpDescription: string;
    cpMeaning: string;
    wlpId: string;
    currencyCode: string;
    currencyLogo: string;
    amount: number;
    amountEUR: number;
    totalAmount: string;
    totalAmountEUR: string;
    detailList: Array<CardsCustomerBreakdownDetails>;
};

export interface CardsCustomerBreakdownDetails {
    integration: string;
    ownerKey: string;
    intGroup: string;
    groupHeader: string;
    wlpId: string;
    emiWlpId: string;
    isVisible: string;
    cpNameCode: string;
    cpDescription: string;
    cpMeaning: string;
    cpOrder: string;
    currencyCode: string;
    currencyLogo: string;
    amount: number;
    amountEur: number;
    amountTotal: string;
    amountTotalEur: string;
    pkey: string;
};

export enum IntergrationTransfersBreakdownTabsEnum {
    SETTLEMENT_CARDS = "SETTLEMENT_CARDS",
    TOP_UP_AVIALABILITY = "TOP_UP_AVIALABILITY",
    PAYMENTS_FROM_PARTNERS = "PAYMENTS_FROM_PARTNERS",
    INTERNAL_PAYMENTS = "INTERNAL_PAYMENTS",
    CARD_TOP_UPS = "CARD_TOP_UPS",
}

export interface DepositPostType {
    paymentId: number | null,
    paymentConfigId: number,
    allowSplit: string,
    emiAccountId: number,
    currencyCode: string,
    amount: number,
    paymentType: PaymentTypeEnum,
    notes?: string,
    createdBy?: number,
}

