import { appBarHeight, mainPaddingLeft, mainPaddingRight } from '@/utils/constant';
import { Theme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

const Typography = (theme: Theme, fontFamily: string): TypographyOptions => ({
    fontFamily,
    h1: {
        fontSize: '1.75rem',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem',
            lineHeight: '1.5rem',
        }
    },
    h2: {
        fontSize: '1.25rem',
        fontWeight: 500,
        lineHeight: '1.5rem',
    },
    h3: {
        fontSize: '0.938rem',
        fontWeight: 700,
        lineHeight: '1.375rem',
    },
    h5: {
        fontWeight: 700,
        fontSize: '0.75rem'
    },
    h4: {
        fontSize: '1rem',
        color: theme.palette.text.secondary,
        fontWeight: 400
    },
    h6: {
        fontWeight: 400,
        color: theme.palette.text.secondary,
        fontSize: '0.75rem'
    },
    subtitle2: {
        fontSize: '2.25rem',
        color: theme.palette.text.primary,
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
        }
    },
    caption: {
        fontSize: '0.688rem',
        lineHeight: '17px',
        fontWeight: 400
    },
    body1: {
        fontSize: '0.938rem',
        fontWeight: 400,
        lineHeight: '22px'
    },
    body2: {
        fontSize: '0.813rem',
        lineHeight: '20px',
        fontWeight: 400,
    },
    mainContent: {
        backgroundColor: theme.palette.primary.light,
        width: '100%',
        minHeight: `calc(100vh - ${appBarHeight + 20}px)`,
        marginTop: `${appBarHeight}px`,
        flexGrow: 1,
    },
    mainContentPadding: {
        [theme.breakpoints.up('md')]: {
            bgColor: 'red',
            paddingLeft: `${mainPaddingLeft}px`,
            paddingRight: `${mainPaddingRight}px`,
            paddingTop: '20px',
            paddingBottom: '50px',
        },
        [theme.breakpoints.down('md')]: {
            bgColor: 'red',
            paddingLeft: `${mainPaddingLeft - 20}px`,
            paddingRight: `${mainPaddingRight - 20}px`,
            paddingTop: '20px',
            paddingBottom: '50px',
        },
        [theme.breakpoints.down('sm')]: {
            bgColor: 'red',
            paddingLeft: `${mainPaddingLeft - 50}px`,
            paddingRight: `${mainPaddingRight - 50}px`,
            paddingTop: '20px',
            paddingBottom: '50px',
        }
    },
    button: {
        textTransform: 'capitalize',
        fontWeight: 700,
        fontSize: '0.938rem',
        lineHeight: '0.938rem',
    },
    smallAvatar: {
        bgcolor: 'transparent',
        width: '1rem',
        height: '1rem',
    },
    mediumAvatar: {
        bgcolor: 'transparent',
        borderRadius: '50%',
        width: '2rem',
        height: '2rem',
        fontSize: '1rem'
    },
    largeAvatar: {
        bgcolor: 'transparent',
        borderRadius: '50%',
        width: '2.625rem',
        height: '2.625rem',
    }
});

export default Typography;
