import XClose from "@/icons/XClose";
import { Box, DialogTitle as MuiDialogTitle, DialogTitleProps, IconButton } from "@mui/material";

export interface Props extends DialogTitleProps {
  children?: React.ReactNode;
  onClose?: () => void;
}

const DialogTitle = (props: Props) => {
  const { children, onClose, ...other } = props;

  return (
    <MuiDialogTitle {...other} sx={{ mb: '0px' }}>
      <Box display='flex' justifyContent={children ? 'space-between' : 'flex-end'} alignItems='center'>
        {children}
        {onClose ? (
          <IconButton
            onClick={onClose} >
            <XClose />
          </IconButton>
        ) : null}
      </Box>
    </MuiDialogTitle>
  );
}

export default DialogTitle;
