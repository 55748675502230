import { SvgIcon } from '@mui/material';

const PaymentOrder = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#2B2B2B" fillOpacity="0.06" />
            <path d="M7.33398 12H16.6673M16.6673 12L12.6673 8M16.6673 12L12.6673 16" stroke="#EF3030" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    </SvgIcon>
);
export default PaymentOrder;