import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import request from '../../services/request';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '@/store/store';
import { showErrorNotification } from '@/utils/errors';
import { ReportsStore, Report } from './reportsTypes';

export const paginationParamsInit = {
    filter: '',
    orderBy: 'createdDate',
    sort: SortDirectionEnum.DESC,
    take: 15,
    skip: 0
};

const initialState: ReportsStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    pagination: paginationParamsInit
};

const slice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        setReports(state, action: PayloadAction<Array<Report>>) {
            state.list = action.payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
            state.pagination = payload;
        }
    }
});
export default slice.reducer;

export const { setReports, setLoading, setError, setCount, setPagination } = slice.actions;

export const getReportsList = (payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/reports/list`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setReports(data.reportRuns));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            dispatch(setLoading(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading(false));
        }
    };
};

export const getAllReportsConfig = async () => {
    const response = await request.get(`/api/console/reports/config/all-reports`);
    const { data } = response;
    return data;
};

export const getAllReportsConfigParameters = async (reportCode: string) => {
    const response = await request.get(`/api/console/reports/config/parameters/${reportCode}`);
    const { data } = response;
    return data;
};


export const downloadReportFile = async (
    reportId: number,
    fileType: string,
    extension: string,
    name: string,
    reportCode: string
) => {
    const response = await request.get(`/api/console/reports/download/${reportId}/${reportCode}/${fileType}?extension=${extension}`,
        {
            responseType: 'blob',
        });
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.download = `${name}_${new Date().getTime()}`;
    link.href = url;
    link.className = 'hidden';
    document.body.appendChild(link);

    link.onclick = function () {
        requestAnimationFrame(function () {
            URL.revokeObjectURL(url);
            setTimeout(() => link.remove(), 300);
        });
    };

    link.click();
};

export const getReportDownloadUrl = async (reportId: number, reportCode: string, fileType: string) => {
    const response = await request.get(`/api/console/reports/get/url/${reportId}/${reportCode}/${fileType}`);
    const { data } = response;
    return data;
};

export const startReportGeneration = async (reportCode: string, parameters: any) => {
    const response = await request.post(`/api/console/reports/run`, { reportCode, parameters });
    const { data } = response;
    return data;
};

export const submitReport = async (reportRunId: number) => {
    const response = await request.post(`/api/console/reports/submit`, { reportRunId });
    const { data } = response;
    return data;
};


