import { Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { t } from "i18next";
import { alpha3ToAlpha2, getNames } from 'i18n-iso-countries';

export const getCountryNameFromIso2 = (iso2Code: string) => {
    const countries = getNames("en");
    return countries[iso2Code] || iso2Code;
};

export const getCountryNameFromIso3 = (iso3Code: string) => {
    const iso2Code = alpha3ToAlpha2(iso3Code);
    const countries = getNames("en");
    return countries[iso2Code] || iso3Code;
};

const IpLocationCell = (params: GridRenderCellParams) => {
    if (!params.value && (!params.row.location || params.row.location === 'Unknown')) {
        return t('form.fields.unknown');
    }

    const [countryCode, cityName] = params.row.location && params.row.location !== 'Unknown'
        ? params.row.location.split(',').map((part: string) => part.trim())
        : ['', ''];

    const country = getCountryNameFromIso2(countryCode);
    const city = cityName || '';

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="body1">{params.value}</Typography>
            </Grid>
            <Grid item xs={12}>
                {(!params.row.location || params.row.location === 'Unknown') ?
                    <Typography variant="body2">{t('form.fields.unknown')}</Typography> :
                    <Grid container item xs={12} alignItems='center' columnGap={0.4}>
                        <img
                            loading="lazy"
                            width="16"
                            height="10"
                            src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png 2x`}
                            alt="Country flag"
                        />
                        <Typography variant="body2">{`${country}${city ? `, ${city}` : ''}`}</Typography>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};

export default IpLocationCell;