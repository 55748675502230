


import { startCase } from "lodash";

/* Changes values like PENDING_EXTERNALLY to Pending externally*/
export const normalizeString = (value: string) => {

    const v = value ? value.toString().toUpperCase().replaceAll('_', ' ') : '';
    return v.charAt(0).toUpperCase() + v.slice(1).toLowerCase();
}

/* Changes values like VaultBalancingSystemAccount to Vault balancing system account*/
export const normalizeCamelCaseString = (value: string) => {
    const v = value.match(/[A-Za-z][a-z]*/g) || [];
    const vJoined = v.join(" ");
    return vJoined.charAt(0).toUpperCase() + vJoined.slice(1).toLowerCase();
}

/* Changes values like PENDING_EXTERNALLY to Pending Externally*/
export const capitalCaseString = (value: string) => {

    const v = value ? value.toString().toUpperCase().replaceAll('_', ' ') : '';
    return startCase(v.toLowerCase());
}

export const getCurrencyNameFormater = () => new Intl.DisplayNames('en-US', {
    type: 'currency'
});
