import * as React from "react";
import { SvgIcon } from '@mui/material';

const Earn = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path opacity="0.5" d="M1.66797 10.0013C1.66797 6.07293 1.66797 4.10875 2.88836 2.88836C4.10875 1.66797 6.07293 1.66797 10.0013 1.66797C13.9297 1.66797 15.8939 1.66797 17.1142 2.88836C18.3346 4.10875 18.3346 6.07293 18.3346 10.0013C18.3346 13.9297 18.3346 15.8939 17.1142 17.1142C15.8939 18.3346 13.9297 18.3346 10.0013 18.3346C6.07293 18.3346 4.10875 18.3346 2.88836 17.1142C1.66797 15.8939 1.66797 13.9297 1.66797 10.0013Z" stroke="currentColor" strokeWidth="1.2" />
            <path d="M5.83203 11.6654L7.74278 9.75462C8.06821 9.42918 8.59585 9.42918 8.92129 9.75462L10.2428 11.0761C10.5682 11.4015 11.0959 11.4015 11.4213 11.0761L14.1654 8.33203M14.1654 8.33203V10.4154M14.1654 8.33203H12.082" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);
export default Earn;