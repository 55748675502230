import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '@/utils/errors';
import { CreateLimitPayloadType, LimitStore, UserLimitType } from './limitTypes';
import { TurnoverLimit } from '../platformLimits/platformLimitsTypes';

const initialState: LimitStore = {
    loading: false,
    userLimits: [],
    error: null,
};

const slice = createSlice({
    name: 'userLimits',
    initialState,
    reducers: {
        setUserLimits(state, action: PayloadAction<Array<UserLimitType>>) {
            state.userLimits = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        }
    }
});

export const { setLoading, setUserLimits } = slice.actions;

export const getUserAccountsLimits = (userId: number) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/limits/user/${userId}`);
            dispatch(setUserLimits([response.data] || []));
        } catch (e) {
            showErrorNotification(e);
        }
        finally { dispatch(setLoading(false)); }
    }
};

export const createUserLimit = async (userId: number, limitId: number, payload: CreateLimitPayloadType): Promise<TurnoverLimit> => {
    const response = await request.post(`/api/console/limits/create/user/${userId}/${limitId}`, payload)
    const { data } = response;
    return data;
}

export const getUseCurrencyLimitsData = async (ccy: string, userId: number): Promise<UserLimitType> => {
    const response = await request.get(`/api/console/limits/operation/${ccy}/${userId}`);
    return response?.data;
};

export default slice.reducer;