import React, { useEffect, useState } from 'react';
import { Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Popover, TextField, styled, Box as MuiBox, Divider, Checkbox, useTheme, Button, InputAdornment, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import Search2 from '@/icons/Search2';
import ChevronDown2 from '@/icons/ChevronDown2';
import { chain, orderBy } from 'lodash';
import TypographyWithEllipsis from '../TypographyWithEllipsis';

export enum HeaderFilterType {
    WLPS = 'WLPS',
    STATUS = 'STATUS',
    CURRENCY = 'CURRENCY',
    CURRENCY_TO = 'CURRENCY_TO',
    CURRENCY_FROM = 'CURRENCY_FROM',
    PROCESSES = 'PROCESSES',
    RISK_GROUPS = 'RISK_GROUPS',
    REGION_CODE = 'REGION_CODE',
    USER_TYPES = 'USER_TYPES',
    TYPE = 'TYPE',
};

const BoxStyled = styled(MuiBox)(
    () => ({
        p: 2,
        display: 'flex',
        alignItems: 'center',
    }),
);

interface FilterSubValue {
    value: string;
    text: string;
    enabled: boolean;
}
export interface FilterOptionType {
    value: string;
    text: string;
    enabled: boolean;
    subValues?: Array<FilterSubValue>;
};

interface Props {
    fieldName: string;
    filterType: HeaderFilterType;
    headerName: string;
    options: Array<FilterOptionType>;
    withSubValues?: boolean;
    subFieldName?: string;
    allSubsWithNull?: boolean;
    updatePaginationParams?: (fieldName: string, filter: string, subfieldName?: string) => void;
    setFilterOptions: (filterType: HeaderFilterType, filterOptions: Array<FilterOptionType>) => void;
};

const getCheckboxState = (filterOptions: Array<FilterOptionType>) => {
    const allEnabled = filterOptions.every(option => option.enabled);
    const allDisabled = filterOptions.every(option => !option.enabled);

    if (filterOptions.some(option => option.subValues && option.subValues.length > 0)) {
        const someChecked = filterOptions.some(option => option.subValues?.some(subValue => subValue.enabled));
        const allChecked = filterOptions.every(option => option.subValues?.some(subValue => subValue.enabled));
        if (allChecked) {
            return { checked: true, indeterminate: false };
        } else if (someChecked) {
            return { checked: false, indeterminate: true };
        } else {
            return { checked: false, indeterminate: false };
        }
    };

    if (allEnabled) return { checked: true, indeterminate: false };
    if (allDisabled) return { checked: false, indeterminate: false };
    return { checked: false, indeterminate: true };
};

const getOptions = (options: Array<FilterOptionType>) => {
    return chain(options)
        .orderBy(['text'], ['asc'])
        .map(option => ({
            ...option,
            subValues: option.subValues ? orderBy(option.subValues, ['text'], ['asc']) : option.subValues,
        }))
        .value();
}

const CustomFilterHeader = ({ fieldName, filterType, headerName, options, withSubValues = false, subFieldName, allSubsWithNull = false, updatePaginationParams, setFilterOptions }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const filterOptions = options;
    const orderedOptions = getOptions(filterOptions);
    const [inputValue, setInputValue] = useState('');

    const [checkboxState, setCheckboxState] = useState(getCheckboxState(filterOptions));

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        // Prevent the event from reaching the column sort handler
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const filteredByInputOptions = orderedOptions.filter(option =>
        option.text.toLowerCase().includes(inputValue.toLowerCase())
    );

    const handleCheckboxChange = (changedOption: FilterOptionType) => () => {
        const updatedFilterOptions = filterOptions.map(option => option.value === changedOption.value ? { ...option, enabled: !option.enabled } : option);
        setFilterOptions(filterType, updatedFilterOptions);
    };

    useEffect(() => {
        setCheckboxState(getCheckboxState(filterOptions));
    }, [filterOptions]);

    const handleSectionChange = (changedOption: FilterOptionType) => () => {
        const updatedFilterOptions = filterOptions.map(option =>
            option.value === changedOption.value
                ? {
                    ...option,
                    enabled: !option.enabled,
                    subValues: option.subValues?.map(subValue => ({ ...subValue, enabled: !option.enabled }))
                }
                : option
        );
        setFilterOptions(filterType, updatedFilterOptions);
    };

    const handleSubValuesChange = (parentOption: FilterOptionType, changedSubOption: FilterSubValue) => () => {
        const updatedFilterOptions = filterOptions.map(option => {
            if (option.value === parentOption.value) {
                const updatedSubValues = option.subValues?.map(subValue =>
                    subValue.value === changedSubOption.value
                        ? { ...subValue, enabled: !subValue.enabled }
                        : subValue
                );
                const areAllDisabled = updatedSubValues?.every(subValue => !subValue.enabled);
                return {
                    ...option,
                    enabled: !areAllDisabled,
                    subValues: updatedSubValues
                };
            }
            return option;
        });

        setFilterOptions(filterType, updatedFilterOptions);
    };

    const handleOnSelectAll = () => {
        const newCheckedState = !checkboxState.checked;
        setCheckboxState({ checked: newCheckedState, indeterminate: false });
        setFilterOptions(filterType, filterOptions.map(option => ({
            ...option,
            enabled: newCheckedState,
            subValues: option.subValues ? option.subValues.map(subValue => ({
                ...subValue,
                enabled: newCheckedState
            })) : option.subValues
        })));
    };

    const handleOnApply = () => {
        // If all are enabled just don't pass the filter value
        if (filterOptions.every(option => option.enabled)) {
            updatePaginationParams && updatePaginationParams(fieldName, '', subFieldName);
        }
        else {
            if (!withSubValues || !subFieldName) {
                const filter = filterOptions.filter(item => item.enabled).map(item => item.value).join(',');
                const filterString = `${fieldName}=${filter}`
                updatePaginationParams && updatePaginationParams(fieldName, filterString);
            } else {
                const filter = filterOptions
                    .filter(option => option.enabled)
                    .map(option => option.value)
                    .join(',');

                const subFilter = filterOptions
                    .flatMap(item =>
                        item.enabled
                            ? item.subValues && item.subValues.length > 0
                                ? item.subValues.filter(subItem => subItem.enabled).map(subItem => subItem.value)
                                : [item.value]
                            : []
                    )
                    .join(',');

                const selectedAllSubValues = filterOptions.every(option =>
                    !option.enabled || (option.subValues && option.subValues.every(subItem => subItem.enabled))
                );

                const subFilterWithNull = (selectedAllSubValues && allSubsWithNull) ? `${subFilter},NULL` : subFilter;

                const filterString = `${fieldName}=${filter}`;
                const subFilterString = `${subFieldName}=${subFilterWithNull}`;

                updatePaginationParams && updatePaginationParams(fieldName, `${filterString};${subFilterString}`, subFieldName);
            }
        }
        handlePopoverClose();
    };

    return (
        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {headerName}
            <IconButton
                aria-label="custom-icon"
                size="small"
                onClick={handlePopoverOpen}
                sx={{ opacity: checkboxState.indeterminate ? 0.9 : 0.5 }}
            >
                <FilterAltIcon fontSize='small' />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Grid container sx={{ minWidth: withSubValues ? '330px' : 'auto', maxWidth: '370px' }}
                    direction='column'>
                    <Grid item sx={{ px: 1, py: 1, }}>
                        <BoxStyled>
                            <TextField
                                value={inputValue}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setInputValue(event.target.value);
                                }}
                                placeholder={t('form.buttons.search').toString()}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ pr: 0 }}>
                                                <Search2 sx={{ fontSize: '0.813rem' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </BoxStyled >
                    </Grid>
                    <Divider light />
                    <Grid item sx={{ maxHeight: '300px', overflow: 'auto' }}>
                        <List component="nav">
                            {(!filteredByInputOptions.some(option => option.subValues && option.subValues.length > 0) && !withSubValues) ?
                                <ListItem key={'selectAll'} disablePadding>
                                    <ListItemButton onClick={handleOnSelectAll}
                                        sx={{
                                            py: 0,
                                            color: theme.palette.secondary.main,
                                            '&:hover': {
                                                '& .MuiSvgIcon-root': {
                                                    color: theme.palette.secondary.main,
                                                }
                                            }
                                        }}>
                                        <Checkbox
                                            edge="start"
                                            checked={checkboxState.checked}
                                            indeterminate={checkboxState.indeterminate}
                                        />
                                        <ListItemText primary={t('form.buttons.selectAll')} />
                                    </ListItemButton>
                                </ListItem> :
                                <ListItem key={'selectAll'} disablePadding
                                    sx={{
                                        minHeight: '48px',
                                        pl: 1,
                                        borderBottom: '1px solid rgba(43, 43, 43, 0.06)',
                                        '&:last-child': {
                                            borderBottom: 'none'
                                        }
                                    }}>
                                    <ListItemButton onClick={handleOnSelectAll}
                                        sx={{
                                            py: 0,
                                            color: theme.palette.secondary.main,
                                            '&:hover': {
                                                '& .MuiSvgIcon-root': {
                                                    color: theme.palette.secondary.main,
                                                }
                                            }
                                        }}>
                                        <Checkbox
                                            edge="start"
                                            checked={checkboxState.checked}
                                            indeterminate={checkboxState.indeterminate}
                                        />
                                        <ListItemText primary={t('form.buttons.selectAll')} />
                                    </ListItemButton>
                                </ListItem>
                            }
                            {filteredByInputOptions.map((option) => {
                                if (option.subValues && option.subValues.length > 0) {
                                    const isSomeSubValuesEnabled = option.subValues.some(subValue => subValue.enabled);
                                    const areAllSubValuesEnabled = option.subValues.every(subValue => subValue.enabled);
                                    const isIndeterminate = isSomeSubValuesEnabled && !areAllSubValuesEnabled;
                                    return (
                                        <Accordion key={option.value} disableGutters
                                            sx={{
                                                '& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
                                                    minHeight: '48px',
                                                },
                                                '& .MuiAccordionSummary-root .MuiAccordionSummary-content': {
                                                    my: 0.1
                                                },
                                                '&.Mui-expanded': {
                                                    margin: 0
                                                },
                                            }}>
                                            <AccordionSummary
                                                expandIcon={<ChevronDown2 />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                sx={{ pl: 1 }}>
                                                <ListItemButton
                                                    sx={{
                                                        py: 0,
                                                        color: theme.palette.secondary.main,
                                                        '&:hover': {
                                                            '& .MuiSvgIcon-root': {
                                                                color: theme.palette.secondary.main,
                                                            }
                                                        }
                                                    }}>
                                                    <Checkbox
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={handleSectionChange(option)}
                                                        edge="start"
                                                        checked={areAllSubValuesEnabled}
                                                        indeterminate={isIndeterminate}
                                                    />
                                                    <ListItemText
                                                        primary={
                                                            <span>
                                                                {`${option.text} `}
                                                                <span style={{ color: theme.palette.secondary.dark }}>
                                                                    ({option.subValues.length})
                                                                </span>
                                                            </span>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ py: 0 }}>
                                                <List dense sx={{ py: 0 }}>
                                                    {option.subValues.map(subOption => (
                                                        <ListItem key={subOption.value} disablePadding>
                                                            <ListItemButton
                                                                onClick={handleSubValuesChange(option, subOption)}
                                                                sx={{
                                                                    py: 0,
                                                                    pl: 3,
                                                                    color: theme.palette.secondary.main,
                                                                    '&:hover': {
                                                                        backgroundColor: 'transparent',
                                                                        '& .MuiSvgIcon-root': {
                                                                            color: theme.palette.secondary.main,
                                                                        },
                                                                    },
                                                                    '& .MuiSvgIcon-root': {
                                                                        color: theme.palette.secondary.main,
                                                                    }
                                                                }}>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={subOption.enabled}
                                                                    sx={{
                                                                        color: theme.palette.secondary.main,
                                                                        '&.Mui-checked': {
                                                                            color: theme.palette.secondary.main,
                                                                        },
                                                                    }}
                                                                />
                                                                <ListItemText primary={subOption.text} />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </AccordionDetails>

                                        </Accordion>
                                    );
                                } else {
                                    return (
                                        <ListItem key={option.value} disablePadding>
                                            <ListItemButton onClick={handleCheckboxChange(option)}
                                                sx={{
                                                    py: 0,
                                                    color: theme.palette.secondary.main,
                                                    '&:hover': {
                                                        '& .MuiSvgIcon-root': {
                                                            color: theme.palette.secondary.main,
                                                        }
                                                    }
                                                }}>
                                                <Checkbox
                                                    edge="start"
                                                    checked={option.enabled}
                                                />
                                                <ListItemText primary={<TypographyWithEllipsis>{option.text}</TypographyWithEllipsis>} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                }
                            })}

                        </List>
                    </Grid>
                    <Divider light />
                    <Grid container item justifyContent="end" sx={{ py: 1, pr: 1 }}>
                        <Button color="primary" size='small' disabled={!checkboxState.checked && !checkboxState.indeterminate} onClick={handleOnApply}>
                            {t("form.buttons.apply")}
                        </Button>
                    </Grid>
                </Grid>
            </Popover >
        </Grid >
    );
};

export default CustomFilterHeader;

