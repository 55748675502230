import { createContext, ReactNode } from 'react';

import defaultConfig from 'config';
import useLocalStorage from 'hooks/useLocalStorage';
import { PaletteMode } from '@mui/material';
import { CustomizationProps } from 'types/config';


const initialState: CustomizationProps = {
...defaultConfig,
    onChangeMenuType: () => { },
    onChangeLocale: () => { },
    onChangeContainer: () => { },
    onChangeBorderRadius: () => { },
    onChangeOutlinedField: () => { }, 
};


const ConfigContext = createContext(initialState);

type ConfigProviderProps = {
    children: ReactNode;
};

function ConfigProvider({ children }: ConfigProviderProps) {
    const [config, setConfig] = useLocalStorage('scm-config', {
        fontFamily: initialState.fontFamily,
        borderRadius: initialState.borderRadius,
        navType: initialState.navType,
        locale: initialState.locale, 
    });

    const onChangeMenuType = (navType: PaletteMode) => {
        setConfig({
            ...config,
            navType
        });
    };


    const onChangeLocale = (locale: string) => {
        setConfig({
            ...config,
            locale
        });
    };


    const onChangeContainer = () => {
        setConfig({
            ...config,
            container: !config.container
        });
    };


    const onChangeBorderRadius = (event: Event, newValue: number | number[]) => {
        setConfig({
            ...config,
            borderRadius: newValue as number
        });
    };

    const onChangeOutlinedField = (outlinedFilled: boolean) => {
        setConfig({
            ...config,
            outlinedFilled
        });
    }; 


    return (
        <ConfigContext.Provider
            value={{
                ...config,
                onChangeMenuType,
                onChangeLocale,
                onChangeContainer,
                onChangeBorderRadius,
                onChangeOutlinedField 
            }}
        >
            {children}
        </ConfigContext.Provider>
    );
}

export { ConfigProvider, ConfigContext };
