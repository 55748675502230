import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types';
import { ONBOARDING_PATH } from '@/config';
import { dispatch } from '@/store/store';

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
    const { isAuthorized, isOnboarded } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const authorizeAndNavigate = async () => {
            if (!isAuthorized) {
                await navigate('/login', { replace: true });
                dispatch({ type: 'RESET_APP' });
            }
        };
        authorizeAndNavigate();
    }, [isAuthorized, navigate]);

    useEffect(() => {
        if (!isOnboarded) {
            navigate(ONBOARDING_PATH, { replace: true });
        }
    }, [isOnboarded, navigate]);

    return children;
};

export default AuthGuard;
