import { styled } from '@mui/material/styles';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { DASHBOARD_PATH } from 'config';
import { gridSpacing } from '@/utils/constant';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { activeItem } from '@/redux/menu/menuSlice';
import { dispatch } from '@/store/store';
import InDevelopment from '@/icons/InDevelopment';

const ErrorWrapper = styled('div')({
    maxWidth: 350,
    margin: '0 auto',
    textAlign: 'center'
});

const ErrorCard = styled(Card)({
    minHeight: '70vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});


const PageInDevelopment = () => {

    const { t } = useTranslation()
    const navigate = useNavigate();
    const handleHome = () => {
        navigate(DASHBOARD_PATH);
        dispatch(activeItem('dashboard'))
    };

    return (
        <ErrorCard>
            <CardContent>
                <Grid container justifyContent="center" spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <ErrorWrapper>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Typography variant="h1" component="div">
                                        {t('errors.inDevelopment')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <InDevelopment sx={{ fontSize: 400 }} viewBox="0 0 342 300" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="outlined" size="large" onClick={handleHome}>
                                        <HomeTwoToneIcon sx={{ fontSize: '1.3rem', mr: 0.75 }} /> {t('form.buttons.goToDashboard')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </ErrorWrapper>
                    </Grid>
                </Grid>
            </CardContent>
        </ErrorCard>
    );
};

export default PageInDevelopment;
