import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector, TypedUseSelectorHook } from 'react-redux';
import rootReducer, { RootState } from './reducer';


const store = configureStore({
    reducer: rootReducer
});


export type AppDispatch = typeof store.dispatch;

const { dispatch, getState } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();
const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, dispatch, useSelector, useDispatch, getState };
