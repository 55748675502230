import * as React from "react";
import { SvgIcon } from '@mui/material';

interface CheckmarkProps {
  strokeWidth?: number;
  [key: string]: any;
}

const Checkmark: React.FC<CheckmarkProps> = ({ strokeWidth = 1.5, ...props }) => (
  <SvgIcon {...props}>
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m5 13 3.526 2.821a2 2 0 0 0 2.736-.224L19 7"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
export default Checkmark;
