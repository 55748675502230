import { OptionsListType, OptionsType } from "@/types/select";
import { FormHelperText, Grid, InputLabel, MenuItem, SelectProps, useTheme } from "@mui/material";
import { Select } from "@mui/material";


type Props = SelectProps & {
    optionsList?: OptionsListType;
    renderOption?: (option: OptionsType) => React.ReactNode;
    error?: boolean;
    helperText?: string;
    mr?: string | number
};

const CustomSelect = (props: Props) => {
    const theme = useTheme();
    const { optionsList, label, value, renderOption, error, helperText, mr, ...others } = props;
    const options = optionsList && optionsList?.length > 0 ? optionsList : [];

    return (
        <Grid container mr={mr ?? 0}>
            {label &&
                <Grid item xs={12}>
                    {label && <InputLabel> {label}</InputLabel>}
                </Grid>}
            <Grid item xs={12}>
                <Select sx={{
                    ...(error && {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.error.main,
                        },
                    }),
                }}
                    {...others} fullWidth value={value || ''}>
                    {options.map(option => (
                        <MenuItem key={option.value.toString()} value={option.value.toString() || ''}>
                            {renderOption ? renderOption(option) : option.text}
                        </MenuItem>
                    ))}
                </Select>
                {error && helperText && (
                    <FormHelperText sx={{ ml: '14px' }} error>
                        {helperText}
                    </FormHelperText>
                )}
            </Grid>
        </Grid>
    );
};

export default CustomSelect;
