import { SvgIcon } from '@mui/material';

const Deactivation = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#EF3030" fillOpacity="0.16" />
            <path d="M16 12L8 12" stroke="#EF3030" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);
export default Deactivation;