import { AppDispatch } from "@/store/store";
import { OverviewSubTabsReponse, SubTabsEnum } from "../emiFinance/emiFinanceTypes";
import { EmiFinanceFeesStore, FeeData, FeeSummary, FeesBreakdownType, PayoutType } from "./emiFinanceFeesTypes";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import request from "@/services/request";
import { showErrorNotification } from "@/utils/errors";

const initialState: EmiFinanceFeesStore = {
    feeSummary: {
        loading: true,
        list: []
    },
    feeData: {
        loading: true,
        list: []
    },
    tabs: [],
    activeTab: SubTabsEnum.ALL,
};

const slice = createSlice({
    name: 'emiFinanceFees',
    initialState,
    reducers: {
        setSummary(state, action: PayloadAction<Array<FeeSummary>>) {
            state.feeSummary.list = action.payload;
        },
        setLoadingSummary(state, action: PayloadAction<boolean>) {
            state.feeSummary.loading = action.payload;
        },
        setFeeData(state, action: PayloadAction<Array<FeeData>>) {
            state.feeData.list = action.payload;
        },
        setLoadingFeeData(state, action: PayloadAction<boolean>) {
            state.feeData.loading = action.payload;
        },
        setBankingTabs(state, action: PayloadAction<Array<OverviewSubTabsReponse>>) {
            state.tabs = action.payload;
        },
        setActiveTab(state, action: PayloadAction<string>) {
            state.activeTab = action.payload;
        },
    }
});

export const {
    setSummary,
    setFeeData,
    setBankingTabs,
    setActiveTab,
    setLoadingSummary,
    setLoadingFeeData
} = slice.actions;

let abortController: AbortController | null = null;

export const getFeeSummary = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingSummary(true));
            const response = await request.get(`/api/console/emi-finance/fees/summary`);
            const data = response?.data || [];
            dispatch(setSummary(data));
        }
        catch (e) {
            dispatch(setSummary([]));
        }
        finally {
            dispatch(setLoadingSummary(false));
        }
    }
};

export const getFeeData = (feeCategory: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            if (abortController) {
                abortController.abort();
            }

            abortController = new AbortController();
            const { signal } = abortController;

            dispatch(setLoadingFeeData(true));
            const response = await request.get(`/api/console/emi-finance/fees/data/${feeCategory}`, { signal });
            const data = response?.data || [];
            dispatch(setFeeData(data));
        } catch (e: any) {
            if (e?.name && e.name !== 'CanceledError') {
                showErrorNotification(e);
                dispatch(setFeeData([]));
            }
        } finally {
            dispatch(setLoadingFeeData(false));
        }
    }
};

export const getFeePayouts = async (integration: string, ownerKey: string, category: string, currencyCode: string): Promise<Array<PayoutType>> => {
    const response = await request.get(`/api/console/emi-finance/fees/payouts/${integration}/${ownerKey}/${category}/${currencyCode}`);
    const data = response?.data || [];
    return data
}

export const recalculatePayout = async (integration: string, ownerKey: string, category: string, currencyCode: string, wlpId: string, dateFrom: string, dateTo: string): Promise<PayoutType> => {
    const response = await request.get(`/api/console/emi-finance/fees/recalculate/payout/${integration}/${ownerKey}/${category}/${currencyCode}/${wlpId}/${dateFrom}/${dateTo}`);
    const data = response?.data || [];
    return data
}

export const runPayoutOperation = async (payload: any) => {
    const response = await request.post('/api/console/operations/payout', { data: payload });
    const { data } = response;
    return data;
};

export const getFeesBreakdown = async (integration: string, ownerKey: string, feeCategory: string): Promise<Array<FeesBreakdownType>> => {
    const response = await request.get(`/api/console/emi-finance/fees/breakdown/${integration}/${ownerKey}/${feeCategory}`);
    return response?.data;
};

export default slice.reducer;