import DialogTitle from "@/components/DialogTitle";
import CustomDataGrid, { CustomDataGridRef } from "@/components/dataGrid/CustomDataGrid";
import AmountValueCell from "@/components/dataGrid/renderCell/AmountValueCell";
import UpperLowerValuesCell from "@/components/dataGrid/renderCell/UpperLowerValuesCell";
import { FeeEnum } from "@/redux/platformFees/platformFeesTypes";
import { UserType } from "@/redux/user/types";
import { getUserFeeOverrides, setFiltersSet, userFeesOverridesPaginationParamsInit } from "@/redux/userFeeOverrides/userFeeOverridesSlice";
import { WlpFee } from "@/redux/wlpFees/wlpFeesTypes";
import { dispatch, useSelector } from "@/store/store";
import { PaginateParams } from "@/types/pagination";
import { useCustomNavigate } from "@/utils/customHooks/useCustomNavigate";
import { Dialog, DialogContent, Grid, Theme, Typography, useTheme } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

interface Props {
    platformFee: WlpFee;
    open: boolean;
    onClose: () => void;
};

const FeesOverrideUserListDialog = ({ open, onClose, platformFee }: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const ref = useRef<CustomDataGridRef>(null);
    const customNavigate = useCustomNavigate();

    const { list, error, count, loading, pagination } = useSelector(
        (state) => state.userFeesOverrides
    );

    const fetch = async (searchParams: PaginateParams) => {
        await dispatch(getUserFeeOverrides(platformFee.currentFeeId, searchParams))
    };


    const handleUserClick = (userId: string, name: string) => {
        customNavigate(
            {
                title: name,
                path: `/users/details/${userId}/profile`,
            }
        );
    };

    const columns = useMemo(() => {
        return getColumns(t, platformFee, theme, handleUserClick)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, platformFee, theme]);

    return (
        <Dialog
            id="client-fees-override-dialog"
            open={open}
            onClose={onClose}
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: '600px',
                    maxHeight: '600px',
                    maxWidth: '1000px',
                    minWidth: '1000px'
                }
            }}>
            <DialogTitle onClose={onClose}>
                {t('fees.feesOverrideTitle', { type: platformFee.processName })}
            </DialogTitle>
            <DialogContent>
                <Grid mt={1}>
                    <CustomDataGrid
                        rows={list}
                        ref={ref}
                        getRowId={(row) => row.userId}
                        withRefreshBtn={true}
                        onFirstCall={() => dispatch(setFiltersSet({ set: false }))}
                        rowCount={count}
                        fetch={fetch}
                        loading={loading}
                        paginationParams={pagination}
                        paginationParamsInit={userFeesOverridesPaginationParamsInit}
                        columns={columns}
                        loadingError={error}
                        withSearch
                    />
                </Grid>
            </DialogContent>
        </Dialog>
    );
};


const getColumns = (t: any, platformFee: WlpFee, theme: Theme, handleUserClick: (userId: string, name: string) => void) => {

    let columns: GridColDef[] = [
        {
            field: 'userId',
            sortable: true,
            headerName: t('form.fields.client'),
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                const upperField = params.row.userProfileType === UserType.BUSINESS ? params.row.companyName : `${params.row.firstName} ${params.row.lastName}`;
                const lowerField = params.row.clientId;
                return <Grid container flexDirection='row' alignItems='center' onClick={() => handleUserClick(params.value, upperField)} sx={{ cursor: 'pointer' }}>
                    <Grid item>
                        <UpperLowerValuesCell upperField={upperField} lowerField={lowerField} upperSx={{ color: theme.palette.blue }} />
                    </Grid>
                </Grid>
            }
        },
        {
            field: 'defaultFee',
            headerName: t('form.fields.defaultFee'),
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                return <FeeCell
                    ccy={platformFee.ccy}
                    theme={theme}
                    feeType={platformFee.feeType}
                    currentFeePercent={platformFee.currentFeePercent}
                    currentMinFee={platformFee.currentMinFee}
                    currentFeeAmount={platformFee.currentFeeAmount}
                    currentFeeMonth={platformFee.currentFeeMonth}
                />
            },
        },
        {
            field: 'clientFee',
            headerName: t('form.fields.clientFee'),
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                return <FeeCell
                    ccy={platformFee.ccy}
                    theme={theme}
                    feeType={platformFee.feeType}
                    currentFeePercent={params.row.feePercent}
                    currentMinFee={params.row.minFee}
                    currentFeeAmount={params.row.feeAmount}
                    currentFeeMonth={params.row.feeMonth} />
            },
        }

    ];


    return columns;
};

interface FeeCellProps {
    ccy: string,
    theme: Theme,
    feeType: FeeEnum,
    currentFeePercent: number,
    currentMinFee: number,
    currentFeeAmount: number,
    currentFeeMonth: number,
}

const FeeCell = ({ ccy, theme, feeType, currentFeePercent, currentMinFee, currentFeeAmount, currentFeeMonth }: FeeCellProps) => {
    const isVolumeFee = feeType === FeeEnum.VOLUME_FEE;
    const isOneTimeAndMonthlyFee = feeType === FeeEnum.ONE_TIME_PLUS_MONTHLY_FEE;
    const color = theme.palette.secondary.dark;

    return (
        <Grid container display='flex' alignItems='center'>
            <Grid>
                {isVolumeFee &&
                    <Grid container direction='column'>
                        <Grid item>
                            <Typography sx={{ color: color }}>
                                <NumericFormat
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={false}
                                    thousandsGroupStyle='thousand'
                                    thousandSeparator={true}
                                    value={currentFeePercent}
                                    suffix={'% '}
                                />
                            </Typography>
                        </Grid>

                        <Grid container item>
                            {currentMinFee !== 0 && (
                                <>
                                    <Typography color={color}>{'(min'}&nbsp;</Typography>
                                    <AmountValueCell value={currentMinFee} currency={ccy} color={color} withColor />
                                    <Typography color={color}>{')'}</Typography>
                                </>
                            )}
                        </Grid>
                    </Grid>
                }
                {isOneTimeAndMonthlyFee &&
                    <Grid container direction='column'>
                        <Grid item>
                            <AmountValueCell value={currentFeeAmount} currency={ccy} color={color} withColor />
                        </Grid>
                        <Grid container item>
                            {currentFeeMonth !== 0 && (
                                <>
                                    <Typography color={color}>{'(monthly'}&nbsp;</Typography>
                                    <AmountValueCell value={currentFeeMonth} currency={ccy} color={color} withColor />
                                    <Typography color={color}>{')'}</Typography>
                                </>
                            )}
                        </Grid>
                    </Grid>
                }
                {!isVolumeFee && !isOneTimeAndMonthlyFee &&
                    <AmountValueCell value={currentFeeAmount} currency={ccy} color={color} withColor />
                }
            </Grid>
        </Grid >
    );
};

export default FeesOverrideUserListDialog;