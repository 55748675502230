import { AppDispatch } from "@/store/store";
import { IntegrationDetailsType, OverviewSubTabsReponse, SubTabsEnum } from "../emiFinance/emiFinanceTypes";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import request from "@/services/request";
import { EmiFinanceProfitLossStore, ProfitLossByIntegrationList } from "./emiFinanceProfitAndLossTypes";
import { groupBy, sortBy, sumBy } from "lodash";
import { PayoutType } from "../emiFinanceFees/emiFinanceFeesTypes";

const initialState: EmiFinanceProfitLossStore = {
    profitLossSummary: {
        loading: true,
        list: []
    },
    tabs: [],
    activeTab: SubTabsEnum.ALL,
};

const slice = createSlice({
    name: 'emiFinanceProfitAndLoss',
    initialState,
    reducers: {
        setProfitLossSummary(state, action: PayloadAction<Array<ProfitLossByIntegrationList>>) {
            state.profitLossSummary.list = action.payload;
        },
        setLoadingSummary(state, action: PayloadAction<boolean>) {
            state.profitLossSummary.loading = action.payload;
        },
        setTabs(state, action: PayloadAction<Array<OverviewSubTabsReponse>>) {
            state.tabs = action.payload;
        },
        setActiveTab(state, action: PayloadAction<string>) {
            state.activeTab = action.payload;
        }
    }
});

export const {
    setProfitLossSummary,
    setLoadingSummary,
    setActiveTab
} = slice.actions;


export const getProfitLossSummary = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingSummary(true));
            const response = await request.get(`/api/console/emi-finance/profit-loss/summary`);
            const data: Array<IntegrationDetailsType> = response?.data || [];
            const groupedData = groupBy(data, 'integration');
            const result = sortBy(
                Object.keys(groupedData).map(integration => {
                    const integrationData = groupedData[integration];
                    const totalAmountEur = sumBy(integrationData, 'amountEur');
                    return {
                        integration,
                        totalAmountEur: totalAmountEur,
                        data: integrationData
                    };
                }),
                ['integration']
            );
            dispatch(setProfitLossSummary(result));
        }
        catch (e) {
            dispatch(setProfitLossSummary([]));
        }
        finally {
            dispatch(setLoadingSummary(false));
        }
    }
};

export const getProfitLossPayouts = async (integration: string, ownerKey: string | null, currencyCode: string): Promise<Array<PayoutType>> => {
    const response = await request.get(`/api/console/emi-finance/profit-loss/payouts/${currencyCode}`, {
        params: {
            integration: integration,
            ownerKey: ownerKey === null ? 'NULL' : ownerKey
        }
    });
    const data = response?.data || [];
    return data
}

export default slice.reducer;