import request from "@/services/request";
import { StatementExportPayload } from "./statementsTypes";

export const downloadStatementFile = async (
  fileExtension: string,
  payload: StatementExportPayload,
  name: string
) => {
  const response = await request.post(
    `/api/console/statements/${fileExtension}`,
    payload,
    {
      responseType: "blob",
      // params: {
      //   cacheBustTimestamp: Date.now(),
      // },
    }
  );
  const url = window.URL.createObjectURL(response.data);
  const link = document.createElement("a");
  link.download = name;
  link.href = url;
  link.className = "hidden";
  document.body.appendChild(link);

  link.onclick = function () {
    requestAnimationFrame(function () {
      URL.revokeObjectURL(url);
      setTimeout(() => link.remove(), 300);
    });
  };

  link.click();
};
