export interface PlatformsStore {
    tabs: { [key: string]: any };
    overview: PlatformOverviewType | null;
    overviewLoading: boolean;
}

export interface Platform {
    emiWlpId: string;
    wlpId: string;
    platformName: string;
    partner: boolean;
    wlpUnderEmi: boolean;
    active: boolean;
};

export enum FeeCategoryCodeType {
    COMPLIANCE = "COMPLIANCE",
    SOFTWARE = "SOFTWARE",
    BANKING = "BANKING",
    CARD = "CARD",
    CRYPTO = "CRYPTO",
};

export enum PlatformCountriesSubTabs {
    DOCUMENT_VERIFICATION = "DOCUMENT_VERIFICATION",
    RESIDENCE_VERIFICATION = "RESIDENCE_VERIFICATION",
    BANKING = "BANKING",
    CARDS = "CARDS",
    CRYPTO = "CRYPTO",
};

export enum PlatformsTab {
    FEES = "FEES",
    ALLOWED_COUNTRIES = "ALLOWED_COUNTRIES"
};

export enum FeeFilterType {
    LOCATION = "LOCATION",
    USER_TYPE = "USER_TYPE",
    INTEGRAION = "INTEGRAION"
};

export interface InvoicesSummary {
    totalEarnings: number;
    unpaidInvoicesAmount: number;
    amountToBeInvoiced: number;
};

export interface PlatformOverviewType {
    wlpId: string;
    platformName: string;
    enabled: boolean;
    name: string;
    portalUrl: string;
    v3portalUrl: string;
    appStoreStatus: string;
    playStoreStatus: string;
    manager: string;
    companyName: string;
    country: string;
    address: string;
    registrationNumber: string;
    email: string;
    phoneNumber: string;
};
