import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EmiFinanceStore, EmiFinanceSummaryType, LiabilityReponseType, EmiFinanceTabEnum, OverviewSubTabsReponse, SystemBalanceGraphic, Currency } from "./emiFinanceTypes";
import { AppDispatch } from "@/store/store";
import request from "@/services/request";
import { CreateFeeType } from "../documentDetails/documentDetailsTypes";

const initialState: EmiFinanceStore = {
    accountBalances: [],
    systemBalances: [],
    userBalances: [],
    earnBalances: [],
    liabilities: null,
    totalAssets: null,
    totalPlatform: null,
    loading: {
        platformLoading: false,
        liabilitiesLoading: false,
        assetsLoading: false,
        userBalancesLoading: false,
        bankingTransactionsLoading: false,
        earnBalances: false,
        accountBalances: false,
        systemBalances: false
    },
};

const slice = createSlice({
    name: 'emiFinance',
    initialState,
    reducers: {
        setAccountBalances(state, action: PayloadAction<Array<EmiFinanceSummaryType>>) {
            state.accountBalances = action.payload;
        },
        setSystemBalances(state, action: PayloadAction<Array<EmiFinanceSummaryType>>) {
            state.systemBalances = action.payload;
        },
        setUserBalances(state, action: PayloadAction<Array<EmiFinanceSummaryType>>) {
            state.userBalances = action.payload;
        },
        setEarnBalances(state, action: PayloadAction<Array<EmiFinanceSummaryType>>) {
            state.earnBalances = action.payload;
        },
        setLiabilities(state, action: PayloadAction<LiabilityReponseType | null>) {
            state.liabilities = action.payload;
        },
        setTotalAssets(state, action: PayloadAction<number | null>) {
            state.totalAssets = action.payload;
        },
        setTotalPlatform(state, action: PayloadAction<number | null>) {
            state.totalPlatform = action.payload;
        },
        setLoading(state, action: PayloadAction<{ [key: string]: boolean }>) {
            state.loading = { ...state.loading, ...action.payload };
        },
    }
});

export const {
    setAccountBalances,
    setSystemBalances,
    setUserBalances,
    setEarnBalances,
    setLiabilities,
    setTotalAssets,
    setTotalPlatform,
    setLoading } = slice.actions;

export default slice.reducer;

export const getAccountBalances = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ accountBalances: true }));
            const response = await request.get('/api/console/emi-finance/balance/business');
            const data = response?.data || [];
            dispatch(setAccountBalances(data));
        }
        catch (e) {
            dispatch(setAccountBalances([]));
        }
        finally {
            dispatch(setLoading({ accountBalances: false }));
        }
    }
};

export const getSystemBalances = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ systemBalances: true }));
            const response = await request.get('/api/console/emi-finance/balance/system');
            const data = response?.data || [];
            dispatch(setSystemBalances(data));
        }
        catch (e) {
            dispatch(setSystemBalances([]));
        }
        finally {
            dispatch(setLoading({ systemBalances: false }));
        }
    }
};


export const getUserBalances = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ userBalancesLoading: true }));
            const response = await request.get('/api/console/emi-finance/balance/client');
            const data = response?.data || [];
            dispatch(setUserBalances(data));
        }
        catch (e) {
            dispatch(setUserBalances([]));
        }
        finally {
            dispatch(setLoading({ userBalancesLoading: false }));
        }
    }
};


export const getEarnBalances = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ earnBalances: true }));
            const response = await request.get('/api/console/emi-finance/balance/earn');
            const data = response?.data || [];
            dispatch(setEarnBalances(data));
        }
        catch (e) {
            dispatch(setEarnBalances([]));
        }
        finally {
            dispatch(setLoading({ earnBalances: false }));
        }
    }
};

export const getLiability = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ liabilitiesLoading: true }));
            const response = await request.get('/api/console/emi-finance/liability');
            const data = response?.data || [];
            dispatch(setLiabilities(data[0] || null));
        }
        catch (e) {
            dispatch(setLiabilities(null));
        }
        finally {
            dispatch(setLoading({ liabilitiesLoading: false }));
        }
    }
};


export const getTotalAssets = () => {
    return async (dispatch: AppDispatch) => {
        try {

            dispatch(setLoading({ assetsLoading: true }));
            const response = await request.get('/api/console/emi-finance/totals/ledgers');
            const data = response?.data[0]?.amount || null;
            dispatch(setTotalAssets(data));
        }
        catch (e) {
            dispatch(setTotalAssets(null));
        }
        finally {
            dispatch(setLoading({ assetsLoading: false }));
        }
    }
};

export const getTotalPlatform = () => {
    return async (dispatch: AppDispatch) => {
        try {

            dispatch(setLoading({ platformLoading: true }));
            const response = await request.get('/api/console/emi-finance/totals/platforms');
            const data = response?.data[0]?.amount || null;
            dispatch(setTotalPlatform(data || null));
        }
        catch (e) {
            dispatch(setTotalPlatform(null));
        }
        finally {
            dispatch(setLoading({ platformLoading: false }));
        }
    }
};

export const getOverviewSubTabs = async (viewType: EmiFinanceTabEnum): Promise<Array<OverviewSubTabsReponse>> => {
    const response = await request.get(`/api/console/emi-finance/integrations/${viewType}`);
    return response?.data;
};

export const getSystemBalancingGraphic = async (integration: string, dateFrom: string, dateTo: string): Promise<Array<SystemBalanceGraphic>> => {
    const response = await request.get(`/api/console/emi-finance/balancing/graphic/${integration}?dateFrom=${dateFrom}&dateTo=${dateTo}`);
    return response?.data;
};

export const getCurrenciesDecimals = async (): Promise<Array<Currency>> => {
    const response = await request.get(`/api/console/currencies/list`);
    return response?.data;
};

export const getCreateFeeTypes = async (): Promise<Array<CreateFeeType>> => {
    const response = await request.get(`/api/console/emi-finance/adjust/create/fee/types`);
    return response?.data;
};