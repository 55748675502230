import { PaginateParams, SortDirectionEnum } from "@/types/pagination";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "@/store/store";
import request from "@/services/request";
import { showErrorNotification } from "@/utils/errors";
import { Store } from "../merchants/merchantsTypes";
import { MerchantUserDetailsStore, SubTabsEnum } from "./merchantUserDetailsTypes";

export const paginationParamsInit = {
    filter: '',
    orderBy: 'createdDate',
    sort: SortDirectionEnum.DESC,
    take: 15,
    skip: 0
};

const userStoresInitPagination = {
    skip: 0,
    take: 20,
    filter: '',
    orderBy: "createdDate",
    sort: SortDirectionEnum.DESC
}

const initialState: MerchantUserDetailsStore = {
    selectedSubTab: SubTabsEnum.STORES,
    storedUserId: null,
    stores: {
        initial: true,
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: userStoresInitPagination
    },
};

const slice = createSlice({
    name: 'merchantUserDetails',
    initialState,
    reducers: {
        reset: () => initialState,
        setSelectedSubTab(state, action: PayloadAction<SubTabsEnum>) {
            state.selectedSubTab = action.payload;
        },
        setStoredUserId: (state, action: PayloadAction<number>) => {
            state.storedUserId = action.payload
        },
        // Stores
        setInitailStores(state, action: PayloadAction<boolean>) {
            state.stores.initial = action.payload;
        },
        setMerchantStores(state, action: PayloadAction<Array<Store>>) {
            state.stores.list = action.payload;
        },
        setLoadingStores: (state, action: PayloadAction<boolean>) => {
            state.stores.loading = action.payload;
        },
        setErrorStores: (state, action: PayloadAction<string>) => {
            state.stores.error = action.payload;
        },
        setCountStores: (state, action: PayloadAction<number>) => {
            state.stores.count = action.payload;
        },
        setPaginationStores: (state, action: PayloadAction<PaginateParams>) => {
            state.stores.pagination = action.payload;
        },
    }
});

export const { reset, setStoredUserId, setSelectedSubTab, setInitailStores, setMerchantStores, setLoadingStores, setErrorStores, setCountStores, setPaginationStores } = slice.actions;


export const getStoresByUser = (payload: PaginateParams, userId: number, initial: boolean, prevStores: Array<Store> = []) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingStores(true));
            const response = await request.get("/api/console/merchants/stores", {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take,
                    userId: userId
                }
            });
            const { data } = response;
            dispatch(setMerchantStores([
                ...prevStores,
                ...data.list
            ]));
            dispatch(setCountStores(data.count));
            dispatch(setPaginationStores({
                ...payload,
                skip: payload.skip + 1
            }));
            if (initial) dispatch(setInitailStores(false));
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLoadingStores(false));
        }
    };
};

export default slice.reducer;