import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '@/utils/errors';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { CreateTokenApiPayload, Token, TokensStore } from './tokenTypes';
import { Wlp } from '../user/types';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { getWlpName } from '@/components/dataGrid/utils/Platform';
import { addFiles } from '@/utils/files';

export const tokensPaginationParamsInit = {
    filter: '',
    orderBy: 'createdAt',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: TokensStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    pagination: tokensPaginationParamsInit,
    filters: {
        [HeaderFilterType.WLPS]: [],
    },
    filtersSet: false,
    financesOpenedSubTab: 0,
    requestsOpenedSubTab: 0,
};

const slice = createSlice({
    name: 'tokens',
    initialState,
    reducers: {
        setList(state, action: PayloadAction<Array<Token>>) {
            state.list = action.payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
            state.pagination = payload;
        },
        // Filters
        setFilters: (state, action: PayloadAction<{ wlps: Array<Wlp> }>) => {
            const wlpsOptions = action.payload.wlps.map(item => ({
                value: item.wlpId,
                text: getWlpName(item.wlpId),
                enabled: true
            }));
            state.filters[HeaderFilterType.WLPS] = wlpsOptions;
            state.filtersSet = true;
        },
        setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, options: Array<FilterOptionType> }>) => {
            state.filters[action.payload.filterType] = action.payload.options;
        },
        setFiltersSet(state, action: PayloadAction<{ set: boolean }>) {
            state.filtersSet = action.payload.set;
        },
        setFinancesSubTab(state, action: PayloadAction<number>) {
            state.financesOpenedSubTab = action.payload;
        },
        setRequestsSubTab(state, action: PayloadAction<number>) {
            state.requestsOpenedSubTab = action.payload;
        },
    }
});

export const { setList, setLoading, setError, setCount, setPagination, setFilters, setFilter, setFiltersSet, setFinancesSubTab, setRequestsSubTab } = slice.actions;

export const getTokensList = (payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/tokens/list`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setList(data.list));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            dispatch(setLoading(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading(false));
        }
    };
};

export const getTokenServiceProviders = async (platform: string): Promise<any[]> => {
    const response = await request.get(`/api/console/tokens/service-providers/${platform}`);
    const { data } = response;
    return data;
};


export const createToken = async (payload: CreateTokenApiPayload, file: File | null): Promise<any> => {
    const formData = new FormData();
    if (payload.name) {
        formData.append("name", payload.name);
    }
    if (payload.description) {
        formData.append("description", payload.description);
    }
    if (payload.serviceProvider) {
        formData.append("serviceProvider", payload.serviceProvider);
    }
    if (payload.quoteCurrency) {
        formData.append("quoteCurrency", payload.quoteCurrency);
    }
    if (payload.currencyCode) {
        formData.append("currencyCode", payload.currencyCode);
    }
    if (payload.website) {
        formData.append("website", payload.website);
    }
    if (payload.wlpId) {
        formData.append("wlpId", payload.wlpId);
    }
    if (payload.buyPrice) {
        formData.append("buyPrice", payload.buyPrice.toString());
    }
    if (payload.sellPrice) {
        formData.append("sellPrice", payload.sellPrice.toString());
    }
    if (payload.spread) {
        formData.append("spread", payload.spread.toString());
    }
    if (payload.decimals) {
        formData.append("decimals", payload.decimals.toString());
    }
    if (payload.displayDecimals) {
        formData.append("displayDecimals", payload.displayDecimals.toString());
    }
    if (payload.hidden) {
        formData.append("hidden", payload.hidden.toString());
    }
    if (payload.sendEnabled) {
        formData.append("sendEnabled", payload.sendEnabled.toString());
    }
    if (payload.buyEnabled) {
        formData.append("buyEnabled", payload.buyEnabled.toString());
    }
    if (payload.sellEnabled) {
        formData.append("sellEnabled", payload.sellEnabled.toString());
    }
    if (payload.skipRequestPhase) {
        formData.append("skipRequestPhase", payload.skipRequestPhase.toString());
    }




    if (file) {
        formData.append("file", file);
    }

    const response = await request.post(
        '/api/console/tokens/create',
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const getTokenDetails = async (wlpId: string, symbol: string): Promise<any> => {
    const response = await request.get(`/api/console/tokens/details/${symbol}/${wlpId}`);
    const { data } = response;
    return data;
};


export const editToken = async (symbol: string, formData: FormData): Promise<any> => {
    const response = await request.put(
        `/api/console/tokens/edit/${symbol}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const getAvaialbleTokensForUser = async (userId: number): Promise<any> => {
    const response = await request.get(`/api/console/tokens/custom/available/user/${userId}`);
    const { data } = response;
    return data;
};

export const createUserTokenAccount = async (payload: any, files: File[]) => {
	const formData = new FormData();
	formData.append("userId", payload.userId.toString());
	formData.append("reason", payload.reason);
	formData.append("ccy", payload.ccy);

	addFiles(formData, files);

	const response = await request.post(`/api/console/tokens/create/account`,
		formData,
		{ headers: { "Content-Type": "multipart/form-data" } }
	);
	const { data } = response;
	return data;
};

export const updateUserTokenAccount = async (type: 'MANUAL_DEPOSIT' | 'MANUAL_WITHDRAWAL', payload: any, files: File[]) => {
	const formData = new FormData();
	formData.append("userId", payload.userId.toString());
	formData.append("reason", payload.reason);
	formData.append("ccy", payload.ccy);
	formData.append("amount", payload.amount.toString());
	formData.append("details", payload.details);
	formData.append("accountId", payload.accountId.toString());
    if(payload.externalId) {
        formData.append("externalId", payload.externalId);
    }

	addFiles(formData, files);

	const response = await request.post(`/api/console/tokens/account/update/${type}`,
		formData,
		{ headers: { "Content-Type": "multipart/form-data" } }
	);
	const { data } = response;
	return data;
};

export default slice.reducer;