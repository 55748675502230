import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '@/utils/errors';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { Wlp } from '../user/types';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { getWlpName } from '@/components/dataGrid/utils/Platform';
import { TokensIssuance, TokensIssuanceStore } from './tokensIssuanceTypes';

export const tokensIssuancePaginationParamsInit = {
    filter: '',
    orderBy: 'createdAt',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: TokensIssuanceStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    pagination: tokensIssuancePaginationParamsInit,
    filters: {
        [HeaderFilterType.WLPS]: [],
    },
    filtersSet: false
};

const slice = createSlice({
    name: 'tokensIssuance',
    initialState,
    reducers: {
        setList(state, action: PayloadAction<Array<TokensIssuance>>) {
            state.list = action.payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
            state.pagination = payload;
        },
        // Filters
        setFilters: (state, action: PayloadAction<{ wlps: Array<Wlp> }>) => {
            const wlpsOptions = action.payload.wlps.map(item => ({
                value: item.wlpId,
                text: getWlpName(item.wlpId),
                enabled: true
            }));
            state.filters[HeaderFilterType.WLPS] = wlpsOptions;
            state.filtersSet = true;
        },
        setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, options: Array<FilterOptionType> }>) => {
            state.filters[action.payload.filterType] = action.payload.options;
        },
        setFiltersSet(state, action: PayloadAction<{ set: boolean }>) {
            state.filtersSet = action.payload.set;
        }
    }
});

export const { setList, setLoading, setError, setCount, setPagination, setFilters, setFilter, setFiltersSet } = slice.actions;

export const getTokensIssuanceList = (payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/tokens/issuance/list`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setList(data.list));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            dispatch(setLoading(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading(false));
        }
    };
};

export const uploadIssuanceFile = async (payload: any) => {
    const response = await request.post('/api/console/tokens/issuance/file', payload);
    const { data } = response;
    return data;
};

export const issueTokenManual = async (payload: any) => {
    const response = await request.post('/api/console/tokens/issuance/manual', payload);
    const { data } = response;
    return data;
};


export const downloadIssuanceFile = async (
    fileId: number,
    name: string
) => {
    const response = await request.get(`/api/console/tokens/issuance/file/${fileId}/download/result`,
        {
            responseType: 'blob',
        });
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.download = `${name}`;
    link.href = url;
    link.className = 'hidden';
    document.body.appendChild(link);

    link.onclick = function () {
        requestAnimationFrame(function () {
            URL.revokeObjectURL(url);
            setTimeout(() => link.remove(), 300);
        });
    };

    link.click();
};

export const downloadUploadedFile = async (
    fileId: number,
    name: string
) => {
    const fileUrl = await request.get(
        `/api/console/tokens/issuance/file/${fileId}/download`,
        // {
        //     responseType: "blob",
        //     params: {
        //         cacheBustTimestamp: Date.now(),
        //     },
        // }
    );
    const response = await request.get(
        fileUrl.data,
        {
            responseType: "blob",
            params: {
                isAwsDownload: true,
            },
        }
    );
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.download = name;
    link.href = url;
    link.className = "hidden";
    document.body.appendChild(link);

    link.onclick = function () {
        requestAnimationFrame(function () {
            URL.revokeObjectURL(url);
            setTimeout(() => link.remove(), 300);
        });
    };

    link.click();
};

export const toggleTokenIssuanceApproval = async (payload: any) => {
    const response = await request.post('/api/console/tokens/issuance/approval/toggle', payload);
    const { data } = response;
    return data;
};


export default slice.reducer;