import StatusChip from "@/components/chips/StatusChip";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { TransferApproveParams } from "./TransferApproveCell";

const StatusCell = (params: GridRenderCellParams | TransferApproveParams) => {
    const val = params.value;
    if (!val) return <></>;

    return (<StatusChip label={val} />);
};

export default StatusCell;