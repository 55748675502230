import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IntegrationDetailsType, SubTabsEnum } from "../emiFinance/emiFinanceTypes";
import { AppDispatch } from "@/store/store";
import request from "@/services/request";
import { PaginateParams, SortDirectionEnum } from "@/types/pagination";
import { CardsCustomerBreakdownBalances, CardsIntegartion, CardsIntegartionPartner, EmiFinanceCardsStore, IntergrationTransfersBreakdownTabsEnum } from "./emiFinanceCardsTypes";
import { TransferDetails } from "../emiFinanceTransfers/emiFinanceTransfersTypes";
import { showErrorNotification } from "@/utils/errors";
import { AccountTypeEnum } from "../accounts/accountsTypes";

export const paginationParamsInit = {
    filter: '',
    orderBy: 'transferDate',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: EmiFinanceCardsStore = {
    integrations: [],
    integrationsLoading: false,
    activeTab: SubTabsEnum.ALL,

    integrationBalances: {},
    cardTopUpData: {},
    cardsTransfersActiveTab: IntergrationTransfersBreakdownTabsEnum.SETTLEMENT_CARDS,
    cardsTransfers: {
        [IntergrationTransfersBreakdownTabsEnum.SETTLEMENT_CARDS]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit
        },
        [IntergrationTransfersBreakdownTabsEnum.TOP_UP_AVIALABILITY]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit
        },
        [IntergrationTransfersBreakdownTabsEnum.PAYMENTS_FROM_PARTNERS]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit
        },
        [IntergrationTransfersBreakdownTabsEnum.INTERNAL_PAYMENTS]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit
        },
        [IntergrationTransfersBreakdownTabsEnum.CARD_TOP_UPS]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit
        }
    }
};

const slice = createSlice({
    name: 'emiFinanceCards',
    initialState,
    reducers: {
        setActiveTab(state, action: PayloadAction<string>) {
            state.activeTab = action.payload;
        },
        setIntegrations(state, action: PayloadAction<Array<CardsIntegartion>>) {
            state.integrations = action.payload;
        },
        setIntegrationsLoading(state, action: PayloadAction<boolean>) {
            state.integrationsLoading = action.payload;
        },
        setIntegrationsBalances(state, action: PayloadAction<{ integration: string; balance: Array<IntegrationDetailsType>, integrationDescription: string }>) {
            const integrationKey = action.payload.integration;
            if (!state.integrationBalances[integrationKey]) {
                state.integrationBalances[integrationKey] = {
                    loading: false,
                    integrationDescription: action.payload.integrationDescription,
                    integrationBalance: [],
                };
            }
            state.integrationBalances[integrationKey].integrationBalance = action.payload.balance;
        },
        setIntegrationsBalancesLoading(state, action: PayloadAction<{ integration: string; loading: boolean, integrationDescription: string }>) {
            const integrationKey = action.payload.integration;
            if (!state.integrationBalances[integrationKey]) {
                state.integrationBalances[integrationKey] = {
                    loading: action.payload.loading,
                    integrationDescription: action.payload.integrationDescription,
                    integrationBalance: [],
                };
            } else {
                state.integrationBalances[integrationKey].loading = action.payload.loading;
            }
        },
        setCardTopUpData(state,
            action: PayloadAction<{
                integration: string,
                cardsTopUpAvailability: Array<IntegrationDetailsType>,
                cardsSettlements: Array<IntegrationDetailsType>,
                cardsTotalReconciledTopUp: Array<IntegrationDetailsType>,
                cardsTotalTopUp: Array<IntegrationDetailsType>,
                isEmiPartner: boolean,
                integrationDescription: string,
                partner: CardsIntegartionPartner,
                currencyList: string,
                serviceOwner: string,
                emi: string,
                integrationType: string
            }>) {
            const integrationKey = action.payload.integration;
            if (!state.cardTopUpData[integrationKey]) {
                state.cardTopUpData[integrationKey] = {
                    loading: false,
                    cardsTopUpAvailability: [],
                    cardsSettlements: [],
                    cardsTotalReconciledTopUp: [],
                    cardsTotalTopUp: [],
                    isEmiPartner: action.payload.isEmiPartner,
                    integrationDescription: action.payload.integrationDescription,
                    partner: action.payload.partner,
                    currencyList: action.payload.currencyList,
                    serviceOwner: action.payload.serviceOwner,
                    emi: action.payload.emi,
                    integrationType: action.payload.integrationType
                };
            }
            state.cardTopUpData[integrationKey].cardsTopUpAvailability = action.payload.cardsTopUpAvailability;
            state.cardTopUpData[integrationKey].cardsSettlements = action.payload.cardsSettlements;
            state.cardTopUpData[integrationKey].cardsTotalReconciledTopUp = action.payload.cardsTotalReconciledTopUp;
            state.cardTopUpData[integrationKey].cardsTotalTopUp = action.payload.cardsTotalTopUp;
        },
        setCardTopUpDataLoading(state, action: PayloadAction<{
            integration: string;
            loading: boolean,
            isEmiPartner: boolean,
            integrationDescription: string,
            partner: CardsIntegartionPartner,
            currencyList: string,
            serviceOwner: string,
            emi: string,
            integrationType: string
        }>) {
            const integrationKey = action.payload.integration;
            if (!state.cardTopUpData[integrationKey]) {
                state.cardTopUpData[integrationKey] = {
                    loading: action.payload.loading,
                    cardsTopUpAvailability: [],
                    cardsSettlements: [],
                    cardsTotalReconciledTopUp: [],
                    cardsTotalTopUp: [],
                    isEmiPartner: action.payload.isEmiPartner,
                    integrationDescription: action.payload.integrationDescription,
                    partner: action.payload.partner,
                    currencyList: action.payload.currencyList,
                    serviceOwner: action.payload.serviceOwner,
                    emi: action.payload.emi,
                    integrationType: action.payload.integrationType
                };
            } else {
                state.cardTopUpData[integrationKey].loading = action.payload.loading;
            }
        },

        setIntegrationTransfers(state, action: PayloadAction<{ type: IntergrationTransfersBreakdownTabsEnum, list: Array<TransferDetails> }>) {
            state.cardsTransfers[action.payload.type].list = action.payload.list;
        },
        setLoading: (state, action: PayloadAction<{ type: IntergrationTransfersBreakdownTabsEnum, loading: boolean }>) => {
            state.cardsTransfers[action.payload.type].loading = action.payload.loading;
        },
        setError: (state, action: PayloadAction<{ type: IntergrationTransfersBreakdownTabsEnum, error: string }>) => {
            state.cardsTransfers[action.payload.type].error = action.payload.error;
        },
        setCount: (state, action: PayloadAction<{ type: IntergrationTransfersBreakdownTabsEnum, count: number }>) => {
            state.cardsTransfers[action.payload.type].count = action.payload.count;
        },
        setPagination: (state, action: PayloadAction<{ type: IntergrationTransfersBreakdownTabsEnum, pagination: PaginateParams }>) => {
            state.cardsTransfers[action.payload.type].pagination = action.payload.pagination;
        },
        setCardsTransfersActiveTab(state, action: PayloadAction<IntergrationTransfersBreakdownTabsEnum>) {
            state.cardsTransfersActiveTab = action.payload;
        }
    }
});

export const {
    setActiveTab,
    setIntegrations,
    setIntegrationsLoading,
    setIntegrationsBalances,
    setIntegrationsBalancesLoading,
    setCardTopUpData,
    setCardTopUpDataLoading,
    setIntegrationTransfers,
    setLoading,
    setError,
    setCount,
    setPagination,
    setCardsTransfersActiveTab
} = slice.actions;

export default slice.reducer;

export const getCardsIntegrations = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setIntegrationsLoading(true));
            const response = await request.get(`/api/console/emi-finance/cards/integrations`);
            const data = response?.data || [];
            dispatch(setIntegrations(data));
        }
        catch (e) {
            dispatch(setIntegrations([]));
        }
        finally {
            dispatch(setIntegrationsLoading(false));
        }
    }
};

export const getIntegrationTotalBalances = async (integration: string, serviceOwner: string, integrationType: string): Promise<Array<IntegrationDetailsType>> => {
    const response = await request.get(`/api/console/emi-finance/cards/total/integration/balances/${integration}/${serviceOwner}/${integrationType}`);
    const data = response?.data || [];
    return data;
};

export const getAllIntegrationsTotalBalances = (integrations: Array<CardsIntegartion>) => {
    return async (dispatch: AppDispatch) => {
        try {
            const fetchPromises = integrations.map(async integration => {
                dispatch(setIntegrationsBalancesLoading({ integration: integration.integration, loading: true, integrationDescription: integration.integrationDescription }));
                try {
                    try {
                        const balance = await getIntegrationTotalBalances(integration.integration, integration.serviceOwner, integration.dataSourceType);
                        dispatch(setIntegrationsBalances({ integration: integration.integration, balance: balance, integrationDescription: integration.integrationDescription }));
                    } catch (error) {
                        console.error(error);
                        dispatch(setIntegrationsBalances({ integration: integration.integration, balance: [], integrationDescription: integration.integrationDescription }));
                    }
                } finally {
                    dispatch(setIntegrationsBalancesLoading({ integration: integration.integration, loading: false, integrationDescription: integration.integrationDescription }));
                }
            });
            await Promise.all(fetchPromises);
        } catch (e) {
            console.error(e);
        }
    }
};

export const getCardsTopUpAvailability = async (integration: string, serviceOwner: string, integrationType: string, emi: string): Promise<Array<IntegrationDetailsType>> => {
    const response = await request.get(`/api/console/emi-finance/cards/topup/availability/${integration}/${serviceOwner}/${integrationType}/${emi}`);
    const data = response?.data || [];
    return data;
};

export const getCardsSettlements = async (integration: string, serviceOwner: string, integrationType: string, emi: string): Promise<Array<IntegrationDetailsType>> => {
    const response = await request.get(`/api/console/emi-finance/cards/settlements/${integration}/${serviceOwner}/${integrationType}/${emi}`);
    const data = response?.data || [];
    return data;
};

export const getCardsTotalReconciledTopUp = async (integration: string, serviceOwner: string, integrationType: string, emi: string): Promise<Array<IntegrationDetailsType>> => {
    const response = await request.get(`/api/console/emi-finance/cards/reconciled/topup/${integration}/${serviceOwner}/${integrationType}/${emi}`);
    const data = response?.data || [];
    return data;
};

export const getCardsTotalTopUp = async (integration: string, serviceOwner: string, integrationType: string, emi: string): Promise<Array<IntegrationDetailsType>> => {
    const response = await request.get(`/api/console/emi-finance/cards/total/topup/${integration}/${serviceOwner}/${integrationType}/${emi}`);
    const data = response?.data || [];
    return data;
};

export const getAllIntegrationsCardTopUpData = (integrations: Array<CardsIntegartion>) => {
    return async (dispatch: AppDispatch) => {
        try {
            await Promise.all(integrations.flatMap(async (integration) => {
                return integration.partnerList.map(async (partner) => {
                    const isEmiPartner = integration.partnerList.length > 1;
                    dispatch(setCardTopUpDataLoading(
                        {
                            integration: isEmiPartner ? partner.emi : integration.integration,
                            loading: true,
                            isEmiPartner: isEmiPartner,
                            integrationDescription: integration.integrationDescription,
                            partner: partner,
                            currencyList: integration.currencyList,
                            serviceOwner: partner.serviceOwner,
                            emi: partner.emi,
                            integrationType: integration.dataSourceType
                        }));
                    try {
                        const cardsTopUpAvailability = await getCardsTopUpAvailability(partner.integration, partner.serviceOwner, partner.dataSourceType, partner.emi);
                        const cardsSettlements = await getCardsSettlements(partner.integration, partner.serviceOwner, partner.dataSourceType, partner.emi);
                        const cardsTotalReconciledTopUp = await getCardsTotalReconciledTopUp(partner.integration, partner.serviceOwner, partner.dataSourceType, partner.emi);
                        const cardsTotalTopUp = await getCardsTotalTopUp(partner.integration, partner.serviceOwner, partner.dataSourceType, partner.emi);
                        dispatch(setCardTopUpData(
                            {
                                integration: isEmiPartner ? partner.emi : integration.integration,
                                cardsTopUpAvailability, cardsSettlements,
                                cardsTotalReconciledTopUp: cardsTotalReconciledTopUp,
                                cardsTotalTopUp: cardsTotalTopUp,
                                isEmiPartner: isEmiPartner,
                                integrationDescription: integration.integrationDescription,
                                partner: partner,
                                currencyList: integration.currencyList,
                                serviceOwner: partner.serviceOwner,
                                emi: partner.emi,
                                integrationType: integration.dataSourceType

                            }));
                    } catch (e) {
                        console.error(e);
                        dispatch(setCardTopUpData(
                            {
                                integration: isEmiPartner ? partner.emi : integration.integration,
                                cardsTopUpAvailability: [],
                                cardsSettlements: [],
                                cardsTotalReconciledTopUp: [],
                                cardsTotalTopUp: [],
                                isEmiPartner: isEmiPartner,
                                integrationDescription: integration.integrationDescription,
                                partner: partner,
                                currencyList: integration.currencyList,
                                serviceOwner: partner.serviceOwner,
                                emi: partner.emi,
                                integrationType: integration.dataSourceType
                            }));
                    } finally {
                        dispatch(setCardTopUpDataLoading({
                            integration: isEmiPartner ? partner.emi : integration.integration,
                            loading: false,
                            isEmiPartner: isEmiPartner,
                            integrationDescription: integration.integrationDescription,
                            partner: partner,
                            currencyList: integration.currencyList,
                            serviceOwner: partner.serviceOwner,
                            emi: partner.emi,
                            integrationType: integration.dataSourceType
                        }));
                    }
                });
            }));
        } catch (e) {
            console.error(e);
        }
    }
};

export const getCardsCustomerBreakdown = async (integration: string, integrationType: string): Promise<Array<CardsCustomerBreakdownBalances>> => {
    const response = await request.get(`/api/console/emi-finance/cards/customer/breakdown/${integration}/${integrationType}`);
    const data = response?.data || [];
    return data;
};

export const getCardsExternalSystemTransfers = (payload: PaginateParams, type: IntergrationTransfersBreakdownTabsEnum, integration: string, serviceOwner: string, integrationType: string, emi: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ type: type, loading: true }));
            const response = await request.get(`/api/console/emi-finance/cards/external/transfers/${integration}/${serviceOwner}/${integrationType}/${emi}`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setIntegrationTransfers({ type: type, list: data.emiTransferDTO }));
            dispatch(setCount({ type: type, count: data.count }));
            dispatch(setPagination({ type: type, pagination: payload }));
            dispatch(setLoading({ type: type, loading: false }));
        } catch (e) {
            dispatch(setIntegrationTransfers({ type: type, list: [] }));
            showErrorNotification(e);
            dispatch(setLoading({ type: type, loading: false }));
        }
    };
};

export const getEmiFinanceSystemTransfers = (payload: PaginateParams, type: IntergrationTransfersBreakdownTabsEnum, transferCategory?: string, integration?: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ type: type, loading: true }));

            const filterString = [
                payload.filter,
                transferCategory && `transferCategory=${transferCategory}`,
                integration && `integration=${integration}`
            ].filter(Boolean).join(';');

            const response = await request.get('/api/console/emi-finance/system-transfers/list', {
                params: {
                    filter: filterString,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setIntegrationTransfers({ type: type, list: data.emiTransferDTO }));
            dispatch(setCount({ type: type, count: data.count }));
            dispatch(setPagination({ type: type, pagination: payload }));
            dispatch(setLoading({ type: type, loading: false }));
            return data;
        } catch (e) {
            dispatch(setIntegrationTransfers({ type: type, list: [] }));
            showErrorNotification(e);
            dispatch(setLoading({ type: type, loading: true }));
        }
    };
};

export const getCardsInternalSystemTransfers = (payload: PaginateParams, type: IntergrationTransfersBreakdownTabsEnum, integration: string, serviceOwner: string, integrationType: string, emi: string, transferCategory?: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ type: type, loading: true }));

            const filterString = [
                payload.filter,
                transferCategory && `transferCategory=${transferCategory}`,
                integration && `integration=${integration}`
            ].filter(Boolean).join(';');

            const response = await request.get(`/api/console/emi-finance/cards/internal/transfers/${integration}/${serviceOwner}/${integrationType}/${emi}`, {
                params: {
                    filter: filterString,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setIntegrationTransfers({ type: type, list: data.emiTransferDTO }));
            dispatch(setCount({ type: type, count: data.count }));
            dispatch(setPagination({ type: type, pagination: payload }));
            dispatch(setLoading({ type: type, loading: false }));
        } catch (e) {
            dispatch(setIntegrationTransfers({ type: type, list: [] }));
            showErrorNotification(e);
            dispatch(setLoading({ type: type, loading: false }));
        }
    };
};

export const getCardsTopUpDocuments = (payload: PaginateParams, type: IntergrationTransfersBreakdownTabsEnum, integration: string, serviceOwner: string, integrationType: string, emi: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ type: type, loading: true }));
            const response = await request.get(`/api/console/emi-finance/cards/top-up/documents/${integration}/${serviceOwner}/${integrationType}/${emi}`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setIntegrationTransfers({ type: type, list: data.emiTransferDTO }));
            dispatch(setCount({ type: type, count: data.count }));
            dispatch(setPagination({ type: type, pagination: payload }));
            dispatch(setLoading({ type: type, loading: false }));
        } catch (e) {
            dispatch(setIntegrationTransfers({ type: type, list: [] }));
            showErrorNotification(e);
            dispatch(setLoading({ type: type, loading: false }));
        }
    };
};

export const getBalancingAccounts = async (wlpId: string, ccy: string, integration: string) => {
    const response = await request.get(`/api/console/emi-finance/balancing/accounts/${wlpId}`, {
        params: {
            filter: `sysAcctType=emiBalancingAccount;currency=${ccy};integration=${integration}`,
            type: AccountTypeEnum.SYSTEM,
        }
    });
    const { data } = response;
    return data;
};