import { SvgIcon } from "@mui/material";

const LogoutExit = (props: any) => (
  <SvgIcon {...props}>
    <svg width={24}
      height={24} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M7.99935 10.0013L9.99935 8.0013M9.99935 8.0013L7.99935 6.0013M9.99935 8.0013L2.66602 8.0013M5.99935 11.3346C5.99935 11.9559 5.99935 12.2665 6.10084 12.5115C6.23617 12.8382 6.49556 13.0978 6.82227 13.2332C7.06729 13.3346 7.37809 13.3346 7.99935 13.3346H11.1993C11.9461 13.3346 12.3193 13.3346 12.6045 13.1893C12.8554 13.0615 13.0597 12.8575 13.1875 12.6067C13.3328 12.3214 13.3327 11.948 13.3327 11.2013V4.80127C13.3327 4.05453 13.3328 3.68121 13.1875 3.396C13.0597 3.14511 12.8554 2.94112 12.6045 2.81329C12.3193 2.66797 11.9461 2.66797 11.1993 2.66797H7.99935C7.37809 2.66797 7.06729 2.66797 6.82227 2.76946C6.49556 2.90479 6.23617 3.16434 6.10084 3.49105C5.99935 3.73608 5.99935 4.0467 5.99935 4.66796"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </SvgIcon>
);
export default LogoutExit; 
