import TypographyWithEllipsis from "@/components/TypographyWithEllipsis";
import Checkmark from "@/icons/Checkmark";
import EditChangePencil from "@/icons/EditChangePencil";
import { LimitType } from "@/redux/limit/limitTypes";
import { Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { NumericFormat } from 'react-number-format';
import { useSelector } from '@/store/store';
import { checkWritePermission } from "@/utils/permissions";
import { PermissionType } from "@/redux/user/types";
import { useTranslation } from "react-i18next";
import DangerTriangle from "@/icons/DangerTriangle";
import { getCurrencySign } from "@/utils/currency";

interface Props {
    params: GridRenderCellParams,
    limitFieldType: LimitType,
    fieldName: string;
    actualField?: string,
    editable?: boolean,
    userId?: number,
    writePermission?: boolean,
    onClick?: () => void,
    onSave?: (editedValue: number) => void;
}

const sizeMapping: { [key: string]: number } = {
    [LimitType.SINGLE]: 90,
    [LimitType.DAILY]: 100,
    [LimitType.MONTHLY]: 110,
    [LimitType.ANNUAL]: 120
};

const LimitCell = ({ params, limitFieldType, fieldName, actualField, editable = false, onClick, onSave }: Props) => {
    const { t } = useTranslation();

    const { isEmi } = useSelector((state) => state.credentials);

    const { permissions } = useSelector((state) => state.user);
    const writePermission = checkWritePermission(permissions, PermissionType.USER_LIMITS);

    useEffect(() => {
        setLimitValue(params.row[fieldName]);
    }, [fieldName, params]);

    const [limitValue, setLimitValue] = useState(params.row[fieldName])
    const actualValue = actualField ? params.row[actualField] : '';
    const ccy = getCurrencySign("EUR");

    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState<number>(params.row[fieldName]);

    const handleEditClick = () => {
        setIsEditing(true);
        onClick && onClick();
    };

    const handleSaveClick = () => {
        setLimitValue(editValue)
        onSave && onSave(editValue);
        setIsEditing(false);
    };

    const saveButtonRef = useRef(null);

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.relatedTarget !== saveButtonRef.current) {
            setEditValue(limitValue);
            setIsEditing(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditValue(parseFloat(e.target.value));
    };

    const maxGridSize = sizeMapping[limitFieldType];

    const persentFrom = (actualField && params.row[fieldName] !== null) ? (actualValue / params.row[fieldName] * 100) : 0;

    return (
        <Grid container alignItems='center' columnGap={1}>
            {actualField && persentFrom > 75 &&
                <Grid item alignItems='center' display='flex'>
                    <DangerTriangle sx={{ fontSize: '21px', ml: 0.5 }} />
                </Grid>}
            <Grid container item xs pl='0.2rem' alignItems='center'>

                {
                    isEditing ?
                        (
                            <Grid container alignItems="center" wrap="nowrap">
                                <Grid item sx={{ 'input': { padding: 0, maxHeight: '23px' }, maxWidth: maxGridSize }}>
                                    <div onKeyDown={(ev: any) => {
                                        if (ev.key === 'Enter') {
                                            handleSaveClick();
                                        }
                                    }}>
                                        <NumericFormat
                                            name="minFee"
                                            autoFocus={true}
                                            value={editValue}
                                            customInput={TextField}
                                            decimalScale={2}
                                            onChange={handleInputChange}
                                            onBlur={handleInputBlur}
                                            displayType={'input'}
                                            inputProps={{ autoComplete: 'off' }}
                                            variant="standard"
                                            InputProps={{
                                                startAdornment:
                                                    <InputAdornment position="start">
                                                        <Typography variant="body1" color='text.primary'>
                                                            {ccy ?? ''}
                                                        </Typography>
                                                    </InputAdornment>
                                            }} />
                                    </div>


                                </Grid>
                                <Grid item sx={{ 'button': { padding: 0, margin: 0.3 }, maxHeight: '23px' }}>
                                    <IconButton size="small" aria-label="close" onClick={handleSaveClick} ref={saveButtonRef}>
                                        <Checkmark sx={{ fontSize: '1.2rem' }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ) :
                        (
                            <Grid container>
                                {limitValue !== null ? (
                                    <>
                                        <Typography pr='4px' variant="body1">{ccy ?? ''}</Typography>
                                        <TypographyWithEllipsis variant="body1">
                                            <NumericFormat
                                                displayType={'text'}
                                                fixedDecimalScale={true}
                                                thousandsGroupStyle='thousand'
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                value={limitValue} />
                                        </TypographyWithEllipsis>
                                        {
                                            (editable && writePermission && isEmi) && (
                                                <IconButton size="small" aria-label="close" onClick={handleEditClick}>
                                                    <EditChangePencil sx={{ fontSize: '0.813rem' }} strokeWidth={2.5} />
                                                </IconButton>
                                            )
                                        }
                                    </>
                                ) :
                                    (
                                        <>
                                            {limitFieldType === LimitType.AVAILABLE_NOW ?
                                                (
                                                    <>
                                                        <Typography pr='4px' variant="body1">{ccy ?? ''}</Typography>
                                                        <TypographyWithEllipsis variant="body1">
                                                            <NumericFormat
                                                                displayType={'text'}
                                                                fixedDecimalScale={true}
                                                                thousandsGroupStyle='thousand'
                                                                thousandSeparator={true}
                                                                decimalScale={2}
                                                                value={limitValue} />
                                                        </TypographyWithEllipsis>
                                                    </>
                                                ) :
                                                <Typography pr='4px' variant="body1">{t('limits.unlimited')}</Typography>}
                                            {
                                                (editable && writePermission && isEmi) && (
                                                    <IconButton size="small" aria-label="close" onClick={handleEditClick}>
                                                        <EditChangePencil sx={{ fontSize: '0.813rem' }} strokeWidth={2.5} />
                                                    </IconButton>
                                                )
                                            }
                                        </>
                                    )

                                }
                            </Grid>
                        )
                }

                {actualField && (
                    <Grid container item xs={12} height='1rem'>
                        <Typography pr='4px' variant='caption' color='text.secondary'>
                            {ccy ?? ''}
                        </Typography>
                        <TypographyWithEllipsis variant='caption' color='text.secondary'>
                            <NumericFormat
                                displayType={'text'}
                                fixedDecimalScale={true}
                                thousandsGroupStyle='thousand'
                                thousandSeparator={true}
                                decimalScale={2}
                                value={actualValue} />
                        </TypographyWithEllipsis>
                    </Grid>
                )}

            </Grid>
        </Grid >
    );
};

export default LimitCell;
