import { SvgIcon } from '@mui/material';

const Trade = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#2B2B2B" fillOpacity="0.06" />
            <path d="M15.0916 12.2426C14.8573 12.0083 14.4774 12.0083 14.2431 12.2426C14.0087 12.4769 14.0087 12.8568 14.2431 13.0911L15.0916 12.2426ZM16.6673 14.6668L17.0916 15.0911C17.3259 14.8568 17.3259 14.4769 17.0916 14.2426L16.6673 14.6668ZM14.2431 16.2426C14.0087 16.4769 14.0087 16.8568 14.2431 17.0911C14.4774 17.3254 14.8573 17.3254 15.0916 17.0911L14.2431 16.2426ZM7.33398 14.0668C7.00261 14.0668 6.73398 14.3355 6.73398 14.6668C6.73398 14.9982 7.00261 15.2668 7.33398 15.2668V14.0668ZM8.90972 11.7578C9.14403 11.9921 9.52393 11.9921 9.75825 11.7578C9.99256 11.5234 9.99256 11.1435 9.75825 10.9092L8.90972 11.7578ZM7.33398 9.3335L6.90972 8.90923C6.67541 9.14355 6.67541 9.52345 6.90972 9.75776L7.33398 9.3335ZM9.75825 7.75776C9.99256 7.52345 9.99256 7.14355 9.75825 6.90923C9.52393 6.67492 9.14403 6.67492 8.90972 6.90923L9.75825 7.75776ZM16.6673 9.9335C16.9987 9.9335 17.2673 9.66487 17.2673 9.3335C17.2673 9.00213 16.9987 8.7335 16.6673 8.7335V9.9335ZM14.2431 13.0911L16.2431 15.0911L17.0916 14.2426L15.0916 12.2426L14.2431 13.0911ZM16.2431 14.2426L14.2431 16.2426L15.0916 17.0911L17.0916 15.0911L16.2431 14.2426ZM7.33398 15.2668H16.6673V14.0668H7.33398V15.2668ZM9.75825 10.9092L7.75825 8.90923L6.90972 9.75776L8.90972 11.7578L9.75825 10.9092ZM7.75825 9.75776L9.75825 7.75776L8.90972 6.90923L6.90972 8.90923L7.75825 9.75776ZM16.6673 8.7335H7.33398V9.9335H16.6673V8.7335Z" fill="#EFB930" />
        </svg>
    </SvgIcon>
);
export default Trade;