import { defaultPagination } from '@/types/pagination';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@/store/store';
import { PlatformsAllowedCountriesStore, RegionEntry, UpdateEnableCountryPayload, UpdateEnableDocsPayload } from './platformsCountriesTypes';
import { FeeCategoryCodeType, PlatformCountriesSubTabs } from '../platforms/platformsTypes';
import { showErrorNotification } from '@/utils/errors';
import { UserType } from '../user/types';

export const paginationParamsInit = defaultPagination;

const initialState: PlatformsAllowedCountriesStore = {
    storedWlpId: '',
    countries: {
        [PlatformCountriesSubTabs.DOCUMENT_VERIFICATION]: {
            loading: false,
            list: [],
            error: null
        },
        [PlatformCountriesSubTabs.RESIDENCE_VERIFICATION]: {
            loading: false,
            list: [],
            error: null
        },
        [PlatformCountriesSubTabs.BANKING]: {
            loading: false,
            list: [],
            error: null,
            selectedUserType: UserType.INDIVIDUAL
        },
        [PlatformCountriesSubTabs.CARDS]: {
            loading: false,
            list: [],
            error: null,
            selectedUserType: UserType.INDIVIDUAL
        },
        [PlatformCountriesSubTabs.CRYPTO]: {
            loading: false,
            list: [],
            error: null,
            selectedUserType: UserType.INDIVIDUAL
        }
    }
};

const slice = createSlice({
    name: 'platformsCountries',
    initialState,
    reducers: {
        setList(state, action: PayloadAction<{ type: PlatformCountriesSubTabs, list: Array<RegionEntry> }>) {
            state.countries[action.payload.type].list = action.payload.list;
        },
        setLoading: (state, action: PayloadAction<{ type: PlatformCountriesSubTabs, loading: boolean }>) => {
            state.countries[action.payload.type].loading = action.payload.loading
        },
        setError: (state, action: PayloadAction<{ type: PlatformCountriesSubTabs, error: string }>) => {
            state.countries[action.payload.type].error = action.payload.error;
        },
        setSelectedUserType(state, action: PayloadAction<{ type: PlatformCountriesSubTabs, userType: UserType }>) {
            state.countries[action.payload.type].selectedUserType = action.payload.userType;
        },
        setStoredWlpId: (state, action: PayloadAction<string>) => {
            state.storedWlpId = action.payload
        },
    },
});

export const { setList, setLoading, setError, setSelectedUserType, setStoredWlpId } = slice.actions;

export const getAllowedDocs = (wlpId: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ type: PlatformCountriesSubTabs.DOCUMENT_VERIFICATION, loading: true }));
            const response = await request.get(`/api/console/platforms/allowed-docs/${wlpId}`);
            const { data } = response;
            dispatch(setList({ type: PlatformCountriesSubTabs.DOCUMENT_VERIFICATION, list: data }));
            dispatch(setLoading({ type: PlatformCountriesSubTabs.DOCUMENT_VERIFICATION, loading: false }));
            return data;
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading({ type: PlatformCountriesSubTabs.DOCUMENT_VERIFICATION, loading: false }));
        }
    };
};

export const updateEnabledDocs = async (payload: UpdateEnableDocsPayload) => {
    const response = await request.post('/api/console/platforms/enable-disable/docs', payload);
    const { data } = response;
    return data;
};

export const updateAllowedCountries = async (type: PlatformCountriesSubTabs, payload: UpdateEnableCountryPayload) => {
    const response = await request.post(`/api/console/platforms/enable-country/${type}`, payload);
    const { data } = response;
    return data;
};

export const getAllowedCountries = (wlpId: string, type: PlatformCountriesSubTabs, userType?: UserType) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ type: type, loading: true }));
            const response = await request.get(`/api/console/platforms/allowed-countries/${wlpId}/${type}`, {
                params: { ...(userType && { userType: userType }) }
            });
            const { data } = response;
            dispatch(setList({ type: type, list: data }));
            dispatch(setLoading({ type: type, loading: false }));
            return data;
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading({ type: type, loading: false }));
        }
    };
};

export const getProcessesV1 = async (wlpId: string, categoryCode: FeeCategoryCodeType) => {
    const response = await request.get(`/api/console/platforms/process/configurations/v1/${wlpId}/${categoryCode}`);
    const { data } = response;
    return data;
};

export default slice.reducer;