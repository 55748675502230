import { lazy } from 'react';
import AuthGuard from '@/utils/routeGuard/AuthGuard';
import MainLayout from '@/layout/MainLayout';
import Loadable from 'components/Loadable';
import { PermissionType } from '@/redux/user/types';
import PermissionGuard from '@/utils/routeGuard/PermissionGuard';
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from '@/pages/maintenance/ErrorFallback';
import BankCardDetails from '@/pages/bankCardDetails/BankCardDetails';
import PageInDevelopment from '@/pages/maintenance/PageInDevelopment';
import ConfigurationAndFees from '@/pages/configurationAndFees/ConfigurationAndFees';

const Dashboard = Loadable(lazy(() => import('@/pages/dashboard/Dashboard')));
const Users = Loadable(lazy(() => import('pages/users/Users')));
const UsersDetails = Loadable(lazy(() => import('pages/userDetails/UsersDetails')));
const Accounts = Loadable(lazy(() => import('pages/accounts/Accounts')));
const AccountDetails = Loadable(lazy(() => import('pages/accountDetails/AccountDetails')));
const Finance = Loadable(lazy(() => import('pages/finance/Finance')));
const Transfers = Loadable(lazy(() => import('pages/transfers/Transfers')));
const TransferDetails = Loadable(lazy(() => import('pages/transferDetails/TransferDetails')));
const Cards = Loadable(lazy(() => import('pages/cards/Cards')));
const CryptoWallets = Loadable(lazy(() => import('@/pages/crypto/CryptoWallets')));
const EarnDetails = Loadable(lazy(() => import('pages/earnDetails/EarnDetails')));
const CryptoTrades = Loadable(lazy(() => import('@/pages/crypto/CryptoTrades')));
const ReferralPayments = Loadable(lazy(() => import('pages/userDetails/userReferals/ReferralPayments')));
const Support = Loadable(lazy(() => import('pages/support/Support')));
const EarnRepaymentsDetails = Loadable(lazy(() => import('@/pages/earn/EarnRepaymentsDetails')));
const EarnDepositDetails = Loadable(lazy(() => import('@/pages/earn/EarnDepositDetails')));
const EarnInterestDetails = Loadable(lazy(() => import('@/pages/earn/EarnInterestDetails')));
const EarnConfigurationDetails = Loadable(lazy(() => import('@/pages/earn/EarnConfigurationDetails')));
const Settings = Loadable(lazy(() => import('@/pages/settings/Settings')));
const WlpUserGroup = Loadable(lazy(() => import('@/pages/userGroupDetails/wlpUserGroup/WlpUserGroup')));
const WlpUserPlan = Loadable(lazy(() => import('@/pages/userPlanDetails/wlpUserPlan/WlpUserPlan')));
const InvoiceDocument = Loadable(lazy(() => import('@/pages/emiBilling/invoiceDocument/InvoiceDocument')));
const Reports = Loadable(lazy(() => import('@/pages/reports/Reports')));

const NotPermitted = Loadable(lazy(() => import('pages/maintenance/NotPermitted')));
const RouteNotFound = Loadable(lazy(() => import('pages/maintenance/RouteNotFound')));

const WlpRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <ErrorBoundary
                fallback={<ErrorFallback error />}   >
                <MainLayout />
            </ErrorBoundary>
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/configuration-and-fees/:tab',
            element:
                <PermissionGuard userPermissions={[PermissionType.PLATFORMS_FEES, PermissionType.USER_GROUPS, PermissionType.USER_LEVEL, PermissionType.LIMITS]}>
                    <ConfigurationAndFees />
                </PermissionGuard>
        },
        {
            path: '/users/details/:id/:tab',
            element:
                <PermissionGuard permission={PermissionType.USERS}>
                    <UsersDetails />
                </PermissionGuard>
        },
        {
            path: '/users/details/:id/referrals/payments/:refId',
            element:
                <PermissionGuard permission={PermissionType.USERS}>
                    <ReferralPayments />
                </PermissionGuard>
        },
        {
            path: '/users/:tab',
            element:
                <PermissionGuard permission={PermissionType.USERS}>
                    <Users />
                </PermissionGuard>
        },
        {
            path: '/accounts/details/:id',
            element:
                <PermissionGuard permission={PermissionType.ACCOUNTS}>
                    <AccountDetails />
                </PermissionGuard>
        },
        {
            path: '/accounts/:tab',
            element:
                <PermissionGuard permission={PermissionType.ACCOUNTS}>
                    <Accounts />
                </PermissionGuard>
        },
        {
            path: '/cards/details/:id',
            element:
                <PermissionGuard permission={PermissionType.CARDS}>
                    <BankCardDetails />
                </PermissionGuard>
        },
        {
            path: '/finances/:tab',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <Finance />
                </PermissionGuard>
        },
        {
            path: '/invoice/details/:id',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <InvoiceDocument />
                </PermissionGuard>
        },
        {
            path: '/settings/:tab',
            element:
                <PermissionGuard permission={PermissionType.SYSTEM_USERS}>
                    <Settings />
                </PermissionGuard>

        },
        {
            path: '/transfers/:tab',
            element:
                <PermissionGuard permission={PermissionType.DOCUMENTS}>
                    <Transfers />
                </PermissionGuard>
        },
        {
            path: '/transfers/details/:id',
            element:
                <PermissionGuard permission={PermissionType.DOCUMENTS}>
                    <TransferDetails />
                </PermissionGuard>
        },
        {
            path: '/cards/:tab',
            element:
                <PermissionGuard permission={PermissionType.CARDS}>
                    <Cards />
                </PermissionGuard>
        },
        {
            path: '/wallets',
            element:
                <PermissionGuard permission={PermissionType.ACCOUNTS}>
                    <CryptoWallets />
                </PermissionGuard>
        },
        {
            path: '/trades',
            element:
                <PermissionGuard permission={PermissionType.DOCUMENTS}>
                    <CryptoTrades />
                </PermissionGuard>
        },
        {
            path: '/earn/details/:id/:uId',
            element:
                <PermissionGuard permission={PermissionType.ACCOUNTS}>
                    <EarnDetails />
                </PermissionGuard>
        },
        {
            path: '/not-permitted',
            element: <NotPermitted />
        },
        {
            path: '*',
            element: <RouteNotFound />
        },
        {
            path: '/support',
            element:
                <PermissionGuard permission={PermissionType.SUPPORT}>
                    <Support />
                </PermissionGuard>

        },
        {
            path: '/analytics',
            element: <PageInDevelopment />
        },
        {
            path: '/earn/:tab',
            element: <PageInDevelopment />,
        },
        {
            path: '/earn/deposits/:proc',
            element: <PermissionGuard permission={PermissionType.EARN}>
                <EarnDepositDetails />
            </PermissionGuard>
        },
        {
            path: '/earn/interest/:proc',
            element: <PermissionGuard permission={PermissionType.EARN}>
                <EarnInterestDetails />
            </PermissionGuard>
        },
        {
            path: '/earn/repayments/:proc',
            element: <PermissionGuard permission={PermissionType.EARN}>
                <EarnRepaymentsDetails />
            </PermissionGuard>
        },
        {
            path: '/user-group/:tab',
            element: <PermissionGuard permission={PermissionType.TOKENS}>
                <WlpUserGroup />
            </PermissionGuard>
        },
        {
            path: '/user-plan/:tab',
            element: <PermissionGuard permission={PermissionType.TOKENS}>
                <WlpUserPlan />
            </PermissionGuard>
        },
        {
            path: '/earn/configuration/:proc',
            element: <PermissionGuard permission={PermissionType.EARN}>
                <EarnConfigurationDetails />
            </PermissionGuard>
        },
        {
            path: '/reports/:tab',
            element: <PermissionGuard permission={PermissionType.REPORTS}>
                <Reports />
            </PermissionGuard>
        },
        {
            path: '/tokens',
            element: <PageInDevelopment />
        }
    ]
};

export default WlpRoutes;
