import * as React from "react";
import { SvgIcon } from '@mui/material';

const Billing = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path opacity="0.5" d="M13.9625 1.66797H6.03751C5.07179 1.66797 4.58892 1.66797 4.19949 1.80347C3.46102 2.06043 2.88123 2.65729 2.63163 3.41752C2.5 3.81842 2.5 4.31551 2.5 5.30969V16.9798C2.5 17.695 3.32083 18.0745 3.84008 17.5993C4.14514 17.3202 4.60486 17.3202 4.90992 17.5993L5.3125 17.9677C5.84716 18.4569 6.65284 18.4569 7.1875 17.9677C7.72216 17.4784 8.52784 17.4784 9.0625 17.9677C9.59716 18.4569 10.4028 18.4569 10.9375 17.9677C11.4722 17.4784 12.2778 17.4784 12.8125 17.9677C13.3472 18.4569 14.1528 18.4569 14.6875 17.9677L15.0901 17.5993C15.3951 17.3202 15.8549 17.3202 16.1599 17.5993C16.6792 18.0745 17.5 17.695 17.5 16.9798V5.30969C17.5 4.31551 17.5 3.81842 17.3684 3.41752C17.1188 2.65729 16.539 2.06043 15.8005 1.80347C15.4111 1.66797 14.9282 1.66797 13.9625 1.66797Z" stroke="currentColor" strokeWidth="1.2" />
            <path d="M8.75 9.16797L14.1667 9.16797" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M5.83203 9.16797H6.2487" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M5.83203 6.25H6.2487" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M5.83203 12.082H6.2487" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M8.75 6.25H14.1667" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M8.75 12.082H14.1667" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default Billing;