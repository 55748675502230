import { defaultPagination } from '@/types/pagination';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { PlatformCountriesSubTabs, FeeCategoryCodeType, PlatformOverviewType, PlatformsStore, PlatformsTab } from './platformsTypes';
import { AppDispatch } from '@/store/store';

export const paginationParamsInit = defaultPagination;

const initialState: PlatformsStore = {
    tabs: {
        [PlatformsTab.FEES]: {
            openedSubTab: FeeCategoryCodeType.COMPLIANCE,
        },
        [PlatformsTab.ALLOWED_COUNTRIES]: {
            openedSubTab: PlatformCountriesSubTabs.DOCUMENT_VERIFICATION,
        },
    },
    overview: null,
    overviewLoading: false,
};

const slice = createSlice({
    name: 'platforms',
    initialState,
    reducers: {
        setSubTab(state, action: PayloadAction<{ tab: PlatformsTab; subTab: FeeCategoryCodeType }>) {
            state.tabs[action.payload.tab].openedSubTab = action.payload.subTab;
        },
        setOverview(state, action: PayloadAction<PlatformOverviewType | null>) {
            state.overview = action.payload;
        },
        setLoadingOverview(state, action: PayloadAction<boolean>) {
            state.overviewLoading = action.payload;
        },

    },
});

export const { setSubTab, setLoadingOverview, setOverview } = slice.actions;

export const getPlatforms = async () => {
    const response = await request.get(`/api/console/platforms/list`);
    const { data } = response;
    return data;
};

export const getOverviewBalances = async () => {
    const response = await request.get(`/api/console/platforms/stats/overview/balances`);
    const { data } = response;
    return data;
};

export const getOverviewFees = async () => {
    const response = await request.get(`/api/console/platforms/stats/overview/fees`);
    const { data } = response;
    return data;
};

export const getPlatformOverview = (wlpId: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingOverview(true));
            const response = await request.get(`/api/console/platforms/overview/${wlpId}`);
            const { data } = response;
            dispatch(setOverview(data));
        }
        catch (e) {
            dispatch(setOverview(null));
        }
        finally {
            dispatch(setLoadingOverview(false));
        }
    }
};

export default slice.reducer;