import StatusChip from "@/components/chips/StatusChip";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const PaymentConfigStatusCell = ({ value, onClick }: { value: boolean, onClick: () => void }) => {
    const { t } = useTranslation();

    if (!value) {
        return <Chip
            sx={{
                borderRadius: '10px',
                minWidth: '95px',
                minHeight: '24px',
                '& .MuiChip-label:hover': {
                    color: 'inherit',
                }
            }}
            label={t('form.buttons.makePrimary')}
            variant="outlined"
            onClick={onClick} />
    }

    return (<StatusChip label='PRIMARY' />);
};

export default PaymentConfigStatusCell;