import { SvgIcon } from '@mui/material';

const InterestPayment = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#2B2B2B" fillOpacity="0.06" />
            <g clipPath="url(#clip0_10711_18330)">
                <path d="M10 14L14 10" stroke="#40927E" strokeWidth="1.2" strokeLinecap="round" />
                <path d="M14.3333 13.6667C14.3333 14.0349 14.0349 14.3333 13.6667 14.3333C13.2985 14.3333 13 14.0349 13 13.6667C13 13.2985 13.2985 13 13.6667 13C14.0349 13 14.3333 13.2985 14.3333 13.6667Z" fill="#40927E" />
                <path d="M10.9993 10.3332C10.9993 10.7014 10.7009 10.9998 10.3327 10.9998C9.96449 10.9998 9.66602 10.7014 9.66602 10.3332C9.66602 9.96498 9.96449 9.6665 10.3327 9.6665C10.7009 9.6665 10.9993 9.96498 10.9993 10.3332Z" fill="#40927E" />
                <path d="M5.33398 12.0002C5.33398 8.85747 5.33398 7.28612 6.3103 6.30981C7.28661 5.3335 8.85795 5.3335 12.0007 5.3335C15.1433 5.3335 16.7147 5.3335 17.691 6.30981C18.6673 7.28612 18.6673 8.85747 18.6673 12.0002C18.6673 15.1429 18.6673 16.7142 17.691 17.6905C16.7147 18.6668 15.1433 18.6668 12.0007 18.6668C8.85795 18.6668 7.28661 18.6668 6.3103 17.6905C5.33398 16.7142 5.33398 15.1429 5.33398 12.0002Z" stroke="#40927E" strokeWidth="1.2" />
            </g>
            <defs>
                <clipPath id="clip0_10711_18330">
                    <rect width="16" height="16" fill="white" transform="translate(4 4)" />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);
export default InterestPayment;