import * as React from "react";
import { SvgIcon } from '@mui/material';

const Settings = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path opacity="0.5" d="M6.5359 3.16973C8.2265 2.16856 9.0718 1.66797 10 1.66797C10.9282 1.66797 11.7735 2.16856 13.4641 3.16973L14.0359 3.50835C15.7265 4.50952 16.5718 5.01011 17.0359 5.83464C17.5 6.65916 17.5 7.66033 17.5 9.66268V10.3399C17.5 12.3423 17.5 13.3434 17.0359 14.168C16.5718 14.9925 15.7265 15.4931 14.0359 16.4943L13.4641 16.8329C11.7735 17.834 10.9282 18.3346 10 18.3346C9.0718 18.3346 8.2265 17.834 6.5359 16.8329L5.9641 16.4943C4.2735 15.4931 3.4282 14.9925 2.9641 14.168C2.5 13.3434 2.5 12.3423 2.5 10.3399V9.66268C2.5 7.66033 2.5 6.65916 2.9641 5.83464C3.4282 5.01011 4.2735 4.50952 5.9641 3.50835L6.5359 3.16973Z" stroke="currentColor" strokeWidth="1.2" />
            <circle cx="10" cy="10" r="2.5" stroke="currentColor" strokeWidth="1.2" />
        </svg>
    </SvgIcon>
);
export default Settings;