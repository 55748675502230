import { OptionsListType } from "@/types/select";
import { Autocomplete, TextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";

interface Props {
  fieldName: string;
  options?: OptionsListType;
  fieldlabel?: string;
  textFieldProps?: TextFieldProps;
  onDropdownChange?: (value: any) => void;
}

const FormSelect = ({
  fieldName,
  fieldlabel,
  onDropdownChange,
  options,
  textFieldProps,
  ...otherProps
}: Props) => {
  const [field, meta, helpers] = useField(fieldName);
  const { setValue } = helpers;
  const { value } = meta;

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue?.value ?? null);
    onDropdownChange && onDropdownChange(newValue);
  };

  const configTextfield = {
    ...field,
    ...otherProps,
    error: false,
    helperText: "",
    fullWidth: true,
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  // Ensure the Autocomplete value is always controlled
  const autoCompleteValue = options?.find(option => option.value === value) || null;

  return (
    <Autocomplete
      options={options || []}
      value={autoCompleteValue}
      onChange={handleChange}
      getOptionLabel={(option) => option?.text ?? ""}
      isOptionEqualToValue={(option, v) => option.value === v?.value}
      renderInput={(params) => (
        <TextField
          {...configTextfield}
          name={fieldName}
          label={fieldlabel || ""}
          {...params}
          {...textFieldProps}
        />
      )}
      sx={{ width: "100%", minWidth: "165px" }}
      fullWidth
    />
  );
};

export default FormSelect;
