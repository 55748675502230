import { SvgIcon } from '@mui/material';

const BillReceivable = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#2B2B2B" fillOpacity="0.06" />
            <path d="M15.6095 10.9428C16 10.5523 16 9.92375 16 8.66667C16 7.40959 16 6.78105 15.6095 6.39052M15.6095 10.9428C15.219 11.3333 14.5904 11.3333 13.3333 11.3333H10.6667C9.40959 11.3333 8.78105 11.3333 8.39052 10.9428M15.6095 10.9428C15.6095 10.9428 15.6095 10.9428 15.6095 10.9428ZM15.6095 6.39052C15.219 6 14.5904 6 13.3333 6L10.6667 6C9.40959 6 8.78105 6 8.39052 6.39052M15.6095 6.39052C15.6095 6.39052 15.6095 6.39052 15.6095 6.39052ZM8.39052 6.39052C8 6.78105 8 7.40959 8 8.66667C8 9.92375 8 10.5523 8.39052 10.9428M8.39052 6.39052C8.39052 6.39052 8.39052 6.39052 8.39052 6.39052ZM8.39052 10.9428C8.39052 10.9428 8.39052 10.9428 8.39052 10.9428Z" stroke="#2B2B2B" strokeWidth="1.2" />
            <path d="M12.6673 8.66667C12.6673 9.03486 12.3688 9.33333 12.0007 9.33333C11.6325 9.33333 11.334 9.03486 11.334 8.66667C11.334 8.29848 11.6325 8 12.0007 8C12.3688 8 12.6673 8.29848 12.6673 8.66667Z" stroke="#2B2B2B" strokeWidth="0.9" />
            <path d="M16 8C14.8954 8 14 7.10457 14 6" stroke="#2B2B2B" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M16 9.3335C14.8954 9.3335 14 10.2289 14 11.3335" stroke="#2B2B2B" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M8 8C9.10457 8 10 7.10457 10 6" stroke="#2B2B2B" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M8 9.3335C9.10457 9.3335 10 10.2289 10 11.3335" stroke="#2B2B2B" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M7.33398 17.5924H8.84061C9.51451 17.5924 10.1957 17.6626 10.8515 17.7977C12.0117 18.0368 13.2332 18.0657 14.4053 17.876C14.9832 17.7825 15.5513 17.6394 16.0656 17.3912C16.5299 17.1671 17.0986 16.8512 17.4806 16.4974C17.8621 16.1441 18.2593 15.5659 18.5413 15.114C18.7831 14.7264 18.6661 14.251 18.2837 13.9622C17.8589 13.6414 17.2285 13.6415 16.8037 13.9623L15.5989 14.8724C15.132 15.2252 14.622 15.5499 14.0144 15.6468C13.9413 15.6584 13.8648 15.669 13.7849 15.6783M13.7849 15.6783C13.7608 15.6811 13.7365 15.6837 13.7119 15.6863M13.7849 15.6783C13.8821 15.6575 13.9786 15.5975 14.0692 15.5185C14.498 15.1442 14.5251 14.5135 14.153 14.0956C14.0667 13.9986 13.9657 13.9178 13.8534 13.8508C11.9885 12.7385 9.08693 13.5856 7.33398 14.8288M13.7849 15.6783C13.7606 15.6835 13.7362 15.6863 13.7119 15.6863M13.7119 15.6863C13.363 15.7221 12.9548 15.7314 12.5018 15.6886" stroke="#2B2B2B" strokeWidth="1.1" strokeLinecap="round" />
            <rect x="5.33398" y="13.3335" width="2" height="5.33333" rx="1" stroke="#2B2B2B" strokeWidth="1.2" />
        </svg>
    </SvgIcon>
);
export default BillReceivable;