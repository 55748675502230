import { AccountTypeEnum } from "../accounts/accountsTypes";

export enum ExportType {
	PDF = 'pdf', EXCEL = 'excel'
}


export interface StatementExportPayload {
  userId: number;
  from: string;
  to: string;
  search: string;
  ccy?: string;
  accountId?: string;
  type?: AccountTypeEnum,
  templateIdentifier?: string;
}