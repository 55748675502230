import { Button, Dialog, DialogContent, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import DialogTitle from '@/components/DialogTitle';
import { showErrorNotification, showSuccessNotification } from "@/utils/errors";
import FormTextField from "@/components/form/FormTextField";
import { UserType } from "@/redux/user/types";
import { OptionsListType } from "@/types/select";
import FormSelect from "@/components/form/FormSelect";
import { createUserGroup } from "@/redux/userGroups/userGroupsSlice";

interface Props {
    wlpId?: string
    open: boolean,
    onComplete: () => void
    onClose: () => void
}

const CreateGroup = ({ wlpId, open, onComplete, onClose }: Props) => {
    const { t } = useTranslation();

    const initialValues = {
        groupName: '',
        userType: ''
    };

    const validationSchema = Yup.object({
        groupName: Yup.string().required(t("form.validator.required").toString()),
        userType: Yup.string().nullable().required(t("form.validator.required").toString()),
    });

    const handleClose = () => {
        onClose();
    };

    const submit = async (formData: any) => {
        try {
            const { groupName, userType } = formData;
            const payload = { name: groupName, userType: userType, ...(wlpId && { wlpId: wlpId }) }
            await createUserGroup(payload);
            showSuccessNotification(t('form.fields.groupCreated'));
        }
        catch (e) {
            showErrorNotification(e);
        }
        finally {
            onComplete();
        }
    };

    const userTypeOptions: OptionsListType = [UserType.INDIVIDUAL, UserType.BUSINESS].map(item => ({
        value: item,
        text: t(`enum.userType.${item}`),
    }));

    return (
        <Dialog
            id="create-group-form"
            open={open}
            onClose={handleClose}
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: '320px',
                    maxHeight: '350px',
                    maxWidth: '500px'
                }
            }}>
            <DialogTitle onClose={handleClose}>
                {t('form.fields.createUserGroup')}
            </DialogTitle>

            <DialogContent>
                <Grid container direction='column' wrap='nowrap'>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={submit}>
                        {({ isSubmitting }) => {
                            return (
                                <Form>
                                    <Grid container item>
                                        <FormTextField
                                            name='groupName'
                                            fullWidth
                                            label={t('form.fields.name')} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormSelect
                                            fieldName="userType"
                                            fieldlabel={t('form.fields.userTypes').toString()}
                                            options={userTypeOptions}
                                        />
                                    </Grid>
                                    <Grid container item justifyContent='space-between' mt='auto' pt={3}>
                                        <Button onClick={handleClose} variant='outlined' color='secondary' disabled={isSubmitting}>{t('form.buttons.cancel')}</Button>
                                        <Button type='submit'>{t('form.buttons.create')}</Button>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CreateGroup;