import { SvgIcon } from '@mui/material';

const UserLevelSubscription = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#40927E" fillOpacity="0.16" />
            <path d="M7.33398 12.6665L9.68493 14.5473C10.2365 14.9885 11.0364 14.9231 11.5089 14.3981L16.6673 8.6665" stroke="#40927E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);
export default UserLevelSubscription;