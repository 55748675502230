import { Store, Tax } from "../merchants/merchantsTypes";

export interface MerchantDetailsStore {
    detailsSubTab: SubTabsEnum;
    storedMerchantId: number | null;
    details: {
        merchantDetails: Store | null;
        loading: boolean;
    },
    products: {
        merchantProducts: Array<Category>;
        loading: boolean;
    }
};

export const enum SubTabsEnum {
    INFORMATION = "INFORMATION",
    PRODUCTS = "PAYOUTS"
};

interface Product {
    id: string;
    productCategoryId: string;
    price: number;
    comparePrice: string;
    ccy: string;
    sku: number;
    hidden: boolean;
    relatedProductId: string;
    updatedDate: string;
    createdDate: string;
    createdBy: string;
    updatedBy: string;
    status: string;
    productImages: Array<ProductImage>;
    title: {
        text: string;
    };
}

export interface ProductImage {
    id: string;
    name: string;
    imageKey: string;
    orderNr: number;
    imageUrl: string;
}

export interface Category {
    id: string;
    parentId: string | null;
    products: Array<Product>;
    title: {
        text: string;
    };
    children: Array<Category>;
    tax: Tax;
}