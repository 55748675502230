import * as React from "react";
import { SvgIcon } from '@mui/material';

const Support = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path d="M14.168 9.00375C14.168 8.71567 14.168 8.57167 14.2113 8.44333C14.3373 8.07037 14.6695 7.92563 15.0022 7.77407C15.3763 7.60368 15.5633 7.51849 15.7486 7.5035C15.9591 7.48648 16.1698 7.53182 16.3496 7.63274C16.5881 7.76653 16.7543 8.02078 16.9246 8.22752C17.7107 9.18233 18.1037 9.65983 18.2476 10.1863C18.3636 10.6112 18.3636 11.0555 18.2476 11.4803C18.0378 12.2482 17.3751 12.892 16.8845 13.4878C16.6336 13.7926 16.5081 13.945 16.3496 14.0339C16.1698 14.1348 15.9591 14.1802 15.7486 14.1632C15.5633 14.1482 15.3763 14.063 15.0022 13.8926C14.6695 13.741 14.3373 13.5963 14.2113 13.2233C14.168 13.095 14.168 12.951 14.168 12.6629V9.00375Z" stroke="currentColor" strokeWidth="1.5" />
            <path d="M5.83464 9.00384C5.83464 8.64117 5.82445 8.31518 5.53123 8.06017C5.42458 7.96742 5.28318 7.90301 5.00039 7.77421C4.62631 7.60382 4.43927 7.51863 4.25394 7.50364C3.69789 7.45867 3.39874 7.83818 3.07808 8.22765C2.29193 9.1825 1.89885 9.65992 1.75502 10.1864C1.63895 10.6113 1.63895 11.0557 1.75502 11.4805C1.9648 12.2484 2.62757 12.8921 3.11814 13.488C3.42738 13.8636 3.72278 14.2063 4.25394 14.1633C4.43927 14.1483 4.62631 14.0631 5.00039 13.8928C5.28318 13.7639 5.42458 13.6995 5.53123 13.6068C5.82445 13.3518 5.83464 13.0258 5.83464 12.6631V9.00384Z" stroke="currentColor" strokeWidth="1.5" />
            <path d="M4.16797 7.5C4.16797 4.73857 6.77964 2.5 10.0013 2.5C13.223 2.5 15.8346 4.73857 15.8346 7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
            <path d="M15.832 14.168V14.8346C15.832 16.3074 14.3396 17.5013 12.4987 17.5013H10.832" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);
export default Support;