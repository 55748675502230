import { PaginateParams } from '@/types/pagination';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { EditPaymentConfigPayload, InvoicesSummary, PaymentConfig, PaymentConfigPayload, PayoutsSummary, PlatformInvoicesAndPayoutsStore, invoicesPaginationParamsInit, } from './platformInvoicesAndPayoutsTypes';
import { payoutsDefaultPagination, withdrawalsDefaultPagination } from '../emiBilling/emiBillingSlice';
import { AppDispatch } from '@/store/store';
import { EmiInvoice, EmiPayout, PayoutWithdrawal, WithdrawalsSummary } from '../emiBilling/emiBillingTypes';

const initialState: PlatformInvoicesAndPayoutsStore = {
    invoicesSummary: {
        data: null,
        loading: false
    },
    invoices: {
        loading: false,
        list: [],
        count: 0,
        error: null,
        pagination: invoicesPaginationParamsInit
    },
    payoutsSummary: {
        data: null,
        loading: false
    },
    payouts: {
        loading: false,
        list: [],
        count: 0,
        error: null,
        pagination: payoutsDefaultPagination
    },
    withdrawals: {
        loading: false,
        list: [],
        count: 0,
        error: null,
        pagination: withdrawalsDefaultPagination
    },
    withdrawalsSummary: {
        data: null,
        loading: false
    },
};

const slice = createSlice({
    name: 'platformInvoicesAndPayouts',
    initialState,
    reducers: {
        setInvoicesSummary(state, action: PayloadAction<InvoicesSummary | null>) {
            state.invoicesSummary.data = action.payload;
        },
        setInvoicesSummaryLoading(state, action: PayloadAction<boolean>) {
            state.invoicesSummary.loading = action.payload;
        },
        setPayoutsSummary(state, action: PayloadAction<PayoutsSummary | null>) {
            state.payoutsSummary.data = action.payload;
        },
        setPayoutsSummaryLoading(state, action: PayloadAction<boolean>) {
            state.payoutsSummary.loading = action.payload;
        },
        setWithdrawalsSummary(state, action: PayloadAction<WithdrawalsSummary | null>) {
            state.withdrawalsSummary.data = action.payload;
        },
        setWithdrawalsSummaryLoading(state, action: PayloadAction<boolean>) {
            state.withdrawalsSummary.loading = action.payload;
        },
        // Invoices
        setInvoices(state, action: PayloadAction<Array<EmiInvoice>>) {
            state.invoices.list = action.payload;
        },
        setInvoicesLoading(state, action: PayloadAction<boolean>) {
            state.invoices.loading = action.payload;
        },
        setInvoicesError: (state, action: PayloadAction<string>) => {
            state.invoices.error = action.payload;
        },
        setInvoicesCount: (state, action: PayloadAction<number>) => {
            state.invoices.count = action.payload;
        },
        setInvoicesPagination: (state, action: PayloadAction<PaginateParams>) => {
            state.invoices.pagination = action.payload;
        },
        // Payouts
        setPayouts(state, action: PayloadAction<Array<EmiPayout>>) {
            state.payouts.list = action.payload;
        },
        setPayoutsLoading(state, action: PayloadAction<boolean>) {
            state.payouts.loading = action.payload;
        },
        setPayoutsError: (state, action: PayloadAction<string>) => {
            state.payouts.error = action.payload;
        },
        setPayoutsCount: (state, action: PayloadAction<number>) => {
            state.payouts.count = action.payload;
        },
        setPayoutsPagination: (state, action: PayloadAction<PaginateParams>) => {
            state.payouts.pagination = action.payload;
        },
        // Withdrawals
        setWithdrawals(state, action: PayloadAction<Array<PayoutWithdrawal>>) {
            state.withdrawals.list = action.payload;
        },
        setWithdrawalsLoading(state, action: PayloadAction<boolean>) {
            state.withdrawals.loading = action.payload;
        },
        setWithdrawalsError: (state, action: PayloadAction<string>) => {
            state.withdrawals.error = action.payload;
        },
        setWithdrawalsCount: (state, action: PayloadAction<number>) => {
            state.withdrawals.count = action.payload;
        },
        setWithdrawalsPagination: (state, action: PayloadAction<PaginateParams>) => {
            state.withdrawals.pagination = action.payload;
        },
    },
});

export const {
    setInvoicesSummary,
    setInvoicesSummaryLoading,
    setPayoutsSummary,
    setPayoutsSummaryLoading,
    setInvoices,
    setInvoicesLoading,
    setInvoicesError,
    setInvoicesCount,
    setInvoicesPagination,
    setPayouts,
    setPayoutsLoading,
    setPayoutsError,
    setPayoutsCount,
    setPayoutsPagination,
    setWithdrawalsSummary,
    setWithdrawalsSummaryLoading,
    setWithdrawals,
    setWithdrawalsLoading,
    setWithdrawalsError,
    setWithdrawalsCount,
    setWithdrawalsPagination
} = slice.actions;


export const getPlatformInvoicesSummary = (wlpId: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setInvoicesSummaryLoading(true));
            const response = await request.get(`/api/console/billing/platform/invoices/summary/${wlpId}`);
            const data = response?.data || null;
            dispatch(setInvoicesSummary(data));
        }
        catch (e) {
            dispatch(setInvoicesSummary(null));
        }
        finally {
            dispatch(setInvoicesSummaryLoading(false));
        }
    }
};

export const getPlatformInvoices = (wlpId: string, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setInvoicesLoading(true));
            const response = await request.get(`/api/console/billing/platform/invoices/list/${wlpId}`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take,
                }
            });
            const data = response?.data || [];
            dispatch(setInvoices(data.emiBillingInvoices));
            dispatch(setInvoicesCount(data.count));
            dispatch(setInvoicesPagination(payload));
        }
        catch (e) {
            dispatch(setInvoices([]));
        }
        finally {
            dispatch(setInvoicesLoading(false));
        }
    }
};

export const getPlatformInvoicesForSummary = async (wlpId: string, payload: PaginateParams) => {
    const response = await request.get(`/api/console/billing/platform/invoices/list/${wlpId}`, {
        params: {
            filter: payload.filter,
            orderBy: payload.orderBy,
            sort: payload.sort.toUpperCase(),
            skip: payload.skip.toString(),
            take: payload.take,
        }
    });
    const data = response?.data || [];
    return data;
};

export const getPlatformPayoutsSummary = (wlpId: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setPayoutsSummaryLoading(true));
            const response = await request.get(`/api/console/billing/platform/payouts/summary/${wlpId}`);
            const data = response?.data || null;
            dispatch(setPayoutsSummary(data));
        }
        catch (e) {
            dispatch(setPayoutsSummary(null));
        }
        finally {
            dispatch(setPayoutsSummaryLoading(false));
        }
    }
};

export const getPlatformPayouts = (wlpId: string, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setPayoutsLoading(true));
            const response = await request.get(`/api/console/billing/platform/payouts`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take,
                    wlpId: wlpId
                }
            });
            const data = response?.data || [];
            dispatch(setPayouts(data.emiBillingPayouts));
            dispatch(setPayoutsCount(data.count));
            dispatch(setPayoutsPagination(payload));
        }
        catch (e) {
            dispatch(setPayouts([]));
        }
        finally {
            dispatch(setPayoutsLoading(false));
        }
    }
};

export const getPlatformPayoutsForSummary = async (wlpId: string, payload: PaginateParams) => {
    const response = await request.get(`/api/console/billing/platform/invoices/list/${wlpId}`, {
        params: {
            filter: payload.filter,
            orderBy: payload.orderBy,
            sort: payload.sort.toUpperCase(),
            skip: payload.skip.toString(),
            take: payload.take,
        }
    });
    const data = response?.data || [];
    return data;
};

export const getPlatformWithdrawalsSummary = (wlpId: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setWithdrawalsSummaryLoading(true));
            const response = await request.get(`/api/console/billing/platform/withdrawals/summary/${wlpId}`);
            const data = response?.data || null;
            dispatch(setWithdrawalsSummary(data));
        }
        catch (e) {
            dispatch(setWithdrawalsSummary(null));
        }
        finally {
            dispatch(setWithdrawalsSummaryLoading(false));
        }
    }
};

export const getPlatformWithdrawals = (wlpId: string, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setWithdrawalsLoading(true));
            const response = await request.get(`/api/console/billing/payouts/withdrawals`, {
                params: {
                    filter: payload.filter ? [payload.filter, `wlpId=${wlpId}`].join(';') : `wlpId=${wlpId}`,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const data = response?.data || [];
            dispatch(setWithdrawals(data.withdrawaList));
            dispatch(setWithdrawalsCount(data.count));
            dispatch(setWithdrawalsPagination(payload));
        }
        catch (e) {
            dispatch(setWithdrawals([]));
        }
        finally {
            dispatch(setWithdrawalsLoading(false));
        }
    }
};

export const getPlatformPaymentsConfig = async (wlpId?: string): Promise<Array<PaymentConfig>> => {
    const params = wlpId ? { wlpId } : {};
    const response = await request.get(`/api/console/billing/payments/config`, { params });
    const data = response?.data || [];
    return data;
};

export const enableDisablePaymentsConfig = async (payload: { wlpId?: string, configId: number }) => {
    const response = await request.post(`/api/console/billing/payment/config/enable-disable`, payload);
    const { data } = response;
    return data;
};

export const changeDefaultPaymentsConfig = async (payload: { wlpId?: string, configId: number }) => {
    const response = await request.post(`/api/console/billing/payment/config/change-default`, payload);
    const { data } = response;
    return data;
};

export const deletePaymentsConfig = async (configId: number, wlpId?: string) => {
    const params = {
        configId,
        ...(wlpId && { wlpId })
    };
    const response = await request.delete(`/api/console/billing/payment/config/delete`, { params });
    const { data } = response;
    return data;
};

export const createPaymentsConfig = async (payload: PaymentConfigPayload) => {
    const response = await request.post(`/api/console/billing/payment/config/create`, payload);
    const { data } = response;
    return data;
};

export const editPaymentsConfig = async (payload: EditPaymentConfigPayload) => {
    const response = await request.post(`/api/console/billing/payment/config/edit`, payload);
    const { data } = response;
    return data;
};

export default slice.reducer;