import * as React from "react";
import { SvgIcon } from '@mui/material';

const Accounts = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="7.4987" cy="7.4987" r="1.66667" stroke="currentColor" strokeWidth="1.2" />
            <path d="M10.8346 12.4987C10.8346 13.4192 10.8346 14.1654 7.5013 14.1654C4.16797 14.1654 4.16797 13.4192 4.16797 12.4987C4.16797 11.5782 5.66035 10.832 7.5013 10.832C9.34225 10.832 10.8346 11.5782 10.8346 12.4987Z" stroke="currentColor" strokeWidth="1.2" />
            <path opacity="0.5" d="M1.66797 9.9987C1.66797 6.856 1.66797 5.28465 2.64428 4.30834C3.62059 3.33203 5.19194 3.33203 8.33464 3.33203H11.668C14.8107 3.33203 16.382 3.33203 17.3583 4.30834C18.3346 5.28465 18.3346 6.856 18.3346 9.9987C18.3346 13.1414 18.3346 14.7127 17.3583 15.6891C16.382 16.6654 14.8107 16.6654 11.668 16.6654H8.33463C5.19194 16.6654 3.62059 16.6654 2.64428 15.6891C1.66797 14.7127 1.66797 13.1414 1.66797 9.9987Z" stroke="currentColor" strokeWidth="1.2" />
            <path d="M15.832 10H12.4987" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M15.832 7.5H11.6654" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path opacity="0.9" d="M15.832 12.5H13.332" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default Accounts;