import { SvgIcon } from '@mui/material';

const Money = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#2B2B2B" fillOpacity="0.06" />
            <path d="M15.17 5.3335H8.83001C8.05743 5.3335 7.67114 5.3335 7.35959 5.4419C6.76881 5.64747 6.30499 6.12496 6.1053 6.73314C6 7.05386 6 7.45153 6 8.24687V17.583C6 18.1551 6.65666 18.4587 7.07206 18.0786C7.31612 17.8552 7.68388 17.8552 7.92794 18.0786L8.25 18.3733C8.67772 18.7647 9.32228 18.7647 9.75 18.3733C10.1777 17.9819 10.8223 17.9819 11.25 18.3733C11.6777 18.7647 12.3223 18.7647 12.75 18.3733C13.1777 17.9819 13.8223 17.9819 14.25 18.3733C14.6777 18.7647 15.3223 18.7647 15.75 18.3733L16.0721 18.0786C16.3161 17.8552 16.6839 17.8552 16.9279 18.0786C17.3433 18.4587 18 18.1551 18 17.583V8.24687C18 7.45153 18 7.05386 17.8947 6.73314C17.695 6.12496 17.2312 5.64747 16.6404 5.4419C16.3289 5.3335 15.9426 5.3335 15.17 5.3335Z" stroke="#2B2B2B" strokeWidth="1.2" />
            <path d="M11 11.3335L15.3333 11.3335" stroke="#2B2B2B" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M8.66602 11.3335H8.99935" stroke="#2B2B2B" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M8.66602 9H8.99935" stroke="#2B2B2B" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M8.66602 13.6665H8.99935" stroke="#2B2B2B" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M11 9H15.3333" stroke="#2B2B2B" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M11 13.6665H15.3333" stroke="#2B2B2B" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default Money;