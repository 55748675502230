import { dispatch, useSelector } from '@/store/store';
import { PaginateParams } from '@/types/pagination';
import { useMemo, useRef, useState } from 'react';
import CustomDataGrid, { CustomDataGridRef } from '@/components/dataGrid/CustomDataGrid';
import { checkPermission, checkWritePermission } from '@/utils/permissions';
import { Permission, PermissionType } from '@/redux/user/types';
import { Button, Stack } from '@mui/material';
import Plus from '@/icons/Plus';
import { useTranslation } from 'react-i18next';
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { RowActionType } from '@/types/rowActions';
import CreateGroup from '@/pages/platformDetails/platformUserGroups/CreateGroup';
import ArrowRight1 from '@/icons/ArrowRight1';
import { useCustomNavigate } from '@/utils/customHooks/useCustomNavigate';
import { getPlatfromUserGroups, paginationParamsInit } from '@/redux/userGroups/userGroupsSlice';
import { UserGroup } from '@/redux/userGroups/userGroupsTypes';

const getUserGroupUrl = (permissions: Array<Permission>): string => {
    if (checkPermission(permissions, PermissionType.USER_GROUPS)) {
        return '/user-group/users';
    }
    if (checkPermission(permissions, PermissionType.USER_GROUPS_FEES)) {
        return '/user-group/fees';
    }
    if (checkPermission(permissions, PermissionType.LIMITS)) {
        return '/user-group/limits';
    }
    return '/not-permitted';
};

const getColumn = (
    actionItems: Array<RowActionType<UserGroup>>,
    writePermission: boolean,
    t: any
) => {
    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('form.fields.name'),
            flex: 1,
        },
        {
            field: 'usersCount',
            headerName: t('form.fields.groupSize'),
            flex: 1,
            sortable: false
        },
        {
            field: 'profileType',
            headerName: t('form.fields.userTypes'),
            flex: 1,
            sortable: false,
            valueFormatter: (params) => {
                if (!params.value) return (t('form.fields.notConfigured'))
                return t(`enum.userType.${params.value}`);
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: t('form.fields.actions'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            sortable: false,
            getActions: (params: GridRowParams) => actionItems.map(item => {
                const icon: JSX.Element =
                    (item.key === "edit" && !writePermission)
                        && item.iconOpposite
                        ? item.iconOpposite
                        : item.icon;

                return (
                    <GridActionsCellItem

                        icon={icon}
                        onClick={() => { item.onClick(params.row) }}
                        label=""
                    />
                )
            })
        }
    ];
    return columns;
}


const WlpUserGroups = () => {
    const ref = useRef<CustomDataGridRef>(null);
    const customNavigate = useCustomNavigate();

    const { list, error, count, loading, pagination } = useSelector((state) => state.userGroups);

    const { t } = useTranslation();

    const [openCreate, setOpenCreate] = useState(false);

    const { permissions } = useSelector((state) => state.user);
    const writePermission = checkWritePermission(permissions, PermissionType.USER_GROUPS);

    const fetch = async (searchParams: PaginateParams) => {
        await dispatch(getPlatfromUserGroups(searchParams))
    };

    const columns = useMemo(() => {
        const actionItems = [
            {
                key: "menu",
                icon: <ArrowRight1 viewBox="0 0 16 16" />,
                onClick: (row: UserGroup) => {
                    customNavigate(
                        {
                            title: row.name,
                            path: getUserGroupUrl(permissions),
                            state: { userGroupId: row.id, userType: row.profileType, groupName: row.name }
                        }
                    );
                },
            },
        ];
        return getColumn(actionItems, writePermission, t)
    }, [writePermission, t, customNavigate, permissions]);

    const createGroupBtn = (
        <Button variant="outlined" color='secondary' onClick={() => setOpenCreate(true)} disabled={!writePermission}>
            <Stack direction='row' columnGap={1}>
                <Plus sx={{
                    fontSize: '0.813rem',
                    margin: 'auto',
                    color: 'inherit',
                    'path': { strokeWidth: 3 }
                }} />
                {t('form.buttons.createNewGroup')}
            </Stack>
        </Button>
    );

    return (
        <>
            <CreateGroup
                open={openCreate}
                onClose={() => setOpenCreate(false)}
                onComplete={() => {
                    setOpenCreate(false);
                    ref?.current?.reloadData && ref?.current?.reloadData();
                }} />
            <CustomDataGrid
                ref={ref}
                rows={list}
                getRowId={(row) => row.id}
                rowCount={count}
                fetch={fetch}
                loading={loading}
                paginationParams={pagination}
                paginationParamsInit={paginationParamsInit}
                columns={columns}
                loadingError={error}
                withSearch={false}
                customButton={createGroupBtn}
                withRefreshBtn
            />
        </>

    );
};

export default WlpUserGroups;