import { Pagination, TablePaginationProps } from "@mui/material";
import { GridPagination, gridPaginationModelSelector, gridRowCountSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid";

const CustomPagination = ({
    className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
    const apiRef = useGridApiContext();
    const rowCount = useGridSelector(apiRef, gridRowCountSelector);
    const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector);

    const totalPages = paginationModel.pageSize > 0 ? Math.ceil(rowCount / paginationModel.pageSize) : 1;
    return (
        <Pagination
            color="primary"
            shape="rounded"
            size="small"
            showFirstButton
            showLastButton
            className={className}
            count={totalPages}
            page={paginationModel.page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
            sx={{ pl: 2 }}
        />
    );
}

const CustomGridPagination = (props: any) => {
    return <GridPagination ActionsComponent={CustomPagination}  {...props}
        sx={{
            '& .MuiToolbar-root': {
                paddingLeft: 0,
            },
            '& .MuiTablePagination-displayedRows': {
                display: 'none'
            },
            '& .MuiTablePagination-spacer': {
                display: 'none'
            },
            '& .MuiPagination-root.MuiTablePagination-actions.MuiPagination-text': {
                marginLeft: 0,
                order: 1
            },
            '& .MuiTablePagination-selectLabel': { order: 2, marginLeft: 3 },
            '& .MuiInputBase-root': { order: 3 }
        }} />;
}

export default CustomGridPagination;
