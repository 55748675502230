import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { showErrorNotification } from '@/utils/errors';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { Wlp } from '../user/types';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { getWlpName } from '@/components/dataGrid/utils/Platform';
import { TokensTransactionsStore, TransactionsTypes } from './tokensTransactionsTypes';
import { getDocumentStatusOptions, getDocumentTypeOptions, getTranferList } from '../transfers/transfersSlice';
import { CryptoTradesEnum, DocumentTypeEnum, DocumentTypeWBH } from '../transfers/transfersTypes';
import { AccountTypeEnum } from '../accounts/accountsTypes';

export const tokensTransactionsPaginationParamsInit = {
    filter: `fromToAccountTypes=TOKEN;ceoNotNull=false`,
    orderBy: 'documentDate',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

export const tokensTradesPaginationParamsInit = {
    filter: `fromToAccountTypes=TOKEN,WALLET,BANK;ceoNotNull=true`,
    orderBy: 'date',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: TokensTransactionsStore = {
    [TransactionsTypes.TRADES]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: tokensTradesPaginationParamsInit,
        filters: {
            [HeaderFilterType.WLPS]: [],
        },
        filtersSet: false
    },
    [TransactionsTypes.TRANSFERS]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: tokensTransactionsPaginationParamsInit,
        filters: {
            [HeaderFilterType.WLPS]: [],
        },
        filtersSet: false
    }
};

const slice = createSlice({
    name: 'tokensTransactions',
    initialState,
    reducers: {
        setList(state, action: PayloadAction<{ type: TransactionsTypes, list: Array<DocumentTypeWBH> }>) {
            state[action.payload.type].list = action.payload.list;
        },
        setLoading: (state, { payload }: PayloadAction<{ type: TransactionsTypes, loading: boolean }>) => {
            state[payload.type].loading = payload.loading;
        },
        setError: (state, { payload }: PayloadAction<{ type: TransactionsTypes, error: string }>) => {
            state[payload.type].error = payload.error;
        },
        setCount: (state, { payload }: PayloadAction<{ type: TransactionsTypes, count: number }>) => {
            state[payload.type].count = payload.count;
        },
        setPagination: (state, { payload }: PayloadAction<{ type: TransactionsTypes, params: PaginateParams }>) => {
            state[payload.type].pagination = payload.params;
        },
        // Filters
        setFilters: (state, action: PayloadAction<{ type: TransactionsTypes, wlps: Array<Wlp>, currencies: Array<FilterOptionType> }>) => {
            const wlpsOptions = action.payload.wlps.map(item => ({
                value: item.wlpId,
                text: getWlpName(item.wlpId),
                enabled: true
            }));
            const statusOptions = getDocumentStatusOptions();
            const typeOptions = getDocumentTypeOptions();
            if (action.payload.type !== TransactionsTypes.TRADES) {
                state[action.payload.type].filters[HeaderFilterType.CURRENCY] = action.payload.currencies;
                state[action.payload.type].filters[HeaderFilterType.TYPE] = typeOptions;
            } else {
                state[action.payload.type].filters[HeaderFilterType.CURRENCY_FROM] = action.payload.currencies;
                state[action.payload.type].filters[HeaderFilterType.CURRENCY_TO] = action.payload.currencies;
            }
            state[action.payload.type].filters[HeaderFilterType.STATUS] = statusOptions;
            state[action.payload.type].filters[HeaderFilterType.WLPS] = wlpsOptions;
            state[action.payload.type].filtersSet = true;
        },
        setFilter: (state, action: PayloadAction<{ type: TransactionsTypes, filterType: HeaderFilterType, options: Array<FilterOptionType> }>) => {
            state[action.payload.type].filters[action.payload.filterType] = action.payload.options;
        },
        setFiltersSet(state, action: PayloadAction<{ type: TransactionsTypes, set: boolean }>) {
            state[action.payload.type].filtersSet = action.payload.set;
        }
    }
});

export const { setList, setLoading, setError, setCount, setPagination, setFilters, setFilter, setFiltersSet } = slice.actions;

export const getTokensTransactionsList = (
    type: TransactionsTypes,
    payload: PaginateParams,
    version: "V1" | "V2" = 'V2',
    accountTypes?: Array<AccountTypeEnum>,
    documentTypes?: Array<DocumentTypeEnum | CryptoTradesEnum>
) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ type, loading: true }));
            const data = await getTranferList(payload, false, version, accountTypes, documentTypes);
            dispatch(setList({ type, list: data.list }));
            dispatch(setCount({ type, count: data.count }));
            dispatch(setPagination({ type, params: payload }));
            dispatch(setLoading({ type, loading: false }));
        } catch (e: any) {
            if (e?.name && e.name !== 'CanceledError') {
                showErrorNotification(e);
                dispatch(setLoading({ type, loading: false }));
            }
        }
    };
};

export const getTokensTransfers = (type: TransactionsTypes, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ type, loading: true }));
            const data = await getTranferList(payload, false);
            dispatch(setList({ type, list: data.list }));
            dispatch(setCount({ type, count: data.count }));
            dispatch(setPagination({ type, params: payload }));
            return data;
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLoading({ type, loading: false }));
        }
    };
};


export default slice.reducer;