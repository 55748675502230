import { SvgIcon } from '@mui/material';

const Money = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#2B2B2B" fillOpacity="0.06" />
            <path d="M10.0007 16.6668C8.12804 16.6668 7.19173 16.6668 6.51913 16.2174C6.22796 16.0229 5.97796 15.7729 5.7834 15.4817C5.33398 14.8091 5.33398 13.8728 5.33398 12.0002C5.33398 10.1275 5.33398 9.19124 5.7834 8.51864C5.97795 8.22747 6.22796 7.97747 6.51913 7.78291C7.19173 7.3335 8.12803 7.3335 10.0007 7.3335L14.0007 7.3335C15.8733 7.3335 16.8096 7.3335 17.4822 7.78291C17.7733 7.97747 18.0233 8.22747 18.2179 8.51864C18.6673 9.19124 18.6673 10.1275 18.6673 12.0002C18.6673 13.8728 18.6673 14.8091 18.2179 15.4817C18.0233 15.7729 17.7733 16.0229 17.4822 16.2174C16.8096 16.6668 15.8733 16.6668 14.0007 16.6668H10.0007Z" stroke="#40927E" strokeWidth="1.4" />
            <path d="M12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10Z" stroke="#40927E" strokeWidth="1.2" />
            <path d="M7.66602 14L7.66602 10" stroke="#40927E" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M16.334 14L16.334 10" stroke="#40927E" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default Money;