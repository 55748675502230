import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '@/utils/errors';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { EarnVaultPlanDepositPaymentScheduleStore } from './earnVaultPlanDepositPaymentScheduleTypes';

export const earnVaultPlanDepositPaymentSchedulePaginationParamsInit = {
    filter: '',
    orderBy: 'transferDate',
    sort: SortDirectionEnum.ASC,
    take: 10,
    skip: 0
};

const initialState: EarnVaultPlanDepositPaymentScheduleStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    pagination: earnVaultPlanDepositPaymentSchedulePaginationParamsInit,
    storedProc: null,
    storedAccountId: null
};

const slice = createSlice({
    name: 'earnVaultPlanDepositPaymentSchedule',
    initialState,
    reducers: {
        reset: () => initialState,
        setList(state, action: PayloadAction<Array<any>>) {
            state.list = action.payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
            state.pagination = payload;
        },
        setStoredData: (state, { payload }: PayloadAction<{ proc: string, accountId: number }>) => {
            state.storedProc = payload.proc;
            state.storedAccountId = payload.accountId;
        }
    }
});

export const { setList, setLoading, setError, setCount, setPagination, setStoredData, reset } = slice.actions;

export const getEarnVaultPlanDepositPaymentSchedule = (proc: string, accountId: number, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/vaults/vault/deposit/payment-schedule/${proc}/${accountId}`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setList(data.list));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            dispatch(setLoading(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading(false));
        }
    };
};





export default slice.reducer;