
import { GridColumnMenu, GridColumnMenuProps } from "@mui/x-data-grid";


export const CustomColumnMenu = (props: GridColumnMenuProps) => {
    return (
        <GridColumnMenu {...props}
            components={{
                ColumnMenuFilterItem: null,
            }} />
    );
}


export default CustomColumnMenu;
