
import DownloadButton from '@/components/DownloadButton';
import ExportStatementDialog from '@/components/ExportStatementDialog';
import CustomDataGrid from '@/components/dataGrid/CustomDataGrid';
import AmountWithLowerValueCell from '@/components/dataGrid/renderCell/AmountWithLowerValueCell';
import CardDetailsCell from '@/components/dataGrid/renderCell/CardDetailsCell';
import DateCell from '@/components/dataGrid/renderCell/DateCell';
import DocumentTypeCell from '@/components/dataGrid/renderCell/DocumentTypeCell';
import StatusCell from '@/components/dataGrid/renderCell/StatusCell';
import { AccountTypeEnum } from '@/redux/accounts/accountsTypes';
import { getCardTransactions, paginationParamsInit, reset, setStoredAccountId } from '@/redux/cardTransactions/cardTransactionsSlice';
import { CardTransactionsEnum, DocumentTypeEnum } from '@/redux/transfers/transfersTypes';
import { dispatch, useSelector } from '@/store/store';
import { PaginateParams } from '@/types/pagination';
import { t } from '@/utils/translate';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';

interface Props {
    accountId: number,
    userId: number,
}

const getColumn = () => {
    const columns: GridColDef[] = [
        {
            field: 'date',
            headerName: t('form.fields.date'),
            flex: 1,
            renderCell: (params: GridRenderCellParams) => DateCell(params),
            sortable: false,
        },
        {
            field: 'activity',
            headerName: t('form.fields.type'),
            flex: 1,
            renderCell: DocumentTypeCell,
            valueGetter: (params) => {
                if (params.value === 'WITHDRAW') return CardTransactionsEnum.CARD_WITHDRAW;
                else if (params.value === 'PURCHASE') return CardTransactionsEnum.CARD_PURCHASE;
                else if (params.value === 'CARD_TOP_UP') return DocumentTypeEnum.CARD_TOPUP;
                else return params.value;
            },
            sortable: false,
        },
        {
            field: 'amount',
            headerName: t('form.fields.amount'),
            flex: 0.8,
            renderCell: (params: GridRenderCellParams) =>
                AmountWithLowerValueCell({
                    value: params.value,
                    currency: params.row.currency,
                    withLogo: true,
                    withMinusSign: false,
                }),
            sortable: false,
        },
        {
            field: 'details',
            headerName: t('form.fields.details'),
            flex: 1.4,
            renderCell: CardDetailsCell,
            sortable: false,
        },
        {
            field: 'status',
            headerName: t('form.fields.status'),
            flex: 0.8,
            sortable: false,
            renderCell: StatusCell
        }
    ];
    return columns;
};

const CardTransaction = ({ accountId, userId }: Props) => {
    const { storedAccountId, initial, list, error, count, loading, pagination } = useSelector((state) => state.cardTransactions);

    const [openExportDialog, setOpenExportDialog] = useState<boolean>(false);

    const fetch = async (searchParams: PaginateParams) => {
        await dispatch(getCardTransactions(accountId, userId, initial, searchParams))
    };

    const columns = useMemo(() => {
        return getColumn()
    }, []);

    const customButton = (
        <DownloadButton onClick={() => setOpenExportDialog(true)} />
    );

    useEffect(() => {
        if (storedAccountId !== accountId) {
            dispatch(reset());
            dispatch(setStoredAccountId(accountId));
        }
    }, [accountId, storedAccountId]);

    return (
        <>
            <ExportStatementDialog
                accountId={accountId}
                userId={userId}
                onClose={() => setOpenExportDialog(false)}
                open={openExportDialog}
                type={AccountTypeEnum.CARD}
            />
            <CustomDataGrid
                rows={list}
                getRowId={(row) => row.transactionNumber}
                readyToFetchCondition={accountId === storedAccountId}
                rowCount={count}
                withRefreshBtn={true}
                fetch={fetch}
                loading={loading}
                paginationParams={pagination}
                paginationParamsInit={paginationParamsInit}
                columns={columns}
                loadingError={error}
                customButton={customButton}
                withSearch
                searchFilterName='details'
            />
        </>
    );
};

export default CardTransaction;
