import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useField } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import AccountItem from './AccountItem';
import { useState } from 'react';
import { Account } from '@/redux/accounts/accountsTypes';
import { getCurrencyLogo } from '@/utils/currency';

interface Props {
	fieldName: string,
	accountList: Array<Account>,
	fieldlabel?: string,
	activeAccount?: Account,
	onChange?: (account: Account | undefined) => void
}

const AccountDropdown2 = ({ fieldName, accountList, fieldlabel, activeAccount, onChange }: Props) => {
	const { t } = useTranslation();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(fieldName);
	const { setValue } = helpers;
	const { value } = meta;
	const disabled = accountList?.length < 1;

	const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(activeAccount);
	const handleChange = (event: any) => {
		const newId = event.target.value;
		setValue(newId);
		const account = accountList.find(p => p.id === newId);
		setSelectedAccount(account);
		onChange && onChange(account);
	};

	const validationFailed = meta && meta.touched && meta.error;
	const textFieldValue = isEmpty(selectedAccount) ?
		t('form.fields.select') : <Box
			display='flex'
		>
			<AccountItem
				balance={selectedAccount?.balanceAvailable ?? 0}
				currency={selectedAccount?.currency ?? ''}
				logoSource={selectedAccount?.currency ? getCurrencyLogo(selectedAccount.currency) : ''}
				name={selectedAccount ? t('enum.accountTypeEnum.' + selectedAccount.type).toString() : ''} />
		</Box>;

	return (
		<FormControl fullWidth >
			{fieldlabel && <InputLabel> {fieldlabel}</InputLabel>}
			<Select
				name={fieldName}
				value={value ?? ''}
				displayEmpty
				disabled={disabled}
				onChange={handleChange}
				renderValue={() => textFieldValue}
				sx={theme => ({
					bgcolor: theme.palette.transparentBlack,
					display: 'flex',
					flex: 1,
					minHeight: '4.6rem',
					'& .Mui-disabled': {
						WebkitTextFillColor: 'unset!important'
					},
					'& .MuiSvgIcon-root': {
						display: disabled ? 'none' : 'inherit'
					}
				})}
			>
				{accountList?.length > 0 &&
					accountList.map((item) => (
						<MenuItem
							sx={{ opacity: 1, padding: 2 }}
							key={item.id}
							value={item.id}>
							<AccountItem
								balance={item?.balanceAvailable ?? 0}
								currency={item?.currency ?? ''}
								logoSource={item?.currency ? getCurrencyLogo(item.currency) : ''}
								name={t('enum.accountTypeEnum.' + item.type).toString()} />
						</MenuItem>
					))}
			</Select>
			{validationFailed && <FormHelperText error>{meta.error}</FormHelperText>}
		</FormControl>
	);
};

export default AccountDropdown2;
