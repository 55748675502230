import { Grid, Typography, useTheme } from "@mui/material";
import { Section } from "../sections/emiSections";
import NavItem from "./NavItem";
import { useTranslation } from "react-i18next";


interface Props {
    section: Section
    open: boolean
}

const SectionItem = ({ section, open }: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const isMainSection = !section.type;
    return (
        <Grid
            py={1}
            sx={{
                ...(isMainSection && {
                    borderBottom: `1px solid ${theme.palette.white.light}`,
                })
            }}>
            {!isMainSection && open &&
                <Typography
                    pl={3}
                    color={theme.palette.white.main}
                    variant="body2">
                    {t('enum.sectionTypes.' + section.type)}
                </Typography>}
            {section.items.map((item) =>
                <NavItem key={item.id} item={item} open={open} />
            )}
        </Grid>
    );
}

export default SectionItem;