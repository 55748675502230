import { PaginateParams, SortDirectionEnum } from "@/types/pagination";
import { MerchantsStore, Store } from "./merchantsTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "@/store/store";
import request from "@/services/request";
import { showErrorNotification } from "@/utils/errors";

export const paginationParamsInit = {
    filter: '',
    orderBy: 'createdDate',
    sort: SortDirectionEnum.DESC,
    take: 15,
    skip: 0
};

const initialState: MerchantsStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    pagination: paginationParamsInit
};

const slice = createSlice({
    name: 'merchants',
    initialState,
    reducers: {
        setStores(state, action: PayloadAction<Array<Store>>) {
            state.list = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setPagination: (state, action: PayloadAction<PaginateParams>) => {
            state.pagination = action.payload;
        }
    }
});

export const { setLoading, setError, setCount, setPagination, setStores } = slice.actions;

export const getStores = (payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get("/api/console/merchants/stores", {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                },
            });
            const { data } = response;
            dispatch(setStores(data.list));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            return data;
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export default slice.reducer;