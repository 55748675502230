import { FilterOptionType } from '@/components/dataGrid/CustomFilterHeader';
import { PaginateParams } from '@/types/pagination';
import { Account } from '../accounts/accountsTypes';

export interface UserAccountsStore {
    [key: string]: {
        loading: boolean,
        list: Array<Account>,
        error: string | null,
        count: number,
        pagination: PaginateParams,
        filters: {
            [key: string]: Array<FilterOptionType>
        },
        filtersSet: boolean;
    }
};

export enum UserAccountTypeEnum {
    USER_ALL = 'USER_ALL',
    CRYPTO = 'CRYPTO',
    BANK = 'BANK',
    WALLET = 'WALLET'
};