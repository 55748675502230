import DialogTitle from "@/components/DialogTitle";
import { logout } from "@/redux/user/userSlice";
import { dispatch } from "@/store/store";
import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setInterval, clearInterval } from 'worker-timers';

interface Props {
    open: boolean,
    onClose: () => void;
    onContinue: () => void;
    time?: number;
};

const splitTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return { minutes, seconds };
}

const ExtendSessionModal = ({ open, onClose, onContinue, time }: Props) => {
    const { t } = useTranslation();

    const defaultTimeout = 30;
    const timeout = time ?? defaultTimeout

    const [timeRemaining, setTimeRemaining] = useState<number>(time ?? defaultTimeout);

    const handleOnLogout = useCallback(async () => {
        await dispatch(logout());
        await dispatch({ type: 'RESET_APP' });
        onClose();
    }, [onClose])

    useEffect(() => {
        if (!open) return;
        setTimeRemaining(timeout);
        const timerId = setInterval(() => {
            setTimeRemaining(prevTime => {
                const newTime = prevTime - 1;
                if (newTime <= 0) {
                    handleOnLogout();
                    return 0;
                }
                return newTime;
            });
        }, 1000);
        return () => { clearInterval(timerId) };
    }, [open, timeout, handleOnLogout]);

    const handleOnClose = () => {
        onClose();
        handleOnLogout();
    };

    const handleOnContinue = () => {
        onContinue();
        onClose();
    };

    const { minutes, seconds } = splitTime(timeRemaining);

    return (
        <Dialog
            id="inactive-modal"
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleOnClose();
                }
            }}
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: '200px',
                    maxHeight: '300px',
                    maxWidth: '500px'
                }
            }}
        >
            <DialogTitle>
                {t('session.sesionExpire')}
            </DialogTitle>

            <DialogContent>

                <Grid container direction='column' wrap='nowrap'>

                    <Grid container item mt={1}>
                        {minutes > 0 ? (
                            <Typography>{t('session.sessionIsSetToExpire', { minutes, seconds })}</Typography>
                        ) : (
                            <Typography>{t('session.sessionIsSetToExpireInSeconds', { seconds })}</Typography>
                        )}
                    </Grid>

                    <Grid container item justifyContent='space-between' mt='auto' pt={2}>
                        <Button onClick={handleOnContinue} variant='outlined' color='secondary'>{t('form.buttons.continue')}</Button>
                        <Button onClick={handleOnClose}>{t('form.buttons.logout')}</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog >
    );
}

export default ExtendSessionModal;