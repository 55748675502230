import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { CreateOrUpdateUserGroupFeePayload, EmiUserGroupStore, UserGroupFee } from './emiUserGroupFeesTypes';
import { FeeCategoryCodeType } from '../platforms/platformsTypes';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { ProcessConfiguration, ProcessesFilterConfiguration } from '../platformFees/platformFeesTypes';
import { AppDispatch } from '@/store/store';
import { showErrorNotification } from '@/utils/errors';
import request from '@/services/request';
import { getProcessFilterOptions } from '../platformFees/platformFeesSlice';
import { UserType } from '../user/types';

export const paginationParamsInit = {
    filter: '',
    orderBy: 'id',
    sort: SortDirectionEnum.ASC,
    take: 25,
    skip: 0
};

export const getPaginationParamsInit = (feeCategoryCode: FeeCategoryCodeType) => {
    return {
        filter: `categoryCode=${feeCategoryCode};processDisabled=false`,
        orderBy: "id",
        sort: SortDirectionEnum.DESC,
        take: 25,
        skip: 0
    };
};

const initialState: EmiUserGroupStore = {
    storedGroupId: 0,
    fees: {
        [FeeCategoryCodeType.BANKING]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: getPaginationParamsInit(FeeCategoryCodeType.BANKING),
            loadingProcesses: false,
            uniqueCcy: [],
            processesConfigurations: null,
            selectedCurrency: "EUR",
            filters: {
                [HeaderFilterType.PROCESSES]: [],
            },
            filtersSet: false
        },
        [FeeCategoryCodeType.CARD]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: getPaginationParamsInit(FeeCategoryCodeType.CARD),
            loadingProcesses: false,
            uniqueCcy: [],
            processesConfigurations: null,
            selectedCurrency: "EUR",
            filters: {
                [HeaderFilterType.PROCESSES]: [],
            },
            filtersSet: false
        },
        [FeeCategoryCodeType.CRYPTO]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: getPaginationParamsInit(FeeCategoryCodeType.CRYPTO),
            loadingProcesses: false,
            uniqueCcy: [],
            processesConfigurations: null,
            selectedCurrency: "USDT",
            filters: {
                [HeaderFilterType.PROCESSES]: [],
            },
            filtersSet: false
        }
    },
};
const slice = createSlice({
    name: 'emiUserGroupFees',
    initialState,
    reducers: {
        reset: () => initialState,
        setFees(state, action: PayloadAction<{ type: FeeCategoryCodeType, list: Array<UserGroupFee> }>) {
            state.fees[action.payload.type].list = action.payload.list;
        },
        setLoading: (state, action: PayloadAction<{ type: FeeCategoryCodeType, loading: boolean }>) => {
            state.fees[action.payload.type].loading = action.payload.loading
        },
        setError: (state, action: PayloadAction<{ type: FeeCategoryCodeType, error: string }>) => {
            state.fees[action.payload.type].error = action.payload.error;
        },
        setCount: (state, action: PayloadAction<{ type: FeeCategoryCodeType, count: number }>) => {
            state.fees[action.payload.type].count = action.payload.count;
        },
        setPagination: (state, action: PayloadAction<{ type: FeeCategoryCodeType, pagination: PaginateParams }>) => {
            state.fees[action.payload.type].pagination = action.payload.pagination;
        },
        // Filters
        setUniqueCCy(state, action: PayloadAction<{ type: FeeCategoryCodeType, list: Array<string> }>) {
            state.fees[action.payload.type].uniqueCcy = action.payload.list;
        },
        setProcessesLoading: (state, action: PayloadAction<{ type: FeeCategoryCodeType, loading: boolean }>) => {
            state.fees[action.payload.type].loadingProcesses = action.payload.loading
        },
        setSelectedCurrency(state, action: PayloadAction<{ type: FeeCategoryCodeType, currency: string }>) {
            state.fees[action.payload.type].selectedCurrency = action.payload.currency;
        },
        setProcessesConfigurations(state, action: PayloadAction<{ type: FeeCategoryCodeType, processesConfigurations: Array<ProcessesFilterConfiguration> }>) {
            state.fees[action.payload.type].processesConfigurations = action.payload.processesConfigurations;
        },
        setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, type: FeeCategoryCodeType, options: Array<FilterOptionType> }>) => {
            state.fees[action.payload.type].filters[action.payload.filterType] = action.payload.options;
        },
        setStoredGroupId: (state, action: PayloadAction<number>) => {
            state.storedGroupId = action.payload
        }
    },
});

export const {
    reset,
    setFees,
    setLoading,
    setError,
    setCount,
    setPagination,
    setUniqueCCy,
    setProcessesLoading,
    setFilter,
    setStoredGroupId,
    setSelectedCurrency,
    setProcessesConfigurations
} = slice.actions;


export const getAndSetProcessesConfigurations = (type: FeeCategoryCodeType, wlpId: string, selectedCurrency: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setProcessesLoading({ type, loading: true }));

            const filter = `hasIntegrationConfig=true;deleted=false;disabled=false;notIntegration=SYSTEM,TOKENS;categoryCode=${type}`;
            const response = await request.get(`/api/console/platforms/process/configurations/v2`, {
                params: {
                    wlpId: wlpId,
                    filter: filter
                }
            });

            const { data } = response as { data: Array<ProcessConfiguration> };
            const processesConfigurations = data.map(item => {
                return {
                    process: item.process,
                    processName: item.name,
                    ccys: item.ccys,
                    integration: item.integration
                };
            });
            dispatch(setProcessesConfigurations({ type, processesConfigurations: processesConfigurations }));

            const uniqueCcy = [...new Set(data.flatMap(process => process.ccys))];
            dispatch(setUniqueCCy({ type, list: uniqueCcy }));

            if (uniqueCcy.length > 0) {
                if (type === FeeCategoryCodeType.CRYPTO) {
                    if (uniqueCcy.includes('USDT')) dispatch(setSelectedCurrency({ type, currency: 'USDT' }));
                    else if (uniqueCcy.includes('BTC')) dispatch(setSelectedCurrency({ type, currency: 'BTC' }));
                    else dispatch(setSelectedCurrency({ type, currency: uniqueCcy[0] }));
                } else {
                    if (uniqueCcy.includes('EUR')) dispatch(setSelectedCurrency({ type, currency: 'EUR' }));
                    else dispatch(setSelectedCurrency({ type, currency: uniqueCcy[0] }));
                }
            };

            const filteredProcessesConfigurations = getProcessFilterOptions(processesConfigurations, selectedCurrency);
            dispatch(setFilter({ filterType: HeaderFilterType.PROCESSES, type: type, options: filteredProcessesConfigurations }));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setFilter({ filterType: HeaderFilterType.PROCESSES, type: type, options: [] }));
        } finally {
            dispatch(setProcessesLoading({ type, loading: false }));
        }
    };
};

export const getUserGroupFees = (type: FeeCategoryCodeType, wlpId: string, userGroupId: number, ccy: string, userType: UserType, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ type, loading: true }));
            const response = await request.get(`/api/console/platforms/user-group/fees/${userGroupId}`, {
                params: {
                    wlpId: wlpId,
                    filter: payload.filter + `;ccy=${ccy};userProfileType=${userType}`,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take,
                }
            });
            const { data } = response;
            dispatch(setFees({ type, list: data.userGroupFees }));
            dispatch(setCount({ type, count: data.count }));
            dispatch(setPagination({ type, pagination: payload }));
            return data;
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLoading({ type, loading: false }));
        }
    };
};

export const createOrUpdateGroupFee = async (wlpId: string, payload: CreateOrUpdateUserGroupFeePayload) => {
    const response = await request.post(`/api/console/platforms/user-group/fee/create-or-update/${wlpId}`, payload);
    return response?.data;
};

export default slice.reducer;