 
import { Tab as MuiTab, styled } from "@mui/material";

const TabButton = styled(MuiTab)(
    ({ theme }) => ({ 
        minHeight:  '1.875rem',
        height:  '1.875rem',
        fontSize: '0.688rem',
        border: '1px solid', 
        borderColor: theme.palette.transparentGreen,
        borderRadius: 10, 
        '&.Mui-selected': {
            background: theme.palette.transparentGreen, 
            border: 'unset'
        }

    }),
);

export default TabButton;
