import FormTextField from "@/components/form/FormTextField";
import { CardDetails, CardShipmentCountry } from "@/redux/cards/cardsTypes";
import { Button, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { alpha3ToAlpha2, getName } from "i18n-iso-countries";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { validatePostalCode } from "@/utils/postalCodeValidation";
import { isEmpty } from "lodash";
import { CardDeliveryAddressType } from "./EmiCardReplaceDialog";
import CountryDropdown from "@/components/form/CountryDropdown";

interface Props {
    oldCard: CardDetails,
    countryList: CardShipmentCountry[],
    deliveryAddress?: CardDeliveryAddressType,
    onConfirm: (payload: CardDeliveryAddressType) => void
}


const CardReplaceDelivery = ({ oldCard, deliveryAddress, countryList, onConfirm }: Props) => {

    const { t } = useTranslation();
    const initialValues = {
        address: deliveryAddress?.address || oldCard?.deliveryAddress?.address || '',
        postalCode: deliveryAddress?.postalCode || oldCard?.deliveryAddress?.zip || '',
        city: deliveryAddress?.city || oldCard?.deliveryAddress?.city || '',
        country: oldCard?.deliveryAddress?.country ?
            {
                alpha3: deliveryAddress?.countryIso3 || oldCard.deliveryAddress.country,
                alpha2: deliveryAddress?.countryIso2 || alpha3ToAlpha2(oldCard.deliveryAddress.country),
                name: deliveryAddress?.countryName || getName(oldCard.deliveryAddress.country, 'en')
            } : '',
    };
    const validationSchema = Yup.object({
        city: Yup.string()
            .required(t('form.validator.required').toString())
            .max(20, t('form.validator.maxChars', { chars: 20, field: t('form.fields.city').toString() }).toString())
            .matches(/^([a-zA-Z-'`\s])+$/, t('form.validator.latinAlpha').toString()),
        address: Yup.string()
            .required(t('form.validator.required').toString())
            .max(95, t('form.validator.maxChars', { chars: 95, field: t('form.fields.address').toString() }).toString())
            .matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin').toString()),
        country: Yup.mixed().required(t("form.validator.required").toString()),
        postalCode: Yup.string()
            .required(t('form.validator.required').toString())
            .max(16, t('form.validator.maxChars', { chars: 16, field: t('form.fields.address') }).toString())
            .matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin').toString())
            .test(
                'postalCode',
                t('form.validator.invalidPostalCode').toString(),
                (value, context) => {
                    const region = context.parent.country?.alpha2;
                    if (value && region !== '' && region) {
                        try {
                            return validatePostalCode(value, region);
                        }
                        catch (e) {
                            return false;
                        }

                    }
                    return true;
                }
            )
    });

    const submit = async (formData: any, formikProps: any) => {

        const { setFieldError } = formikProps;
        if (!formData.country.name || !formData?.country?.alpha2 || !formData?.country?.alpha3) {
            setFieldError('country', t('form.validator.required'));
            return;
        }
        const payload: CardDeliveryAddressType = {
            countryName: formData.country.name,
            countryIso2: formData.country.alpha2,
            countryIso3: formData.country.alpha3,
            city: formData.city,
            address: formData.address,
            postalCode: formData.postalCode
        };
        onConfirm(payload);
    };


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submit}
        >
            {({ isSubmitting, errors, setFieldTouched }) => (
                <Form style={{ width: '100%' }}>
                    <Grid container spacing={1} alignContent='flex-start'>
                        <Grid item xs={12}>
                            <Typography variant='h2' >
                                {`${t('form.fields.card')} ${t('form.fields.deliveryInfo')}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                name='address'
                                label={t('form.fields.address')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}  >
                            <FormTextField
                                name='city'
                                label={t('form.fields.city')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CountryDropdown
                                countryList={countryList}
                                fieldName="country"
                                fieldLabel={t('form.fields.country').toString()}
                                onDropdownChange={() => setTimeout(() => setFieldTouched('postalCode', true))} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextField
                                name='postalCode'
                                label={t('form.fields.postalCode')}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='flex-end' mt='auto'>
                        <Button type='submit'
                            disabled={isSubmitting || !isEmpty(errors)} >
                            {t('form.buttons.next')}
                        </Button>
                    </Grid>
                </Form>)}
        </Formik >);
}


export default CardReplaceDelivery;
