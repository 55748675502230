import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { FeeCategoryCodeType } from '../platforms/platformsTypes';
import { CreatePlatformPlanPayload, EditPlatformPlanPayload, UserLevel, UserLevelTab, UserLevelsStore } from './userLevelsTypes';
import { AppDispatch } from '@/store/store';
import { showErrorNotification } from '@/utils/errors';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';

export const paginationParamsInit = {
    filter: '',
    orderBy: 'name',
    sort: SortDirectionEnum.ASC,
    take: 25,
    skip: 0
};

const initialState: UserLevelsStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    pagination: paginationParamsInit,
    tabs: {
        [UserLevelTab.FEES]: {
            openedSubTab: FeeCategoryCodeType.BANKING,
        },
    },
};

const slice = createSlice({
    name: 'userLevels',
    initialState,
    reducers: {
        setPlans(state, action: PayloadAction<Array<UserLevel>>) {
            state.list = action.payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
            state.pagination = payload;
        },
        setSubTab(state, action: PayloadAction<{ tab: UserLevelTab; subTab: FeeCategoryCodeType }>) {
            state.tabs[action.payload.tab].openedSubTab = action.payload.subTab;
        },
    }
});


export const { setPlans, setLoading, setError, setCount, setPagination, setSubTab } = slice.actions;


export const getPlatfromUserLevels = (payload: PaginateParams, wlpId?: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/user-level/list/all`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take,
                    ...(wlpId ? { wlpId: wlpId } : {})
                }
            });
            const { data } = response;
            dispatch(setPlans(data.userLevels));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const editPlatformPlan = async (userLevelId: number, payload: EditPlatformPlanPayload) => {
    const response = await request.put(`/api/console/user-level/edit/${userLevelId}`, payload);
    const { data } = response;
    return data;
};

export const createPlatformPlan = async (payload: CreatePlatformPlanPayload) => {
    const response = await request.put(`/api/console/user-level/create`, payload);
    const { data } = response;
    return data;
};

export const getWlpUserLevels = async (wlpId?: string): Promise<Array<UserLevel>> => {
    const response = await request.get(`/api/console/user-level/list`, {
        params: {
            ...(wlpId && { wlpId: wlpId }),
        }
    });
    const { data } = response;
    return data;
};

export default slice.reducer;