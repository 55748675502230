import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { FeeCategoryCodeType } from "@/redux/platforms/platformsTypes";
import TabsButton from "@/components/TabsButton";
import { dispatch } from "@/store/store";
import TabButton from "@/components/TabButton";
import { getRegions } from "@/redux/wlpFees/wlpFeesSlice";
import WlpServiceFeesTab from "./WlpServiceFeesTab";
import WlpFeesTab from "./WlpFeesTab";


const WlpFees = () => {
    const { t } = useTranslation();

    const [openedSubTab, setOpenedSubTab] = useState(FeeCategoryCodeType.COMPLIANCE);

    const fetchRegions = useCallback(() => {
        dispatch(getRegions());
    }, [])

    useEffect(() => {
        fetchRegions()
    }, [fetchRegions])

    const tabsOption = [
        {
            value: 0,
            label: t('pages.tabs.compliance'),
            feeType: FeeCategoryCodeType.COMPLIANCE,
        },
        {
            value: 1,
            label: t('pages.tabs.software'),
            feeType: FeeCategoryCodeType.SOFTWARE,
        },
        {
            value: 2,
            label: t('pages.tabs.banking'),
            feeType: FeeCategoryCodeType.BANKING,
        },
        {
            value: 3,
            label: t('pages.tabs.cards'),
            feeType: FeeCategoryCodeType.CARD,
        },
        {
            value: 5,
            label: t('pages.tabs.crypto'),
            feeType: FeeCategoryCodeType.CRYPTO,
        }
    ];

    const riskGroupCodeOptions = ['LOW', 'MEDIUM', 'HIGH'].map(riskGroupCode => ({
        value: riskGroupCode,
        text: t("compliance.individualComplianceOverview.riskGroup." + riskGroupCode),
        enabled: true
    }));

    const userTypesOptions = ['BUSINESS', 'INDIVIDUAL'].map(userType => ({
        value: userType,
        text: t('enum.userType.' + userType),
        enabled: true
    }));

    return (
        <Grid container>
            <Grid item xs={12}>
                <TabsButton
                    value={openedSubTab}
                    onChange={(e, val) => {
                        setOpenedSubTab(val);
                    }}
                    variant="scrollable"
                    scrollButtons="auto">
                    {tabsOption.map((item) => (
                        <TabButton label={item.label} key={item.value} value={item.feeType} />
                    ))}
                </TabsButton>
            </Grid>
            <Grid item xs={12} mt={2}>
                {openedSubTab === FeeCategoryCodeType.SOFTWARE ?
                    <WlpServiceFeesTab
                        key={openedSubTab}
                        feeType={openedSubTab}
                    /> :
                    <WlpFeesTab
                        key={openedSubTab}
                        feeType={openedSubTab}
                        riskGroupCodeOptions={riskGroupCodeOptions}
                        userTypesOptions={userTypesOptions}
                    />}
            </Grid>
        </Grid>
    )
};

export default WlpFees;