import React, { Ref } from 'react';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardProps } from '@mui/material';


interface Props extends CardProps {
    light?: boolean,
    contentPadding?: string
    contentBottomPadding?: string
    flex?: boolean
}
const DetailCard = React.forwardRef(
    (
        {
            children,
            sx = {},
            title,
            light,
            contentPadding,
            contentBottomPadding,
            flex,
            ...others
        }: Props,
        ref: Ref<HTMLDivElement>
    ) => {
        const theme = useTheme();

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    bgcolor: light ? theme.palette.background.paper : theme.palette.transparentBlack,
                    border: light ? `1px solid ${theme.palette.transparentBlack}` : 'unset',
                    ...sx,
                }}
            >
                <CardContent sx={{
                    p: contentPadding ? contentPadding : '25px',
                    pb: contentBottomPadding ? contentBottomPadding : '24px',
                    height: '100%',
                    display: flex ? 'flex' : undefined,
                    flexDirection: flex ? 'column' : undefined
                }}>
                    {children}
                </CardContent>
            </Card>
        );
    }
);

export default DetailCard;
