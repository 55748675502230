import { DataGridProps } from "./dataGridTypes";
import { createSlice } from '@reduxjs/toolkit';


const initialState: DataGridProps = {
    density: 'standard',
};

const dataGrid = createSlice({
    name: 'dataGrid',
    initialState,
    reducers: {
        setDensity(state, action) {
            state.density = action.payload;
        },
    }
});

export default dataGrid.reducer;

export const { setDensity } = dataGrid.actions;