export interface ChartLegendProps {
    key: string,
    color: string
}


export enum TurnoverCategoryEnum {
    ACCOUNT = 'account',
    CRYPTO = 'crypto',
    CARDS = 'cards',
    EARN = 'earn',
}

export enum ChartPeriodEnum {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
    ALL = 'all',
};

export enum KpiCodesEnum {
    TOTAL_IN_ACCOUNTS = 'TOTAL_IN_ACCOUNTS',
    TOTAL_IN_CRYPTO = 'TOTAL_IN_CRYPTO',
    TOTAL_IN_CARDS = 'TOTAL_IN_CARDS',
    VALIDATED_USERS = 'VALIDATED_USERS',
    TOTAL_IN_FEE = 'TOTAL_IN_FEE',
    SUPPORT_TICKETS = 'SUPPORT_TICKETS',
    PENDING_TRANSFERS = 'PENDING_TRANSFERS',
    PENDING_USERS = 'PENDING_USERS',
    SUPPORT_CHATS = 'SUPPORT_CHATS'
};

export enum ChartCategoryEnum {
    ALL = 'ALL',
    STANDARD = 'STANDARD',
    POOLING = 'POOLING',
    CRYPTO = 'CRYPTO'
};

export enum ChartKpiCodeEnum {
    TOTAL_IN_ACCOUNTS = 'TOTAL_IN_ACCOUNTS',
    TOTAL_IN_CARDS = 'TOTAL_IN_CARDS',
    TOTAL_IN_EARN_FIAT = 'TOTAL_IN_EARN_FIAT',
    TRX_AMOUNT_PER_DAY = 'TRX_AMOUNT_PER_DAY',
    TOTAL_TRADES_PER_DAY = 'TOTAL_TRADES_PER_DAY',
    DEBIT_TURNOVER = 'DEBIT_TURNOVER',
    TOTAL_PURCHASE_PER_DAY = 'TOTAL_PURCHASE_PER_DAY',
    TOTAL_IN_CRYPTO = 'TOTAL_IN_CRYPTO',
    USERS_PER_DAY = 'USERS_PER_DAY',
    TOTAL_IN_EARN_CRYPTO = 'TOTAL_IN_EARN_CRYPTO',
}

export interface CharLineType {
    key: string,
    color: string
}


export interface StackedLineChartDataType {
    period: string,
    account: number | null,
    crypto: number | null,
    cards: number | null,
}


export interface PieChartDataType {
    name: string,
    value: number | null,
    color: string
}

export interface CustomBarDataType {
    graphicDate: string,
    amount: number | null
}