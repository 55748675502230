import { AccountTypeEnum } from "../accounts/accountsTypes";

export interface LimitStore {
    loading: boolean,
    userLimits: Array<UserLimitType>,
    error: string | null
}

export interface UserLimitType {
    limitId: number,
    wlpId: string,
    sysCcy: string,
    sysCurrencySymbol: string,
    maxAmountSysCcy: number,
    minAmountSysCcy: number,
    dayLimitSysCcy: number,
    monthLimitSysCcy: number,
    annualLimitSysCcy: number,
    actualDailySysCcy: number,
    actualMonthlySysCcy: number,
    actualAnnualSysCcy: number,
    availableBeforeLimitSysCcy: number,
    actualDailySysCcyString: string,
    actualMonthlySysCcyString: string,
    actualAnnualSysCcyString: string,
    availableBeforeLimitSysCcyString: string,
    ccy: string,
    currencySymbol: string,
    maxAmountCcy: number,
    minAmountCcy: number,
    dayLimitCcy: number,
    monthLimitCcy: number,
    annualLimitCcy: number,
    actualDailyCcy: number,
    actualMonthlyCcy: number,
    actualAnnualCcy: number,
    availableBeforeLimitCcy: number,
    actualDailyCcyString: string,
    actualMonthlyCcyString: string,
    actualAnnualCcyString: string,
    availableBeforeLimitCcyString: string,
    turnoverLimitType: TurnoverLimitType,
    uiCcy?: string,
    uiAvailableBalanceFormatted?: string,
    uiMonthLimitCcyFormatted?: string,
    uiIsLimitSet?: boolean,
}

export interface AccountLimitsDetailsByWlp {
    createdBy: string,
    updatedBy: string,
    entityVersion: number,
    id: number,
    wlpId: string,
    userId: string,
    riskGroupCode: string,
    name: string,
    userLevelId: string,
    integration: string,
    accountType: AccountTypeEnum,
    ccy: string,
    userProfileType: string,
    maxAmount: number,
    minAmount: number,
    dayLimit: number,
    monthlyLimit: number,
    annualLimit: number,
    default: boolean,
    currencySymbol: string,
    bankOrCardType: boolean
}

export enum LimitType {
    AVAILABLE_NOW = 'availableBeforeLimitSysCcy',
    SINGLE = 'maxAmountSysCcy',
    DAILY = 'dayLimitSysCcy',
    MONTHLY = 'monthLimitSysCcy',
    ANNUAL = 'annualLimitSysCcy'
}

export enum TurnoverLimitType {
    DEFAULT = 'DEFAULT',
    USER = 'USER',
    KYC_VERIFIED = 'KYC_VERIFIED',
    RISK_GROUP = 'RISK_GROUP',
    PROFILE_TYPE = 'PROFILE_TYPE',
    USER_LEVEL = 'USER_LEVEL',
    USER_GROUP = 'USER_GROUP'
}

export interface CreateLimitPayloadType {
    maxAmountSysCcy: number,
    dayLimitSysCcy: number,
    monthLimitSysCcy: number,
    annualLimitSysCcy: number,
}
