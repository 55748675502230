import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import request from '../../services/request';
import { LookupValueType } from './fndTypes';
import { FilterOptionType } from '@/components/dataGrid/CustomFilterHeader';

export const currenciesKey = 'currencies';

export const fndDefaultPagination: PaginateParams = {
	filter: '',
	orderBy: 'lookupValuesId',
	sort: SortDirectionEnum.DESC,
	take: 200,
	skip: 0
};

export const getActiveFndLookupsValues = async (lookupType: string, lang?: string) => {
	const response = await request.get(`/api/console/fnd/values/active/${lookupType}/${lang ?? 'EN'}`);
	const { data } = response;
	return data;
};

export const getAllFndLookupsValues = async (lookupType: string, payload: PaginateParams): Promise<Array<LookupValueType>> => {
	const response = await request.get(`/api/console/fnd/values/all/${lookupType}`, {
		params: {
			filter: payload.filter,
			orderBy: payload.orderBy,
			sort: payload.sort,
			skip: payload.skip,
			take: payload.take
		}
	});
	const { data } = response;
	return data;
};

export const fetchCurrencies = async (lookupType: string): Promise<Array<FilterOptionType>> => {
	try {
		const currencies = await getAllFndLookupsValues(lookupType, fndDefaultPagination);
		return currencies.map(c => ({
			value: c.lookupCode,
			text: c.description,
			enabled: true
		}));
	} catch (error) {
		return [];
	}
};

export const getFndLookupValuesByAdmin = async (lookupType: string): Promise<Array<LookupValueType>> => {
	const response = await request.get(`/api/console/fnd/values/all/${lookupType}`);
	const { data } = response;
	return data;
};