import React, { useState } from 'react';
import { Backdrop, Button, CircularProgress, Dialog, DialogContent, FormHelperText, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form, Formik, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import SecurityIcon from '@mui/icons-material/Security';
import { dispatch, useSelector } from '@/store/store';
import { RootState } from '@/store/reducer';
import { next, reject2FA, set2FALoading, set2FAMessage } from '@/redux/twoFa/twoFaSlice';
import FormTextField from './form/FormTextField';
import DialogTitle from './DialogTitle';

const AutoSubmit = () => {
	const { values, submitForm } = useFormikContext<{
		authenticatorCode: string
	}>();
	React.useEffect(() => {
		if (values?.authenticatorCode?.length === 6) {
			submitForm();
		}
	}, [values, submitForm]);
	return null;
};

const TwoFAWeb = (): React.ReactElement => {
	const { t } = useTranslation();
	const [error, setError] = useState<string | null>(null);
	const { open, message, loading, setupMissing } = useSelector(
		(state: RootState) => state.twoFa
	);

	const initialValues = {
		authenticatorCode: ''
	};

	const validationSchema = Yup.object({
		authenticatorCode: Yup.string().matches(/^\d{6}$/, t('form.validator.sixDigitsRequired').toString())
	});

	const confirm = async (formData: any, formikProps: any) => {
		const { setSubmitting, resetForm } = formikProps;
		setError(null);
		try {
			const { authenticatorCode } = formData;
			dispatch(set2FAMessage(null));
			dispatch(set2FALoading(true));
			dispatch(next(authenticatorCode));
		}
		catch (e: any) {
			setSubmitting(false);
			setError(e?.message ?? 'Something went wrong');
		} finally {
			resetForm({});
		}
	};

	const handleCancel = () => {
		dispatch(reject2FA('CANCELLED'));
	};

	return (
		<Dialog open={open} maxWidth='xs' >
			<DialogTitle onClose={handleCancel}>
				{t('form.twofa.header')}
			</DialogTitle>
			<DialogContent >
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={loading}
					onClick={handleCancel}
				>
					<CircularProgress />
				</Backdrop>

				<Grid container
					spacing={2}
					alignItems='center'
					justifyContent='center'
					rowGap={3}
					p='1rem 3rem 2rem 3rem'
				>
					<Grid item xs={12} container justifyContent='center'>
						<SecurityIcon sx={theme => ({ fontSize: '4rem', color: theme.palette.primary.main })} />
					</Grid>


					<Grid item xs={12}>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={confirm}
						>
							{({ errors }) => {
								return (
									<Form style={{ width: '100%' }} >
										<AutoSubmit />
										<Grid item xs={12} container rowGap={1}>
											<Grid item xs={12} mb={4}>
												<Typography textAlign='center'>{t('form.twofa.digitp')}</Typography>
											</Grid>
											{setupMissing ?
												<Grid item xs={12}>
													<Typography textAlign='center'>{t('form.twofa.setupMissing')}</Typography>
												</Grid> :
												<Grid item xs={12}>
													<FormTextField
														name="authenticatorCode"
														fullWidth
														autoFocus={true}
														disabled={loading}
														inputProps={{ autoComplete: 'off' }}
														placeholder={t('form.twofa.placeholder').toString()}
														label={t('form.twofa.label')} />
													<Grid item xs={12} container justifyContent='right' sx={{ p: 0 }}>
														{(isEmpty(errors) || message) &&
															<FormHelperText> {message} </FormHelperText>
														}
														{error && <FormHelperText> {error} </FormHelperText>}
													</Grid>
												</Grid>
											}
										</Grid>
									</Form>);
							}}
						</Formik>
					</Grid>
					<Grid item xs={12}>
						<Button
							fullWidth
							disabled={true}
							onClick={() => confirm}
						>
							{t('form.buttons.confirm')}
						</Button>
					</Grid>
				</Grid >
			</DialogContent>
		</Dialog >
	);
};

export default TwoFAWeb;

