import { AppDispatch } from '@/store/store';
import { ChartPeriodEnum } from '@/types/charts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { StatisticsReponseType, TurnoverChartStore, TuronverDataType } from './turnoverChartTypes';

const initialState: TurnoverChartStore = {
	list: [],
	period: ChartPeriodEnum.MONTH,
	loading: true
};


const slice = createSlice({
	name: 'turnoverChart',
	initialState,
	reducers: {
		setCharData(state, action: PayloadAction<Array<TuronverDataType>>) {
			state.list = action.payload;
		},
		setPeriod: (state, { payload }: PayloadAction<ChartPeriodEnum>) => {
			state.period = payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
	}
});

export const { setCharData, setPeriod, setLoading } = slice.actions;

let turonverController: AbortController;

export const getTuronverData = (period: ChartPeriodEnum) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));

			if (turonverController) {
				turonverController.abort('Cancel previous api call');
			}
			turonverController = new AbortController();
			const response = await request.get(`/api/console/dashboard/turnover/${period}`, { signal: turonverController.signal });

			if (response?.data) {
				dispatch(setCharData(response.data));
			}

			dispatch(setLoading(false));

		} catch (e: any) {
			if (e?.name && e.name !== 'CanceledError') {
				dispatch(setLoading(false));
			}

			dispatch(setCharData([]));
		}
	};
};

export const getStatisticsData = async (): Promise<Array<StatisticsReponseType>> => {
	const response = await request.get('/api/console/dashboard/statistics');
	const { data } = response;
	return data;

};


export default slice.reducer;
