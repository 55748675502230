import { PaginateParams, SortDirectionEnum } from "@/types/pagination";
import { EmiInvoice, EmiPayout, PayoutWithdrawal, WithdrawalsSummary } from "../emiBilling/emiBillingTypes";

export const invoicesPaginationParamsInit = {
    filter: 'invoiceCategory=INVOICE',
    orderBy: 'invoiceNumber',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

export const payoutsPaginationParamsInit = {
    filter: 'invoiceCategory=PAYOUT',
    orderBy: 'invoiceDate',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

export interface PlatformInvoicesAndPayoutsStore {
    invoicesSummary: {
        data: InvoicesSummary | null,
        loading: boolean
    },
    invoices: {
        loading: boolean,
        list: Array<EmiInvoice>,
        error: string | null,
        count: number,
        pagination: PaginateParams
    },
    payoutsSummary: {
        data: PayoutsSummary | null,
        loading: boolean
    },
    payouts: {
        loading: boolean,
        list: Array<EmiPayout>,
        error: string | null,
        count: number,
        pagination: PaginateParams
    },
    withdrawals: {
        loading: boolean,
        list: Array<PayoutWithdrawal>,
        error: string | null,
        count: number,
        pagination: PaginateParams
    },
    withdrawalsSummary: {
        data: WithdrawalsSummary | null,
        loading: boolean
    },
};

export interface InvoicesSummary {
    totalEarnings: number;
    unpaidInvoicesAmount: number;
    amountToBeInvoiced: number;
};

export interface PayoutsSummary {
    totalFeePayouts: number;
    unpaidInvoicesAmount: number;
    unpaidPayoutsCount: number;
    amountPendingPayouts: number;
};

export interface PaymentConfig {
    id: number;
    partyId: number;
    disabled: boolean;
    isDefault: boolean;
    activeFrom: string;
    activeTo: string;
    paymentType: string;
    paymentAccountId: number;
    currencyCode: string;
    availableBalance: number;
    bankName: string;
    bankCountry: string;
    bankAddress: string;
    swiftCode: string;
    beneficiaryName: string;
    beneficiaryIdNumber: string;
    beneficiaryType: BeneficiaryType;
    beneficiaryAddress: string;
    iban: string;
    accountNumber: string;
    sortCode: string;
    beneficiaryAccountId: number;
    cryptoAddress: string;
    cryptoNetwork: string;
    paymentDescription: string;
    isUsed: boolean;
    isHidden: boolean;
    paymentWalletId: string;
};

export enum CreateConfigType {
    FIAT = 'FIAT',
    CRYPTO = 'CRYPTO'
};

export enum BeneficiaryType {
    INDIVIDUAL = 'INDIVIDUAL',
    BUSINESS = 'BUSINESS'
};

export interface BenificiaryIbanData {
    bic: string;
    bank: string;
    address: string;
    country: string;
    iban: string;
    valid: boolean;
};

export interface PaymentConfigPayload {
    beneficiaryType: BeneficiaryType;
    partyId: number;
    payerWalletId: string;
    bankName: string;
    bankCountry: string;
    bankAddress: string;
    swiftCode: string;
    beneficiaryName: string;
    registrationNumber: string;
    iban: string;
    sortCode: string;
    accountNumber: string;
    cryptoAddress?: string;
    cryptoNetwork?: string;
    description: string;
    wlpId?: string;
};

export interface EditPaymentConfigPayload {
    beneficiaryType: BeneficiaryType;
    configId: number;
    bankName: string;
    bankCountry: string;
    bankAddress: string;
    swiftCode: string;
    beneficiaryName: string;
    beneficiaryAddress: string;
    registrationNumber: string;
    iban: string;
    sortCode: string;
    accountNumber: string;
    cryptoAddress: string;
    cryptoNetwork: string;
    description: string;
    hidden: boolean;
};