import { BalanceType, OverviewSubTabsReponse } from "../emiFinance/emiFinanceTypes"

export interface EmiFinanceEarnStore {
    totalVaultsAccount: Array<BalanceType>,
    totalVaults: Array<BalanceType>,
    deposits: Array<BalanceType>,
    totalLiabilities: Array<BalanceType>,
    interestPayments: Array<EarnPaymentsDetailsList>,
    depositRepaymnets: Array<EarnPaymentsDetailsList>,
    vaults: {
        count: number,
        list: Array<VaultDetails>,
        error: string | null,
    },
    vaultDeposits: {
        count: number,
        list: Array<VaultDeposit>,
        loading: boolean,
        error: string | null,
    }
    liabilities: {
        list: {
            [key: string]: Array<EarnPaymentsDetails>,
        }
        loading: boolean,
        loadingError: boolean
    },
    tabs: Array<OverviewSubTabsReponse>,
    activeTab: string,
    loading: boolean
}

export enum EarnPaymentsEnum {
    DEPOSIT_REPAYMENT = "DEPOSIT_REPAYMENT",
    INTEREST_PAYMENT = "INTEREST_PAYMENT",
    VAULT_DEPOSIT = "VAULT_DEPOSIT",
    VAULT_AUTO_EXTEND = "VAULT_AUTO_EXTEND",
    VAULT_EXTEND = "VAULT_EXTEND"
};

export interface VaultDetails {
    proc: string;
    serviceOwner: string;
    wlpId: string;
    vaultName: string;
    vaultDescription: string;
    yearlyInterestRate: number;
    depositPeriodMonths: number;
    ccy: string;
    vaultCount: number;
    vaultHolding: number;
    vaultDepositPaid: number;
    vaultInterestPaid: number;
    vaultDepositLiabilities: number;
    vaultInterestLiabilities: number;
    vaultDepositAutoReturns: number;
    vaultAccountBalance: number;
}

export interface VaultDeposit {
    vaultId: number;
    accountId: number;
    vaultName: string;
    userId: number;
    email: string;
    userName: string;
    clientId: string;
    walletId: string;
    ccy: string;
    accountStatus: string;
    interestRate: number;
    interestPaymentPeriod: string;
    depositPeriod: number;
    autoExtendOffRequestTime: string;
    autoExtendCount: number;
    autoExtendStatus: string;
    createdAt: string;
    dateOfDepositReturn: string;
    vaultHolding: number;
    vaultDepositPaid: number;
    vaultInterestPaid: number;
    vaultDepositLiabilities: number;
    vaultInterestLiabilities: number;
    vaultDepositAutoReturns: number;
}

export interface EarnPaymentsPerPeriod {
    availableAmount: number;
    availableAmountEUR: number;
    currencyCode: string;
    currencyLogo: string;
    emi: string;
    emiWlpId: string;
    id: number;
    paymentAmount: number;
    paymentAmountEur: number;
    periodCode: string;
    sysAcctType: string;
    totalAvailableAmount: number;
    totalAvailableAmountEur: number;
    totalPaymentAmount: number;
    totalPaymentAmountEur: number;
    transactionType: string;
    vaultPlan: string;
    vaultProc: string;
    wlpId: string;
}

export interface EarnPaymentsDetails {
    id: number;
    emi: string;
    emiWlpId: string;
    wlpId: string;
    vaultProc: string;
    vaultPlan: string;
    transactionType: EarnPaymentsEnum;
    sysAcctType: string;
    periodCode: string;
    currencyCode: string;
    paymentAmount: number;
    availableAmount: number;
    paymentAmountEur: number;
    availableAmountEUR: number;
    totalPaymentAmount: number;
    totalAvailableAmount: number;
    totalPaymentAmountEur: number;
    totalAvailableAmountEur: number;
    detailList: Array<EarnPaymentsDetailsList>;
    currencyLogo: string;
}

export interface EarnPaymentsDetailsList {
    id: number;
    emi: string;
    emiWlpId: string;
    wlpId: string;
    vaultProc: string;
    vaultPlan: string;
    transactionType: EarnPaymentsEnum;
    sysAcctType: string;
    periodCode: string;
    currencyCode: string;
    currencyLogo: string;
    paymentAmount: number;
    availableAmount: number;
    paymentAmountEur: number;
    availableAmountEUR: number;
    totalPaymentAmount: number;
    totalAvailableAmount: number;
    totalPaymentAmountEur: number;
    totalAvailableAmountEur: number;
}