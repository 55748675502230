import { AppDispatch } from "@/store/store";
import { BalanceType, OverviewSubTabsReponse, SubTabsEnum } from "../emiFinance/emiFinanceTypes";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import request from "@/services/request";
import { PaginateParams, SortDirectionEnum, defaultPagination } from "@/types/pagination";
import { EarnPaymentsDetails, EarnPaymentsDetailsList, EarnPaymentsEnum, EmiFinanceEarnStore, VaultDeposit, VaultDetails } from "./emiFinanceEarnTypes";

export const paginationParamsInit = defaultPagination;

export const vaultsDefaultPagination: PaginateParams = {
    filter: '',
    orderBy: '',
    sort: SortDirectionEnum.DESC,
    take: 15,
    skip: 0
}

const initialState: EmiFinanceEarnStore = {
    totalVaultsAccount: [],
    totalVaults: [],
    deposits: [],
    totalLiabilities: [],
    interestPayments: [],
    depositRepaymnets: [],
    vaults: {
        count: 0,
        list: [],
        error: null,
    },
    vaultDeposits: {
        count: 0,
        list: [],
        loading: false,
        error: null,
    },
    liabilities: {
        list: {
            [EarnPaymentsEnum.INTEREST_PAYMENT]: [],
            [EarnPaymentsEnum.DEPOSIT_REPAYMENT]: []
        },
        loading: false,
        loadingError: false
    },
    tabs: [],
    activeTab: SubTabsEnum.OVERVIEW,
    loading: false
};

const slice = createSlice({
    name: 'emiFinanceEarn',
    initialState,
    reducers: {
        setSysVaultsAccounts(state, action: PayloadAction<Array<BalanceType>>) {
            state.totalVaultsAccount = action.payload;
        },
        setTotalVaults(state, action: PayloadAction<Array<BalanceType>>) {
            state.totalVaults = action.payload;
        },
        setEarnTotalLiabilities(state, action: PayloadAction<Array<BalanceType>>) {
            state.totalLiabilities = action.payload;
        },
        setEarnInterestPaymentsTotal(state, action: PayloadAction<Array<EarnPaymentsDetailsList>>) {
            state.interestPayments = action.payload;
        },
        setEarnDepositRepaymnetsTotal(state, action: PayloadAction<Array<EarnPaymentsDetailsList>>) {
            state.depositRepaymnets = action.payload;
        },
        setEarnTabs(state, action: PayloadAction<Array<OverviewSubTabsReponse>>) {
            state.tabs = action.payload;
        },
        setActiveTab(state, action: PayloadAction<string>) {
            state.activeTab = action.payload;
        },
        setLiabilities(state, action: PayloadAction<{ transactionType: EarnPaymentsEnum, list: Array<EarnPaymentsDetails> }>) {
            state.liabilities.list[action.payload.transactionType] = action.payload.list;
        },
        setLiabilitiesLoading(state, action: PayloadAction<boolean>) {
            state.liabilities.loading = action.payload;
        },
        setLiabilitiesLoadingError(state, action: PayloadAction<boolean>) {
            state.liabilities.loadingError = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setVaults(state, action: PayloadAction<Array<VaultDetails>>) {
            state.vaults.list = action.payload;
        },
        setVaultsCount(state, action: PayloadAction<number>) {
            state.vaults.count = action.payload;
        },
        setVaultsDeposits(state, action: PayloadAction<Array<VaultDeposit>>) {
            state.vaultDeposits.list = action.payload;
        },
        setVaultsDepositsCount(state, action: PayloadAction<number>) {
            state.vaultDeposits.count = action.payload;
        },
        setVaultsDepositsLoading(state, action: PayloadAction<boolean>) {
            state.vaultDeposits.loading = action.payload;
        }
    }
});


export const {
    setSysVaultsAccounts,
    setTotalVaults,
    setEarnTotalLiabilities,
    setEarnInterestPaymentsTotal,
    setEarnDepositRepaymnetsTotal,
    setEarnTabs,
    setActiveTab,
    setLiabilities,
    setLiabilitiesLoading,
    setLiabilitiesLoadingError,
    setLoading,
    setVaults,
    setVaultsCount,
    setVaultsDeposits,
    setVaultsDepositsCount,
    setVaultsDepositsLoading } = slice.actions;

export default slice.reducer;

export const getEarnTotalSysVaultAccounts = () => {
    return async (dispatch: AppDispatch) => {
        try {

            dispatch(setLoading(true));
            const response = await request.get(`/api/console/emi-finance/earn/total/accounts`);
            const data = response?.data || [];
            dispatch(setTotalVaults(data));
        }
        catch (e) {
            dispatch(setTotalVaults([]));
        }
        finally {
            dispatch(setLoading(false));
        }
    }
}

export const getEarnTotalVaults = () => {
    return async (dispatch: AppDispatch) => {
        try {

            dispatch(setLoading(true));
            const response = await request.get(`/api/console/emi-finance/earn/total/vaults`);
            const data = response?.data || [];
            dispatch(setTotalVaults(data));
        }
        catch (e) {
            dispatch(setTotalVaults([]));
        }
        finally {
            dispatch(setLoading(false));
        }
    }
}

export const getEarnTotalLiabilities = () => {
    return async (dispatch: AppDispatch) => {
        try {

            dispatch(setLoading(true));
            const response = await request.get(`/api/console/emi-finance/earn/total/liabilities`);
            const data = response?.data || [];
            dispatch(setEarnTotalLiabilities(data));
        }
        catch (e) {
            dispatch(setEarnTotalLiabilities([]));
        }
        finally {
            dispatch(setLoading(false));
        }
    }
}

export const getEarnTotalPaymentsTotal = (transactionType: string, periodCode: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/emi-finance/earn/total/payments/period/${transactionType}/${periodCode}`);
            const data = response?.data || [];
            if (transactionType === EarnPaymentsEnum.INTEREST_PAYMENT) {
                dispatch(setEarnInterestPaymentsTotal(data));
            } else if (transactionType === EarnPaymentsEnum.DEPOSIT_REPAYMENT) {
                dispatch(setEarnDepositRepaymnetsTotal(data));
            }
        }
        catch (e) {
            if (transactionType === EarnPaymentsEnum.INTEREST_PAYMENT) {
                dispatch(setEarnInterestPaymentsTotal([]));
            } else if (transactionType === EarnPaymentsEnum.DEPOSIT_REPAYMENT) {
                dispatch(setEarnDepositRepaymnetsTotal([]));
            }
        }
        finally {
            dispatch(setLoading(false));
        }
    }
}

export const getEarnTotalPaymentsByWlp = async (transactionType: string, periodCode: string) => {
    const response = await request.get(`/api/console/emi-finance/earn/total/payments/wlp/${transactionType}/${periodCode}`);
    const data = response?.data || [];
    return data
}

export const getEarnTotalPaymentsPerCcy = (transactionType: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLiabilitiesLoading(true));
            const response = await request.get(`/api/console/emi-finance/earn/total/payments/ccy/${transactionType}`);
            const data = response?.data || [];
            if (transactionType === EarnPaymentsEnum.INTEREST_PAYMENT) {
                dispatch(setLiabilities({ transactionType, list: data }));
            } else if (transactionType === EarnPaymentsEnum.DEPOSIT_REPAYMENT) {
                dispatch(setLiabilities({ transactionType, list: data }));
            }
        }
        catch (e) {
            setLiabilitiesLoadingError(true);
            if (transactionType === EarnPaymentsEnum.INTEREST_PAYMENT) {
                dispatch(setLiabilities({ transactionType, list: [] }));
            } else if (transactionType === EarnPaymentsEnum.DEPOSIT_REPAYMENT) {
                dispatch(setLiabilities({ transactionType, list: [] }));
            }
        }
        finally {
            dispatch(setLiabilitiesLoading(false));
        }
    }
}

export const getEarnVaultsList = (payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/emi-finance/earn/vaults/list`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setVaults(data.list));
            dispatch(setVaultsCount(data.count));
        }
        catch (e) {
            dispatch(setVaults([]));
        }
        finally {
            dispatch(setLoading(false));
        }
    }
};

export const getVaultDetails = (proc: string, params: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setVaultsDepositsLoading(true));
            const response = await request.get(`/api/console/emi-finance/earn/vault/details/${proc}`, {
                params: {
                    filter: params.filter,
                    orderBy: params.orderBy,
                    sort: params.sort.toUpperCase(),
                    skip: params.skip.toString(),
                    take: params.take,
                }
            });
            const { data } = response;
            dispatch(setVaultsDeposits(data.list));
            dispatch(setVaultsDepositsCount(data.count));
        }
        catch (e) {
            dispatch(setVaultsDeposits([]));
        }
        finally {
            dispatch(setVaultsDepositsLoading(false));
        }
    }
};