import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
    error?: boolean,
    text?: string;
    onReload?: () => void
}
const NoRowsOverlay = ({ error, text, onReload }: Props) => {

    const { t } = useTranslation('translations');

    return (
        <Grid
            container
            item
            xs={12}
            direction="column"
            alignItems='center'
            justifyContent='center'
            rowGap={1}
            style={{ height: '110px' }}>
            <Grid item>
                {t(error ? 'errors.somethingWentWrong' : (!text ? 'form.fields.noRows' : text))}
            </Grid>
            {error && onReload && (
                <Grid item>
                    <Button
                        onClick={onReload}
                        color="secondary"
                        variant="outlined">
                        {t('form.buttons.reload')}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export default NoRowsOverlay;
