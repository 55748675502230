import { PaginateParams } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { Button, Grid } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { sortBy } from 'lodash';
import { FilterParam, SelectedFilterParam } from '@/types/filterParam';
import SearchInput from './components/SearchInput';
import AvailableFilters from './components/AvailableFilters';


interface Props {
	allFilterFields: FilterParam[],
	paginateInitialState: PaginateParams,
	pagination: PaginateParams,
	withRefreshBtn?: boolean,
	handleRefreshButton?: any,
	withAnyFilter?: boolean,
	onSearch: (pagination: PaginateParams) => void,
}

export const transformFilterToObject = (filter: string) => {
	if (filter) {
		return filter.split(';').reduce(
			(obj, str) => {
				const parts = str.split('=');
				return { ...obj, [parts[0]]: parts[1] };
			}, {});
	} else {
		return {};
	}
};


interface FilterItem {
	field: string,
	value: string
}
export const transformFilterToArray = (filter: string): FilterItem[] | [] => {
	if (filter) {

		return filter.split(';').reduce(
			(obj: any, str) => {
				const parts = str.split('=');
				return ([...obj, { field: parts[0], value: parts[1] } as FilterItem]);
			}, [] as FilterItem[]);
	} else {
		return [];
	}
};



const getValue = (obj: any, key: string) => {

	if (obj[key]) {
		return obj[key]
	}
	else
		return '';

}


export const getDefaultFilterUpdates = (defaultFilter: string, newFilter: string) => {

	let filter: string;
	if (defaultFilter !== null && defaultFilter !== '') {
		const defaultFilterKeys = Object.keys(transformFilterToObject(defaultFilter)) || [];
		const defaultFilterObj = transformFilterToObject(defaultFilter);
		const newFilterObj = transformFilterToObject(newFilter);
		const newFilterKeys = Object.keys(newFilterObj) || [];
		// if  default filter includes any of new keys, update default filter
		const updateDefaults = defaultFilterKeys.some(p => {
			return newFilterKeys.includes(p);
		});
		if (updateDefaults) {
			filter = newFilterKeys.map(p => `${p}=${newFilterKeys.includes(p) ? getValue(newFilterObj, p) : getValue(defaultFilterObj, p)}`).join(';');
		}
		else {
			//add new filter key to default filter
			filter = defaultFilter + ';' + newFilter;
		}

	}
	else {
		filter = newFilter;
	}

	return filter;

};


const removeDefaultFilters = (pagination: PaginateParams, paginateInitialState: PaginateParams, filterFields: FilterParam[]) => {
	const initFiltersKey = transformFilterToArray(paginateInitialState.filter);
	const currentFiltersKey = transformFilterToArray(pagination.filter);
	const filtersKeysToShow = currentFiltersKey.filter(p => !initFiltersKey.some(init => init.field === p.field));
	const activeFilter = filterFields.filter(f => filtersKeysToShow.some(key => key.field === f.field)) || [];
	const filter = activeFilter.map(p => { return { ...p, value: currentFiltersKey.find(k => k.field === p.field)?.value ?? '' } });
	return filter;
}

const initialAnyFilter: SelectedFilterParam = {
	field: "any",
	label: "Any",
	type: "input"
}

const SearchAdvanced = ({ allFilterFields, pagination, paginateInitialState, withRefreshBtn, handleRefreshButton, withAnyFilter = false, onSearch }: Props): React.ReactElement => {

	const filterFields = sortBy(allFilterFields, 'field')

	const initFilters = removeDefaultFilters(pagination, paginateInitialState, filterFields);
	const [anyFilter, setAnyFilter] = useState<SelectedFilterParam>(initialAnyFilter);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [selectedFilters, setSelectedFilters] = useState<Array<SelectedFilterParam>>(initFilters);
	const [activeFilter, setActiveFilter] = useState<SelectedFilterParam | null>(withAnyFilter ? anyFilter : null);

	const availableFilterFields = filterFields.filter(f => f.field !== activeFilter?.field && !selectedFilters.some(s => s.field === f.field));

	const setActiveFilterValue = (value: string) => {
		if (activeFilter && activeFilter?.type === 'input') {
			setActiveFilter(prev => prev ? { ...prev, value: value } : null);
		} else if (activeFilter && activeFilter?.type === 'select') {
			setSelectedFilters(prev => [...prev, { ...activeFilter, value: value }]);
			setActiveFilter(null);
		}
	}

	const setAnyFilterValue = (value: string) => {
		setAnyFilter({ ...anyFilter, value: value });
		setActiveFilter({ ...anyFilter, value: value });
	}

	const setAnySelectedFilterValue = (value: string) => {
		setSelectedFilters(prev => {
			return [{ ...prev[0], value: value }];
		});
	}

	const addFilter = (newFilter: SelectedFilterParam | null) => {
		if (!withAnyFilter && activeFilter && activeFilter?.value) {
			setSelectedFilters(prev => [...prev, activeFilter]);
		}
		else if (withAnyFilter) {
			setSelectedFilters(prev => {
				const updatedFilters = prev.filter(filter => filter.field !== 'any');
				return [...updatedFilters];
			});
		}
		setActiveFilter(newFilter);
	}

	const handleRemoveFilter = (removeItem: SelectedFilterParam | null) => {
		if (removeItem === null) return;
		if (activeFilter?.field === removeItem.field) {
			setActiveFilter(null);
		}
		setSelectedFilters(prev => prev.filter(p => p.field !== removeItem.field));
	}

	const handleRemoveSelectedFilter = (removeItem: SelectedFilterParam | null) => {
		if (removeItem === null) return;
		const updatedFilters = selectedFilters.filter(filter => filter.field !== removeItem.field);
		setSelectedFilters(updatedFilters);
		handleSubmit(updatedFilters);
	}

	const handleSearch = (filterString: string | null, clear: boolean) => {
		if (withAnyFilter && selectedFilters.length === 1 && selectedFilters[0].field === 'any' && selectedFilters[0].value === '') {
			setSelectedFilters(initFilters);
		}
		if (clear) {
			const paginationState = { ...paginateInitialState };
			onSearch(paginationState);
			return;
		}
		if (filterString !== null) {
			const paginationState = { ...pagination };
			const filter = getDefaultFilterUpdates(paginateInitialState.filter, filterString);
			paginationState.filter = filter;
			onSearch(paginationState);
		}

	};

	const getFilterString = (filters: Array<SelectedFilterParam>) => {
		let filterString = '';
		filters.map(item => {
			if (item?.value && item.value.toString().trim() !== '') {
				if (filterString !== '') {
					filterString += ';';
				}
				filterString = filterString.concat(`${item.field}=${item.value.toString().trim()}`);
			}
			return '';
		});
		return filterString;
	}

	const handleSubmit = async (selectedFilters: SelectedFilterParam[]) => {
		try {
			setIsSubmitting(true);

			let filters;

			if (!activeFilter) {
				filters = [...selectedFilters];
			} else {
				filters = [...selectedFilters, { ...activeFilter }];
				if (activeFilter && activeFilter.value) {
					activeFilter && setSelectedFilters(prev => [...prev, activeFilter]);
					setActiveFilter(null);
				}
			}

			const filterString = getFilterString(filters);

			if (filterString !== '') {
				handleSearch(filterString, false);
			} else {
				handleSearch(null, true);
			}

		} catch (err) {
			console.log(err);
			showErrorNotification(err);
		}
		finally { setIsSubmitting(false); }
	};

	return (
		<Grid container item xs={12}>

			<Grid item container>
				<Grid item>
					<SearchInput
						filter={(selectedFilters.length === 0 && withAnyFilter && !activeFilter) ? anyFilter : activeFilter}
						filterValue={activeFilter?.value ?? ''}
						onFilterValueChange={setActiveFilterValue}
						onAnyFilterValueChange={setAnyFilterValue}
						onAnySelectedValueChange={setAnySelectedFilterValue}
						onDelete={handleRemoveFilter}
						onDeleteSelected={handleRemoveSelectedFilter}
						onSearch={() => handleSubmit(selectedFilters)}
						selectedFilters={selectedFilters}
						withAnyFilter={withAnyFilter}
					/>
				</Grid>
				<Grid item ml={2}>
					<Button
						size="large"
						onClick={() => handleSubmit(selectedFilters)}
						disabled={isSubmitting}
					>
						{t('form.buttons.search')}
					</Button>
				</Grid>
			</Grid>

			<Grid container justifyContent='space-between'>
				<Grid item>
					<AvailableFilters addFilter={addFilter} availableFilters={availableFilterFields} />
				</Grid>
				<Grid item alignSelf='end'>
					{withRefreshBtn && filterFields &&
						<Grid item >
							<Button
								onClick={handleRefreshButton}
								size="large"
								color="secondary" >
								{t('form.buttons.refresh')}
							</Button>
						</Grid>
					}
				</Grid>
			</Grid>

		</Grid >
	);

};

export default SearchAdvanced;
