import * as React from "react";
import { SvgIcon } from '@mui/material';
const ArrowRight1 = (props: any) => (
    <SvgIcon {...props}>
        <svg
            width={16}
            height={16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path d="M3.625 8H12.375M12.375 8L8.625 4.25M12.375 8L8.625 11.75" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);
export default ArrowRight1;
