import { FC } from 'react';
import { Button, ButtonProps } from '@mui/material';
import DownloadExport from '@/icons/DownloadExport';


const DownloadButton: FC<ButtonProps> = (props: ButtonProps) => {

	return (
		<Button
			sx={{
				px: 0,
				minWidth: '36px',
				width: '36px'
			}}
			color="secondary"
			variant="outlined"
			{...props}>
			<DownloadExport sx={{ fontSize: '19px' }} />
		</Button>
	);
};

export default DownloadButton;
