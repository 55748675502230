import { Grid, useTheme } from "@mui/material";
import AmountValueCell from "./AmountValueCell";

export interface AmountBalanceCellProps {
    value: number;
    lowerValue?: number;
    currency: string;
    lowerValueCurrency?: string;
    withDash?: boolean;
    showLowerWithSameCcy?: boolean;
    sameColor?: boolean;
    upperValueColor?: string;
    lowerValueColor?: string;
    upperValuePositiveColor?: string;
    upperValueFontWeight?: number;
    lowerValueFontWeight?: number;
    withMinusSign?: boolean;
    withLogo?: boolean;
    decimalScale?: number;
}

const AmountWithLowerValueCell = ({
    value,
    lowerValue,
    currency,
    lowerValueCurrency,
    withDash,
    showLowerWithSameCcy = true,
    sameColor = true, upperValueColor,
    lowerValueColor,
    upperValuePositiveColor = 'blue',
    withMinusSign,
    withLogo,
    decimalScale,
    upperValueFontWeight = 500,
    lowerValueFontWeight = 400
}: AmountBalanceCellProps) => {
    const theme = useTheme();

    const isNegative = value < 0;
    const color = isNegative ? theme.palette.error.main : upperValuePositiveColor;

    return (
        <Grid container>
            <Grid container item xs={12} alignItems='center' flexWrap='nowrap'>
                <AmountValueCell
                    value={value}
                    currency={currency}
                    withColor={true}
                    fontWeight={upperValueFontWeight}
                    color={upperValueColor ? upperValueColor : color}
                    withDash={withDash}
                    withMinusSign={withMinusSign}
                    withLogo={withLogo}
                    decimalScale={decimalScale} />
            </Grid>
            {
                lowerValue !== undefined && lowerValue !== null && lowerValue !== 0 &&
                lowerValueCurrency &&
                (showLowerWithSameCcy || lowerValueCurrency !== currency) &&
                <Grid container item xs={12} alignItems='center' flexWrap='nowrap'>
                    <AmountValueCell
                        value={lowerValue}
                        currency={lowerValueCurrency}
                        fontWeight={lowerValueFontWeight}
                        withColor={true}
                        color={lowerValueColor ? lowerValueColor : (sameColor ? color : (!isNegative ? '#8A9BB1' : "#c59595"))}
                        withDash={withDash}
                        withMinusSign={withMinusSign}
                        withLogo={withLogo}
                        decimalScale={decimalScale} />
                </Grid>
            }
        </Grid >
    );
}

export default AmountWithLowerValueCell;