import { TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useField } from 'formik';

type Props = { name: string } & TextFieldProps;

const StyledTextField = styled(TextField)(({ theme }) => ({
	'& .MuiInputBase-input.Mui-disabled': {
		opacity: 1,
		WebkitTextFillColor: theme.palette.primary.main,
	},
}));

const FormTextField = ({ name, ...otherProps }: Props) => {
	const [field, meta] = useField(name);

	const configTextField: TextFieldProps = {
		...field,
		...otherProps,
	};

	if (meta && meta.touched && meta.error) {
		configTextField.error = true;
		configTextField.helperText = meta.error;
	}

	return <StyledTextField {...configTextField} />;
};

export default FormTextField;